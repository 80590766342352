import { css } from 'styled-components'

const NumberInputReset = css`
	::-webkit-inner-spin-button,
	::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	-moz-appearance: textfield;
`

export default NumberInputReset
