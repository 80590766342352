import styled from 'styled-components'
import { COLORS } from '@/globals/global.styles'

export const PageFooterStyled = styled.div<{ showSocialMedia: boolean }>`
	display: flex;
	align-content: center;
	flex-direction: column;
	width: 100%;

	${({ showSocialMedia }) => (showSocialMedia ? `
		height: 170px;
	` : `
		height: 120px;
	`)}

	margin-top: -16px;
	background-color: ${COLORS.background.secondary};
`

export const Divider = styled.div`
	width: 90%;
	border: 1px solid ${COLORS.border.minor};
	margin: 10px auto;
`

export const MessageWrapper = styled.div`
	display: flex;
	margin-top: 15px;
`

export const FooterIcon = styled.img`
	width: 83px;
	margin-left: 20px;
	margin-right: 20px;
`

export const FooterMessageWrapper = styled.div`
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`

export const FooterMessage = styled.span`
	font-weight: 500;
	font-size: 14px;
	line-height: 1.5em;
`

export const Link = styled.a`
	font-weight: 500;
	font-size: 14px;
	line-height: 1.5em;
	color: ${COLORS.text.link};
	text-decoration: none;
`

export const FooterSocialMediaWrapper = styled.div`
	display: flex;
	height: 56px;
	align-items: center;
	margin-left: 20px;
	margin-right: 20px;
`

export const FooterSocialMediaFollowUs = styled.span`
	font-weight: 500;
	font-size: 14px;
	margin-right: 30px;
`

export const FooterSocialMediaIconsWrapper = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-content: center;
	max-width: 230px;
	width: 100%;
`
