import React, { useContext } from 'react'
import styled from 'styled-components'
import { Markup } from 'interweave'
import productSauna from 'Assets/sauna-kit/sauna.webp'
import SingleProductWrapper from 'Views/check-in-views/products-view/single-product-wrapper.component'
import HypeLines from 'Components/ui/hype-lines.component'
import { Section } from 'Components/view-structure.component'
import { COLORS } from 'Globals/global.styles'
import { TranslationContext } from 'Src/context'
import i18next from 'i18next'

function getGuideLines() {
	return [
		i18next.t('2x Lumene face mask'),
		i18next.t('2x Villi Vichy Vahva water 0.5l'),
		i18next.t('2x Rhubarb Sour Lemonade 0.33l'),
		i18next.t('2x Cleansing sponges'),
		i18next.t('2x Extra towels'),
		i18next.t('2x Bathrobes'),
		i18next.t('1x Unique Osmia pine sauna scent'),
	]
}

const SectionStyled = styled(Section)`
	margin-bottom: 48px;
`

const TextContent = styled.div`
	font-size: 14px;
	color: ${COLORS.text.secondary};
	margin-bottom: 10px;
	margin-top: -15px;
`

const SaunaViewComponent = () => {
	const translation = useContext(TranslationContext)
	const guideLines = getGuideLines()

	return (
		<SingleProductWrapper
			title={translation.t('Sauna kit')}
			description={translation.t('Get everything you need to sauna like a Finn. Create the full experience with a little aromatherapy, self care and some traditional drinks. Your kit will be waiting in the apartment when you arrive.')}
			image={productSauna}
			buttonText={translation.t('Add Sauna Kit')}
		>
			<TextContent>
				<Markup
					noWrap
					allowList={['b']}
					content={translation.t('Kit for <b>2 people</b> includes')}
				/>
			</TextContent>
			<SectionStyled>
				<HypeLines lines={guideLines} />
			</SectionStyled>
		</SingleProductWrapper>
	)
}

export default SaunaViewComponent
