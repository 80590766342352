import logger from '../logger/logger'
import { ApiPatchError, CommunesApi } from 'Types/api.types'
import { getCoreApiBaseUrl } from '@/utils/env'

const apiHost = getCoreApiBaseUrl()

async function getItalianProvinces(search: string): Promise<CommunesApi | ApiPatchError> {
	const url = new URL(`${apiHost}/italian-communes`)
	url.searchParams.append('search', search)

	try {
		const response = await fetch(url, { method: 'GET' })
		return await response.json()
	} catch (error) {
		logger.error('Could not fetch Italian communes data', error)
		throw error
	}
}

export default getItalianProvinces
