import OthersActionTypes from './others.types'

/**
 * TRAVEL REASONS
 */

const updateTravelReasons = (travelReasons) => (dispatch) => {
	dispatch({
		type: OthersActionTypes.UPDATE_TRAVEL_REASONS,
		payload: travelReasons,
	})
}

export {
	updateTravelReasons,
}
