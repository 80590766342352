import { createReducer } from '@reduxjs/toolkit'
import { hideEmailVerificationDialog, showEmailVerificationDialog } from './ui.actions'

export interface UiState {
	isVerificationDialogOpened: boolean;
}

const defaultState : UiState = {
	isVerificationDialogOpened: false,
}

const uiReducer = createReducer(defaultState, (builder) => {
	builder.addCase(showEmailVerificationDialog, (state) => {
		state.isVerificationDialogOpened = true
	})

	builder.addCase(hideEmailVerificationDialog, (state) => {
		state.isVerificationDialogOpened = false
	})
})

export default uiReducer
