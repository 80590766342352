import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { format, parseISO } from 'date-fns'

import productMealkitMain from 'Assets/products/product-mealkit.webp'
import productMealKitDishes from 'Assets/mealkit/mealkit.webp'

import SingleProductWrapper from 'Views/check-in-views/products-view/single-product-wrapper.component'
import HypeLines from 'Components/ui/hype-lines.component'
import Select from 'Components/ui/select.component'
import GuestAmountSelector from 'Components/guest-amount-selector.component'
import { ContentMainHeading, InvalidFieldsAlertSection, Section } from 'Components/view-structure.component'

import { selectProducts, selectProductsConsumptionDate, selectProductsAmount } from 'Redux/reservation/products/products.selectors'
import { updateCurrentStepField } from 'Redux/app/app.actions'
import { selectAdditionalGuests } from 'Redux/reservation/guests/guests.selectors'

import { COLORS, FONT_WEIGHTS } from 'Globals/global.styles'
import config from 'Globals/constants'
import { selectAppState } from 'Redux/app/app.selectors'

import { TranslationContext } from 'Src/context'

const { ACTIVE_PRODUCT_KEYS } = config

const HYPE_LINES = [
	'Inari Green Curry',
	'Asparagus and Kombu Jelly',
	'Chilled Thai Basil Soup',
	'Smoked Shiitake with Ponzu',
	'Roasted Turnip with Salmon Roe',
	'Bossam Pork with Accoutrements',
	'Caramelised Sweet Potato and Coffee Parfait',
]

const getInstructions = (selectedDate) => ([
	selectedDate ? `Estimated delivery time is ${format(parseISO(selectedDate.startDate), 'HH:mm')}-${format(parseISO(selectedDate.endDate), 'HH:mm')}` : '',
	'The courier will call you when they arrive. If you’re not home, no worries; they’ll leave the package inside your apartment’s fridge.',
])

const ImageWrapper = styled.div`
	width: 100%;
	height: 250px;
	margin-bottom: 20px;
	margin-top: -15px;

	> img {
		width: 100%;
		height: 100%;
	}
`

const SectionStyled = styled(Section)`
	margin-bottom: 36px;
`

const SmallSectionStyled = styled(Section)`
	margin-bottom: 24px;
`

const Divider = styled.div`
	width: 75%;
	border: 1px solid ${COLORS.border.minor};
	margin: 25px auto;
`

const TextContent = styled.div`
	font-size: 14px;
	color: ${COLORS.text.secondary};
	margin-bottom: 10px;
	margin-top: -15px;
`

const BoldText = styled.span`
	font-size: 14px;
	font-weight: ${FONT_WEIGHTS.bold};
`

const ContentMainHeadingStyled = styled(ContentMainHeading)`
	margin-bottom: -15px;
`

const MealKitViewComponent = ({
	appState,
	products,
	productsConsumptionDates,
	productsAmount,
	updateField,
	guests,
}) => {
	const [availableTimes, setAvailableTimes] = useState([])
	const [availableDeliveryOptions, setAvailableDeliveryOptions] = useState([])

	const translation = useContext(TranslationContext)

	const { currentStepFormErrors: formErrors } = appState

	const mealKitCount = productsAmount?.find(
		(prodData) => prodData.productKey === ACTIVE_PRODUCT_KEYS.mealkit,
	)?.amount || 1

	const deliveryDate = productsConsumptionDates?.find(
		(prodData) => prodData.productKey === ACTIVE_PRODUCT_KEYS.mealkit,
	)?.consumptionDate || new Date().toISOString()
	const chosenDeliveryTime = availableTimes?.find((time) => time.startDate === deliveryDate) || null

	useEffect(() => {
		const mealKitProduct = products.find((prod) => prod.productKey === ACTIVE_PRODUCT_KEYS.mealkit)
		const availableTimes = mealKitProduct?.availability[0]?.timeslots?.filter((time) => !!time.isAvailable)
		setAvailableTimes(availableTimes)
		setAvailableDeliveryOptions(availableTimes.map((time, index) => {
			const timeLabel = `${format(parseISO(time.startDate), 'dd/MM/yyyy HH:mm')}
				 - ${format(parseISO(time.endDate), 'HH:mm')}`
			return {
				value: index,
				label: timeLabel,
			}
		}))
		updateField('amount', { productKey: ACTIVE_PRODUCT_KEYS.mealkit, amount: mealKitCount })
	}, [])

	return (
		<SingleProductWrapper
			title={translation.t('Dinner Experience by Chef Kim Mikkola')}
			titleAfterImage
			/* eslint-disable max-len */
			description={translation.t('Kim Mikkola is a Michelin-awarded chef and a Helsinki native, who has spent a fair bit of time in South Korea. Go on a journey from Helsinki to the side streets of Seoul by combining techniques and spices from Northeast Asia with the best local ingredients and flavours.')}
			image={productMealkitMain}
			buttonText={translation.t('Add Meal kit')}
			deliveryDate={format(parseISO(deliveryDate), 'dd/MM/yyyy')}
			numberOfProduct={mealKitCount}
		>
			{!!formErrors?.length && <InvalidFieldsAlertSection />}
			<ImageWrapper>
				<img src={productMealKitDishes} alt={translation.t('mealkit by platform pro')} />
			</ImageWrapper>
			<TextContent>
				{translation.t('Your menu includes')}
				<BoldText>
					{' '}
					{translation.t('7 courses')}
					:
					{' '}
				</BoldText>
			</TextContent>
			<SectionStyled>
				<HypeLines lines={HYPE_LINES} />
			</SectionStyled>
			<ContentMainHeading>
				{translation.t('How it works')}
			</ContentMainHeading>
			<TextContent>
				{/* eslint-disable max-len */}
				{translation.t('This meal kit on steroids takes 90% of the work out of making a gourmet feast. The prepared elements for each dish are delivered to your apartment along with instructions on how to assemble them. You’ll find all the necessary cooking gadgets in your apartment’s kitchen (no blow torch needed).')}
			</TextContent>
			<Divider />
			<ContentMainHeading>
				{translation.t('Select delivery date')}
			</ContentMainHeading>
			<SmallSectionStyled>
				<HypeLines lines={getInstructions(chosenDeliveryTime)} useDots />
			</SmallSectionStyled>
			<Select
				label={translation.t('Select delivery date*')}
				name="mealkit-platform-pro delivery-date"
				options={availableDeliveryOptions}
				value={availableDeliveryOptions[
					availableTimes.findIndex((time) => time.startDate === chosenDeliveryTime?.startDate)
				]}
				onChange={(time) => updateField(
					'deliveryDate',
					{ productKey: ACTIVE_PRODUCT_KEYS.mealkit, deliveryDate: availableTimes[time.value].startDate },
				)}
				isValid={!formErrors?.includes('deliveryDate')}
			/>
			<br />
			<ContentMainHeading>
				{translation.t('Select quantity')}
			</ContentMainHeading>
			<TextContent>
				{translation.t('You&apos;ll need one more dinner kit per person')}
			</TextContent>
			<GuestAmountSelector
				label={translation.t('Number of people')}
				name="mealkitCount"
				value={mealKitCount}
				minValue={1}
				maxValue={guests.length + 1}
				handleChange={(value) => updateField(
					'amount',
					{ productKey: ACTIVE_PRODUCT_KEYS.mealkit, amount: value },
				)}
			/>
			<br />
			<ContentMainHeadingStyled>
				{translation.t('Review and confirm')}
			</ContentMainHeadingStyled>
		</SingleProductWrapper>
	)
}

const mapStateToProps = createStructuredSelector({
	appState: selectAppState,
	products: selectProducts,
	productsConsumptionDates: selectProductsConsumptionDate,
	productsAmount: selectProductsAmount,
	guests: selectAdditionalGuests,
})

const mapDispatchToProps = (dispatch) => ({
	updateField: (fieldName, data) => dispatch(updateCurrentStepField(fieldName, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MealKitViewComponent)
