import { getCoreApiBaseUrl, isWhiteLabelDomain } from './env'

export function setCorrectFavicon() : void {
	const isWhiteLabel = isWhiteLabelDomain()
	const link = document.createElement('link')

	if (isWhiteLabel) {
		link.href = '/favicons/white-label.ico'
	} else {
		link.href = '/favicons/bobw.ico'
	}

	link.rel = 'icon'

	document.head.appendChild(link)
}

export function getReservationSlug() : string {
	const url = window.location.pathname
	const urlArray = url.split('/')
	const reservationSlug = urlArray[urlArray.length - 1]

	return reservationSlug
}

export function setWindowVariable() {
	const apiHost = getCoreApiBaseUrl()
	const reservationSlug = getReservationSlug()

	window.bobw = {
		flow: 'CHECK_IN',
		reservationSlug,
		apiHost,
	}
}
