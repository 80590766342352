import React from 'react'
import styled, { css } from 'styled-components'

import { COLORS } from '../../globals/global.styles'

const TableStyled = styled.table`
	width: 100%;
	margin-bottom: 32px;
	border-spacing: 0;
`

const Table = ({ children }) => (
	<TableStyled>
		<tbody>{children}</tbody>
	</TableStyled>
)

const LabelCellStyles = css`
	color: ${COLORS.text.label};
`

const ValueCellStyles = css`
	text-align: right;
`

const TableCell = styled.td`
	${(props) => {
		switch (props.variant) {
			case 'label':
				return LabelCellStyles
			case 'value':
				return ValueCellStyles
			default:
				return null
		}
	}}

	padding: 8px 0 4px 0;
	border-bottom: 1px solid ${COLORS.border.minor};
	width: 50%;
`

const TableRow = ({ label, value, children }) => (
	<tr>
		<TableCell variant="label">{label}</TableCell>
		<TableCell variant="value">{children || value}</TableCell>
	</tr>
)

export { Table, TableRow }
