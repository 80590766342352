import React, { useState } from 'react'
import styled from 'styled-components'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { FONT_WEIGHTS, generateMediaQuery } from 'Globals/global.styles'

const Wrapper = styled.div`
	margin-bottom: 8px;

	.slide {
		background-color: transparent;
	}

	.control-dots {
		display: none;
	}

	.carousel-root button.control-arrow {
		height: 30px;
		width: 30px;
		border-radius: 50%;
		top: 35%;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 1;
		filter: alpha(opacity=100);
		background: rgba(0, 0, 0, 0.7);
		padding: 3px;

		${generateMediaQuery.mobileSmall(`
			top: 40%;
		`, 320)}
	}

	.carousel-root button.control-arrow.control-disabled {
		display: none;
	}

	.carousel-root .control-next {
		right: 7%;
	}

	.carousel-root .control-prev {
		left: 7%;
	}

	.carousel-root .control-arrow:before {
		display: none;
	}

	.carousel-root .control-arrow:after {
		display: inline-block;
		border-top: 3px solid #fff;
		border-right: 3px solid #fff;
		content: '';
		width: 8px;
		height: 8px;
	}

	.carousel-root .control-next:after {
		margin: 0 8px 0 5px;
		transform: rotate(45deg);
	}

	.carousel-root .control-prev:after {
		margin: 0 5px 0 8px;
		transform: rotate(-135deg);
	}
`

const CarouselItemStyled = styled.div`
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 16px;
	text-align: center;

	${generateMediaQuery.mobileSmall(`
		padding-top: 0px;
	`)}
`

const CarouselItemHeading = styled.div`
	margin-bottom: 8px;
	font-size: 24px;
	font-weight: ${FONT_WEIGHTS.normal};
	line-height: 1.3em;
`

const CarouselItemText = styled.div`
	font-weight: ${FONT_WEIGHTS.light};
	margin-bottom: 0;
	font-size: 16px;

	${generateMediaQuery.mobileSmall(`
		font-size: 14px;
	`)}
`

const CarouselImageContainer = styled.div`
	position: relative;
	margin-bottom: 16px;
	overflow: hidden;
	border-radius: 12px;

	> div {
		// This padding is for dynamically setting the height of the image according to its width
		padding-bottom: 100%;

		> img {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
`

const CarouselItem = ({ img, heading, text }) => (
	<CarouselItemStyled>
		<CarouselImageContainer>
			<div>
				<img src={img} alt={heading} />
			</div>
		</CarouselImageContainer>
		<CarouselItemHeading>{heading}</CarouselItemHeading>
		<CarouselItemText>{text}</CarouselItemText>
	</CarouselItemStyled>
)

const Carousel = ({ data }) => {
	const [showArrow, setShowArrow] = useState(true)

	return (
		<Wrapper>
			<ReactCarousel
				showArrows={showArrow}
				showStatus={false}
				showThumbs={false}
				swipeable
				onSwipeStart={() => setShowArrow(false)}
				onSwipeEnd={() => setShowArrow(true)}
			>
				{data.map((itemData, idx) => (
					<CarouselItem key={idx} {...itemData} />
				))}
			</ReactCarousel>
		</Wrapper>
	)
}

export default Carousel
