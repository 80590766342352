import { createSelector } from 'reselect'
import { format, parse } from 'date-fns'

import { selectCurrentStepChangedFieldsToAPI } from '../app/app.selectors'
import { selectReservation, selectReservationHasStarted } from '../reservation/reservation.selectors'
import {
	selectAdditionalDocumentFieldsRequired,
	selectCurrentAdditionalGuest,
	selectCurrentAdditionalGuestDocumentDetailsRequired,
	selectMainGuestCustomer,
	selectMainGuestDocument,
	selectMainGuestDocumentDetailsRequired,
	selectMainGuestNewCreditCard,
} from '../reservation/guests/guests.selectors'
import { getEarlyCheckinProduct, getLateCheckoutProduct } from '../reservation/times/times.utils'
import {
	selectArrivalTimeData,
	selectAvailableTimes,
	selectDepartureTimeData,
} from '../reservation/times/times.selectors'
import {
	selectCleaningMidStayProduct,
	selectOrderedEarlyCheckIn,
	selectOrderedLateCheckOut,
	selectOrderedProducts,
	selectProducts,
	selectProductsAdditionalData,
	selectProductsAmount,
	selectProductsConsumptionDate,
	selectProductsPageListItems,
	selectSelectedProductKeys,
} from '../reservation/products/products.selectors'
import { selectSelectedCleaningDatesTimes } from '../reservation/cleaning-mid-stay/cleaning-mid-stay.selectors'

import config from '../../globals/constants'
import { isProductKeySelected, isTimeProduct } from 'Redux/reservation/products/products.utils'
import { selectCustomerVerificationCode } from '../customer/customer.selectors'
import { selectBusinessLead } from 'Redux/business-lead/business-lead.selectors'

const {
	STEPS,
} = config

const { CHECK_IN } = STEPS
const CHECK_IN_STEPS = {
	...CHECK_IN.MAIN,
	...CHECK_IN.SUB,
	...CHECK_IN.COMPLETED,
}

const combineCustomerData = (customer, document, documentId, address) => {
	if (!customer && !document && !address) return null

	const customerData = customer || {}

	delete customerData.phoneCountryCallingCode
	delete customerData.phoneCountryCode

	if (document && document.type && document.number) {
		if (document.issueCountry || document.issueCountry === null) delete document.issueCountry
		if (document.issueProvince || document.issueProvince === null) delete document.issueProvince

		customerData.customerDocuments = [document]
	}

	if (address) {
		customerData.customerAddress = address
	}

	return Object.keys(customerData).length ? customerData : null
}

const selectMainGuestPatchData = createSelector(
	[
		selectCurrentStepChangedFieldsToAPI,
		selectMainGuestCustomer,
		selectMainGuestDocument,
		selectMainGuestDocumentDetailsRequired,
		selectAdditionalDocumentFieldsRequired,
	],
	(
		changedFields,
		customer,
		mainGuestDocument,
		documentDetailsVisible,
		additionalDocumentFieldsRequired,
	) => {
		const { id: documentId } = mainGuestDocument ?? {}
		const {
			adultCount,
			childrenCount,
			mainGuest: mainGuestChangedFields,
			mainGuestCustomer: mainGuestCustomerChangedFields,
			mainGuestDocument: mainGuestDocumentChangedFields,
			mainGuestAddress: mainGuestAddressChangedFields,
			customerSignature: mainGuestSignature,
		} = changedFields ?? {}

		let customerChangedFields = mainGuestCustomerChangedFields

		if (mainGuestCustomerChangedFields?.birthProvince) {
			customerChangedFields = {
				...customerChangedFields,
				data: {
					italyBirthCommune: mainGuestCustomerChangedFields.birthProvince,
				},
			}

			delete customerChangedFields.birthProvince
		}

		let documentChangedFields = null

		if (documentDetailsVisible) {
			if (additionalDocumentFieldsRequired) {
				documentChangedFields = {
					...mainGuestDocumentChangedFields,
					issuingCountryCode: mainGuestDocumentChangedFields?.issueCountry,
				}

				if (mainGuestDocumentChangedFields?.issueProvince) {
					documentChangedFields = {
						...documentChangedFields,
						data: {
							italyIssuingCommune: mainGuestDocumentChangedFields?.issueProvince,
						},
					}
				}
			} else {
				documentChangedFields = mainGuestDocumentChangedFields
			}
		}

		const data = {}
		const customerData = combineCustomerData(
			customerChangedFields,
			documentChangedFields,
			documentId,
			mainGuestAddressChangedFields,
		)

		if (customerData) {
			data.customer = customerData
		}

		if (mainGuestChangedFields) {
			data.reservationGuests = [
				{
					...mainGuestChangedFields,
					customerId: customer.id,
				},
			]
		}

		if (mainGuestSignature) {
			data.customerSignature = { signature: mainGuestSignature }
		}

		const hasAdultCount = adultCount >= 0
		const hasChildrenCount = childrenCount >= 0

		if (hasAdultCount && hasChildrenCount) {
			data.adultCount = adultCount
			data.childrenCount = childrenCount
		}

		return Object.keys(data).length ? data : null
	},
)

const selectMainGuestContactsData = createSelector(
	[
		selectMainGuestPatchData,
		selectProductsAdditionalData,
		selectCurrentStepChangedFieldsToAPI,
		selectBusinessLead,
		selectCustomerVerificationCode,
	],
	(
		mainGuestPatchData,
		productsAdditionalData,
		changedFields,
		businessLead,
		customerVerificationCode,
	) => {
		const { travelReasons } = changedFields || {}
		const data = {}

		if (mainGuestPatchData) {
			data.customer = mainGuestPatchData.customer
		}

		if (travelReasons) {
			data.travelReasons = travelReasons
		}

		if (data.customer?.email) {
			data.customer.$emailVerificationCode = customerVerificationCode === '' ? undefined : customerVerificationCode
		}

		return Object.keys(data).length ? data : null
	},
)

const selectMainGuestIdentificationData = createSelector(
	[selectMainGuestPatchData],
	(mainGuestApiData) => {
		const { adultCount, childrenCount } = mainGuestApiData || {}
		const hasAdultCount = adultCount >= 0
		const hasChildrenCount = childrenCount >= 0

		// Indetification step should not update adultCount and childrenCount
		if (hasAdultCount && hasChildrenCount) {
			delete mainGuestApiData.adultCount
			delete mainGuestApiData.childrenCount
		}
		return mainGuestApiData
	},
)

const selectGuestsData = createSelector(
	[selectCurrentStepChangedFieldsToAPI],
	(changedFields) => {
		const { adultCount, childrenCount } = changedFields || {}

		if (adultCount || childrenCount) {
			return {
				adultCount,
				childrenCount,
			}
		}

		return null
	},
)

const selectMainGuestDetailsData = createSelector(
	[selectMainGuestPatchData],
	(mainGuestApiData) => mainGuestApiData,
)

const selectAdditionalGuestDetailsData = createSelector(
	[
		selectCurrentStepChangedFieldsToAPI,
		selectCurrentAdditionalGuest,
		selectCurrentAdditionalGuestDocumentDetailsRequired,
		selectAdditionalDocumentFieldsRequired,
	],
	(
		changedFields,
		{ customer, document },
		documentDetailsVisible,
		additionalDocumentFieldsRequired,
	) => {
		const { id: customerId } = customer
		const { id: documentId } = document || {}
		const {
			adultCount,
			childrenCount,
			currentAdditionalGuest: guestChangedFields,
			currentAdditionalGuestCustomer: guestCustomerChangedFields,
			currentAdditionalGuestDocument: guestDocumentChangedFields,
			currentAdditionalGuestAddress: guestAddressChangedFields,
		} = changedFields || {}

		let customerChangedFields = guestCustomerChangedFields

		if (guestCustomerChangedFields?.birthProvince) {
			customerChangedFields = {
				...customerChangedFields,
				data: {
					italyBirthCommune: guestCustomerChangedFields.birthProvince,
				},
			}

			delete customerChangedFields.birthProvince
		}

		let documentChangedFields = null

		if (documentDetailsVisible) {
			if (additionalDocumentFieldsRequired) {
				documentChangedFields = {
					...guestDocumentChangedFields,
					issuingCountryCode: guestDocumentChangedFields?.issueCountry,
				}

				if (guestDocumentChangedFields?.issueProvince) {
					documentChangedFields = {
						...documentChangedFields,
						data: {
							italyIssuingCommune: guestDocumentChangedFields?.issueProvince,
						},
					}
				}
			} else {
				documentChangedFields = guestDocumentChangedFields
			}
		}

		const customerData = combineCustomerData(
			customerChangedFields,
			documentChangedFields,
			documentId,
			guestAddressChangedFields,
		)

		const hasAdultCount = adultCount >= 0
		const hasChildrenCount = childrenCount >= 0

		if (!guestChangedFields && !customerData && !hasAdultCount && !hasChildrenCount) return null

		if (!guestChangedFields && !customerData && hasAdultCount && hasChildrenCount) {
			return {
				adultCount,
				childrenCount,
			}
		}

		return {
			adultCount,
			childrenCount,
			reservationGuests: [
				{
					...guestChangedFields,
					...(customerData && { customer: customerData }),
					...(customerId && { customerId }),
				},
			],
		}
	},
)

const selectArrivalTimeAPIPatchData = createSelector(
	[
		selectReservation,
		selectReservationHasStarted,
		selectArrivalTimeData,
		selectAvailableTimes,
		selectProducts,
		selectOrderedEarlyCheckIn,
	],
	(
		reservation,
		reservationHasStarted,
		arrivalTimeData,
		availableTimes,
		selectProducts,
		earlyCheckInProduct,
	) => {
		const {
			selectedArrivalTimeUTC,
			confirmedArrivalDateUTC,
		} = arrivalTimeData

		const arrivalTimeHasChanged = selectedArrivalTimeUTC !== confirmedArrivalDateUTC

		if (!arrivalTimeHasChanged || earlyCheckInProduct) return null

		const data = {
			orderItems: [],
		}

		// it might happen that a customer removed ECI/LCO products before payment
		// check if it still in the list of selected products
		const initiallySelectedEciProduct = getEarlyCheckinProduct(selectedArrivalTimeUTC, availableTimes)
		const isEciProductConfirmed = isProductKeySelected(selectProducts, initiallySelectedEciProduct)

		if (isEciProductConfirmed && !reservationHasStarted) {
			data.orderItems.push({
				productId: initiallySelectedEciProduct,
				consumptionDate: selectedArrivalTimeUTC,
			})
		} else if (selectedArrivalTimeUTC && !isEciProductConfirmed && !initiallySelectedEciProduct) {
			data.startDate = selectedArrivalTimeUTC
		}

		if (!data.orderItems.length) delete data.orderItems

		return data
	},
)

const selectDepartureTimeAPIPatchData = createSelector(
	[
		selectReservation,
		selectDepartureTimeData,
		selectAvailableTimes,
		selectProducts,
		selectOrderedLateCheckOut,
	],
	(
		reservation,
		departureTimeData,
		availableTimes,
		selectProducts,
		lateCheckOutProduct,
	) => {
		const {
			selectedDepartureTimeUTC,
			confirmedDepartureDateUTC,
		} = departureTimeData

		const departureTimeHasChanged = selectedDepartureTimeUTC !== confirmedDepartureDateUTC

		if (!departureTimeHasChanged || !selectedDepartureTimeUTC || lateCheckOutProduct) return null

		const data = {
			orderItems: [],
		}

		// it might happen that a customer removed ECI/LCO products before payment
		// check if it still in the list of selected products
		const initiallySelectedLcoProduct = getLateCheckoutProduct(selectedDepartureTimeUTC, availableTimes)
		const isLcoProductConfirmed = isProductKeySelected(selectProducts, initiallySelectedLcoProduct)

		if (isLcoProductConfirmed) {
			data.orderItems.push({
				productId: initiallySelectedLcoProduct,
				consumptionDate: selectedDepartureTimeUTC,
			})
		} else if (selectedDepartureTimeUTC && !isLcoProductConfirmed && !initiallySelectedLcoProduct) {
			data.endDate = selectedDepartureTimeUTC
		}

		if (!data.orderItems.length) delete data.orderItems

		return data
	},
)

const selectFinalStepAPIPatchData = createSelector(
	[
		selectArrivalTimeAPIPatchData,
		selectDepartureTimeAPIPatchData,
		selectProductsPageListItems,
		selectOrderedProducts,
		selectSelectedProductKeys,
		selectProductsAdditionalData,
		selectProductsConsumptionDate,
		selectProductsAmount,
	],
	(
		arrivalTimeAPIPatchData,
		departureTimeAPIPatchData,
		products,
		orderedProducts,
		selectedProductKeys,
		productsAdditionalData,
		productsConsumptionDates,
		productsAmount,
	) => {
		const data = {
			checkInDone: true,
			orderItems: [],
		}

		if (arrivalTimeAPIPatchData) {
			data.startDate = arrivalTimeAPIPatchData.startDate
			data.orderItems.push(...(arrivalTimeAPIPatchData.orderItems || []))
		}

		if (departureTimeAPIPatchData) {
			data.endDate = departureTimeAPIPatchData.endDate
			data.orderItems.push(...(departureTimeAPIPatchData.orderItems || []))
		}

		if (selectedProductKeys?.length) {
			selectedProductKeys.forEach((productKey) => {
				if (isTimeProduct(productKey)) {
					return
				}
				const product = products.find((prod) => prod.productKey === productKey)

				if (product && !product.isComplimentary) {
					const productData = productsAdditionalData?.find(
						(prodData) => prodData.productKey === productKey,
					)?.data
					const consumptionDate = productsConsumptionDates?.find(
						(prodData) => prodData.productKey === productKey,
					)?.consumptionDate
					const amount = productsAmount?.find(
						(prodData) => prodData.productKey === productKey,
					)?.amount || 1

					for (let i = 0; i < amount; i++) {
						data.orderItems.push({
							productId: product.productId,
							...(!!productData && { data: productData }),
							...(!!consumptionDate && { consumptionDate }),
						})
					}
				}
			})
		}

		if (orderedProducts?.length) {
			orderedProducts.forEach(({ productKey, id }) => {
				if (!selectedProductKeys?.includes(productKey)) {
					const productData = productsAdditionalData?.find(
						(prodData) => prodData.productKey === productKey,
					)?.data
					if (productData) {
						data.orderItems.push({
							id,
							data: productData,
						})
					}
				}
			})
		}

		if (!data.orderItems.length) delete data.orderItems

		return Object.keys(data).length ? data : null
	},
)

const selectTimesAPIPatchData = selectFinalStepAPIPatchData

const selectProductsAPIPatchData = selectFinalStepAPIPatchData

const selectSummaryAPIPatchData = createSelector([
	selectFinalStepAPIPatchData,
], (
	finalStepData,
) => {
	return Object.keys(finalStepData).length ? finalStepData : null
})

const selectAddCardAPIPatchData = createSelector(
	[selectMainGuestNewCreditCard],
	(newCard) => {
		const {
			name,
			type,
			expirationDate: expirationDateString,
			numberMasked,
			transactionId,
		} = newCard

		return {
			creditCards: [{
				name,
				type,
				expirationDate: format(parse(expirationDateString, 'MM/yy', new Date()), 'yyyy-MM'),
				numberMasked,
				transactionId,
			}],
		}
	},
)

const selectCleaningMidStayConfirmAPIPatchData = createSelector(
	[
		selectSelectedCleaningDatesTimes,
		selectCleaningMidStayProduct,
	],
	(
		selectedCleaningDatesTimes,
		{ productId },
	) => {
		const data = {}

		if (selectedCleaningDatesTimes?.length) {
			data.orderItems = selectedCleaningDatesTimes.map(({ startDate }) => ({
				productId,
				consumptionDate: startDate,
			}))
		}

		return Object.keys(data).length ? data : null
	},
)

const selectBusinessLeadFinalData = createSelector(
	[selectBusinessLead],
	(selectBusinessLead) => {
		const data = {
			businessLeadCapture: selectBusinessLead,
		}
		return data
	},
)
const getStepAPIPatchDataSelector = (step) => {
	switch (step) {
		case CHECK_IN_STEPS['main-guest-contacts']:
			return selectMainGuestContactsData

		case CHECK_IN_STEPS['business-lead-form']:
			return selectBusinessLeadFinalData

		case CHECK_IN_STEPS['main-guest-identification']:
			return selectMainGuestIdentificationData

		case CHECK_IN_STEPS['guests']:
			return selectGuestsData

		case CHECK_IN_STEPS['main-guest-details']:
			return selectMainGuestDetailsData

		case CHECK_IN_STEPS['additional-guest-details']:
			return selectAdditionalGuestDetailsData

		case CHECK_IN_STEPS['times']:
			return selectTimesAPIPatchData

		case CHECK_IN_STEPS['product-property-unit-category-upgrade-free']:
		case CHECK_IN_STEPS['product-property-unit-category-upgrade']:
			return () => null

		case CHECK_IN_STEPS['products']:
			return selectProductsAPIPatchData

		case CHECK_IN_STEPS['summary']:
			return selectSummaryAPIPatchData

		case CHECK_IN_STEPS['add-card']:
			return selectAddCardAPIPatchData

		case CHECK_IN_STEPS['guest-area-cleaning-mid-stay-confirm']:
			return selectCleaningMidStayConfirmAPIPatchData

		default:
			return () => null
	}
}

export default getStepAPIPatchDataSelector
