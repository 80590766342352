import React, { useState, useRef } from 'react'
import { default as ReactSelect } from 'react-select'
import styled from 'styled-components'

import FieldLabel from './field-label.component'
import HelperText from './field-helper-text.component'

import {
	COLORS,
	ZINDEX,
	FONT_WEIGHTS,
} from '../../globals/global.styles'

const Label = styled(FieldLabel)`
	display: inline-block;
	margin-bottom: 2px;
`

const Wrapper = styled.div``

const reactSelectCustomStyles = {
	clearIndicator: (provided) => ({
		...provided,
		opacity: 0.6,
	}),
	control: (
		provided,
		{ menuIsOpen, isFocused, selectProps: { alertModeOn } },
	) => {
		const styles = {
			...provided,
			height: '40px',
			borderRadius: 0,
			borderColor: COLORS.border.secondary,
			boxShadow: 'none',
			'&:hover': null,
		}

		if (alertModeOn) {
			styles.borderColor = COLORS.border.alert
		} else if (menuIsOpen || isFocused) {
			styles.borderColor = COLORS.border.highlight
		}

		return styles
	},
	dropdownIndicator: (provided) => ({
		...provided,
		opacity: 0.6,
	}),
	group: (provided) => ({
		...provided,
		borderBottom: `1px solid ${COLORS.border.minor}`,
		paddingTop: 0,
		paddingBottom: 0,
	}),
	groupHeading: (provided) => ({
		...provided,
		margin: '4px 0',
	}),
	menu: (provided) => ({ ...provided, zIndex: ZINDEX.selectMenu }),
	multiValue: (provided) => ({
		...provided,
		backgroundColor: COLORS.background.secondary,
	}),
	multiValueRemove: (provided) => ({
		...provided,
		'&:hover': {
			backgroundColor: COLORS.background.secondaryHover,
		},
	}),
	option: (provided, { isFocused, isSelected }) => {
		const styles = {
			...provided,
			fontWeight: FONT_WEIGHTS.light,
			'&:active': {
				color: 'white',
				backgroundColor: COLORS.background.highlight,
			},
		}

		if (isSelected) {
			styles.backgroundColor = COLORS.background.highlight
		} else if (isFocused) {
			styles.backgroundColor = COLORS.background.hover
		}

		return styles
	},
	placeholder: (provided) => ({
		...provided,
		paddingTop: '5px',
	}),
	singleValue: (
		provided,
		{ selectProps: { menuIsOpen, isFocused } },
	) => {
		const styles = {
			...provided,
			margin: 0,
			paddingTop: '4px',
			fontWeight: FONT_WEIGHTS.light,
		}

		if (isFocused || menuIsOpen) {
			styles.display = 'none'
		}

		return styles
	},
	valueContainer: (provided, { isMulti }) => ({
		...provided,
		paddingLeft: isMulti ? provided.paddingLeft : '16px',
	}),
}

const Select = ({
	label,
	helperText = '',
	name,
	isValid,
	className,
	selectRef = useRef(),
	reactSelectStyles = {},
	onChange,
	...otherProps
}) => {
	const [isFocused, setIsFocused] = useState(false)
	const alertModeOn = isValid === false

	return (
		<Wrapper
			onKeyUp={(event) => {
				if (event.key === 'Escape') {
					selectRef.current.blur()
				}
			}}
			className={className}
			alertModeOn={alertModeOn}
			onFocus={() => setIsFocused(true)}
			onBlur={() => setIsFocused(false)}
		>
			{label ? (
				<Label htmlFor={name} alertModeOn={alertModeOn} isFocused={isFocused}>
					{label}
				</Label>
			) : null}
			<ReactSelect
				onChange={(value) => {
					onChange(value)
					selectRef.current.blur()
				}}
				styles={{
					...reactSelectCustomStyles,
					...reactSelectStyles,
				}}
				name={name}
				inputId={name}
				alertModeOn={alertModeOn}
				isFocused={isFocused}
				ref={selectRef}
				openMenuOnFocus
				{...otherProps}
			/>
			{!!helperText && (
				<HelperText
					alertModeOn={alertModeOn}
					isFocused={isFocused}
					text={helperText}
				/>
			)}
		</Wrapper>
	)
}

export default Select
