import React, { FC, useContext, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled, { css } from 'styled-components'
import { switchLanguage } from 'Redux/app/app.actions'
import { selectLanguage } from 'Redux/app/app.selectors'
import { LeftOutlined, PhoneOutlined } from '@ant-design/icons'

import BobWLogo from 'Assets/bob-w-horizontal-logo-brown.svg'
import CloseButtonIcon from 'Assets/icons/icon-close.svg'

import { COLORS, FONT_WEIGHTS, ZINDEX } from 'Globals/global.styles'

import Button from './ui/button.component'
import ResetButton from './ui/reset-button.component'
import PopoverLanguage from './ui/popover-language.component'
import { ConstantsContext, TranslationContext } from 'Src/context'
import { RootState } from 'Src/redux/store'
import { selectIsWhiteLabel } from 'Src/redux/reservation/reservation.selectors'
import { throttle } from 'lodash'

const HEADER_HEIGHT = '41px'
const CARD_PADDING = '16px'

const Sticky = styled.div`
	position: fixed;
	width: 100%;
	background-color: ${COLORS.background.primary};
	text-align: center;
`

const SkipButton = styled(Button)`
	float: right;
	margin-left: auto;
	border: 1px solid ${COLORS.border.primary};
	min-width: 71px;
`

const FooterButton = styled(Button)`
		margin-left: 16px;
`

interface PrevNextButtonsProps {
	readonly onPrevClick?: any;
	readonly nextButtonText?: any;
	readonly prevButtonText?: any;
	readonly disableNextButton?: boolean;
	readonly hidePrevButton?: boolean;
	readonly hideSkipButton?: boolean;
	readonly onSkipClick?: () => void;
	readonly skipButtonText?: string;
}

const PrevNextButtonsContainer : FC<PrevNextButtonsProps> = ({
	onPrevClick,
	nextButtonText,
	prevButtonText,
	disableNextButton,
	hidePrevButton = false,
	hideSkipButton = true,
	onSkipClick,
	skipButtonText = 'Skip',
}) => {
	const translation = useContext(TranslationContext)

	return (
		<>
			{hidePrevButton || (
				<Button variant="transparent" onClick={onPrevClick}>
					{prevButtonText || translation.t('Back')}
				</Button>
			)}

			{
				hideSkipButton || (
					<SkipButton variant="transparent" onClick={onSkipClick}>
						{translation.t(skipButtonText)}
					</SkipButton>
				)
			}

			<FooterButton variant="primary" disabled={disableNextButton} type="submit">
				{nextButtonText || translation.t('Next')}
			</FooterButton>
		</>
	)
}

/**
 * ————— HEADER >>
 */

const HeaderStickyStyled = styled(Sticky)`
	align-items: center;
	z-index: ${ZINDEX.stickyHeader};
	top: 0;
	height: ${HEADER_HEIGHT};
`

const PopoverLanguageStyled = styled(PopoverLanguage)`
	margin-left: ${(props) => (props.positionatedVeryRight ? '0' : '15px')};
	margin-right: ${(props) => (props.positionatedVeryRight ? '5px' : '0')};
	float: right;
`

const HeaderContent = styled.div`
	position: relative;
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	box-sizing: border-box;
`

const ButtonsContainer = styled.div`
	padding: 11px 16px 4px 8px;
`

const HeaderButton = css`
	> span {
		> svg,
		> svg path {
			font-size: 22px;
		}
	}
`

const BackButton = styled(ResetButton)`
	float: left;
	${HeaderButton}
`

const ContactButton = styled.a`
	cursor: pointer;
	float: right;
	${HeaderButton}
`

const CloseButton = styled.div`
	cursor: pointer;
	float: right;

	> img {
		height: 22px;
		cursor: pointer;
	}
`

const Logo = styled.div<{ isWhiteLabel: boolean }>`
	display: inline-block;
	width: 75px;
	visibility: ${({ isWhiteLabel }) => (isWhiteLabel ? 'hidden' : 'visible')};
	opacity: ${({ isWhiteLabel }) => (isWhiteLabel ? '0' : '1')};

	> img {
		width: 75px;
		top: 10px;
		position: absolute;
		left: calc(50% - 37.5px);
	}
`

const ProgressBar = styled.div`
	display: flex;
	position: absolute;
	bottom: -5px;
	width: 100%;
	height: 5px;
	background-color: ${COLORS.fill.minor};

	div {
		flex: 1 0 auto;
	}
`

const ProgressBlock = styled.div<{ isWhiteLabel: boolean }>`
	background-color: ${({ isWhiteLabel }) => (isWhiteLabel ? '#14B39F' : COLORS.fill.primary)};
`

interface HeaderStickyProps {
	readonly progress?: number;
	readonly progressMax?: number;
	readonly contactButton?: any;
	readonly onBackButtonClick?: () => void;
	readonly onCloseButton?: () => void;
}

const HeaderSticky : FC<HeaderStickyProps> = ({
	progress = 0,
	progressMax = 0,
	onBackButtonClick,
	contactButton,
	onCloseButton,
}) => {
	const translation = useContext(TranslationContext)
	const { reloadConfig } = useContext(ConstantsContext)
	const dispatch = useDispatch()

	const { language } = useSelector(
		createStructuredSelector<RootState, { language: string }>({ language: selectLanguage }),
	)

	const progressBlocks = []
	const handleLanguageSwitch = ({ code } : { code: string }) => dispatch(switchLanguage(code))
	const isWhiteLabel = useSelector(selectIsWhiteLabel)

	for (let i = 0; i < progressMax; i++) {
		progressBlocks.push(
			i < progress ? <ProgressBlock key={i} isWhiteLabel={isWhiteLabel} /> : <div key={i} />,
		)
	}

	return (
		<HeaderStickyStyled>
			<HeaderContent>
				<ButtonsContainer>
					{!!onBackButtonClick
						&& (
							<BackButton title={translation.t('Go to previous step')} type="button" onClick={onBackButtonClick}>
								<LeftOutlined />
							</BackButton>
						)}

					<Logo isWhiteLabel={isWhiteLabel}>
						<img src={BobWLogo} alt="Bob W logo" />
					</Logo>

					{!!onCloseButton && (
						<CloseButton onClick={() => onCloseButton()}>
							<img
								src={CloseButtonIcon}
								alt={translation.t('Close current page')}
							/>
						</CloseButton>
					)}

					<PopoverLanguageStyled
						positionatedVeryRight={Boolean(onCloseButton)}
						value={language}
						onChange={(value: any) => {
							handleLanguageSwitch(value)
							reloadConfig()
						}}
					/>

					{!!contactButton?.url && (
						<ContactButton
							href={contactButton.url}
							onClick={() => contactButton.onClick && contactButton.onClick()}
						>
							<PhoneOutlined />
						</ContactButton>
					)}
				</ButtonsContainer>
				{
					!!progressMax
					&& <ProgressBar title={translation.t('Progress bar')}>{progressBlocks}</ProgressBar>
				}
			</HeaderContent>
		</HeaderStickyStyled>
	)
}

const Section = styled.div`
	margin-bottom: 16px;
`

const ContentHeaderStyled = styled.div`
	margin: -11px -${CARD_PADDING} 32px -${CARD_PADDING};
    padding: 32px 0;
	text-align: center;
	background-color: ${COLORS.background.header};
	position: relative;
`

const MainHeadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const MainHeading = styled.h1`
	margin: 0 0 16px;
	font-size: 28px;
	font-weight: ${FONT_WEIGHTS.bold};
`

const SubHeading = styled.h2`
	padding: 0 ${CARD_PADDING};

	&,
	& > b {
		margin: 4px 0 0 0;
		font-size: 14px;
		font-weight: ${FONT_WEIGHTS.light};
		color: ${COLORS.text.secondary};
	}

	> b {
		font-weight: ${FONT_WEIGHTS.normal};
	}
`

const HeadingChildrenWrapper = styled.div`
	margin-top: 10px;
`

interface ContentHeaderProps {
	readonly className?: any;
	readonly mainText?: any;
	readonly subText?: any;
	readonly children?: any;
}

const ContentHeader : FC<ContentHeaderProps> = ({
	className,
	mainText,
	subText,
	children,
}) => {
	return	(
		<ContentHeaderStyled className={className}>

			<MainHeadingContainer>
				<MainHeading>
					{mainText}
				</MainHeading>
			</MainHeadingContainer>

			{!!subText && <SubHeading>{subText}</SubHeading>}
			{!!children && <HeadingChildrenWrapper>{children}</HeadingChildrenWrapper>}
		</ContentHeaderStyled>
	)
}

const ContentMainHeading = styled.h2`
	margin: 0 0 16px;
	font-size: 20px;
	font-weight: ${FONT_WEIGHTS.normal};

	> span {
		padding-left: 8px;
		font-size: 14px;
	}
`

const ContentSubHeading = styled.h3`
	margin: -12px 0 32px;
	font-size: 14px;
	line-height: 17px;
	color: ${COLORS.text.secondary};
	font-weight: ${FONT_WEIGHTS.light};
`

const FooterSectionStyled = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin: 64px 0 32px 0;
`

const FooterSection = (props: any) => (
	<FooterSectionStyled>
		<PrevNextButtonsContainer {...props} />
	</FooterSectionStyled>
)

export const FooterSectionStyledSticky = styled.div`
	position: sticky;
	bottom: 0;
	z-index: ${ZINDEX.stickyFooter};
	padding: 20px;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	background: ${COLORS.background.primary};
	margin: 44px 0 12px 0;
`

const FooterSectionSticky : FC = ({ children, ...props }) => {
	const footerRef = useRef<HTMLDivElement | null>(null)

	function checkPosition() {
		const footer = footerRef?.current

		if (footer !== null) {
			const footerHeight = footer.clientHeight
			const footerTop = footer.getBoundingClientRect().top
			const footerPositionFromBottom = window.innerHeight - footerTop - footerHeight

			// 	set the footer position from bottom
			if (footerPositionFromBottom < 4) {
				footer.style.boxShadow = '0px -3px 36px 0px rgba(0, 0, 0, 0.25)'
			} else {
				footer.style.boxShadow = 'none'
			}
		}
	}

	const throttledCheckPosition = throttle(checkPosition, 50)

	useEffect(() => {
		window.addEventListener('scroll', throttledCheckPosition, { passive: true })
		window.addEventListener('resize', throttledCheckPosition, { passive: true })

		return () => {
			window.removeEventListener('scroll', throttledCheckPosition)
			window.removeEventListener('resize', throttledCheckPosition)
		}
	}, [])

	useEffect(() => {
		if (footerRef?.current !== null) {
			checkPosition()
		}
	}, [footerRef])

	return (
		<FooterSectionStyledSticky
			ref={footerRef}
			{...props}
		>
			{ children }
		</FooterSectionStyledSticky>
	)
}

export const PrevNextButtonsSticky : FC<PrevNextButtonsProps> = (props) => (
	<FooterSectionSticky>
		<PrevNextButtonsContainer {...props} />
	</FooterSectionSticky>
)

const InvalidFieldsAlertSectionStyled = styled(Section)`
	color: ${COLORS.text.alert};
`

const InvalidFieldsAlertSection = ({ formErrors } : any) => {
	const translation = useContext(TranslationContext)
	const errorMessage = formErrors?.includes('duplicatedGuestEmailUsed')
		? translation.t('Please enter a different email for each guest or leave empty.')
		: translation.t('Some fields need your attention!')

	return (
		<InvalidFieldsAlertSectionStyled>
			{errorMessage}
		</InvalidFieldsAlertSectionStyled>
	)
}

export {
	HeaderSticky,
	Section,
	ContentHeader,
	MainHeading,
	ContentMainHeading,
	ContentSubHeading,
	FooterSection,
	FooterSectionSticky,
	InvalidFieldsAlertSection,
}
