import React, { useCallback, useContext } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import {
	HeaderSticky,
	Section,
	ContentHeader,
	ContentMainHeading,
	ContentSubHeading,
	FooterSection,
	InvalidFieldsAlertSection,
} from 'Components/view-structure.component'
import { BlockSelector } from '../../../components/ui'
import MainGuestContactsFields from '../../../components/main-guest-contacts-fields.component'

import {
	selectAppState,
	selectIsSubmitting,
} from 'Redux/app/app.selectors'
import { updateCurrentStepField } from 'Redux/app/app.actions'
import {
	goToStep,
	goToPrevStep,
} from 'Redux/app/go-to-step.actions'
import { selectIsWhiteLabel, selectReservation } from 'Redux/reservation/reservation.selectors'
import {
	selectMainGuestCustomer,
	selectMainGuestIsEmailFieldVisible,
} from 'Redux/reservation/guests/guests.selectors'
import {
	selectProductsAdditionalData,
	selectHasOrderedParking,
} from 'Redux/reservation/products/products.selectors'
import { selectTravelReasons } from 'Redux/reservation/others/others.selectors'

import { TranslationContext, ConstantsContext } from 'Src/context'
import PageView from 'Src/components/PageView'
import { clearCustomerVerificationCode, updateCustomerVerificationCode } from 'Src/redux/customer/customer.actions'
import { clearEmailErrorCode } from 'Src/redux/error/error.actions'

const BlockSelectorStyled = styled(BlockSelector)`
	margin-bottom: 16px;
`

const GuestContactsView = ({
	appState,
	isSubmitting,
	reservation,
	customer,
	hasOrderedParking,
	productsAdditionalData,
	isEmailFieldVisible,
	travelReasons,
	updateField,
	goToNextStep,
	goToPrevStep,
}) => {
	const translation = useContext(TranslationContext)
	const { constants: { ACTIVE_PRODUCT_KEYS, TRAVEL_REASONS } } = useContext(ConstantsContext)
	const dispatch = useDispatch()

	const {
		progressData,
		currentStepFormErrors: formErrors,
	} = appState

	const {
		property: {
			phone: propertyPhone,
		},
	} = reservation
	const {
		email,
		phone,
		phoneCountryCallingCode,
		phoneCountryCode,
	} = customer

	const carPlate = productsAdditionalData?.find(
		(prodData) => prodData.productKey === ACTIVE_PRODUCT_KEYS.parking,
	)?.data?.carPlateNumber || ''

	const handleEmailVerificationSubmit = useCallback((code, clearCodeCallback) => {
		dispatch(clearEmailErrorCode())
		dispatch(updateCustomerVerificationCode(code))

		goToNextStep()

		dispatch(clearCustomerVerificationCode())
		clearCodeCallback()
	}, [])

	const handleEmailVerificationResend = useCallback(() => {
		goToNextStep()
	}, [])

	const isWhiteLabel = useSelector(selectIsWhiteLabel)

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault()
				goToNextStep()
			}}
		>
			<PageView
				Header={() => (
					<HeaderSticky
						{...progressData}
						onBackButtonClick={isWhiteLabel ? null : goToPrevStep}
						contactButton={{ url: `tel:${propertyPhone}` }}
					/>
				)}
			>
				<ContentHeader
					mainText={translation.t('Contact Information')}
					subText={translation.t('Enter the details of the main guest')}
				/>

				{!!formErrors?.length && <InvalidFieldsAlertSection />}

				<Section>
					<MainGuestContactsFields
						{...{
							email,
							phone,
							hasOrderedParking,
							carPlate,
							phoneCountryCallingCode,
							phoneCountryCode,
							isEmailFieldVisible,
							updateField,
							formErrors,
							onEmailVerificationSubmit: handleEmailVerificationSubmit,
							onEmailVerificationResend: handleEmailVerificationResend,
						}}
					/>
				</Section>

				<Section>
					<ContentMainHeading>{translation.t('Type of trip')}</ContentMainHeading>
					<ContentSubHeading>
						(
						{translation.t('Choose all that apply')}
						)
					</ContentSubHeading>

					<BlockSelectorStyled
						isMulti
						options={TRAVEL_REASONS}
						value={travelReasons}
						onChange={(options) => updateField('travelReasons', options)}
					/>
				</Section>

				<FooterSection
					onPrevClick={goToPrevStep}
					disableNextButton={isSubmitting}
					hidePrevButton={isWhiteLabel}
				/>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	appState: selectAppState,
	reservation: selectReservation,
	customer: selectMainGuestCustomer,
	hasOrderedParking: selectHasOrderedParking,
	productsAdditionalData: selectProductsAdditionalData,
	isEmailFieldVisible: selectMainGuestIsEmailFieldVisible,
	travelReasons: selectTravelReasons,
	isSubmitting: selectIsSubmitting,
})

const mapDispatchToProps = (dispatch) => ({
	updateField: (fieldName, data) => dispatch(updateCurrentStepField(fieldName, data)),
	goToNextStep: () => dispatch(goToStep()),
	goToPrevStep: () => dispatch(goToPrevStep()),
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestContactsView)
