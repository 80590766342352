import { GlobalOutlined, DownOutlined } from '@ant-design/icons'

import config from 'Src/globals/constants'

import React, { useState, useRef } from 'react'
import { default as ReactSelect } from 'react-select'
import styled from 'styled-components'

import {
	COLORS,
	ZINDEX,
	FONT_WEIGHTS,
} from '../../globals/global.styles'

const { LANGUAGES } = config

const Wrapper = styled.div`
	.anticon-global > svg {
		font-size: 22px
	}

	cursor: pointer;

	.anticon-down svg {
		width: 12px;
		margin: 3px 5px;
	}

	.active svg {
		fill: ${COLORS.fill.primary};
	}
`

const reactSelectCustomStyles = {
	control: (
		provided,
	) => {
		const styles = {
			...provided,
			display: 'none',
		}

		return styles
	},
	menu: (provided) => ({
		...provided, zIndex: ZINDEX.selectMenu, width: '100px', marginLeft: '-60px',
	}),
	option: (provided, { isFocused, isSelected, ...rest }) => {
		const [{ value: active }] = rest.getValue()
		const { code: curOption } = rest.data

		const styles = {
			...provided,
			fontWeight: active === curOption ? FONT_WEIGHTS.bold : FONT_WEIGHTS.light,
			'&:active': {
				color: 'white',
				backgroundColor: COLORS.background.highlight,
			},
		}

		if (isSelected) {
			styles.backgroundColor = COLORS.background.highlight
		} else if (isFocused) {
			styles.backgroundColor = COLORS.background.hover
		}

		return styles
	},
}

const Select = ({
	name,
	selectRef = useRef(),
	reactSelectStyles,
	onChange,
	...otherProps
}) => (
	<ReactSelect
		onChange={(value) => {
			onChange(value)
			selectRef.current.blur()
		}}
		styles={{
			...reactSelectCustomStyles,
			...reactSelectStyles,
		}}
		name={name}
		inputId={name}
		ref={selectRef}
		{...otherProps}
	/>
)

const PopoverLanguage = ({
	value,
	className,
	...otherProps
}) => {
	const [isActive, setIsActive] = useState(false)

	const val = {
		value,
		label: value.split('-')[0],
	}
	const selectRef = useRef()

	return (
		<Wrapper
			tabIndex="0"
			className={className}
			onBlur={() => {
				setIsActive(false)
				selectRef.current.select.onMenuClose()
			}}
			onClick={() => {
				setIsActive(true)
				selectRef.current.select.onMenuOpen()
			}}
		>
			<GlobalOutlined />

			<DownOutlined className={isActive ? 'active' : ''} />

			<Select
				selectRef={selectRef}
				value={val}
				{...otherProps}
				options={LANGUAGES}
			/>
		</Wrapper>
	)
}

export default PopoverLanguage
