import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { ExclamationCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'

import { getProductPriceBreakdownString, getSingleProductPriceTotal } from 'Src/utils'

import { ResetButton } from '../../../components/ui'

import { COLORS, FONT_WEIGHTS } from 'Globals/global.styles'

import { TranslationContext } from 'Src/context'

const SUBTEXT_FONT_SIZE = '14px'

const Wrapper = styled.div`
	margin-bottom: 20px;
`

const Title = styled.div`
	font-size: 20px;
	flex: 1;
`

const Details = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
`

const SubText = styled.div`
	font-size: ${SUBTEXT_FONT_SIZE};
`

const ButtonsContainer = styled.div``

const ProductTotal = styled.span`
	font-size: ${SUBTEXT_FONT_SIZE};
	font-weight: ${FONT_WEIGHTS.normal};
`

const ProductTotalPrice = styled.span`
	display: inline-block;
	${({ isFree }) => isFree && css`
		margin-right: 2px;
		text-decoration: line-through;
	`}
`

const FreeProductText = styled.span`
	display: inline-block;
	color: ${COLORS.text.highlight};
`

const Button = styled(ResetButton)`
	margin-left: 16px;
	font-size: 16px;
	${({ isDisabled }) => isDisabled
		&& css`
			cursor: default;
			pointer-events: none;

			> span > svg,
			> span > svg > path {
				color: ${COLORS.border.minor};
			}
		`}
`

const Border = styled.div`
	padding-top: 14px;
	border-bottom: 1px solid ${COLORS.border.minor};
`

const FooterNotes = styled.div`
	margin-top: 8px;
	font-size: 14px;
	font-weight: ${FONT_WEIGHTS.light};

	> span {
		margin-right: 8px;
	}
`

const ProductRow = ({
	productsAmount,
	product,
	guestCount,
	durationOfStay,
	onRemove,
	paidUpgradeData,
	currencyIcon,
}) => {
	const translation = useContext(TranslationContext)
	const {
		title,
		footerNote,
		productKey,
		price,
		originalPrice,
	} = product
	const productCount = productsAmount?.find(
		(prodData) => prodData.productKey === productKey,
	)?.amount || 1

	const priceBreakdown = paidUpgradeData
		? `${currencyIcon}${paidUpgradeData.upgradePricePerNight} x ${translation.t('{durationOfStay} nights', { durationOfStay })}`
		: getProductPriceBreakdownString(
			product,
			guestCount,
			durationOfStay,
			productCount > 1,
			translation,
			currencyIcon,
		)
	const totalPrice = paidUpgradeData
		? paidUpgradeData.upgradePricePerNight * durationOfStay
		: getSingleProductPriceTotal(product, guestCount, durationOfStay, productCount)?.toFixed(2)

	return (
		<Wrapper>
			{priceBreakdown && <Title>{title}</Title>}
			<Details>
				{!priceBreakdown && <Title>{title}</Title>}
				<SubText>{priceBreakdown}</SubText>
				<ButtonsContainer>
					<ProductTotal>
						<ProductTotalPrice isFree={price > 0 && originalPrice === 0}>
							{`${currencyIcon}${totalPrice}`}
						</ProductTotalPrice>
						{price > 0 && originalPrice === 0 && (
							<FreeProductText>{translation.t('Free')}</FreeProductText>
						)}
					</ProductTotal>
					<Button
						type="button"
						title={translation.t('Remove product {title}', { title })}
						onClick={onRemove}
						isDisabled={price > 0 && originalPrice === 0}
					>
						<MinusCircleOutlined />
					</Button>
				</ButtonsContainer>
			</Details>
			{!footerNote
				? <Border />
				: (
					<FooterNotes>
						<ExclamationCircleOutlined />
						{footerNote}
					</FooterNotes>
				)}
		</Wrapper>
	)
}

export default ProductRow
