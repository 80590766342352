import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import { selectIsSubmitting } from 'Redux/app/app.selectors'
import { showConfirmationPopup } from 'Redux/app/app.actions'
import {
	goToStep,
	goToPrevStep,
} from 'Redux/app/go-to-step.actions'
import { selectReservation } from 'Redux/reservation/reservation.selectors'
import {
	selectCleaningMidStayState,
	selectSelectedCleaningDatesTimes,
} from 'Redux/reservation/cleaning-mid-stay/cleaning-mid-stay.selectors'
import {
	selectOrderedCleaningDatesTimes,
} from 'Redux/reservation/products/products.selectors'
import {
	clearSelectedCleaningDateTimes,
	removeSelectedCleaningDateTime,
} from 'Redux/reservation/cleaning-mid-stay/cleaning-mid-stay.actions'

import {
	HeaderSticky,
	Section,
	FooterSection,
} from 'Components/view-structure.component'
import YourCleaningsList from './your-cleanings-list.component'

import { FONT_WEIGHTS } from 'Globals/global.styles'

import { TranslationContext, ConstantsContext } from 'Src/context'
import PageView from 'Src/components/PageView'

const ImageFullWidth = styled.div`
	position: relative;
	margin: -16px -16px 16px -16px;
	height: 155px;
	background: url(${({ image }) => image}) center center;
	background-size: cover;
`

const PageHeading = styled.h1`
	margin-bottom: 0;
	margin-top: 0;
	font-size: 28px;
	font-weight: ${FONT_WEIGHTS.normal};
`

const PageDescription = styled.div`
	margin-bottom: 32px;
	font-weight: ${FONT_WEIGHTS.light};

	&,
	& > b {
		font-size: 14px;
	}

	b {
		font-weight: ${FONT_WEIGHTS.normal};
	}
`

const SectionStyled = styled(Section)`
	margin-bottom: 32px;
`

const GuestAreaCleaningMidStayConfirmView = ({
	isSubmitting,
	reservation,
	cleaningMidStayState,
	selectedCleaningDatesTimes,
	orderedCleaningDatesTimes,
	removeSelectedCleaningDateTime,
	clearSelectedCleaningDateTimes,
	goToSelectTime,
	showConfirmationPopup,
	goToCleaningStartView,
	goToGuestAreaWithStore,
}) => {
	const translation = useContext(TranslationContext)
	const {
		constants: {
			ACTIVE_PRODUCT_KEYS,
			DISPLAYABLE_PRODUCTS_DETAILS,
			COMMON_STRINGS,
		},
	} = useContext(ConstantsContext)

	const headerImage = DISPLAYABLE_PRODUCTS_DETAILS
		.find((product) => product.productKey === ACTIVE_PRODUCT_KEYS.cleaningMidStay)?.image

	const { maxCleaningTimesCount } = cleaningMidStayState
	const selectedTimesCount = selectedCleaningDatesTimes?.length || 0

	const goToCleaningStartViewWithConfirmation = () => {
		if (selectedTimesCount) {
			showConfirmationPopup(
				COMMON_STRINGS.leaveStepWithoutSaving,
				() => {
					clearSelectedCleaningDateTimes()
					goToCleaningStartView()
				},
				translation.t('Confirm'),
				translation.t('Cancel'),
			)

			return
		}

		goToCleaningStartView()
	}

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault()
				goToGuestAreaWithStore()
			}}
		>
			<PageView
				Header={() => <HeaderSticky onCloseButton={goToCleaningStartViewWithConfirmation} />}
			>
				<ImageFullWidth image={headerImage} />

				<PageHeading>{translation.t('Confirm schedule')}</PageHeading>
				<PageDescription>
					{/* eslint-disable max-len */}
					{translation.t('These are your scheduled cleanings. Please add any remaining cleanings and confirm your final schedule. You can also come back and schedule your remaining cleanings later. ')}
					<br />
					<br />
					{translation.t('You can have {maxCleaningTimesCount} {maxCleaningTimesCount, plural, =1{cleaning} other{cleanings}} during your current stay.', {
						maxCleaningTimesCount,
					})}
				</PageDescription>

				<SectionStyled>
					<YourCleaningsList
						reservation={reservation}
						cleaningMidStayState={cleaningMidStayState}
						selectedCleaningDatesTimes={selectedCleaningDatesTimes}
						orderedCleaningDatesTimes={orderedCleaningDatesTimes}
						removeSelectedCleaningDateTime={removeSelectedCleaningDateTime}
						goToSelectTime={goToSelectTime}
					/>
				</SectionStyled>

				<FooterSection
					onPrevClick={goToCleaningStartViewWithConfirmation}
					nextButtonText={translation.t('Confirm schedule')}
					prevButtonText={translation.t('Cancel')}
					disableNextButton={isSubmitting}
				/>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	reservation: selectReservation,
	cleaningMidStayState: selectCleaningMidStayState,
	selectedCleaningDatesTimes: selectSelectedCleaningDatesTimes,
	orderedCleaningDatesTimes: selectOrderedCleaningDatesTimes,
	isSubmitting: selectIsSubmitting,
})

const mapDispatchToProps = (dispatch) => ({
	removeSelectedCleaningDateTime: (dateTime) => dispatch(removeSelectedCleaningDateTime(dateTime)),
	clearSelectedCleaningDateTimes: () => dispatch(clearSelectedCleaningDateTimes()),
	showConfirmationPopup: (text, onConfirm, confirmButtonText, cancelButtonText) => dispatch(
		showConfirmationPopup(text, onConfirm, null, confirmButtonText, cancelButtonText),
	),
	goToSelectTime: () => dispatch(goToStep(
		{ step: 'guest-area-cleaning-mid-stay-select-time' },
		{ toSkipPatch: true },
	)),
	goToCleaningStartView: () => dispatch(goToPrevStep({ resetStepHistoryTip: true })),
	goToGuestAreaWithStore: () => dispatch(goToStep({ step: 'guest-area' }, { resetStepHistoryTip: true })),
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestAreaCleaningMidStayConfirmView)
