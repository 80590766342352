import React, { FC, useContext } from 'react'
import styled from 'styled-components'

import { SelectCountry } from './ui'

import { TranslationContext } from 'Src/context'
import SelectItalianProvinces from 'Components/ui/select-italian-communes.component'
import { ValueType } from 'react-select/src/types'
import { SelectOption } from 'Components/ui/async-select.component'

const SelectCountryStyled = styled(SelectCountry)`
	margin-bottom: 16px;
`

const SelectItalianProvincesStyled = styled(SelectItalianProvinces)`
	margin-bottom: 16px;
`

interface Props {
	documentIssueCountry: string;
	documentIssueProvince: string;
	documentIssueProvinceRequired: boolean;
	updateField: (field: string, value: any) => void;
	formErrors: string[];
}

const GuestAdditionalDocumentFields: FC<Props> = ({
	documentIssueCountry,
	documentIssueProvince,
	documentIssueProvinceRequired,
	updateField,
	formErrors,
}) => {
	const translation = useContext(TranslationContext)

	const handleProvinceChange = (province: ValueType<SelectOption, false>): void => {
		updateField('documentIssueProvince', province?.value)
	}

	return (
		<>
			<SelectCountryStyled
				label={`${translation.t('Country of issue')}*`}
				placeholder={translation.t('Select country')}
				name="documentIssueCountry"
				value={documentIssueCountry}
				onChange={({ value }: {value: string}) => updateField('documentIssueCountry', value)}
				isValid={!formErrors?.includes('documentIssueCountry')}
			/>

			{documentIssueProvinceRequired && (
				<SelectItalianProvincesStyled
					label={`${translation.t('Comune of issue')}*`}
					placeholder={translation.t('Find a province')}
					name="documentIssueProvince"
					value={documentIssueProvince}
					onChange={handleProvinceChange}
					isValid={!formErrors?.includes('documentIssueProvince')}
				/>
			)}
		</>
	)
}

export default GuestAdditionalDocumentFields
