import React from 'react'
import styled, { css } from 'styled-components'

import {
	COLORS,
	FONT_WEIGHTS,
	generateMediaQuery,
} from 'Globals/global.styles'
import { CheckOutlined } from '@ant-design/icons'

const HypeLineWrapper = styled.div`
	display: flex;
	flex-wrap: nowrap;
	margin-bottom: 8px;
	color: ${COLORS.text.secondary};
	font-size: 14px;
	line-height: 20px;
`

const HypeLineDotBullet = styled.div`
	margin: 5px 20px 0 0;

	background-color: ${COLORS.misc.bob_w_gold_bright};
	border: 1px solid ${COLORS.misc.bob_w_gold_bright};
	min-height: 7px;
	max-height: 7px;
	min-width: 7px;
	max-width: 7px;
	border-radius: 100%;

	${generateMediaQuery.mobileSmall(css`
		margin: 5px 16px 0 0;
	`)}
`

const HypeLineBullet = styled(CheckOutlined)`
	margin: 2px 20px 0 0;

	svg path {
		color: ${COLORS.misc.bob_w_gold_bright}
	}

	${generateMediaQuery.mobileSmall(css`
		margin: 2px 16px 0 0;
	`)}
`

const HypeLine = styled.div`
	&,
	> div,
	& > b {
		color: ${COLORS.text.secondary};
		font-size: 14px;
		line-height: 20px;
	}

	& > b {
		font-weight: ${FONT_WEIGHTS.normal};
	}
`

const HypeLines = ({ lines, useDots }) => (
	lines.map((line) => !!line && (
		<HypeLineWrapper key={line}>
			{useDots ? (
				<HypeLineDotBullet />
			) : (
				<HypeLineBullet />
			)}
			<HypeLine>{line}</HypeLine>
		</HypeLineWrapper>
	))
)

export default HypeLines
