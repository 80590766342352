import React, { useContext } from 'react'
import styled from 'styled-components'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import productHeaderImage from 'Assets/stocked-fridge/stocked-fridge-details-header.webp'
import SingleProductWrapper from 'Views/check-in-views/products-view/single-product-wrapper.component'
import HypeLines from 'Components/ui/hype-lines.component'
import { ContentMainHeading } from 'Components/view-structure.component'
import { COLORS } from 'Globals/global.styles'
import { TranslationContext, ConstantsContext } from 'Src/context'
import { selectProducts } from 'Redux/reservation/products/products.selectors'
import { selectReservation } from 'Redux/reservation/reservation.selectors'
import { updateCurrentStepField } from 'Redux/app/app.actions'

const TextContent = styled.div`
	font-size: 14px;
	color: ${COLORS.text.secondary};
	margin-bottom: 24px;
`

const StockedFridgeViewComponent = ({ reservation }) => {
	const translation = useContext(TranslationContext)

	const { constants: { STOCKED_FRIDGE_DETAILS } } = useContext(ConstantsContext)
	const ingredients = STOCKED_FRIDGE_DETAILS[reservation.property.key]?.ingredients || []

	const delivery = [
		translation.t('Your groceries will be waiting in your apartment’s fridge on the day you arrive.'),
		translation.t('Need delivery on a different day? Contact us to set it up.'),
	]

	return (
		<SingleProductWrapper
			title={translation.t('Stocked Fridge with Wolt')}
			titleAfterImage
			/* eslint-disable max-len */
			description={translation.t('Crazy travel schedule and no time to run to the store? Let us take care of it. We’ll stock your fridge with a few days worth of essentials. Get healthy classics and local favourites perfect for snacks or breakfast.')}
			image={productHeaderImage}
			buttonText={translation.t('Add Stocked Fridge')}
		>
			<ContentMainHeading>
				{translation.t('Delivery')}
			</ContentMainHeading>
			<TextContent>
				<HypeLines lines={delivery} useDots />
			</TextContent>
			{ingredients.length > 0
				&& (
					<>
						<ContentMainHeading>
							{translation.t('What’s included')}
						</ContentMainHeading>
						<TextContent>
							<HypeLines lines={ingredients} />
						</TextContent>
					</>
				)}

			{/* For automated test purposes */}
			<div hidden>product-stocked-fridge</div>
		</SingleProductWrapper>
	)
}

const mapStateToProps = createStructuredSelector({
	products: selectProducts,
	reservation: selectReservation,
})

const mapDispatchToProps = (dispatch) => ({
	updateField: (fieldName, data) => dispatch(updateCurrentStepField(fieldName, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StockedFridgeViewComponent)
