enum ReservationActionTypes {
	RESET_RESERVATION_STATE = 'RESET_RESERVATION_STATE',
	UPDATE_RESERVATION = 'UPDATE_RESERVATION',

	GET_RESERVATION_START = 'GET_RESERVATION_START',
	GET_RESERVATION_SUCCESS = 'GET_RESERVATION_SUCCESS',

	PATCH_RESERVATION_START = 'PATCH_RESERVATION_START',
	PATCH_RESERVATION_SUCCESS = 'PATCH_RESERVATION_SUCCESS',
}

export default ReservationActionTypes
