import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled, { css } from 'styled-components'

import GuestAmountSelector from 'Components/guest-amount-selector.component'
import GuestRow from 'Components/guest-row.component'
import {
	ContentHeader,
	ContentMainHeading,
	FooterSection,
	HeaderSticky,
	Section,
} from 'Components/view-structure.component'
import { ResetButton } from 'Components/ui'

import { TranslationContext } from 'Src/context'

import { selectAppState, selectIsSubmitting } from 'Redux/app/app.selectors'
import { showAlertPopup, showConfirmationPopup } from 'Redux/app/app.actions'
import { goToPrevStep, goToStep } from 'Redux/app/go-to-step.actions'
import { selectIsCityTaxRequired, selectCity, selectReservation } from 'Redux/reservation/reservation.selectors'
import {
	selectAdditionalGuests,
	selectAllAdditionalGuestDetailsAreComplete,
	selectMainGuestCustomer,
} from 'Redux/reservation/guests/guests.selectors'
import { selectProductsPageListItems } from 'Redux/reservation/products/products.selectors'

import { removeAdditionalGuest } from 'Redux/reservation/guests/guests.actions'

import { COLORS, FONT_WEIGHTS } from 'Globals/global.styles'
import { checkProductsForAvailability } from 'Redux/reservation/products/products.actions'
import PageView from 'Src/components/PageView'
import { CityTaxInfo } from 'Components/ui/city-tax-info.component'

const Heading = styled(ContentMainHeading)`
	> span {
		color: ${COLORS.text.secondary};
	}

	${({ greatSuccess, problem }) => {
		if (greatSuccess) {
			return css`
				> span {
					color: ${COLORS.text.greatSuccess};
				}
			`
		} if (problem) {
			return css`
				> span {
					color: ${COLORS.text.alert};
				}
			`
		}
	}}
`

const AddGuestButton = styled(ResetButton)`
	margin-top: 8px;
	font-weight: ${FONT_WEIGHTS.normal};
	line-height: 2.2em;
	color: ${COLORS.text.link};

	&:hover {
		color: ${COLORS.text.linkHover};
	}
`

const GuestsView = ({
	appState: { progressData },
	isSubmitting,
	reservation,
	mainGuestCustomer,
	additionalGuests,
	availableProducts,
	allAdditionalGuestDetailsAreComplete,
	removeAdditionalGuest,
	showConfirmationPopup,
	showAlertPopup,
	updateProductsWithAvailability,
	goToNextStep,
	goToPrevStep,
	openMainGuestView,
	openAdditionalGuestView,
	city,
	isCityTaxRequired,
}) => {
	const [isFetching, setIsFetching] = useState(false)
	const [currentGuestsCount, setCurrentGuestsCount] = useState(reservation.adultCount + reservation.childrenCount)
	const translation = useContext(TranslationContext)
	const {
		slug: reservationSlug,
		property: {
			phone: propertyPhone,
		},
		requestedPropertyUnitCategory: {
			capacity,
			extraCapacity,
		},
	} = reservation
	const {
		firstName: mainGuestFirstName,
		lastName: mainGuestLastName,
	} = mainGuestCustomer

	const maxGuestCount = capacity + extraCapacity
	const addedGuestsCount = (additionalGuests?.length ?? 0) + 1

	const onNextClicked = () => {
		let popupText

		if (currentGuestsCount < addedGuestsCount) {
			/* eslint-disable max-len */
			popupText = translation.t('The guest count is smaller than the number of added guests. Please remove {count} {count, plural, =1{guest} other{guests}} to proceed!', { count: addedGuestsCount - currentGuestsCount })
		} else if (currentGuestsCount > addedGuestsCount) {
			/* eslint-disable max-len */
			popupText = translation.t('The guest count is bigger than the number of added guests. Please add {count} {count, plural, =1{guest} other{guests}} to proceed!', { count: currentGuestsCount - addedGuestsCount })
		} else if (!allAdditionalGuestDetailsAreComplete) {
			popupText = translation.t('Please fill in all the required details of each guest to proceed!')
		}

		if (popupText) {
			showAlertPopup(popupText)
			return
		}

		goToNextStep()
	}

	useEffect(() => {
		const run = async () => {
			setIsFetching(true)
			await updateProductsWithAvailability()
			setIsFetching(false)
		}

		if (!availableProducts?.length) {
			run()
		}
	}, [])

	const removeGuestWithConfirmationPrompt = (reservationSlug, guestId) => {
		showConfirmationPopup(
			translation.t('Are you sure you want to remove the guest?'),
			() => removeAdditionalGuest(reservationSlug, guestId),
			translation.t('Yes, remove'),
			translation.t('No'),
		)
	}

	const getGuestViewHeadingDescriptions = useCallback(() => {
		if (isCityTaxRequired) {
			return (
				<>
					{translation.t('According to local law, we have to collect city tax and details of all guests staying at Bob W')}
				</>
			)
		}
		return (
			<>
				{translation.t('This information is collected for your safety and it is ')}

				<b>{translation.t('legally required')}</b>
			</>
		)
	}, [])

	const mainText = useMemo(() => {
		if (isCityTaxRequired) {
			return translation.t('Guest Details & City tax')
		}

		return translation.t('Guest Details')
	}, [isCityTaxRequired])

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault()
				onNextClicked()
			}}
		>
			<PageView
				Header={() => (
					<HeaderSticky
						{...progressData}
						onBackButtonClick={goToPrevStep}
						contactButton={{ url: `tel:${propertyPhone}` }}
					/>
				)}
			>
				<ContentHeader
					mainText={mainText}
					subText={getGuestViewHeadingDescriptions()}
				/>

				<Section>
					<GuestAmountSelector
						label={translation.t('Number of guests')}
						name="guestCount"
						value={currentGuestsCount}
						minValue={1}
						maxValue={maxGuestCount}
						handleChange={(value) => setCurrentGuestsCount(value)}
					/>
				</Section>

				<Section>
					<Heading
						greatSuccess={
							addedGuestsCount === currentGuestsCount
							&& allAdditionalGuestDetailsAreComplete
						}
						problem={addedGuestsCount > currentGuestsCount}
					>
						{translation.t('Guests')}
						{' '}
						<span>
							{translation.t('{addedGuestsCount} of {currentGuestsCount} added', {
								addedGuestsCount,
								currentGuestsCount,
							})}
						</span>
					</Heading>

					<GuestRow
						firstName={mainGuestFirstName}
						lastName={mainGuestLastName}
						subText={translation.t('Main Guest')}
						editGuest={openMainGuestView}
					/>

					{additionalGuests.map((guest) => {
						const {
							id: guestId,
							guestDetailsAreComplete,
							customer: { id, firstName, lastName },
						} = guest

						return (
							<GuestRow
								key={id}
								firstName={firstName}
								lastName={lastName}
								hasIncompleteDetails={!guestDetailsAreComplete}
								removeGuest={() => removeGuestWithConfirmationPrompt(reservationSlug, guestId)}
								editGuest={() => openAdditionalGuestView(guestId)}
							/>
						)
					})}

					{addedGuestsCount < currentGuestsCount && (
						<AddGuestButton
							type="button"
							onClick={() => openAdditionalGuestView(null)}
						>
							+
							{' '}
							{translation.t('Add Guest')}
						</AddGuestButton>
					)}
				</Section>

				<Section>
					<CityTaxInfo city={city} isCityTaxRequired={isCityTaxRequired} />
				</Section>

				<FooterSection
					onPrevClick={goToPrevStep}
					disableNextButton={isSubmitting || isFetching}
					nextButtonText={isFetching ? translation.t('Loading...') : translation.t('Next')}
				/>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	appState: selectAppState,
	reservation: selectReservation,
	mainGuestCustomer: selectMainGuestCustomer,
	availableProducts: selectProductsPageListItems,
	additionalGuests: selectAdditionalGuests,
	allAdditionalGuestDetailsAreComplete: selectAllAdditionalGuestDetailsAreComplete,
	isSubmitting: selectIsSubmitting,
	city: selectCity,
	isCityTaxRequired: selectIsCityTaxRequired,
})

const mapDispatchToProps = (dispatch) => ({
	removeAdditionalGuest: (reservationId, guestId) => dispatch(
		removeAdditionalGuest(reservationId, guestId),
	),
	updateProductsWithAvailability: () => dispatch(checkProductsForAvailability()),
	showAlertPopup: (text) => dispatch(showAlertPopup(text)),
	showConfirmationPopup: (text, onConfirm, confirmButtonText, cancelButtonText) => dispatch(
		showConfirmationPopup(text, onConfirm, null, confirmButtonText, cancelButtonText),
	),
	goToNextStep: () => dispatch(goToStep()),
	goToPrevStep: () => dispatch(goToPrevStep()),
	openMainGuestView: () => dispatch(goToStep({ step: 'main-guest-details' })),
	openAdditionalGuestView: (guestId) => dispatch(goToStep(
		{
			step: 'additional-guest-details',
			guestId,
		},
	)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestsView)
