import { CardAuthorizationState, CreditCardFormat, CreditCardModel, CreditCardState } from '@/models/credit-card'
import { getCoreApiBaseUrl } from '@/utils/env'

interface AuthorizationUrlParams {
	readonly cardId: string;
	readonly returnPath: string;
	readonly slug: string;
}

export function generateAuthorizationUrl({ cardId, returnPath, slug } : AuthorizationUrlParams) : string {
	const returnUrl = new URL(`${window.location.origin}/${slug}${returnPath}`)
	const coreApiBaseUrl = getCoreApiBaseUrl()
	const encodedReturnUrl = encodeURIComponent(returnUrl.toString())

	return `${coreApiBaseUrl}/credit-cards/${cardId}/auth?returnUrl=${encodedReturnUrl}`
}

export function isCardAuthorizationValid(state: CardAuthorizationState) : boolean {
	return (
		state === CardAuthorizationState.Authorized
        || state === CardAuthorizationState.Unauthorizable
	)
}

export function isCreditCardValid(card: CreditCardModel) : boolean {
	return (
		card.state === CreditCardState.Enabled
        && card.format === CreditCardFormat.Physical
        && isCardAuthorizationValid(card.authorizationState)
	)
}

export function getValidGuestCards(guestCards: CreditCardModel[]) : CreditCardModel[] {
	return guestCards
		.filter((card) => isCreditCardValid(card))
}

export function getLastValidGuestCard(guestCards: CreditCardModel[]): CreditCardModel | null {
	const validCards = getValidGuestCards(guestCards)
	const sortedCards = validCards.sort((cardA, cardB) => {
		const dateA = new Date(cardA.createdAt)
		const dateB = new Date(cardB.createdAt)

		return dateB.getTime() - dateA.getTime()
	})

	return sortedCards[0] ?? null
}

export function isGuestCardValid(guestCards: CreditCardModel[], cardId: unknown) : boolean {
	if (typeof cardId !== 'string' || guestCards.length === 0) {
		return false
	}

	const foundCard = guestCards.find((card) => card.id === cardId)

	if (foundCard === undefined) {
		return false
	}

	return isCreditCardValid(foundCard)
}

export function getValidCreditCard(
	reservationCard: CreditCardModel | null,
	guestCards: CreditCardModel[],
): CreditCardModel | null {
	if (
		reservationCard !== null
		&& isCreditCardValid(reservationCard)
	) {
		return reservationCard
	}

	if (guestCards.length === 0) {
		return null
	}

	return getLastValidGuestCard(guestCards)
}
