import { getCoreApiBaseUrl } from '@/utils/env'
import { transformBreakfastPartnerContent } from '@/api/transforms/transforms'
import { BreakfastContentData } from 'Types/breakfast-content.types'

const apiHost = getCoreApiBaseUrl()

type Response = Promise<BreakfastContentData|null>

const getBreakfastContent = async (propertyId: string, languageCode: string): Response => {
	const url = `${apiHost}/product-content?propertyId=${propertyId}&productType=breakfast&$expand[]=relatedContent.productPartnerContent`

	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'x-language-code': languageCode,
			},
		})
		const res = await response.json()
		return res.data?.length > 0 ? transformBreakfastPartnerContent(res.data[0]) : null
	} catch {
		return null
	}
}

export default getBreakfastContent
