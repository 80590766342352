import React, { FC, useMemo } from 'react'

import { ValueType } from 'react-select/src/types'
import Select from './select.component'

export interface SelectOption {
	readonly label: string;
	readonly value: string;
}

interface Props {
	value: string;
	label: string;
	name: string;
	placeholder: string;
	isValid: boolean;
	provinces: SelectOption[];
	onChange: (province: ValueType<SelectOption, false>) => void;
}

const SelectProvinces: FC<Props> = ({
	value: countrySubdivisionCode,
	provinces,
	...otherProps
}) => {
	const value = useMemo(() => {
		return provinces.find((province) => province.value === countrySubdivisionCode)
	}, [countrySubdivisionCode, provinces])

	return (
		<Select
			className={null}
			value={value}
			options={provinces}
			{...otherProps}
		/>
	)
}

export default SelectProvinces
