import React, { FC, useEffect, useState } from 'react'
import ls from './local-storage/local-storage'
import CheckIn from './flows/check-in.component'
import ErrorView from './views/error-view.component'
import Popup from './components/popup.component'
import withSnackbar from './components/with-snackbar.component'
import { handleBrowserBackForwardButton } from 'Redux/app/go-to-step.actions'
import { closePopupAction } from 'Redux/app/app.actions'
import { ConstantsContext } from 'Src/context'
import { getConfig } from 'Globals/constants'
import { useAppDispatch, useAppSelector } from 'Redux/hooks'

import {
	selectPopupData,
	selectSnackbarText,
	selectErrorViewVisible,
} from 'Redux/app/app.selectors'
import EnvironmentSwitcher from 'Components/debug/EnvironmentSwitcher'
import { setCorrectFavicon } from '@/utils/dom'
import { useAnalyticsScripts } from '@/hooks/use-analytics-scripts'

interface Props {
	readonly showSnackbar: any;
	readonly closeSnackbar: any;
}

setCorrectFavicon()

const App: FC<Props> = ({ showSnackbar, closeSnackbar }) => {
	const { flow, reservationSlug } = window.bobw
	const [constants, setConstants] = useState(getConfig())
	const dispatch = useAppDispatch()
	const popupData = useAppSelector(selectPopupData)
	const snackbarText = useAppSelector(selectSnackbarText)
	const errorViewVisible = useAppSelector(selectErrorViewVisible)
	// @ts-ignore TODO: improve redux typings
	const closePopup = () => dispatch(closePopupAction())

	useAnalyticsScripts()

	useEffect(() => {
		ls.init(flow, reservationSlug)

		const listener = (event : PopStateEvent) => dispatch(
			// @ts-ignore TODO: improve typings
			handleBrowserBackForwardButton(event, constants.COMMON_STRINGS.leaveStepWithoutSaving),
		)

		window.addEventListener('popstate', listener)

		return () => window.removeEventListener('popstate', listener)
	}, [constants.COMMON_STRINGS.leaveStepWithoutSaving])

	useEffect(() => {
		if (snackbarText) {
			showSnackbar(snackbarText)
		} else {
			closeSnackbar(1000)
		}
	}, [snackbarText])

	const reloadConfig = () => {
		setConstants(getConfig())
	}

	if (errorViewVisible || !flow || !reservationSlug) {
		return (
			<>
				<ErrorView flow={flow} />
				<EnvironmentSwitcher />
			</>
		)
	}

	return (
		// @ts-ignore TODO: improve typings
		<ConstantsContext.Provider value={{ constants, reloadConfig }}>
			{(() => {
				if (flow === 'CHECK_IN') {
					return (
						<>
							<CheckIn flow={flow} reservationSlug={reservationSlug} />
							<EnvironmentSwitcher />
						</>
					)
				}

				return (
					<>
						<ErrorView flow={flow} />
						<EnvironmentSwitcher />
					</>
				)
			})()}

			{/* @ts-ignore TODO: improve TODO: typings */}
			<Popup {...popupData} close={closePopup} />

		</ConstantsContext.Provider>
	)
}

export default withSnackbar(App)
