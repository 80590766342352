import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { UiState } from './ui.reducer'

export function selectUiState(state: RootState) : UiState {
	return state.ui
}

export const selectIsEmailVerificationDialogOpened = createSelector(selectUiState, (uiState) => {
	return uiState.isVerificationDialogOpened
})
