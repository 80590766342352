import React from 'react'
import { inRange } from 'lodash'

import FormInput from './form-input.component'

const combineDisplayValue = (value) => {
	if (!value) return ''

	const monthInt = parseInt((value).slice(0, 2))

	if (!inRange(monthInt, 1, 13)) return value

	return value.length > 1
		? `${value.slice(0, 2)}/${value.slice(2, 4)}`
		: value
}

const MonthYearPicker = ({
	handleChange,
	value: currentValue,
	...otherProps
}) => {
	const onChange = (value) => {
		let newValue = (value || '').replace('/', '')

		const monthInt = parseInt(newValue.substring(0, 2))
		const hasEnteredValidMonth = inRange(monthInt, 1, 13)

		if (monthInt && !hasEnteredValidMonth) return

		// Allow only numbers
		if (newValue && !/^\d+$/.test(newValue)) return

		// Allow only one zero in the start of the value
		if (parseInt(newValue) === 0) {
			newValue = '0'
		}

		// Add zero if chars 2-9 entered as first chars
		if (newValue.length === 1 && inRange(monthInt, 2, 10)) {
			newValue = `0${newValue}`
		}

		const isAddingSlashSign = value?.includes('/') && !currentValue?.includes('/')
		const isAddingValidSlashSign = isAddingSlashSign && /^(0[1-9]|[1-9]|1[0-2])\/\d{0,2}$/.test(value)

		if (isAddingSlashSign && !isAddingValidSlashSign) return

		const isDeletingSlashSign = !value?.includes('/') && currentValue?.includes('/')

		if (isAddingValidSlashSign) {
			newValue = newValue.length === 1 ? `0${newValue}/` : value
		} else if (isDeletingSlashSign) {
			newValue = newValue.slice(0, 1)
		} else {
			newValue = combineDisplayValue(newValue)
		}

		handleChange(newValue)
	}

	return (
		<FormInput
			{...otherProps}
			handleChange={onChange}
			value={currentValue}
			maxLength={5}
			exclamationMark
		/>
	)
}

export default MonthYearPicker
