import {
	CardAuthorizationState,
	CreditCardFormat,
	CreditCardModel,
	CreditCardState,
	CreditCardType,
} from '@/models/credit-card'

export interface CreditCard {
	readonly authorizationState: string | null;
	readonly createdAt: string;
	readonly customerId: string;
	readonly expirationDate: string;
	readonly format: string;
	readonly id: string;
	readonly name: string;
	readonly numberMasked: string;
	readonly propertyId: string;
	readonly state: string;
	readonly type: string;
	readonly transactionId: string | null;
}

function transformCreditCardFormat(format: string) : CreditCardFormat {
	switch (format) {
		case 'physical': {
			return CreditCardFormat.Physical
		}

		case 'virtual': {
			return CreditCardFormat.Virtual
		}

		default: {
			return CreditCardFormat.Unknown
		}
	}
}

function transformCreditCardState(state: string) : CreditCardState {
	switch (state) {
		case 'enabled': {
			return CreditCardState.Enabled
		}

		default: {
			return CreditCardState.Unknown
		}
	}
}

function transformCreditCardType(type: string) : CreditCardType {
	switch (type) {
		case 'visa': {
			return CreditCardType.Visa
		}

		case 'mastercard': {
			return CreditCardType.MasterCard
		}

		case 'amex': {
			return CreditCardType.Amex
		}

		case 'diners': {
			return CreditCardType.Diners
		}

		case 'discover': {
			return CreditCardType.Discover
		}

		case 'jcb': {
			return CreditCardType.Jcb
		}

		case 'unionpay': {
			return CreditCardType.UnionPay
		}

		default: {
			return CreditCardType.Unknown
		}
	}
}

function transformCardAuthorizationState(state: string | null) : CardAuthorizationState {
	switch (state) {
		case 'authorized': {
			return CardAuthorizationState.Authorized
		}

		case 'confirmed': {
			return CardAuthorizationState.Confirmed
		}

		case 'canceled': {
			return CardAuthorizationState.Canceled
		}

		case 'declined': {
			return CardAuthorizationState.Declined
		}

		case 'pending': {
			return CardAuthorizationState.Pending
		}

		case 'requested': {
			return CardAuthorizationState.Requested
		}

		case 'authorizable': {
			return CardAuthorizationState.Authorizable
		}

		case 'unauthorizable': {
			return CardAuthorizationState.Unauthorizable
		}

		default: {
			return CardAuthorizationState.Unknown
		}
	}
}

export function transformCreditCard(creditCard: CreditCard | null) : CreditCardModel | null {
	if (creditCard === null) {
		return null
	}

	const { id, numberMasked, name, expirationDate, createdAt } = creditCard
	const format = transformCreditCardFormat(creditCard.format)
	const state = transformCreditCardState(creditCard.state)
	const type = transformCreditCardType(creditCard.type)
	const authorizationState = transformCardAuthorizationState(creditCard.authorizationState)
	const transactionId = creditCard?.transactionId ?? ''

	return {
		authorizationState,
		createdAt,
		format,
		id,
		numberMasked,
		expirationDate,
		state,
		transactionId,
		type,
		cardHolderName: name,
	}
}

function isCreditCard(card: CreditCardModel | null) : card is CreditCardModel {
	return card !== null
}

export function transformCreditCards(creditCards: (CreditCard | null)[]) : CreditCardModel[] {
	return creditCards
		.map(transformCreditCard)
		.filter(isCreditCard)
}
