import React, { useContext, useEffect, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'
import { format, utcToZonedTime } from 'date-fns-tz'

import {
	ContentHeader,
	ContentMainHeading,
	ContentSubHeading,
	FooterSection,
	HeaderSticky,
	InvalidFieldsAlertSection,
	Section,
} from 'Components/view-structure.component'
import { Select } from '../../../components/ui'

import {
	combineArrivalTimeOptions,
	combineDepartureTimeOptions,
	getCurrencyIcon,
	getPropertyTimeZonedDate,
} from 'Src/utils'

import { selectAppState, selectIsFinalStep, selectIsSubmitting } from 'Redux/app/app.selectors'
import { updateCurrentStepField } from 'Redux/app/app.actions'
import { goToPrevStep, goToStep } from 'Redux/app/go-to-step.actions'
import { selectIsWhiteLabel, selectReservation, selectReservationHasStarted } from 'Redux/reservation/reservation.selectors'
import {
	selectArrivalTimeData,
	selectAvailableTimes,
	selectDepartureTimeData,
} from 'Redux/reservation/times/times.selectors'
import {
	selectOrderedEarlyCheckIn,
	selectOrderedLateCheckOut,
	selectSelectedProductKeys,
} from 'Redux/reservation/products/products.selectors'
import { getAvailableTimes } from 'Redux/reservation/times/times.actions'

import { COLORS, FONT_WEIGHTS } from 'Globals/global.styles'

import { TranslationContext } from 'Src/context'
import PageView from 'Src/components/PageView'
import { PropertyKey, propertyKeysMap } from 'Src/globals/constants'

const SelectStyled = styled(Select)`
	margin-bottom: 16px;
`

const ConfirmedTimes = styled.div`
	> div:last-child {
		margin-left: 32px;
		padding-left: 32px;

		border-left-width: 1px;
		border-left-style: solid;
		border-left-color: ${COLORS.border.minor};
	}
`
const ConfirmedTimesItem = styled.div`
	display: inline-block;
`
const CTItemLabel = styled.div`
	font-size: 13px;
`
const CTItemDate = styled.div`
	font-size: 14px;
`
const CTItemTime = styled.div`
	font-size: 20px;
	font-weight: ${FONT_WEIGHTS.normal};

	> span {
		font-size: 13px;
		font-weight: ${FONT_WEIGHTS.light};
	}
`

const TimeSection = styled.div`
	margin-bottom: 16px;
`

const TimeSectionLabel = styled.div`
	font-size: 14px;
	margin-bottom: 2px;
`

const TimesView = ({
	appState,
	isFinalStep,
	isSubmitting,
	reservation,
	reservationHasStarted,

	selectedProducts,
	orderedEarlyCheckIn,
	orderedLateCheckOut,
	availableTimes,

	arrivalTimeData,

	departureTimeData,

	getTimes,
	updateField,
	goToNextStep,
	goToPrevStep,
}) => {
	const translation = useContext(TranslationContext)
	const {
		progressData,
		currentStepFormErrors: formErrors,
	} = appState
	const {
		property: {
			phone: propertyPhone,
			timezone: propertyTimeZone,
			defaultCurrency,
			key: propertyKey,
		},
	} = reservation
	const { selectedArrivalTimeUTC, confirmedArrivalDateUTC } = arrivalTimeData

	const {
		selectedDepartureTimeUTC,
		confirmedDepartureDateUTC,
	} = departureTimeData

	const {
		checkIn,
		checkOut,
		superEarlyCheckIn: superEarly,
		superLateCheckOut: superLate,
	} = availableTimes

	const regularCheckInTimes = checkIn?.filter((time) => !time.productId)
	const regularCheckOutTimes = checkOut?.filter((time) => !time.productId)
	const currencyIcon = getCurrencyIcon(defaultCurrency)
	const isOsloGro16Property = propertyKeysMap[propertyKey] === PropertyKey.OsloGro16

	const updateTimesProductKeys = () => {
		const toggleSelectedSuperCIorCO = (chosenTime, alreadySelectedTime) => {
			if (
				(chosenTime && !alreadySelectedTime)
				|| (!chosenTime && alreadySelectedTime)
			) {
				updateField('products', [alreadySelectedTime || chosenTime.productKey])
			} else if (
				chosenTime && alreadySelectedTime && alreadySelectedTime !== chosenTime.productKey
			) {
				updateField('products', [alreadySelectedTime])
				updateField('products', [chosenTime.productKey])
			}
		}

		if (superEarly) {
			const choseSuperEarlyCheckIn = superEarly.find((time) => time.startDate === selectedArrivalTimeUTC)
			const hasSuperEarlyCheckIn = selectedProducts.find(
				(key) => superEarly.map(({ productKey }) => productKey).includes(key),
			)
			toggleSelectedSuperCIorCO(choseSuperEarlyCheckIn, hasSuperEarlyCheckIn)
		}
		if (superLate) {
			const choseSuperLateCheckOut = superLate.find((time) => time.endDate === selectedDepartureTimeUTC)
			const hasSuperLateCheckOut = selectedProducts.find(
				(key) => superLate.map(({ productKey }) => productKey).includes(key),
			)
			toggleSelectedSuperCIorCO(choseSuperLateCheckOut, hasSuperLateCheckOut)
		}
	}

	const formatToTime = (time) => {
		return format(
			utcToZonedTime(new Date(time), propertyTimeZone),
			'HH:mm',
		)
	}

	useEffect(() => {
		const run = async () => {
			await getTimes()
		}

		run()
	}, [])

	useEffect(() => {
		updateTimesProductKeys()
	}, [arrivalTimeData, departureTimeData])

	useEffect(() => {
		if (orderedEarlyCheckIn) {
			updateField('arrivalTime', orderedEarlyCheckIn.consumptionDate)
		}
	}, [orderedEarlyCheckIn])

	useEffect(() => {
		if (orderedLateCheckOut) {
			updateField('departureTime', orderedLateCheckOut.consumptionDate)
		}
	}, [orderedLateCheckOut])

	const hasArrivalTimes = useMemo(() => {
		const hasRegularCheckInTimes = regularCheckInTimes?.length > 0 ?? false
		const hasSuperEarlyTimes = superEarly?.length > 0 ?? false
		return hasRegularCheckInTimes || hasSuperEarlyTimes
	}, [regularCheckInTimes, superEarly])

	useEffect(() => {
		if (!orderedEarlyCheckIn && !hasArrivalTimes) {
			updateField('arrivalTime', confirmedArrivalDateUTC)
		} else if (!orderedEarlyCheckIn && hasArrivalTimes) {
			updateField('arrivalTime', null)
		}
	}, [orderedEarlyCheckIn, hasArrivalTimes])

	const isWhiteLabel = useSelector(selectIsWhiteLabel)

	const arrivalTime = useMemo(() => {
		if (regularCheckInTimes?.length > 0 || orderedEarlyCheckIn) {
			const dateToFormat = orderedEarlyCheckIn
				? orderedEarlyCheckIn.consumptionDate
				: regularCheckInTimes[0].startDate

			return formatToTime(dateToFormat)
		} else if (!hasArrivalTimes) {
			return formatToTime(confirmedArrivalDateUTC)
		}

		return '15:00'
	}, [regularCheckInTimes, orderedEarlyCheckIn, hasArrivalTimes])

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault()
				goToNextStep()
			}}
		>
			<PageView
				Header={() => (
					<HeaderSticky
						{...progressData}
						onBackButtonClick={goToPrevStep}
						contactButton={{ url: `tel:${propertyPhone}` }}
					/>
				)}
			>
				<ContentHeader
					mainText={translation.t('Arrival and Departure')}
					subText={translation.t('These are our regular check-in and check-out times')}
				>
					<ConfirmedTimes>
						<ConfirmedTimesItem>
							<CTItemLabel>{translation.t('Check-In')}</CTItemLabel>
							<CTItemDate>
								{getPropertyTimeZonedDate(
									confirmedArrivalDateUTC,
									propertyTimeZone,
								)}
							</CTItemDate>
							<CTItemTime>
								<span>{translation.t('from')}</span>
								{' '}
								{ arrivalTime }
							</CTItemTime>
						</ConfirmedTimesItem>

						<ConfirmedTimesItem>
							<CTItemLabel>{translation.t('Check-Out')}</CTItemLabel>
							<CTItemDate>
								{getPropertyTimeZonedDate(
									confirmedDepartureDateUTC,
									propertyTimeZone,
								)}
							</CTItemDate>
							<CTItemTime>
								<span>{translation.t('till')}</span>
								{' '}
								{(regularCheckOutTimes?.length > 0 || orderedLateCheckOut) && formatToTime(
									orderedLateCheckOut
										? orderedLateCheckOut.consumptionDate
										: regularCheckOutTimes[regularCheckOutTimes.length - 1]?.endDate,
								)}
							</CTItemTime>
						</ConfirmedTimesItem>
					</ConfirmedTimes>
				</ContentHeader>

				{!!formErrors?.length && <InvalidFieldsAlertSection />}

				<Section>
					<ContentMainHeading>
						{ translation.t('Pick your preferred time') }
					</ContentMainHeading>

					<ContentSubHeading>
						{ isOsloGro16Property
							? translation.t('Please tell us your arrival time below, so we can welcome you and hand over the apartment keys.')
							: translation.t('When one person checks out early, another can check in early. Tell us about your plans in advance to help a fellow traveller out and earn some good karma.') }
					</ContentSubHeading>

					<ContentSubHeading>
						{ isWhiteLabel
							? translation.t('Below times are currently available for check-in and check-out. If you do not see your preferred time feel free to contact us.')
							: translation.t('Below times are currently available for check-in and check-out. If you do not see your preferred time feel free to contact Bob.') }
					</ContentSubHeading>
				</Section>

				<Section>
					{ (orderedEarlyCheckIn || !hasArrivalTimes) && (
						<TimeSection>
							<TimeSectionLabel>
								{ translation.t('Arrival time') }
							</TimeSectionLabel>
							<div>{ arrivalTime }</div>
						</TimeSection>
					)}

					{
						!orderedEarlyCheckIn && hasArrivalTimes && (
							<SelectStyled
								label={`${translation.t('Select arrival time')}*`}
								name="arrival time"
								placeholder={translation.t('Select time')}
								isDisabled={reservationHasStarted}
								isSearchable={false}
								styles={{
									option: (base) => ({
										...base,
										height: '100%',
										width: '100%',
										display: 'flex',
										justifyContent: 'space-between',
									}),
									control: (base) => ({
										...base,
										border: '1px solid rgb(0,0,0)',
										borderRadius: 0,
									}),
								}}
								options={checkIn && combineArrivalTimeOptions(
									superEarly || [],
									regularCheckInTimes,
									propertyTimeZone,
									translation,
									currencyIcon,
								)}
								value={
									selectedArrivalTimeUTC && !Number.isNaN(Date.parse(selectedArrivalTimeUTC)) && {
										value: selectedArrivalTimeUTC,
										label: formatToTime(selectedArrivalTimeUTC),
									}
								}
								onChange={(option) => updateField('arrivalTime', option.value?.startDate)}
								isValid={!formErrors?.includes('arrivalTime')}
							/>
						)
					}

					{ orderedLateCheckOut && (
						<TimeSection>
							<TimeSectionLabel>
								{ translation.t('Departure time') }
							</TimeSectionLabel>
							<div>{ formatToTime(orderedLateCheckOut.consumptionDate) }</div>
						</TimeSection>
					)}

					{ !orderedLateCheckOut && (
						<SelectStyled
							label={`${translation.t('Select departure time')}*`}
							name="departure time"
							placeholder={translation.t('Select time')}
							isSearchable={false}
							styles={{
								option: (base) => ({
									...base,
									height: '100%',
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
								}),
								control: (base) => ({
									...base,
									border: '1px solid rgb(0,0,0)',
									borderRadius: 0,
								}),
							}}
							options={checkOut && combineDepartureTimeOptions(
								regularCheckOutTimes,
								superLate || [],
								propertyTimeZone,
								translation,
								currencyIcon,
							)}
							value={
								selectedDepartureTimeUTC && !Number.isNaN(Date.parse(selectedDepartureTimeUTC)) && {
									value: selectedDepartureTimeUTC,
									label: formatToTime(selectedDepartureTimeUTC),
								}
							}
							onChange={(option) => updateField('departureTime', option.value?.endDate)}
							isValid={!formErrors?.includes('departureTime')}
						/>
					)}
				</Section>

				<FooterSection
					onPrevClick={goToPrevStep}
					disableNextButton={isSubmitting}
					nextButtonText={isFinalStep ? translation.t('Finish Check-In') : translation.t('Next')}
				/>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	appState: selectAppState,
	isFinalStep: selectIsFinalStep,
	reservation: selectReservation,
	reservationHasStarted: selectReservationHasStarted,
	availableTimes: selectAvailableTimes,
	orderedEarlyCheckIn: selectOrderedEarlyCheckIn,
	orderedLateCheckOut: selectOrderedLateCheckOut,
	selectedProducts: selectSelectedProductKeys,
	arrivalTimeData: selectArrivalTimeData,
	departureTimeData: selectDepartureTimeData,
	isSubmitting: selectIsSubmitting,
})

const mapDispatchToProps = (dispatch) => ({
	getTimes: () => dispatch(getAvailableTimes()),
	updateField: (fieldName, data) => dispatch(updateCurrentStepField(fieldName, data)),
	goToNextStep: () => {
		dispatch(goToStep())
	},
	goToPrevStep: () => dispatch(goToPrevStep()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TimesView)
