import { combineReducers } from 'redux'
import appReducer from './app/app.reducer'
import customerReducer from './customer/customer.reducer'
import errorReducer from './error/error.reducer'
import reservationReducer from './reservation/reservation.reducer'
import uiReducer from './ui/ui.reducer'
import businessLeadReducer from 'Redux/business-lead/business-lead.reducer'

const rootReducer = combineReducers({
	appState: appReducer,
	reservationState: reservationReducer,
	businessLeadState: businessLeadReducer,
	customer: customerReducer,
	error: errorReducer,
	ui: uiReducer,
})

export default rootReducer
