import { environmentNames, getRealEnvironment } from 'Src/utils/env'
import config from '../globals/constants'

const { IS_DEVELOPMENT } = config
const isLoggingEnabled = !IS_DEVELOPMENT

const isItErrorFrom3rdPartyScripts = (stackString) => {
	if (!stackString) return false

	const listOfScriptUrls = [
		'chat-assets.frontapp.com/v1/app.bundle.js',
	]

	return listOfScriptUrls.some((url) => stackString.includes(url))
}

const shouldIgnoreByMessage = (message) => {
	if (!message || typeof message !== 'string') return false

	const listOfMessages = [
		'Object Not Found Matching Id',
	]

	return listOfMessages.some((msg) => !!message.includes(msg))
}

class Logger {
	init(datadogLogs, datadogRum) {
		const {
			reservationSlug,
			flow,
		} = window.bobw

		this.datadogLogs = datadogLogs
		this.datadogRum = datadogRum

		const realEnvironment = getRealEnvironment()
		const realEnvironmentName = environmentNames[realEnvironment]

		if (isLoggingEnabled) {
			this.datadogLogs.init({
				clientToken: 'pub2221d4b3e49af8c2789ab4c72e5b45df',
				service: 'guest-portal-fe',
				env: realEnvironmentName,
				site: 'datadoghq.eu',
				beforeSend: (event) => {
					if (['network', 'console'].includes(event.error?.origin)
					|| isItErrorFrom3rdPartyScripts(event.error?.stack)
					|| shouldIgnoreByMessage(event.message)) {
						event.status = 'warning'
					}
				},
			})

			this.datadogLogs.setLoggerGlobalContext({
				reservation: { slug: reservationSlug },
				flow,
			})

			this.datadogRum.init({
				applicationId: '4ea05e15-fbba-4e33-89b1-a01e8c0bef14',
				clientToken: 'pubaab38de39d2b30cee05fa532a9a4e40e',
				site: 'datadoghq.eu',
				service: 'guest-portal',
				env: realEnvironmentName,
				trackResources: true,
				trackLongTasks: true,
				trackInteractions: true,
				sessionReplaySampleRate: 0,
				defaultPrivacyLevel: 'mask-user-input',
			})
		}
	}

	error(...params) {
		if (isLoggingEnabled) {
			this.datadogLogs.logger.error(...params)
		}
	}

	warn(...params) {
		if (isLoggingEnabled) {
			this.datadogLogs.logger.warn(...params)
		}
	}

	info(...params) {
		if (isLoggingEnabled) {
			this.datadogLogs.logger.info(...params)
		}
	}

	debug(...params) {
		if (isLoggingEnabled) {
			this.datadogLogs.logger.debug(...params)
		}
	}
}

export default new Logger()
