import { useEffect } from 'react'

export const useCodeScript = (code: string, position: string = 'body') => {
	useEffect(() => {
		if (code !== '') {
			const placement = document.querySelector(position) ?? document.body
			const script = document.createElement('script')

			try {
				script.appendChild(document.createTextNode(code))
				placement.appendChild(script)
			} catch (e) {
				script.text = code
				placement.appendChild(script)
			}

			return () => {
				placement.removeChild(script)
			}
		}
	}, [code])
}

export const useUrlScript = (url: string, position: string = 'body') => {
	useEffect(() => {
		const placement = document.querySelector(position) ?? document.body
		const script = document.createElement('script')
		script.src = url
		script.async = true

		placement.appendChild(script)

		return () => {
			placement.removeChild(script)
		}
	}, [url])
}
