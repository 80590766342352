import React, { useContext } from 'react'
import styled from 'styled-components'

import { Datepicker, FormInput, RadioButtons, SelectCountry } from './ui'
import { ContentMainHeading, ContentSubHeading } from './view-structure.component'

import { ConstantsContext, TranslationContext } from 'Src/context'

const FormInputStyled = styled(FormInput)`
	margin-bottom: 16px;
`
const DatepickerStyled = styled(Datepicker)`
	margin-bottom: 16px;
`
const SelectCountryStyled = styled(SelectCountry)`
	margin-bottom: 16px;
`
const RadioButtonsStyled = styled(RadioButtons)`
	margin-bottom: 16px;
`

const IdentificationFields = ({
	heading,
	firstName,
	lastName,
	birthDate,
	nationalityCode,
	birthPlaceAndDateRequired,
	genderRequired,
	gender,
	updateField,
	formErrors,
}) => {
	const translation = useContext(TranslationContext)
	const { constants: { SEX_OPTIONS } } = useContext(ConstantsContext)

	return (
		<>
			<ContentMainHeading>{heading}</ContentMainHeading>
			<ContentSubHeading>
				{translation.t('We need the following information for legal and safety reasons.')}
			</ContentSubHeading>

			<FormInputStyled
				label={`${translation.t('First name')}*`}
				name="firstName"
				type="text"
				value={firstName}
				handleChange={(value) => updateField('firstName', value)}
				isValid={!formErrors?.includes('firstName')}
			/>
			<FormInputStyled
				label={`${translation.t('Last name')}*`}
				name="lastName"
				type="text"
				value={lastName}
				handleChange={(value) => updateField('lastName', value)}
				isValid={!formErrors?.includes('lastName')}
			/>

			{!birthPlaceAndDateRequired && (
				<DatepickerStyled
					label={`${translation.t('Date of birth')}*`}
					value={birthDate}
					handleChange={(value) => updateField('birthDate', value)}
					isValid={!formErrors?.includes('birthDate')}
				/>
			)}

			<SelectCountryStyled
				label="Citizenship*"
				placeholder={translation.t('Select citizenship')}
				name="nationality"
				value={nationalityCode}
				onChange={({ value }) => updateField('nationalityCode', value)}
				isValid={!formErrors?.includes('nationalityCode')}
			/>

			{genderRequired && (
				<RadioButtonsStyled
					label={`${translation.t('Sex')}*`}
					name="gender"
					options={SEX_OPTIONS}
					value={SEX_OPTIONS.find((sex) => sex.value === gender)}
					handleChange={({ value }) => updateField('gender', value)}
					isValid={!formErrors?.includes('gender')}
				/>
			)}
		</>
	)
}

export default IdentificationFields
