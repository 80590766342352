import React from 'react'
import { useSnackbar } from 'react-simple-snackbar'
import { COLORS } from '../globals/global.styles'

const withSnackbar = (WrappedComponent) => (props) => {
	const [showSnackbar, closeSnackbar] = useSnackbar({
		position: 'bottom-right',
		style: {
			color: '#4E4E4E',
			position: 'absolute',
			right: '0',
			bottom: '0',
			padding: '4px',
			borderRadius: '2px',
			boxShadow:
				'rgba(0, 0, 0, 0.1) 0px 15px 24px -6px, rgba(0, 0, 0, 0.15) 0px -5px 24px -6px',
			backgroundColor: COLORS.background.primary,
		},
		closeStyle: {
			display: 'none',
		},
	})

	return (
		<WrappedComponent
			showSnackbar={showSnackbar}
			closeSnackbar={(delay) => setTimeout(closeSnackbar, delay)}
			{...props}
		/>
	)
}

export default withSnackbar
