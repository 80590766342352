import React, { useContext, useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import SingleProductWrapper from 'Views/check-in-views/products-view/single-product-wrapper.component'

import { selectProducts, selectUpgradeableRoom } from 'Redux/reservation/products/products.selectors'
import { selectReservation } from 'Redux/reservation/reservation.selectors'
import { selectCurrentStepData } from 'Redux/app/app.selectors'
import { selectDurationOfStay } from 'Redux/reservation/times/times.selectors'

import config, { analyticsBrand } from 'Globals/constants'
import { COLORS, FONT_WEIGHTS } from 'Globals/global.styles'

import { TranslationContext } from 'Src/context'

import { Section } from 'Components/view-structure.component'
import HypeLines from 'Components/ui/hype-lines.component'

import IconLocation from 'Assets/icons/icon-location-black.svg'
import IconMessageFromBob from 'Assets/icons/message-from-bob-icon.svg'

import { getImageUrlBySequence, mewsImage } from 'Src/utils'
import { sendProductAddedEvent, sendProductViewedEvent } from 'Src/utils/analytics'

const { PROPERTY_ADDITIONAL_DETAILS, ACTIVE_PRODUCT_KEYS } = config

const TextContent = styled.div`
	font-size: 16px;
	color: ${COLORS.text.secondary};
	margin-bottom: 8px;
	margin-top: -15px;
`

const MessageFromBob = styled(Section)`
	padding: 24px;
	height: 210px;
	background-color: ${COLORS.background.keyCode};
	color: white;
	margin-bottom: 90px;
	border-radius: 4px;
	font-weight: ${FONT_WEIGHTS.bold};
`

const MessageIcon = styled.img`
	width: 78px;
	position: relative;
	left: 0px;
	top: 5px;
`

const LocationContainer = styled.div`
	display: flex;
	margin-bottom: 24px;
	margin-top: -32px;
	align-items: baseline;
`

const LocationIcon = styled.img`
	width: 24px;
	height: 24px;
	margin-right: 4px;
	position: relative;
	top: 2px;
`

const MuchLoveText = styled.div`
	font-size: 16px;
	font-weight: ${FONT_WEIGHTS.bold};
	color: white;
	position: relative;
	left: 100px;
	top: 20px;
}
`

const LocationText = styled.span`
	margin-left: 5px;
`

const BoldText = styled.span`
	font-size: 16px;
	font-weight: ${FONT_WEIGHTS.normal};
`

const Features = styled.div`
	font-size: 16px;
`

const Spacing = styled.div`
	height: 9px;
`

const bulletPointsRegex = /\d\.\s+|[a-z]\)\s+|•\s+|[A-Z]\.\s+|[IVX]+\.\s+/g

const PropertyUnitCategoryUpgrade = ({
	reservation,
	upgradeableRooms,
	durationOfStay,
	stepData,
}) => {
	const [upgradeRoomData, setUpgradeRoomData] = useState({})
	const translation = useContext(TranslationContext)

	const getInfo = (upgradeableRoomId) => {
		const room = upgradeableRooms.find(
			({ id, productKey }) => id === upgradeableRoomId && productKey === stepData.productKeys?.[0],
		)
		setUpgradeRoomData(room)
	}

	useEffect(() => {
		if (stepData.selectedRoom) {
			getInfo(stepData.selectedRoom)
		}
	}, [])

	const analyticsProduct = useMemo(() => {
		if (upgradeRoomData.id !== undefined) {
			const totalPrice = upgradeRoomData.pricePerNight * durationOfStay

			return {
				brand: analyticsBrand,
				category: 'Unit Category Upgrade',
				currency: reservation.property.defaultCurrency,
				image_url: getImageUrlBySequence(upgradeRoomData.images),
				name: upgradeRoomData.name,
				price: upgradeRoomData.pricePerNight,
				product_id: upgradeRoomData.id,
				quantity: durationOfStay,
				value: totalPrice,
				variant: upgradeRoomData.type,
			}
		}
	}, [upgradeRoomData, durationOfStay])

	useEffect(() => {
		if (analyticsProduct !== undefined) {
			sendProductViewedEvent(analyticsProduct)
		}
	}, [analyticsProduct])

	const {
		property: {
			key: propertyKey,
		},
	} = reservation

	const {
		name,
		description,
		images = [],
		features = '',
		pricePerNight: updateRoomPricePerNight,
	} = upgradeRoomData

	const isFreeUpgrade = stepData.productKeys?.includes(ACTIVE_PRODUCT_KEYS.propertyUnitFreeUpgrade)

	function handleSubmit() {
		if (analyticsProduct !== undefined) {
			sendProductAddedEvent(analyticsProduct)
		}
	}

	return (
		<SingleProductWrapper
			title={name}
			titleAfterImage
			image={mewsImage(images, 400, 190)}
			buttonText={translation.t('Add Upgrade')}
			productDataKey="propertyUnitCategoryId"
			productData={stepData.selectedRoom}
			// eslint-disable-next-line react/jsx-no-bind
			onSubmit={handleSubmit}
			hidePrice
			customTotalPrice={!isFreeUpgrade && (updateRoomPricePerNight * durationOfStay)}
			customPriceDescription={
				!isFreeUpgrade
				&& `${translation.t('Extra for')} ${durationOfStay} ${translation.t(durationOfStay === 1 ? 'day' : 'days')}`
			}
			priceInsteadOfCancelButton={!isFreeUpgrade}
			lighterHeading
		>
			<LocationContainer>
				<LocationIcon src={IconLocation} alt={name} />
				<LocationText>{PROPERTY_ADDITIONAL_DETAILS[propertyKey].propertyAddress}</LocationText>
			</LocationContainer>
			{isFreeUpgrade && (
				<MessageFromBob>
					{translation.t('MESSAGE FROM BOB')}
					<Spacing />
					{translation.t('It’s great to have you back again. To give you a warm welcome, here’s a complimentary apartment upgrade.')}
					<MuchLoveText>
						{translation.t('Much love, Bob')}
					</MuchLoveText>
					<MessageIcon src={IconMessageFromBob} alt="Message from Bob" />
				</MessageFromBob>
			)}
			<TextContent>
				{description}
			</TextContent>
			<br />
			<TextContent>
				<BoldText>
					{translation.t('You will get all the features from the apartment you have booked, plus:')}
				</BoldText>
			</TextContent>
			{features
				&& (
					<Features>
						<HypeLines lines={features.replace(bulletPointsRegex, '').split(/\r?\n/)} />
					</Features>
				)}
		</SingleProductWrapper>
	)
}

const mapStateToProps = createStructuredSelector({
	reservation: selectReservation,
	stepData: selectCurrentStepData,
	durationOfStay: selectDurationOfStay,
	products: selectProducts,
	upgradeableRooms: selectUpgradeableRoom,
})

export default connect(mapStateToProps)(PropertyUnitCategoryUpgrade)
