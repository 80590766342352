import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormInput, SelectCountry } from './ui'
import { ContentMainHeading, ContentSubHeading } from './view-structure.component'
import { TranslationContext } from 'Src/context'
import SelectProvinces from './ui/select-provinces.component'
import { countriesWithSubdivisions } from 'Globals/constants'
import getCountrySubdivisions from 'Src/api/get-country-subdivisions'

const FormInputStyled = styled(FormInput)`
	margin-bottom: 16px;
`
const SelectCountryStyled = styled(SelectCountry)`
	margin-bottom: 16px;
`
const SelectProvincesStyled = styled(SelectProvinces)`
	margin-bottom: 16px;
`

const ResidenceFields = ({
	subHeading,
	addressLine1,
	city,
	countryCode,
	countrySubdivisionCode,
	isAddressRequired,
	isProvinceRequired = false,
	updateField,
	formErrors,
}) => {
	const translation = useContext(TranslationContext)
	const hasProvinceField = isProvinceRequired && countriesWithSubdivisions.has(countryCode)
	const [provinces, setProvinces] = useState([])

	async function updateProvinces() {
		setProvinces([])

		if (typeof countryCode !== 'string' || hasProvinceField === false) {
			return
		}

		const response = await getCountrySubdivisions(countryCode)

		if ('data' in response) {
			const subdivisionsData = response.data

			const options = subdivisionsData.map((province) => {
				let label = province.name
				const sameNameCount = subdivisionsData.filter(({ name }) => name === province.name).length

				if (sameNameCount > 1) {
					label = `${province.name} (${province.type})`
				}

				return {
					label,
					value: province.code,
				}
			})

			setProvinces(options)
		}
	}

	useEffect(() => {
		updateProvinces()
	}, [countryCode])

	// Not to send wrong province code when country is changed
	// e.g.: ES-PM when country is changed from ES to FR
	useEffect(() => {
		if (typeof countryCode !== 'string' || typeof countrySubdivisionCode !== 'string') {
			return
		}

		if (typeof countrySubdivisionCode === 'string') {
			const subdivisionCountryCode = countrySubdivisionCode.split('-')[0]

			if (subdivisionCountryCode !== countryCode) {
				updateField('countrySubdivisionCode', null)
			}
		}
	}, [countryCode, countrySubdivisionCode])

	return (
		<>
			<ContentMainHeading>{translation.t('Place of Residence')}</ContentMainHeading>
			{subHeading && <ContentSubHeading>{subHeading}</ContentSubHeading>}

			<SelectCountryStyled
				label={`${translation.t('Country')}*`}
				placeholder={translation.t('Select country')}
				name="country"
				value={countryCode}
				onChange={({ value }) => updateField('countryCode', value)}
				isValid={!formErrors?.includes('countryCode')}
			/>

			{hasProvinceField && (
				<SelectProvincesStyled
					label={`${translation.t('Province')}*`}
					name="province"
					value={countrySubdivisionCode}
					provinces={provinces}
					onChange={({ value }) => updateField('countrySubdivisionCode', value)}
					isValid={!formErrors?.includes('countrySubdivisionCode')}
					countryCode={countryCode}
				/>
			)}

			<FormInputStyled
				label={`${translation.t('City')}*`}
				name="city"
				type="text"
				value={city}
				handleChange={(value) => updateField('city', value)}
				isValid={!formErrors?.includes('city')}
			/>

			{isAddressRequired && (
				<FormInputStyled
					label={`${translation.t('Address')}*`}
					name="addressLine1"
					type="text"
					value={addressLine1}
					handleChange={(value) => updateField('addressLine1', value)}
					isValid={!formErrors?.includes('addressLine1')}
				/>
			)}
		</>
	)
}

export default ResidenceFields
