import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { CheckOutlined } from '@ant-design/icons'

import { COLORS, FONT_WEIGHTS, generateMediaQuery } from 'Globals/global.styles'
import config from '../../../globals/constants'
import { Button } from '../../../components/ui'

import { TranslationContext } from 'Src/context'

const { PRODUCT_CHARGE_METHODS } = config

const ProductCardWrapper = styled.div`
	position: relative;
	cursor: pointer;
	width: 100%;
	background: ${COLORS.background.primary};

	${({ isSelected }) => isSelected && `
		outline: 2px solid ${COLORS.misc.green};
	`}
`

const ProductDetails = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: ${COLORS.background.secondary};
	padding: 24px 16px 16px 16px;

	${generateMediaQuery.mobileSmall(`
		padding: 16px 12px 12px 12px;
	`)}
`

const ProductTitleWrapper = styled.div`
	display: flex;
	align-items: center;
`

const ProductTitle = styled.div`
	font-size: 16px;
	line-height: 1em;
	font-weight: ${FONT_WEIGHTS.normal};
`

const FreePriceIcon = styled.span`
	width: 42px;
	height: 14px;
	
	margin-left: 10px;
	text-align: center;
	font-size: 11px;
	font-weight: ${FONT_WEIGHTS.bold};
	line-height: 1.5em;
	
	border: solid 1px ${COLORS.misc.green};
	background-color: ${COLORS.misc.green};
	color: ${COLORS.background.secondary};
`

const ProductDescription = styled.div`
	margin-top: 8px;
	margin-bottom: 32px;
	font-size: 13px;
	color: ${COLORS.text.secondary};
`

const PriceTag = styled.div`
	font-size: 14px;
	font-weight: ${FONT_WEIGHTS.normal};
`

const PriceLinedThrough = styled.span`
	text-decoration: line-through;
	display: inline-block;
	margin-right: 2px
`

const FreePrice = styled.span`
	display: inline-block;
	color: ${COLORS.text.highlight};
`

const ButtonStyled = styled(Button)`
	height: 34px;
	min-width: 95px;
	line-height: 2.3em;
	
	${({ isDisabled }) => isDisabled && css`
		opacity: 0.5;
	`}
`

const IconSelected = styled.div`
	position: absolute;
	right: 6px;
	top: 6px;
	border-radius: 100%;
	background-color: ${COLORS.misc.green};
	padding: 4px;

	> span {
		display: block;

		svg,
		svg > path {
			font-size: 13px;
			color: ${COLORS.background.secondary};
		}
	}
`

const PriceAndButton = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
`

const PriceWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const ImageContainer = styled.div`
	height: 125px;
	width: 100%;
	
	${({ image }) => `background-image: url(${image});`}
	background-position: center center;
	background-size: cover;
`

const PriceExplanation = styled.div`
	font-size: 12px;
`

const FooterNote = styled.div`
	padding: 4px 0 2px;
	background-color: ${COLORS.misc.green};
	font-size: 12px;
	text-align: center;
	color: ${COLORS.background.primary};
`

const getButtonText = (isSelected, isDisabled, addButtonText, showDefaultText, translation) => {
	if (isDisabled && !showDefaultText) return translation.t('Unavailable')
	if (isSelected && !showDefaultText) return translation.t('Remove')
	return addButtonText || translation.t('Add')
}

const getPriceExplanation = (price, chargeMethod, translation) => {
	if (!price) return null

	switch (chargeMethod) {
		case PRODUCT_CHARGE_METHODS.perPersonPerTimeUnit:
			return <PriceExplanation>{translation.t('Per night per guest')}</PriceExplanation>
		case PRODUCT_CHARGE_METHODS.perTimeUnit:
			return <PriceExplanation>{translation.t('Per night')}</PriceExplanation>
		case PRODUCT_CHARGE_METHODS.once:
			return <PriceExplanation>{translation.t('Per reservation')}</PriceExplanation>
		case PRODUCT_CHARGE_METHODS.multiple:
			return <PriceExplanation>{translation.t('Per 2 people')}</PriceExplanation>
		default:
			return null
	}
}

const getPriceFormatting = (price, chargeMethod, currency, translation) => {
	if (!price) return null

	switch (chargeMethod) {
		case PRODUCT_CHARGE_METHODS.multiple:
			return <PriceTag>{translation.t('From {currency}{price}', { currency, price: price.toFixed(2) })}</PriceTag>
		default:
			return <PriceTag>{`${currency ?? '€'}${price.toFixed(2)}`}</PriceTag>
	}
}

const ProductCard = ({
	title,
	description,
	addButtonText,
	image,
	onClick,
	isSelected,
	price,
	fullPrice,
	currency,
	chargeMethod,
	footerNote,
	isDisabled,
	className,
}) => {
	const translation = useContext(TranslationContext)
	return (
		<ProductCardWrapper
			isSelected={isSelected}
			onClick={onClick}
			className={className}
		>
			{!!image && (
				<ImageContainer image={image} />
			)}

			<ProductDetails isSelected={isSelected}>
				<div>
					<ProductTitleWrapper>
						<ProductTitle>{title}</ProductTitle>
						{!price && (<FreePriceIcon>{translation.t('FREE')}</FreePriceIcon>)}
					</ProductTitleWrapper>
					<ProductDescription>{description}</ProductDescription>
				</div>
				<PriceAndButton>
					<ButtonStyled variant="transparent_with_border" isDisabled={isDisabled}>
						{getButtonText(isSelected, isDisabled, addButtonText, !price && fullPrice, translation)}
					</ButtonStyled>
					{!!price && (
						<PriceWrapper>
							<PriceTag>{getPriceFormatting(price, chargeMethod, currency, translation)}</PriceTag>
							{getPriceExplanation(price, chargeMethod, translation)}
						</PriceWrapper>
					)}

					{!price && fullPrice && (
						<PriceWrapper>
							<PriceTag>
								<PriceLinedThrough>
									{getPriceFormatting(fullPrice, chargeMethod, currency, translation)}
								</PriceLinedThrough>
								<FreePrice>{translation.t('Free')}</FreePrice>
							</PriceTag>
							{getPriceExplanation(fullPrice, chargeMethod, translation)}
						</PriceWrapper>
					)}
				</PriceAndButton>
			</ProductDetails>

			{(footerNote && isSelected) && (
				<FooterNote>{footerNote}</FooterNote>
			)}

			{isSelected && (
				<IconSelected title={translation.t('{title} is selected', { title })}>
					<CheckOutlined />
				</IconSelected>
			)}
		</ProductCardWrapper>
	)
}

export default ProductCard
