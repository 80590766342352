import type { i18n } from 'i18next'
import React from 'react'

export const TranslationContext = React.createContext<i18n>({} as i18n)

type ReloadConfig = () => void

type ConstantContextProps = {
    [key: string]: string | ReloadConfig,
    reloadConfig: ReloadConfig
}

export const ConstantsContext = React.createContext<ConstantContextProps>({} as ConstantContextProps)
