interface GtagEventParams {
  readonly name: string;
  readonly label: string;
  readonly category: string;
  readonly value?: number;
  readonly nonInteraction?: boolean;
  readonly dimensions?: Record<string, string | number>
}

export interface AnalyticsProduct {
	readonly brand: string;
	readonly category: string;
	readonly currency: string;
	readonly image_url: string;
	readonly name: string;
	readonly price: number;
	readonly product_id: string;
	readonly quantity: number;
	readonly value: number;
	readonly variant: string;
	readonly coupon?: string;
	readonly position?: number;
	readonly sku?: string;
	readonly url?: string;
}

export interface AnalyticsOrderDetails {
	readonly subtotal: number;
	readonly currency: string;
	readonly checkout_id?: string;
	readonly order_id?: string;
	readonly affiliation?: string;
	readonly total?: number;
	readonly revenue?: number;
	readonly shipping?: number;
	readonly discount?: number;
	readonly coupon?: string;
}

/**
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 *
 * gtag('event', 'video_auto_play_start', {
 * 	'event_label': 'My promotional video',
 * 	'event_category': 'video_auto_play',
 * 	'non_interaction': true
 * });
 */
export function sendGtagEvent({
	name,
	label,
	category,
	value,
	dimensions = {},
	nonInteraction = false,
} : GtagEventParams) {
	window.gtag?.('event', name, {
		value,
		event_label: label,
		event_category: category,
		non_interaction: nonInteraction,
		...dimensions,
	})
}

// https://segment.com/docs/connections/spec/ecommerce/v2/#product-list-viewed
export function sendProductListViewedEvent(category: string, products: AnalyticsProduct[]) {
	window.analytics?.track('Product List Viewed', {
		category,
		products,
	})
}

// https://segment.com/docs/connections/spec/ecommerce/v2/#product-viewed
export function sendProductViewedEvent(product: AnalyticsProduct) {
	window.analytics?.track('Product Viewed', product)
}

// https://segment.com/docs/connections/spec/ecommerce/v2/#product-added
export function sendProductAddedEvent(product: AnalyticsProduct) {
	window.analytics?.track('Product Added', product)
}

// https://segment.com/docs/connections/spec/ecommerce/v2/#product-removed
export function sendProductRemovedEvent(product: AnalyticsProduct) {
	window.analytics?.track('Product Removed', product)
}

// https://segment.com/docs/connections/spec/ecommerce/v2/#checkout-step-viewed
export function sendCheckoutStepViewedEvent() {
	window.analytics?.track('Checkout Step Viewed')
}

// https://segment.com/docs/connections/spec/ecommerce/v2/#order-completed
export function sendOrderCompletedEvent(orderDetails: AnalyticsOrderDetails, products: AnalyticsProduct[]) {
	window.analytics?.track('Order Completed', {
		...orderDetails,
		products,
	})
}
