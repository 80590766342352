import styled from 'styled-components'

const ResetButton = styled.button`
	display: inline-block;
	background: none;
	border: none;
	outline: none;
	padding: 0;
	cursor: pointer;
`

export default ResetButton
