import React from 'react'
import styled from 'styled-components'

import config from '../globals/constants'

const { PAYMENT_METHODS } = config

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

const PaymentIcon = styled.img`
	width: 48px;

`

const PaymentIcons = ({ className }) => (
	<Wrapper className={className}>
		{Object.keys(PAYMENT_METHODS).map((method) => (
			<PaymentIcon
				src={PAYMENT_METHODS[method].icon}
				alt={method}
				key={method}
			/>
		))}
	</Wrapper>
)

export default PaymentIcons
