import React, { useContext, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import {
	ContentHeader,
	ContentMainHeading,
	FooterSection,
	HeaderSticky,
	InvalidFieldsAlertSection,
	Section,
} from 'Components/view-structure.component'
import { Button, FormInput, PhoneInput } from '../../../components/ui'
import IdentificationFields from '../../../components/guest-identification-fields.component'
import ResidenceFields from '../../../components/guest-residence-fields.component'
import AdditionalDetailsFields from '../../../components/guest-additional-details-fields.component'

import { areAdditionalGuestResidencyFieldsRequired, getDocumentTypes } from 'Src/utils'

import { selectAppState, selectIsSubmitting } from 'Redux/app/app.selectors'
import { updateCurrentStepField } from 'Redux/app/app.actions'
import { goToPrevStep } from 'Redux/app/go-to-step.actions'
import {
	clearCurrentAdditionalGuest,
	initCurrentAdditionalGuest,
	mainGuestAddressToCurrentAdditionalGuest,
} from 'Redux/reservation/guests/guests.actions'
import { selectReservation } from 'Redux/reservation/reservation.selectors'
import {
	selectAdditionalDocumentFieldsRequired,
	selectAdditionalGuestGenderRequired,
	selectAddressRequired,
	selectBirthPlaceAndDateRequired,
	selectCurrentAdditionalGuest,
	selectCurrentAdditionalGuestDocumentDetailsRequired,
	selectCurrentAdditionalGuestVisitedCountryRequired,
	selectGuestNextDestinationRequired,
} from 'Redux/reservation/guests/guests.selectors'

import { TranslationContext } from 'Src/context'
import PageView from 'Src/components/PageView'
import BirthlpaceAndDate from 'Components/guest-birthplace-date.component'

const FormInputStyled = styled(FormInput)`
	margin-bottom: 16px;
`
const PhoneInputStyled = styled(PhoneInput)`
	margin-bottom: 16px;
`
const ResidenceSubHeading = styled.div`
	> span {
		font-size: 13px;
		margin-right: 8px;
	}
`
const CopyAddressButtonStyled = styled(Button)`
	height: 32px;
	padding: 0 12px;
	font-size: 13px;
	line-height: 2.3em;
`

const AdditionalGuestDetailsView = ({
	appState,
	isSubmitting,
	reservation,
	guest,
	documentDetailsRequired,
	additionalDocumentFieldsRequired,
	visitedCountryRequired,
	nextDestinationRequired,
	birthPlaceAndDateRequired,
	isAddressRequired,
	genderRequired,
	initCurrentAdditionalGuest,
	clearCurrentAdditionalGuest,
	updateField,
	mainGuestAddressToGuest,
	goToPrevStepWithApiPatch,
	goToPrevStep,
}) => {
	const translation = useContext(TranslationContext)

	const {
		progressData,
		currentStepFormErrors: formErrors,
	} = appState

	const {
		property: {
			phone: propertyPhone,
			countryCode: propertyCountryCode,
			country: propertyCountry,
		},
	} = reservation

	const {
		guest: additionalGuest,
		customer,
		document,
		address,
	} = guest || {}

	const { visitedCountryCode, destinationCity, destinationCountryCode } = additionalGuest || {}

	const {
		id: customerId,
		firstName,
		lastName,
		birthDate,
		birthCountryCode,
		birthProvince,
		nationalityCode,
		email,
		phone,
		gender,
		phoneCountryCallingCode,
		phoneCountryCode,
		customerAddress,
	} = customer || {}

	const {
		type: documentType,
		number: documentNumber,
		issueCountry: documentIssueCountry,
		issueProvince: documentIssueProvince,
	} = document || {}

	const {
		addressLine1,
		city,
		countryCode: guestCountryCode,
	} = address || {}

	const residenceFieldsVisible = useMemo(
		() => areAdditionalGuestResidencyFieldsRequired(propertyCountryCode),
		[propertyCountryCode],
	)

	const documentTypes = useMemo(
		() => getDocumentTypes(propertyCountryCode, nationalityCode, translation),
		[propertyCountryCode, nationalityCode, translation],
	)

	useEffect(() => {
		initCurrentAdditionalGuest()

		return () => clearCurrentAdditionalGuest()
	}, [])

	useEffect(() => {
		if (guest && documentTypes.length > 0 && (documentType === undefined || documentType === '')) {
			updateField('documentType', documentTypes[0].value)
		}
	}, [guest, documentType, documentTypes])

	const subHeading = useMemo(() => {
		if (residenceFieldsVisible && isAddressRequired) {
			return (
				<ResidenceSubHeading>
					<span>{translation.t('Address is the same as for main guest?')}</span>
					<CopyAddressButtonStyled
						variant="transparent_with_border"
						onClick={() => mainGuestAddressToGuest()}
					>
						{translation.t('Copy address')}
					</CopyAddressButtonStyled>
				</ResidenceSubHeading>
			)
		}
		return null
	}, [isAddressRequired, residenceFieldsVisible, mainGuestAddressToGuest, translation])

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault()
				goToPrevStepWithApiPatch()
			}}
		>
			<PageView
				Header={() => (
					<HeaderSticky
						{...progressData}
						onBackButtonClick={goToPrevStep}
						contactButton={{ url: `tel:${propertyPhone}` }}
					/>
				)}
			>
				<ContentHeader
					mainText={customerId ? translation.t('Guest Info') : translation.t('New Guest Info')}
					subText={translation.t('Enter the details of the guest')}
				/>

				{!!formErrors?.length && <InvalidFieldsAlertSection formErrors={formErrors} />}

				{guest && customer && (
					<>
						<Section>
							<IdentificationFields
								{...{
									heading: translation.t('Details of the guest'),
									firstName,
									lastName,
									birthDate,
									nationalityCode,
									birthPlaceAndDateRequired,
									propertyCountryCode,
									gender,
									genderRequired,
									customerAddress,
									updateField,
									formErrors,
								}}
							/>
						</Section>

						{birthPlaceAndDateRequired && (
							<Section>
								<BirthlpaceAndDate
									{...{
										heading: translation.t('Birthplace and date'),
										birthDate,
										birthCountryCode,
										birthProvince,
										updateField,
										formErrors,
									}}
								/>
							</Section>
						)}
					</>
				)}

				{residenceFieldsVisible && (
					<Section>
						<ResidenceFields
							{...{
								subHeading,
								addressLine1,
								city,
								isAddressRequired,
								countryCode: guestCountryCode,
								updateField,
								formErrors,
							}}
						/>
					</Section>
				)}

				{customer && (
					<Section>
						<ContentMainHeading>
							{translation.t('Contact details of the guest')}
						</ContentMainHeading>

						<FormInputStyled
							label="Email"
							name="email"
							type="email"
							value={email}
							handleChange={(value) => updateField('email', value)}
							isValid={!formErrors?.includes('email') && !formErrors?.includes('duplicatedGuestEmailUsed')}
						/>
						<PhoneInputStyled
							label={translation.t('Phone number')}
							value={{
								phone,
								phoneCountryCallingCode,
								phoneCountryCode,
							}}
							handleChange={(value) => updateField('phone', value)}
							isValid={!formErrors?.includes('phone')}
						/>
					</Section>
				)}

				{document && (visitedCountryRequired || documentDetailsRequired || nextDestinationRequired) && (
					<Section>
						<AdditionalDetailsFields
							{...{
								documentType,
								documentNumber,
								documentIssueCountry,
								documentIssueProvince,
								documentDetailsRequired,
								additionalDocumentFieldsRequired,
								visitedCountryCode,
								visitedCountryRequired,
								nextDestinationRequired,
								destinationCountryCode,
								destinationCity,
								documentTypes,
								propertyCountry,
								updateField,
								formErrors,
							}}
						/>
					</Section>
				)}

				<FooterSection
					onPrevClick={goToPrevStep}
					disableNextButton={isSubmitting}
					nextButtonText={customerId ? translation.t('Update') : translation.t('Add Guest')}
					prevButtonText={translation.t('Cancel')}
				/>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	appState: selectAppState,
	reservation: selectReservation,
	guest: selectCurrentAdditionalGuest,
	birthPlaceAndDateRequired: selectBirthPlaceAndDateRequired,
	isAddressRequired: selectAddressRequired,
	documentDetailsRequired: selectCurrentAdditionalGuestDocumentDetailsRequired,
	additionalDocumentFieldsRequired: selectAdditionalDocumentFieldsRequired,
	visitedCountryRequired: selectCurrentAdditionalGuestVisitedCountryRequired,
	nextDestinationRequired: selectGuestNextDestinationRequired,
	genderRequired: selectAdditionalGuestGenderRequired,
	isSubmitting: selectIsSubmitting,
})

const mapDispatchToProps = (dispatch) => ({
	initCurrentAdditionalGuest: () => dispatch(initCurrentAdditionalGuest()),
	clearCurrentAdditionalGuest: () => dispatch(clearCurrentAdditionalGuest()),
	updateField: (fieldName, data) => dispatch(updateCurrentStepField(fieldName, data)),
	mainGuestAddressToGuest: () => dispatch(mainGuestAddressToCurrentAdditionalGuest()),
	goToPrevStepWithApiPatch: () => dispatch(goToPrevStep({ withApiPatch: true })),
	goToPrevStep: () => dispatch(goToPrevStep()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AdditionalGuestDetailsView)
