import logger from '../logger/logger'
import { getCoreApiBaseUrl } from '@/utils/env'

const apiHost = getCoreApiBaseUrl()

export const getProductAvailabilityByType = async (serviceId, reservationId, type) => {
	const url = `${apiHost}/product-availability/?serviceId=${serviceId}&reservationId=${reservationId}&type=${type}`

	try {
		const response = await fetch(url, { method: 'GET' })
		const { data, message, errors } = await response.json()
		if (!data?.length) {
			logger.warn('There is no availability for the selected product', message)
			let errorCodes
			if (errors) {
				errorCodes = errors.map((err) => err.code)
			}
			return { error: true, errorCodes }
		}
		return data
	} catch (error) {
		if (!error.response) {
			throw error
		}
		logger.error('Could not fetch product availability data', error)
	}
}

export const getProductAvailability = async (productId, reservationId) => {
	const url = `${apiHost}/product-availability/${productId}?reservationId=${reservationId}`

	try {
		const response = await fetch(url, { method: 'GET' })
		const { data, message, errors } = await response.json()
		if (!data?.length) {
			logger.warn('There is no availability for the selected product', message)
			// :todo See if this is even needed
			let errorCodes
			if (errors) {
				errorCodes = errors.map((err) => err.code)
			}
			return { error: true, errorCodes }
		}
		return data
	} catch (error) {
		if (!error.response) {
			throw error
		}
		logger.error('Could not fetch product availability data', error)
	}
}
