const AppActionTypes = {
	INIT_APP_STATE: 'INIT_APP_STATE',
	SET_CURRENT_STEP: 'SET_CURRENT_STEP',
	SET_CURRENT_STEP_FORM_ERRORS: 'SET_CURRENT_STEP_FORM_ERRORS',
	REMOVE_CURRENT_STEP_FORM_ERROR: 'REMOVE_CURRENT_STEP_FORM_ERROR',
	SET_POPUP_DATA: 'SET_POPUP_DATA',
	SET_SNACKBAR_TEXT: 'SET_SNACKBAR_TEXT',
	SET_IS_SUBMITTING: 'SET_IS_SUBMITTING',
	UPDATE_PROGRESS: 'UPDATE_PROGRESS',
	SET_ERROR_VIEW_VISIBLE: 'SET_ERROR_VIEW_VISIBLE',
	SET_PCI_PROXY_MERCHANT_ID: 'SET_PCI_PROXY_MERCHANT_ID',
	SWITCH_LANGUAGE: 'SWITCH_LANGUAGE',
}

export default AppActionTypes
