import { isTimeProduct } from 'Redux/reservation/products/products.utils'
import { EnrichedProduct } from 'Types/reservation-state.types'
import config from 'Globals/constants'

const { ACTIVE_PRODUCT_KEYS } = config

export function filterDisplayableProducts(products: EnrichedProduct[] = [], isBreakfastCampaignActive: boolean) {
	return	products.filter((product) => {
		const productDisplayable = !isTimeProduct(product.productKey)
			&& !product?.isUpgradeableProduct

		if (productDisplayable) {
			const isBreakfastProduct = isBreakfastCampaignActive
				&& product.productKey === ACTIVE_PRODUCT_KEYS.breakfast

			if (isBreakfastProduct) {
				return false
			}

			const isCampaignBreakfastProduct = isBreakfastCampaignActive
				&& product.productKey === ACTIVE_PRODUCT_KEYS.breakfastComplimentary

			if (isCampaignBreakfastProduct) {
				return true
			} else {
				return productDisplayable && !product.isComplimentary
			}
		}

		return false
	})
}
