import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import { InfoCircleOutlined } from '@ant-design/icons'
import { TranslationContext } from '@/context'
import { usePopper } from 'react-popper'
import { useSelector } from 'react-redux'
import { selectCity } from 'Redux/reservation/reservation.selectors'

const Tooltip = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	max-width: 254px;
	border-radius: var(--border-radius-5, 24px);
	background: #FFF;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
	padding: 16px;
	font-size: 14px;
	visibility: hidden;

	&[data-show="true"] {
		visibility: visible;
	}
`

const TooltipTrigger = styled.div`
	cursor: pointer;
	font-size: 16px;
`

const TooltipTitle = styled.div`
	font-weight: 500;
	font-size: 16px;
`
const CityTaxTooltip = () => {
	const translation = useContext(TranslationContext)
	const [referenceEl, setReferenceEl] = useState<HTMLDivElement|null>(null)
	const [popperEl, setPopperEl] = useState<HTMLDivElement|null>(null)
	const { styles, attributes } = usePopper(referenceEl, popperEl, {
		placement: 'right',
	})

	const city = useSelector(selectCity)

	const showTooltip = () => {
		popperEl?.setAttribute('data-show', 'true')
	}

	const hideTooltip = () => {
		popperEl?.removeAttribute('data-show')
	}
	return (
		<>
			<TooltipTrigger
				ref={setReferenceEl}
				onMouseEnter={showTooltip}
				onMouseLeave={hideTooltip}
			>
				<InfoCircleOutlined />
			</TooltipTrigger>

			<Tooltip
				ref={setPopperEl}
				style={styles.popper}
				{...attributes.popper}
			>
				<TooltipTitle>{translation.t('City Tax')}</TooltipTitle>
				<div>
					{translation.t('In {city}, Bob W has to collect city tax. The amount of tax will be calculated in accordance with local law and will be charged before your arrival. If you have any questions, please contact us or consult the local laws for more details.', { city })}
				</div>
			</Tooltip>
		</>
	)
}

export default CityTaxTooltip
