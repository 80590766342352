import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'

import { trackPageView } from '../utils'

import BobWLogo from '../assets/bob-w-logo-face.svg'
import Button from '../components/ui/button.component'
import { TranslationContext } from 'Src/context'
import { isWhiteLabelDomain } from '@/utils/env'

const Wrapper = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	> img {
		height: 10em;
	}
`

const ErrorPageFooter = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	padding-top: 32px;
`

const ErrorView = ({ flow }) => {
	const translation = useContext(TranslationContext)

	useEffect(() => {
		trackPageView(flow, 'error-page')
	}, [])

	const isWhiteLabel = isWhiteLabelDomain()

	return (
		<form action="https://bobw.co">
			<Wrapper>
				{ isWhiteLabel ? null : <img src={BobWLogo} alt="Bob W" /> }
				<div dangerouslySetInnerHTML={
					isWhiteLabel ? {
						__html: translation.t(`<p>Seems that the booking has already expired or you have made a mistake in the address.</p>
`, { escapeInterpolation: false }),
					} : {
						__html: translation.t(`<p>What a bummer! I have a problem locating your booking.<br />Seems that it has already expired or you have made a mistake in the address.</p><p>Happy to help you out if you have any questions.<br />Send me an email at hello@bobw.co</p><p>See you around,<br />Bob W</p>
`, { escapeInterpolation: false }),
					}
				}
				/>

				{ isWhiteLabel ? null : (
					<ErrorPageFooter>
						<Button variant="primary" type="submit">
							{translation.t('Start a new booking')}
						</Button>
					</ErrorPageFooter>
				) }
			</Wrapper>
		</form>
	)
}

export default ErrorView
