import { PayloadAction } from '@reduxjs/toolkit'
import * as ProductUtils from './products.utils'
import ProductsActionTypes from './products.types'
import { EnrichedProduct, ReservationState } from 'Types/reservation-state.types'

type Payload = {
	productKey?: string,
	productKeys?: string[],
	productData?: object,
	amount?: number | string,
	date?: string,
	products?: EnrichedProduct[]
}

type ProductsAmountPayload = {
	productKey: string,
	amount?: number | string
}

type ProductsConsumptionDatePayload = {
	productKey: string,
	consumptionDate: string,
}

const productsReducer = (state: ReservationState, action: PayloadAction<Payload>) => {
	switch (action.type) {
		case ProductsActionTypes.UPDATE_PRODUCTS: {
			const { payload: { products } } = action
			return {
				...state,
				productsState: {
					...state.productsState,
					products,
				},
			}
		}
		case ProductsActionTypes.UPDATE_SELECTED_PRODUCT_KEYS: {
			const { productsState: { selectedProductKeys } } = state
			const { payload: { productKey, productKeys } } = action
			const changedProdKeys = productKeys || [productKey]

			return {
				...state,
				productsState: {
					...state.productsState,
					selectedProductKeys: ProductUtils.combineSelectedProductKeys(selectedProductKeys, changedProdKeys),
				},
			}
		}

		case ProductsActionTypes.UPDATE_SELECTED_PRODUCT_ADDITIONAL_DATA: {
			const { productsAdditionalData } = state.productsState
			const productKey = action.payload.productKey!
			const productData = action.payload.productData!
			const productIndex = productsAdditionalData.findIndex((data: any) => data.productKey === productKey)
			const productsAdditionalDataUpdated = [...productsAdditionalData]

			if (productIndex === -1) {
				productsAdditionalDataUpdated.push({
					productKey,
					data: productData,
				})
			} else {
				productsAdditionalDataUpdated[productIndex] = {
					productKey,
					data: productData,
				}
			}

			return {
				...state,
				productsState: {
					...state.productsState,
					productsAdditionalData: productsAdditionalDataUpdated,
				},
			}
		}
		// :todo The whole logic needs refactoring, these comsuption date and amount will be merged, another branch
		case ProductsActionTypes.UPDATE_SELECTED_PRODUCT_CONSUMPTION_DATE: {
			let consumptionDates = state.productsState.productsConsumptionDates as ProductsConsumptionDatePayload[]
			const { payload: { productKey, date } } = action
			consumptionDates = consumptionDates?.filter(
				(prodData) => prodData.productKey !== productKey,
			) ?? []
			let selectedProductsConsumptionDate = state.productsState.productsConsumptionDates as Payload
			selectedProductsConsumptionDate = consumptionDates?.find(
				(prodData) => prodData.productKey !== productKey,
			) ?? {}
			// @ts-ignore
			selectedProductsConsumptionDate.consumptionDate = date
			// @ts-ignore
			selectedProductsConsumptionDate.productKey = productKey
			return {
				...state,
				productsState: {
					...state.productsState,
					productsConsumptionDates: [
						...consumptionDates,
						selectedProductsConsumptionDate,
					],
				},
			}
		}
		case ProductsActionTypes.UPDATE_SELECTED_PRODUCT_AMOUNT: {
			let productsAmount = state.productsState.productsAmount as ProductsAmountPayload[]
			const { payload: { productKey, amount } } = action
			productsAmount = productsAmount?.filter(
				(prodData) => prodData.productKey !== productKey,
			) ?? []
			const selectedProductAmount = productsAmount?.find(
				(prodData) => prodData.productKey !== productKey,
			) ?? {}
			// @ts-ignore
			selectedProductAmount.amount = amount
			// @ts-ignore
			selectedProductAmount.productKey = productKey
			return {
				...state,
				productsState: {
					...state.productsState,
					productsAmount: [
						...productsAmount,
						selectedProductAmount,
					],
				},
			}
		}
		default:
			return state
	}
}

export default productsReducer
