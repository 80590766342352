import i18next from 'i18next'
import ICU from 'i18next-icu'
import LanguageDetector from 'i18next-browser-languagedetector'

const LANGUAGES = [
	{
		code: 'en-GB',
		two_chars: 'en',
	},
	{
		code: 'es-ES',
		two_chars: 'es',
	},
	{
		code: 'et-EE',
		two_chars: 'ee',
	},
	{
		code: 'fi-FI',
		two_chars: 'fi',
	},
	{
		code: 'it-IT',
		two_chars: 'it',
	},
	{
		code: 'nl-NL',
		two_chars: 'nl',
	},
	{
		code: 'no-NO',
		two_chars: 'no',
	},
	{
		code: 'de-DE',
		two_chars: 'de',
	},
	{
		code: 'el-GR',
		two_chars: 'el',
	},
]

const resources = LANGUAGES.reduce((result: { [code: string]: any }, language) => {
	let translation
	try {
		// eslint-disable-next-line
		translation = require(`../translations/${language.code}/guest-area.json`)
		// eslint-disable-next-line no-empty
	} catch (e) {}

	result[language.code] = { translation }
	return result
}, {})

interface I18nextStrings {
	[key: string]: string
}

interface ITranslation {
	[key: string]: string | I18nextStrings
}

export const format = (translation: ITranslation) => {
	const i18NextLocalMapping: I18nextStrings = {}

	Object.keys(translation).forEach((key) => {
		if (typeof (translation[key]) === 'string') {
			i18NextLocalMapping[key] = translation[key] as string
		} else {
			const [contextKey]: string[] = Object.keys(translation[key])

			i18NextLocalMapping[key] = (translation[key] as I18nextStrings)[contextKey]
		}
	})

	return i18NextLocalMapping
}

let inited: boolean = false

const initTranslator = async () => {
	if (inited) return i18next

	await i18next.use(ICU)
		.use(LanguageDetector)
		.init({
			resources,
			nsSeparator: false,
			keySeparator: false,
			detection: { order: ['querystring'] },
			debug: false,
			fallbackLng: 'en-GB',
			supportedLngs: LANGUAGES.map((language) => language.code),
		})

	inited = true

	return i18next
}

export const getTranslator = () => i18next

export const findLanguageCode = (languageCode: string) => LANGUAGES.find(
	({ code, two_chars }) => code === languageCode || two_chars === languageCode,
)

export const isValid = (languageCode: string | null) => Boolean(languageCode && findLanguageCode(languageCode))

// languageCode in format en-GB
export const setLanguage = (languageCode: string | null) => {
	if (!isValid(languageCode)) {
		return false
	}

	const { code } = findLanguageCode(String(languageCode)) || {}

	getTranslator().changeLanguage(code)

	return code
}

export default initTranslator
