import styled from 'styled-components'
import { Regular14 } from './Typography.styled'

export const HorizontalDivider = styled.div`
	height: 1px;
	background-color: #DBDFE3;
	border-radius: 4px;
`

export const RulesAndAgreementsStyled = styled.div`
	display: flex;
	flex-flow: column;
	row-gap: 24px;
`

export const Text = styled.div`
	${Regular14}
`
