import React, { useContext } from 'react'
import styled from 'styled-components'
import { TranslationContext } from '@/context'
import { COLORS } from 'Globals/global.styles'
import CityTaxTooltip from 'Views/check-in-views/summary-view/city-tax-tooltip.component'

const Wrapper = styled.div`
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Title = styled.div`
  font-size: 20px;
  display: flex;
  gap: 8px;
  flex: 1;
`
const SubText = styled.div`
  font-size: 16px;
`

const Border = styled.div`
  border-bottom: 1px solid ${COLORS.border.minor};
`

const CityTaxProduct = ({ isCityTaxRequired = false }) => {
	const translation = useContext(TranslationContext)
	if (isCityTaxRequired) {
		return (
			<>
				<Wrapper>
					<Title>
						{translation.t('City Tax')}
						<CityTaxTooltip />
					</Title>
					<SubText>
						{translation.t('The amount will be based on local regulation. Please reach out if you have any questions.')}
					</SubText>
				</Wrapper>
				<Border />
			</>
		)
	}
	return null
}

export default CityTaxProduct
