import styled from 'styled-components'

export const EnvironmentSwitcherComponent = styled.div`
    display: grid;
    row-gap: 4px;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 12px;
    margin: 12px;
    background-color: beige;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    opacity: 0.5;
    transition: opacity 0.3s;
	z-index: 1000;

    &:hover {
      opacity: 1;
    }
`

export const EnvironmentSwitcherTitle = styled.div`
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
`

export const EnvironmentSwitcherContent = styled.div`
	display: grid;
	row-gap: 8px;
`

export const EnvironmentSwitcherSelectContent = styled.div`
	display: flex;
	column-gap: 4px;
`
