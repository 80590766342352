/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { createStructuredSelector } from 'reselect'

import { ContentMainHeading, ContentSubHeading, HeaderSticky } from 'Components/view-structure.component'
import Button from '../../../components/ui/button.component'

import { selectAppState } from '../../../redux/app/app.selectors'
import { selectReservation } from '../../../redux/reservation/reservation.selectors'
import { COLORS, FONT_WEIGHTS, generateMediaQuery } from '../../../globals/global.styles'
import config from '../../../globals/constants'

import { TranslationContext } from 'Src/context'
import PageView from 'Src/components/PageView'
import { redirectToReception } from 'Src/utils/reception-helpers'

const {
	CHECK_IN_FLOW_SURVEY_ID,
} = config

const Wrapper = styled.div`
	text-align: center;
	margin-top: 20%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.content {
		.top {
			background: white;
		}

		.bottom {
			padding-top: 20px;
			background: rgb(244,247,246);
		}

		.top::after {
			content: "";
			float: left;
			display: block;
			height: 4rem;
			width: 100%;
			background: hsla(0, 0%, 100%, 0.5);
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 70 500 60' preserveAspectRatio='none'%3E%3Crect x='0' y='0' width='500' height='500' style='stroke: none; fill: rgb(244,247,246);' /%3E%3Cpath d='M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z' style='stroke: none; fill: white;'%3E%3C/path%3E%3C/svg%3E");
			background-size: 100% 100%;
			margin-bottom: 36px;

			${generateMediaQuery.mobileSmall(`
				margin-bottom: 4px;
			`)}
		}
	}
`

const CheckmarkAnimation = styled.div`
	.success-checkmark {
		width: 80px;
		height: 115px !important;
		margin: 0 auto;

		.check-icon {
			width: 80px;
			height: 80px;
			position: relative;
			border-radius: 50%;
			box-sizing: content-box;
			border: 4px solid #4CAF50;

			&::before {
				top: 3px;
				left: -2px;
				width: 30px;
				transform-origin: 100% 50%;
				border-radius: 100px 0 0 100px;
			}

			&::after {
				top: 0;
				left: 30px;
				width: 60px;
				transform-origin: 0 50%;
				border-radius: 0 100px 100px 0;
				animation: rotate-circle 4.25s ease-in;
			}

			&::before, &::after {
				content: '';
				height: 100px;
				position: absolute;
				background: #FFFFFF;
				transform: rotate(-45deg);
			}

			.icon-line {
				height: 7px;
				background-color: #4CAF50;
				display: block;
				border-radius: 2px;
				position: absolute;
				z-index: 10;

				&.line-tip {
					top: 43px;
					left: 21px;
					width: 20px;
					transform: rotate(45deg);
					animation: icon-line-tip 0.75s;
				}

				&.line-long {
					top: 38px;
					right: 14px;
					width: 36px;
					transform: rotate(-45deg);
					animation: icon-line-long 0.75s;
				}
			}

			.icon-circle {
				top: -4px;
				left: -4px;
				z-index: 10;
				width: 80px;
				height: 80px;
				border-radius: 50%;
				position: absolute;
				box-sizing: content-box;
				border: 6px solid rgb(76, 175, 80);
			}

			.icon-fix {
				top: 8px;
				width: 5px;
				left: 26px;
				z-index: 1;
				height: 85px;
				position: absolute;
				transform: rotate(-45deg);
				background-color: #FFFFFF;
			}
		}
	}

	@keyframes icon-line-tip {
		0% {
			width: 0;
			left: 1px;
			top: 19px;
		}
		54% {
			width: 0;
			left: 1px;
			top: 19px;
		}
		70% {
			width: 50px;
			left: -8px;
			top: 37px;
		}
		84% {
			width: 17px;
			left: 21px;
			top: 48px;
		}
		100% {
			width: 25px;
			left: 14px;
			top: 45px;
		}
	}

	@keyframes icon-line-long {
		0% {
			width: 0;
			right: 46px;
			top: 54px;
		}
		65% {
			width: 0;
			right: 46px;
			top: 54px;
		}
		84% {
			width: 55px;
			right: 0px;
			top: 35px;
		}
		100% {
			width: 47px;
			right: 8px;
			top: 38px;
		}
	}
`

const SuccessViewFooterStyled = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	padding: 32px 0 32px 0;
	margin: 0 -16px -16px;
	background: rgb(244,247,246);
`

const BookingConfirmWrapper = styled.div`
	margin-left: 15%;
	margin-right: 15%;
`

const NextStepSection = styled.div`
	margin-right: -16px;
	margin-left: -16px;
`

const NextStepHeader = styled.div`
	font-size: 14px;
	font-weight: ${FONT_WEIGHTS.bold};
`

const NextStepText = styled.div`
	margin: 4px 32px 0 32px;
	padding: 0 16px;
	font-size: 14px;
	font-weight: ${FONT_WEIGHTS.light};
	color: ${COLORS.text.secondary};

	${generateMediaQuery.mobileSmall(`
		margin: 0 16px 0 16px;
	`)}
`

const SuccessView = ({
	reservation,
}) => {
	const translation = useContext(TranslationContext)
	const {
		property: {
			phone: propertyPhone,
		},
		slug: reservationSlug,
	} = reservation

	useEffect(() => {
		const s = document.createElement('script')
		s.setAttribute('id', 'survicate-script')
		s.src = config.SURVICATE_LIBRARY_URL
		s.async = true
		document.body.insertAdjacentElement('beforeend', s)

		const openSurvey = () => {
			// eslint-disable-next-line
			window._sva.setVisitorTraits({
				property: reservation.property.key,
				confirmationNumber: reservation.bookingNumber,
			})
			// eslint-disable-next-line
			window._sva.showSurvey(CHECK_IN_FLOW_SURVEY_ID)
		}

		window.addEventListener('SurvicateReady', openSurvey)

		return () => {
			window.removeEventListener('SurvicateReady', openSurvey)
			document.getElementById('survicate-script')?.remove()
			document.getElementById('survicate-box')?.remove()
		}
	}, [])

	const onSubmit = (ev) => {
		ev.preventDefault()
		redirectToReception(reservationSlug)
	}

	return (
		<form
			onSubmit={onSubmit}
		>
			<PageView
				Header={() => (
					<HeaderSticky
						contactButton={{ url: `tel:${propertyPhone}` }}
					/>
				)}
			>
				<Wrapper>
					<CheckmarkAnimation>
						<div className="success-checkmark">
							<div className="check-icon">
								<span className="icon-line line-tip" />
								<span className="icon-line line-long" />
								<div className="icon-circle" />
								<div className="icon-fix" />
							</div>
						</div>
					</CheckmarkAnimation>
					<BookingConfirmWrapper>
						<ContentMainHeading>
							{translation.t('Booking Completed')}
						</ContentMainHeading>
						<ContentSubHeading>
							{translation.t('You are all set for your stay!')}
						</ContentSubHeading>
					</BookingConfirmWrapper>
					<NextStepSection className="content">
						<div className="top" />
						<div className="bottom">
							<NextStepHeader>
								{translation.t('What to do next')}
							</NextStepHeader>
							<NextStepText>
								{translation.t('Please continue to our online Guest area to see your apartment details - location, access info, wifi password, etc.')}
							</NextStepText>
						</div>
					</NextStepSection>
					<SuccessViewFooterStyled>
						<Button variant="primary" type="submit">
							{translation.t('Continue to Guest Area')}
						</Button>
					</SuccessViewFooterStyled>
				</Wrapper>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	appState: selectAppState,
	reservation: selectReservation,
})

export default connect(mapStateToProps)(SuccessView)
