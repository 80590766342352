import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import {
	goToStep,
	goToPrevStep,
} from 'Redux/app/go-to-step.actions'
import { selectReservation } from 'Redux/reservation/reservation.selectors'
import { selectCleaningMidStayState } from 'Redux/reservation/cleaning-mid-stay/cleaning-mid-stay.selectors'
import { selectOrderedCleaningDatesTimes } from 'Redux/reservation/products/products.selectors'

import {
	HeaderSticky,
	Section,
} from 'Components/view-structure.component'
import YourCleaningsList from './your-cleanings-list.component'

import config from 'Globals/constants'
import { FONT_WEIGHTS } from 'Globals/global.styles'
import { TranslationContext } from 'Src/context'
import PageView from 'Src/components/PageView'

const {
	ACTIVE_PRODUCT_KEYS,
	DISPLAYABLE_PRODUCTS_DETAILS,
} = config

const headerImage = DISPLAYABLE_PRODUCTS_DETAILS
	.find((product) => product.productKey === ACTIVE_PRODUCT_KEYS.cleaningMidStay)?.image

const ImageFullWidth = styled.div`
	position: relative;
	margin: -16px -16px 16px -16px;
	height: 155px;
	background: url(${({ image }) => image}) center center;
	background-size: cover;
`

// :todo - refactor - PageHeading and PageDescription used similarly in multiple files
const PageHeading = styled.h1`
	margin-bottom: 0;
	margin-top: 0;
	font-size: 28px;
	font-weight: ${FONT_WEIGHTS.normal};
`

const PageDescription = styled.div`
	margin-bottom: 32px;
	font-weight: ${FONT_WEIGHTS.light};

	&,
	& > b {
		font-size: 14px;
	}

	b {
		font-weight: ${FONT_WEIGHTS.normal};
	}
`

const SectionStyled = styled(Section)`
	margin-bottom: 32px;
`

const GuestAreaCleaningMidStayYourScheduleView = ({
	reservation,
	cleaningMidStayState,
	orderedCleaningDatesTimes,
	goToSelectTime,
	goToPrevStep,
}) => {
	const { maxCleaningTimesCount } = cleaningMidStayState
	const translation = useContext(TranslationContext)

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault()
				goToSelectTime()
			}}
		>
			<PageView
				Header={() => <HeaderSticky onCloseButton={goToPrevStep} />}
			>
				<ImageFullWidth image={headerImage} />

				<PageHeading>{translation.t('Your schedule')}</PageHeading>
				<PageDescription>
					{/* eslint-disable max-len */}
					{translation.t('These are your scheduled cleanings. If you have any cleanings remaining, then please schedule them below.')}
					{' '}
					{translation.t('Let us know if you have any questions or any feedback regarding the cleanings in the chat.')}
					{' '}
					{translation.t('You can have {maxCleaningTimesCount} {maxCleaningTimesCount, plural, =1{cleaning} other{cleanings}} during your current stay.',
						{ maxCleaningTimesCount })}
				</PageDescription>

				<SectionStyled>
					<YourCleaningsList
						reservation={reservation}
						cleaningMidStayState={cleaningMidStayState}
						orderedCleaningDatesTimes={orderedCleaningDatesTimes}
						goToSelectTime={goToSelectTime}
					/>
				</SectionStyled>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	reservation: selectReservation,
	cleaningMidStayState: selectCleaningMidStayState,
	orderedCleaningDatesTimes: selectOrderedCleaningDatesTimes,
})

const mapDispatchToProps = (dispatch) => ({
	goToSelectTime: () => dispatch(goToStep(
		{ step: 'guest-area-cleaning-mid-stay-select-time' },
		{ toSkipPatch: true },
	)),
	goToPrevStep: () => dispatch(goToPrevStep()),
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestAreaCleaningMidStayYourScheduleView)
