export enum FLOW_SERVICE_MAP {
	CHECK_IN = 'check-in',
	CHECK_OUT = 'check-out',
}

export type StepsType = {
	CHECK_IN: any,
	CHECK_OUT?: any,
}

type Flow = keyof typeof FLOW_SERVICE_MAP

export interface StepData {
	step: string
	guestId?: string
	productKey?: string
}

export const enum ErrorCode {
	UNKNOWN,
	EMAIL_VERIFICATION_REQUIRED,
	TOO_MANY_VERIFICATION_ATTEMPTS,
	WRONG_VERIFICATION_CODE,
	VERIFICATION_CODE_EXPIRED
}

export interface DetailedError {
	readonly fieldName: string;
	readonly message: string;
	readonly code: ErrorCode;
}

export type AppError = string | DetailedError;

export interface AppState {
	currentStepData?: any
	flow: Flow
	currentStepFormErrors: AppError[]
	progressData: object
	stepDataIndex: number | null
	stepDataHistory: StepData[]
	popupData?: string
	snackbarText?: string
	isSubmitting?: boolean
	errorViewVisible?: boolean
	languageCode: string
}
