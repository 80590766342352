import PaymentIcons from 'Components/payment-icons.component'
import React from 'react'
import styled from 'styled-components'
import { FONT_WEIGHTS } from 'Globals/global.styles'
import config from 'Globals/constants'
import { getLastUnmaskedDigits } from '@/utils'

const { PAYMENT_METHODS } = config

const CardInfoWrapper = styled.div`
	display: inline-block;
`

const CardIconAndNumber = styled.div`
	display: inline-block;
`

const CardIconAndNumberWrapper = styled.div`
	display: flex;
	align-items: center;
`

const PaymentIcon = styled.img`
	width: 48px;
	margin-right: 10px;
`

const MaskDots = styled.span`
	margin-right: 6px;
	font-size: 12px;
	letter-spacing: 3px;
`

const Last4Digits = styled.span`
	padding-top: 1px;
	font-size: 13px;
	font-weight: ${FONT_WEIGHTS.light};
`

const SummaryCreditCardDetails = ({ creditCard }) => {
	const {
		type,
		numberMasked,
	} = creditCard || {}

	return (
		<CardInfoWrapper>
			{creditCard ? (
				<CardIconAndNumber>
					<CardIconAndNumberWrapper>
						<PaymentIcon
							src={(PAYMENT_METHODS[type] || {}).icon}
							alt={type}
						/>
						<MaskDots>&#x25CF;&#x25CF;&#x25CF;&#x25CF;</MaskDots>
						{numberMasked && (
							<Last4Digits>{getLastUnmaskedDigits(numberMasked)}</Last4Digits>
						)}
					</CardIconAndNumberWrapper>
				</CardIconAndNumber>
			) : <PaymentIcons />}
		</CardInfoWrapper>
	)
}

export default SummaryCreditCardDetails
