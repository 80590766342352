import logger from '../../../logger/logger'

import CleaningMidStayActionTypes from './cleaning-mid-stay.types'
import { selectSelectedCleaningDatesTimes } from './cleaning-mid-stay.selectors'
import { getCoreApiBaseUrl } from '@/utils/env'
/* eslint import/no-cycle: 0 */

const updateCurrentCleaningTime = (cleaningTime) => (dispatch) => {
	dispatch({
		type: CleaningMidStayActionTypes.UPDATE_CURRENT_CLEANING_TIME,
		payload: { cleaningTime },
	})
}

const updateCurrentCleaningDate = (cleaningDate) => (dispatch) => {
	dispatch({
		type: CleaningMidStayActionTypes.UPDATE_CURRENT_CLEANING_DATE,
		payload: { cleaningDate },
	})
}

const currentCleaningDateTimeToArray = () => (dispatch) => {
	dispatch({ type: CleaningMidStayActionTypes.CURRENT_CLEANING_DATE_TIME_TO_ARRAY })
}

const clearSelectedCleaningDateTimes = () => (dispatch) => {
	dispatch({ type: CleaningMidStayActionTypes.CLEAR_SELECTED_CLEANING_DATE_TIMES })
}

/**
 * GET AVAILABLE CLEANING TIMES
 */

const getAvailableCleaningTimesSuccess = (data) => (dispatch) => {
	dispatch({
		type: CleaningMidStayActionTypes.GET_AVAILABLE_CLEANING_TIMES_SUCCESS,
		payload: { data },
	})
}

const getAvailableCleaningTimes = (reservationId, productId) => (dispatch) => {
	const apiHost = getCoreApiBaseUrl()
	const url = `${apiHost}/product-availability/${productId}?reservationId=${reservationId}`

	fetch(url, { method: 'GET' })
		.then((response) => response.json())
		.then(({ data, message }) => {
			if (!data?.length) {
				logger.error('Could not fetch available cleaning times', message)
				return
			}

			dispatch(getAvailableCleaningTimesSuccess(data[0]))
		})
		.catch((error) => {
			if (!error.response) {
				throw error
			}

			logger.error('Could not fetch available cleaning times', error)
		})
}

/**
 * REMOVING CLEANING TIMES
 */

const removeSelectedCleaningDateTime = (dateTime) => (dispatch, getState) => {
	const selectedCleaningDatesTimes = selectSelectedCleaningDatesTimes(getState())
	const foundDateTime = selectedCleaningDatesTimes.find(({ startDate }) => startDate === dateTime)

	if (foundDateTime !== undefined) {
		dispatch({
			type: CleaningMidStayActionTypes.REMOVE_SELECTED_CLEANING_DATE_TIME,
			payload: { dateTime: foundDateTime },
		})
	}
}

export {
	updateCurrentCleaningDate,
	updateCurrentCleaningTime,
	currentCleaningDateTimeToArray,
	clearSelectedCleaningDateTimes,
	getAvailableCleaningTimes,
	removeSelectedCleaningDateTime,
}
