import feathers from '@feathersjs/client'
import logger from '../logger/logger'
import { getCoreApiBaseUrl } from '@/utils/env'

const client = feathers()
const apiHost = getCoreApiBaseUrl()

// feathers.rest('http://your.machines.ip.address:3030') when testing with BrowserStack
const restClient = feathers.rest(apiHost)

client.configure(restClient.fetch(fetch))

client.hooks({
	error({ error }) {
		logger.warn(`Feathers error hook: ${error.message}`, error)
	},
})

export { client }
