import { createSelector } from 'reselect'
import getStepAPIChangesSelector from '../step-specific/step-api-changes.selectors'
import {
	selectHasImmediatelyPurchasablePaidProducts,
	selectProductsPageListItems,
	selectSelectedProductKeys,
	selectUpgradeableRoom,
} from '../reservation/products/products.selectors'
import config from '../../globals/constants'
import { getStepIndex } from './app.utils'
import { AppState } from '../../types/app-state.types'
import { RootState } from '../store'
import {
	selectAskForBusinessLead,
	selectIsBreakfastCampaignActive,
	selectIsCityTaxRequired,
	selectIsWhiteLabel,
} from 'Redux/reservation/reservation.selectors'

import { filterDisplayableProducts } from '@/utils/products'

const { STEPS, ACTIVE_PRODUCT_KEYS } = config
const selectAppState = (state: RootState) : AppState => state.appState

const selectCurrentStepData = createSelector([selectAppState], ({ stepDataIndex, stepDataHistory }) => {
	if (stepDataIndex !== null) {
		return stepDataHistory[stepDataIndex] ?? null
	}

	return null
})

const selectCurrentStep = createSelector(
	[selectCurrentStepData, selectIsWhiteLabel],
	(currentStepData, isWhiteLabel) => {
		if (isWhiteLabel && currentStepData?.step === STEPS.CHECK_IN.MAIN['start']) {
			return STEPS.CHECK_IN.MAIN['main-guest-contacts']
		}

		return currentStepData?.step ?? null
	},
)

const selectMainFlowSteps = createSelector([
	selectAppState,
	selectCurrentStep,
	selectProductsPageListItems,
	selectSelectedProductKeys,
	selectHasImmediatelyPurchasablePaidProducts,
	selectUpgradeableRoom,
	selectIsCityTaxRequired,
	selectIsBreakfastCampaignActive,
	selectAskForBusinessLead,
], (
	appState,
	currentStep,
	productsListItems,
	selectedProductKeys,
	hasImmediatelyPurchasablePaidProducts,
	hasUpgradeableRoom,
	isCityTaxRequired,
	isBreakfastCampaignActive,
	shouldAskForBusinessLead,
) => {
	const { flow } = appState
	const MAIN_FLOW_STEPS = STEPS[flow].MAIN

	const STEPS_ARRAY = [
		MAIN_FLOW_STEPS['start'],
		MAIN_FLOW_STEPS['main-guest-contacts'],
	]

	if (shouldAskForBusinessLead) {
		STEPS_ARRAY.push(MAIN_FLOW_STEPS['business-lead'])
	}

	STEPS_ARRAY.push(MAIN_FLOW_STEPS['main-guest-identification'])
	STEPS_ARRAY.push(MAIN_FLOW_STEPS['guests'])

	const toShowProductsView = !!productsListItems?.length
		&& filterDisplayableProducts(productsListItems, isBreakfastCampaignActive).length > 0

	if (toShowProductsView) {
		STEPS_ARRAY.push(MAIN_FLOW_STEPS['products'])
	}

	const toShowUpgradeRoomView = !!hasUpgradeableRoom?.length

	if (toShowUpgradeRoomView) {
		STEPS_ARRAY.push(MAIN_FLOW_STEPS['room-upgrade'])
	}

	STEPS_ARRAY.push(MAIN_FLOW_STEPS['times'])

	const toShowSummaryView = !!hasImmediatelyPurchasablePaidProducts
		|| currentStep === MAIN_FLOW_STEPS['summary']
		|| selectedProductKeys.includes(ACTIVE_PRODUCT_KEYS.propertyUnitPaidUpgrade)
		|| isCityTaxRequired

	if (toShowSummaryView) {
		STEPS_ARRAY.push(MAIN_FLOW_STEPS['summary'])
	}

	return STEPS_ARRAY
})

const selectIsFinalStep = createSelector(
	[
		selectMainFlowSteps,
		selectCurrentStep,
	],
	(
		mainFlowSteps,
		currentStep,
	) => {
		const stepIdx = getStepIndex(mainFlowSteps, currentStep)
		return stepIdx >= mainFlowSteps.length - 1
	},
)

const selectCurrentStepChangedFieldsToAPI = (state: RootState) => {
	const currentStep = selectCurrentStep(state)
	const selectStepAPIChanges = getStepAPIChangesSelector(currentStep)
	return selectStepAPIChanges(state)
}

const selectCurrentStepHasChanges = createSelector(
	[selectCurrentStepChangedFieldsToAPI],
	(changedFieldsAPI) => !!changedFieldsAPI && !!Object.keys(changedFieldsAPI).length,
)

const selectLanguage = createSelector(
	[selectAppState],
	(appState) => appState?.languageCode || 'en-GB',
)

const selectPopupData = createSelector(
	[selectAppState],
	(appState) => appState.popupData,
)

const selectSnackbarText = createSelector(
	[selectAppState],
	(appState) => appState.snackbarText,
)

const selectIsSubmitting = createSelector(
	[selectAppState],
	(appState) => appState.isSubmitting,
)

const selectErrorViewVisible = createSelector(
	[selectAppState],
	(appState) => appState.errorViewVisible,
)

export {
	selectAppState,
	selectLanguage,
	selectCurrentStepData,
	selectCurrentStep,
	selectMainFlowSteps,
	selectIsFinalStep,
	selectCurrentStepHasChanges,
	selectCurrentStepChangedFieldsToAPI,
	selectPopupData,
	selectSnackbarText,
	selectIsSubmitting,
	selectErrorViewVisible,
}
