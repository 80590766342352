const BOB_W_GOLD = 'rgb(140, 127, 78)'
const BOB_W_GOLD_BRIGHT = 'rgb(185, 158, 55)'
const BOB_W_GOLD_DARK = 'rgb(125, 103, 22)'
const BOB_W_GOLD_SUPER_LIGHT = 'rgb(239, 234, 218)'
const RED = 'rgb(232, 3, 37)'
const BLACK = 'rgb(0, 0, 0)'
const GREEN = 'rgb(41, 168, 76)'
const DARK_ORANGE = 'rgb(255, 184, 78)'
const DARK_GRAY_BLUE = 'rgb(43, 72, 91)'
const LIGHT_GRAY_BLUE = 'rgb(244, 247, 246)'
const GRAY_LIGHT_1 = 'rgb(248, 248, 248)'
const GRAY_LIGHT_2 = 'rgb(220, 220, 220)'
const GRAY_LIGHT_3 = 'rgb(191, 191, 191)'

const COLORS = {
	text: {
		primary: BLACK,
		secondary: 'rgb(78, 78, 78)',
		minor: 'rgb(148, 148, 148)',
		details: 'rgb(101, 101, 101)',
		white: 'rgb(255, 255, 255)',
		label: BLACK,
		highlight: BOB_W_GOLD,
		hightlightBright: BOB_W_GOLD_BRIGHT,
		alert: RED,
		greatSuccess: GREEN,
		link: BOB_W_GOLD,
		linkHover: BOB_W_GOLD_DARK,
	},
	border: {
		primary: 'rgb(0, 0, 0)',
		secondary: 'rgb(0, 0, 0)',
		minor: GRAY_LIGHT_2,
		hover: BOB_W_GOLD_BRIGHT,
		highlight: BOB_W_GOLD,
		alert: RED,
	},
	background: {
		action: 'rgb(0, 0, 0)',
		actionHover: 'rgb(50, 50, 50)',
		primary: 'rgb(255, 255, 255)',
		secondary: GRAY_LIGHT_1,
		secondaryHover: 'rgb(221, 221, 221)',
		warning: BOB_W_GOLD_SUPER_LIGHT,
		hover: GRAY_LIGHT_1,
		highlight: BOB_W_GOLD,
		highlightHover: BOB_W_GOLD_DARK,
		backdrop: 'rgba(0, 0, 0, 0.5)',
		disabled: GRAY_LIGHT_3,
		mainBackground: 'rgb(232, 232, 232)',
		header: LIGHT_GRAY_BLUE,
		keyCode: DARK_GRAY_BLUE,
	},
	fill: {
		primary: BOB_W_GOLD_BRIGHT,
		secondary: 'rgb(102, 102, 102)',
		minor: GRAY_LIGHT_2,
	},
	misc: {
		bob_w_gold_bright: BOB_W_GOLD_BRIGHT,
		green: GREEN,
		darkOrange: DARK_ORANGE,
	},
}

const FONT_WEIGHTS = {
	bold: 700,
	normal: 500,
	light: 400,
}

const ZINDEX = {
	stickyHeader: 5555,
	stickyFooter: 3333,
	selectMenu: 4444,
	popup: 6666,
}

const SCREEN_SIZES = {
	mobileSmallPx: 350,
	mobileLargePx: 390,
	tabletPx: 900,
}

const generateMediaQuery = {
	mobileSmall: (content, size = SCREEN_SIZES.mobileLargePx) => (`
		@media
			only screen and (min-device-pixel-ratio: 2) and (max-width: ${size}px),
			only screen and (   min-resolution: 192dpi) and (max-width: ${size}px),
			only screen and (   min-resolution: 2dppx)  and (max-width: ${size}px),
			(max-width: ${SCREEN_SIZES.mobileSmallPx}px) { 
				${content}
			}
	`),

	allMobile: (content) => (`
		@media
			only screen and (min-device-pixel-ratio: 2) and (max-width: ${SCREEN_SIZES.tabletPx}px),
			only screen and (   min-resolution: 192dpi) and (max-width: ${SCREEN_SIZES.tabletPx}px),
			only screen and (   min-resolution: 2dppx)  and (max-width: ${SCREEN_SIZES.tabletPx}px),
			(max-width: ${SCREEN_SIZES.tabletPx}px) { 
				${content}
			}
	`),
}

export {
	COLORS,
	FONT_WEIGHTS,
	ZINDEX,
	SCREEN_SIZES,
	generateMediaQuery,
}
