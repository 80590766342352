import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { CustomerState } from './customer.reducer'

export function selectCustomerState(state: RootState) : CustomerState {
	return state.customer
}

export const selectCustomerVerificationCode = createSelector(selectCustomerState, (customer) => {
	return customer.$emailVerificationCode
})
