import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectIsSubmitting } from 'Src/redux/app/app.selectors'
import { Spinner } from '../Spinner.styled'
import CodeInput from '../CodeInput/CodeInput'
import { SpinnerContainer } from './EmailVerification.styled'

interface Props {
	readonly codeLength: number;
  readonly onSubmit: (code: string, clearCodeCallback: () => void) => void;
}

const CodeContainer : FC<Props> = ({ codeLength, onSubmit }) => {
	const isSubmitting = useSelector(selectIsSubmitting)

	if (isSubmitting) {
		return (
			<SpinnerContainer>
				<Spinner />
			</SpinnerContainer>
		)
	}

	return (
		<CodeInput
			codeLength={codeLength}
			onSubmit={onSubmit}
		/>
	)
}

export default CodeContainer
