import React from 'react'
import styled from 'styled-components'

import { COLORS } from '../../globals/global.styles'

const LinkStyled = styled.a`
	&,
	& svg,
	& path {
		color: ${COLORS.text.link};
	}

	&:hover,
	&:hover svg,
	&:hover path {
		color: ${COLORS.text.linkHover};
	}
`

const Link = ({ children, ...otherProps }) => (
	<LinkStyled {...otherProps}>{children}</LinkStyled>
)

export default Link
