import { createAction } from '@reduxjs/toolkit'

export const enum EmailErrorCode {
	EMAIL_VERIFICATION_REQUIRED,
	TOO_MANY_VERIFICATION_ATTEMPTS,
	WRONG_VERIFICATION_CODE,
	VERIFICATION_CODE_EXPIRED
}

export const setEmailErrorCode = createAction<string>('error/setEmailErrorCode')
export const updateEmailErrorCode = createAction<EmailErrorCode>('error/updateEmailErrorCode')
export const clearEmailErrorCode = createAction('error/clearEmailErrorCode')
