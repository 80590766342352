import React, { FC, useContext } from 'react'
import { TranslationContext } from 'Src/context'
import { SubTitle, TextAction, SuccessMessage, ResendItem } from './EmailVerification.styled'

interface Props {
	readonly isResending: boolean;
  readonly onResend: () => void;
}

const ResendAction : FC<Props> = ({ isResending, onResend }) => {
	const translation = useContext(TranslationContext)

	if (isResending) {
		return (
			<SuccessMessage>
				{ translation.t('Code has been sent') }
			</SuccessMessage>
		)
	}

	return (
		<ResendItem>
			<SubTitle>
				{ translation.t('Didn’t get it?') }
			</SubTitle>

			{ ' ' }

			<TextAction onClick={onResend}>
				{ translation.t('Resend') }
			</TextAction>
		</ResendItem>
	)
}

export default ResendAction
