import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'
import { format, utcToZonedTime } from 'date-fns-tz'

import {
	HeaderSticky,
	Section,
	ContentMainHeading,
	ContentSubHeading,
	FooterSection,
	InvalidFieldsAlertSection,
} from 'Components/view-structure.component'
import {
	Select,
	BlockSelector,
} from 'Components/ui'

import { selectAppState } from 'Redux/app/app.selectors'
import {
	updateCurrentStepField,
	showConfirmationPopup,
} from 'Redux/app/app.actions'
import {
	goToStep,
	goToPrevStep,
} from 'Redux/app/go-to-step.actions'
import { selectReservation } from 'Redux/reservation/reservation.selectors'
import {
	selectAvailableCleaningDatesTimes,
	selectCurrentCleaningDate,
	selectCurrentCleaningTime,
	selectSelectedCleaningDatesTimes,
} from 'Redux/reservation/cleaning-mid-stay/cleaning-mid-stay.selectors'

import {
	FONT_WEIGHTS,
	generateMediaQuery,
} from 'Globals/global.styles'

import { TranslationContext, ConstantsContext } from 'Src/context'
import { selectOrderedCleaningDatesTimes } from 'Src/redux/reservation/products/products.selectors'
import isSameDay from 'date-fns/isSameDay'
import PageView from 'Src/components/PageView'

const DescriptionSection = styled(Section)`
	${generateMediaQuery.mobileSmall(`
		margin: 10px 0 20px 0;
	`)}
`

const PageHeading = styled.h1`
	margin-bottom: 0;
	margin-top: 0;
	font-size: 28px;
	font-weight: ${FONT_WEIGHTS.normal};
`

const PageDescription = styled.div`
	margin-bottom: 32px;
	font-weight: ${FONT_WEIGHTS.light};

	&,
	& > b {
		font-size: 14px;
	}

	b {
		font-weight: ${FONT_WEIGHTS.normal};
	}
`

const SelectStyled = styled(Select)`
	margin-bottom: 32px;
`

const ContentSubHeadingStyled = styled(ContentSubHeading)`
	margin-bottom: 16px;
`

const BlockSelectorStyled = styled(BlockSelector)`
	margin-bottom: 16px;
`

function formatLabel(date) {
	return format(utcToZonedTime(date), 'EEE, d MMMM yyyy')
}

const GuestAreaCleaningMidStaySelectTimeView = ({
	appState: {
		currentStepFormErrors: formErrors,
	},
	reservation: {
		property: { timezone: propertyTimeZone },
	},
	availableDatesTimes,
	cleaningDate,
	cleaningTime,
	updateField,
	showConfirmationPopup,
	goToPrevStep,
	goToConfirmationViewWithStore,
	orderedCleaningDatesTimes,
	selectedCleaningDatesTimes,
}) => {
	const translation = useContext(TranslationContext)
	const {
		constants: {
			COMMON_STRINGS,
		},
	} = useContext(ConstantsContext)

	const dateOptions = [...Object.entries(availableDatesTimes)]
		// filter already ordered and already selected days
		.filter(([, dateValue]) => {
			const { startDate } = dateValue[0]

			const isOrdered = orderedCleaningDatesTimes.some(({ consumptionDate }) => {
				return isSameDay(new Date(startDate), new Date(consumptionDate))
			})

			if (isOrdered) {
				return false
			}

			const isSelected = selectedCleaningDatesTimes.some(({ startDate: selectedStartDate }) => {
				return isSameDay(new Date(startDate), new Date(selectedStartDate))
			})

			if (isSelected) {
				return false
			}

			return true
		})
		.map(([dateKey, dateValue]) => {
			const { startDate } = dateValue[0]

			return {
				value: dateKey,
				label: formatLabel(startDate),
			}
		})

	const dateValue = cleaningDate && dateOptions.find(({ value }) => value === cleaningDate)
	// Using the first available cleaning date if no date selected yet
	const firstDateOption = dateOptions[0].value

	const timeOptions = availableDatesTimes[cleaningDate || firstDateOption]
		.map(({ startDate, endDate, isAvailable }) => ({
			value: startDate,
			endValue: endDate,
			label: `${format(utcToZonedTime(startDate, propertyTimeZone), 'HH:mm')}${endDate ? ` - ${format(utcToZonedTime(endDate, propertyTimeZone), 'HH:mm')}` : ''}`,
			isDisabled: !cleaningDate || !isAvailable,
		}))

	const goBackWithConfirmation = () => {
		if (cleaningDate || cleaningTime) {
			showConfirmationPopup(
				COMMON_STRINGS.leaveStepWithoutSaving,
				() => {
					updateField('currentCleaningDate', null)
					updateField('currentCleaningTime', null)
					goToPrevStep()
				},
				'Confirm',
				'Cancel',
			)

			return
		}

		goToPrevStep()
	}

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault()
				goToConfirmationViewWithStore()
			}}
		>
			<PageView
				Header={() => <HeaderSticky onCloseButton={() => goBackWithConfirmation()} />}
			>
				<DescriptionSection>
					<PageHeading>{translation.t('Choose date and time')}</PageHeading>
					<PageDescription>
						{/* eslint-disable max-len */}
						{translation.t('Keep in mind that cleanings are done only from Monday to Friday between 9:00 and 17:00.')}
					</PageDescription>
				</DescriptionSection>

				{!!formErrors?.length && <InvalidFieldsAlertSection />}

				<Section>
					<ContentMainHeading>{translation.t('Choose date')}</ContentMainHeading>
					<ContentSubHeadingStyled>{translation.t('On what date would you like to have the cleaning?')}</ContentSubHeadingStyled>
					<SelectStyled
						label={translation.t('Date')}
						name="mid-day-cleaning date"
						placeholder={translation.t('Choose date')}
						options={dateOptions}
						value={dateValue}
						onChange={({ value }) => updateField('currentCleaningDate', value)}
						isValid={!formErrors?.includes('cleaningDate')}
					/>

					<ContentMainHeading>{translation.t('Choose time')}</ContentMainHeading>
					<ContentSubHeadingStyled>
						{translation.t('Choose below your preferred time period when to have the cleaning. The cleaner will come during your select period and it will take 1 hour to complete the cleaning.')}
						<br />
						{translation.t('Duration: 1 hour')}
					</ContentSubHeadingStyled>
					<div data-testid="cleaning-times">
						<BlockSelectorStyled
							label={formErrors?.includes('cleaningTime') && translation.t('Choose a cleaning time')}
							options={timeOptions}
							value={cleaningTime && { value: cleaningTime }}
							onChange={({ value, endValue }) => updateField('currentCleaningTime', {
								startDate: value,
								endDate: endValue,
							})}
							isValid={!formErrors?.includes('cleaningTime')}
						/>
					</div>
				</Section>

				<FooterSection
					onPrevClick={goBackWithConfirmation}
					nextButtonText={translation.t('Add cleaning')}
					prevButtonText={translation.t('Cancel')}
				/>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	appState: selectAppState,
	reservation: selectReservation,
	availableDatesTimes: selectAvailableCleaningDatesTimes,
	cleaningDate: selectCurrentCleaningDate,
	cleaningTime: selectCurrentCleaningTime,
	orderedCleaningDatesTimes: selectOrderedCleaningDatesTimes,
	selectedCleaningDatesTimes: selectSelectedCleaningDatesTimes,
})

const mapDispatchToProps = (dispatch) => ({
	updateField: (fieldName, data) => dispatch(updateCurrentStepField(fieldName, data)),
	showConfirmationPopup: (text, onConfirm, confirmButtonText, cancelButtonText) => dispatch(
		showConfirmationPopup(text, onConfirm, null, confirmButtonText, cancelButtonText),
	),
	goToPrevStep: () => dispatch(goToPrevStep({ resetStepHistoryTip: true })),
	goToConfirmationViewWithStore: () => dispatch(goToStep(
		{ step: 'guest-area-cleaning-mid-stay-confirm' },
		{ resetStepHistoryTip: true },
	)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestAreaCleaningMidStaySelectTimeView)
