import React, { FC } from 'react'
import {
	LocationContent,
	LocationIcon,
	LocationLink,
} from './breakfast-partner-card.styled'
import IconLocation from 'Assets/icons/icon-location.svg'

interface Props {
    readonly googleMapsUrl: string | null;
    readonly distance: string;
}

const DistanceDetails: FC<Props> = ({ googleMapsUrl, distance }) => {
	if (!googleMapsUrl) return null

	return (
		<LocationContent>
			<LocationIcon
				src={IconLocation}
				alt={distance}
			/>

			<LocationLink
				href={googleMapsUrl}
				target="_blank"
				rel="noopener noreferrer"
			>
				{ distance }
			</LocationLink>
		</LocationContent>
	)
}

export default DistanceDetails
