import React from 'react'

import Select from './select.component'

import config from '../../globals/constants'

const {
	COUNTRIES,
} = config

const combineOptionData = ({ code, name }) => ({
	value: code,
	label: name,
})

const SelectCountry = ({
	value: countryCode,
	...otherProps
}) => {
	const country = countryCode && COUNTRIES.find(({ code }) => code === countryCode)
	const value = country && {
		value: country.code,
		label: country.name,
	}

	const countryOptions = COUNTRIES.map(combineOptionData)

	return (
		<Select
			value={value}
			options={countryOptions}
			{...otherProps}
		/>
	)
}

export default SelectCountry
