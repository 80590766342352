import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button } from './ui'
import { COLORS, ZINDEX } from '../globals/global.styles'
import { TranslationContext } from 'Src/context'
import { Card } from './PageView.styled'

const PopupContainer = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${COLORS.background.backdrop};
	z-index: ${ZINDEX.popup};
`

const PopupStyled = styled.div`
	display: inline-block;
	width: 100%;
	max-width: 600px;
	padding: 0 8px;
`

const ButtonContainer = styled.div`
	padding-top: 8px;
	text-align: right;
`

const ButtonStyled = styled(Button)`
	margin-left: 16px;
`

const CardContentContainer = styled.div`
  padding: 16px;
`

const Popup = ({
	content,
	callback,
	onConfirm,
	confirmButtonText,
	onCancel,
	cancelButtonText,
	close,
}) => {
	if (!content) return null

	const Content = typeof content === 'function' && content
	const translation = useContext(TranslationContext)

	return (
		<PopupContainer>
			<PopupStyled>
				<Card>
					<CardContentContainer>
						{Content ? <Content /> : content}

						<ButtonContainer>
							{onConfirm ? (
								<>
									<ButtonStyled
										variant="secondary"
										onClick={() => {
											if (onCancel) onCancel()
											close()
										}}
									>
										{cancelButtonText || translation.t('Cancel')}
									</ButtonStyled>
									<ButtonStyled
										variant="primary"
										onClick={() => {
											onConfirm()
											close()
										}}
									>
										{confirmButtonText || translation.t('Confirm')}
									</ButtonStyled>
								</>
							) : (
								<ButtonStyled
									variant="primary"
									onClick={() => {
										if (callback) {
											callback()
										}
										close()
									}}
								>
									Ok
								</ButtonStyled>
							)}
						</ButtonContainer>
					</CardContentContainer>

				</Card>
			</PopupStyled>
		</PopupContainer>
	)
}

export default Popup
