import React, { useContext } from 'react'
import styled from 'styled-components'
import { EditOutlined, MinusCircleOutlined } from '@ant-design/icons'

import { ResetButton } from './ui'

import { COLORS } from '../globals/global.styles'

import { TranslationContext } from 'Src/context'

const Wrapper = styled.div`
	display: flex;
	padding: 16px 0;
	border-bottom: 1px solid ${COLORS.border.minor};
	
	&:last-child {
		border-bottom: none;
	}
`

const Details = styled.div`
	flex: 1 1 auto;
`

const GuestName = styled.div``

const SubText = styled.div`
	font-size: 14px;
	line-height: 2.2em;
	color: ${COLORS.text.minor};
`

const AlertText = styled.div`
	font-size: 14px;
	line-height: 2.2em;
	color: ${COLORS.text.alert};
`

const ButtonsContainer = styled.div`
	flex: 1 0 auto;
`

const Button = styled(ResetButton)`
	float: right;

	:not(:last-child) {
		margin-left: 32px;
	}
`

const GuestRow = ({
	firstName,
	lastName,
	hasIncompleteDetails,
	subText,
	editGuest,
	removeGuest,
}) => {
	const fullName = (`${firstName || ''} ${lastName || ''}`).trim()
	const translation = useContext(TranslationContext)

	return (
		<Wrapper>
			<Details>
				<GuestName data-dd-privacy="mask" data-hj-suppress>{fullName}</GuestName>

				{!!subText && <SubText>{subText}</SubText>}

				{!!hasIncompleteDetails && (
					<AlertText>
						{translation.t('Please fill all the required fields')}
					</AlertText>
				)}
			</Details>

			<ButtonsContainer>
				{!!removeGuest && (
					<Button data-dd-privacy="mask" data-hj-suppress title={`${translation.t('Remove guest')} ${fullName}`} type="button" onClick={removeGuest}>
						<MinusCircleOutlined />
					</Button>
				)}
				<Button data-hj-suppress data-dd-privacy="mask" title={`${translation.t('Edit guest')} ${fullName}`} type="button" onClick={editGuest}>
					<EditOutlined />
				</Button>
			</ButtonsContainer>
		</Wrapper>
	)
}

export default GuestRow
