import BusinessLeadTypes from './business-lead.types'
import { ReservationApi } from 'Types/api.types'
import { Action } from 'redux'

interface BusinessLeadAction extends Action {
	payload: string;
}

const defaultState = {
	contactEmail: '',
	contactPerson: '',
	contactJobTitle: '',
	companyName: '',
	companySizeRange: '',
}

export type BusinessLeadState = ReservationApi['businessLeadCapture'] | null;
const businessLeadReducer = (state: BusinessLeadState = defaultState, action:BusinessLeadAction) => {
	switch (action.type) {
		// BUSINESS LEAD
		case BusinessLeadTypes.UPDATE_BUSINESS_LEAD_CONTACT_EMAIL:
			return {
				...state,
				contactEmail: action.payload,
			}

		case BusinessLeadTypes.UPDATE_BUSINESS_LEAD_CONTACT_NAME:
			return {
				...state,
				contactPerson: action.payload,
			}

		case BusinessLeadTypes.UPDATE_BUSINESS_LEAD_CONTACT_JOB_TITLE:
			return {
				...state,
				contactJobTitle: action.payload,
			}

		case BusinessLeadTypes.UPDATE_BUSINESS_LEAD_NAME:
			return {
				...state,
				companyName: action.payload,
			}

		case BusinessLeadTypes.UPDATE_BUSINESS_LEAD_COMPANY_SIZE:
			return {
				...state,
				companySizeRange: action.payload,
			}

		case BusinessLeadTypes.REMOVE_BUSINESS_LEAD:
			return null

		default:
			return state
	}
}

export default businessLeadReducer
