const GuestsActionTypes = {
	UPDATE_MAIN_GUEST: 'UPDATE_MAIN_GUEST',
	UPDATE_MAIN_GUEST_CUSTOMER: 'UPDATE_MAIN_GUEST_CUSTOMER',
	UPDATE_MAIN_GUEST_DOCUMENT: 'UPDATE_MAIN_GUEST_DOCUMENT',
	UPDATE_MAIN_GUEST_ADDRESS: 'UPDATE_MAIN_GUEST_ADDRESS',

	INIT_CURRENT_ADDITIONAL_GUEST: 'INIT_CURRENT_ADDITIONAL_GUEST',
	CLEAR_CURRENT_ADDITIONAL_GUEST: 'CLEAR_CURRENT_ADDITIONAL_GUEST',
	UPDATE_CURRENT_ADDITIONAL_GUEST: 'UPDATE_CURRENT_ADDITIONAL_GUEST',
	UPDATE_CURRENT_ADDITIONAL_GUEST_CUSTOMER: 'UPDATE_CURRENT_ADDITIONAL_GUEST_CUSTOMER',
	UPDATE_CURRENT_ADDITIONAL_GUEST_DOCUMENT: 'UPDATE_CURRENT_ADDITIONAL_GUEST_DOCUMENT',
	UPDATE_CURRENT_ADDITIONAL_GUEST_ADDRESS: 'UPDATE_CURRENT_ADDITIONAL_GUEST_ADDRESS',

	REMOVE_ADDITIONAL_GUEST_START: 'REMOVE_ADDITIONAL_GUEST_START',
	REMOVE_ADDITIONAL_GUEST_SUCCESS: 'REMOVE_ADDITIONAL_GUEST_SUCCESS',

	UPDATE_MAIN_GUEST_NEW_CREDIT_CARD: 'UPDATE_MAIN_GUEST_NEW_CREDIT_CARD',
}

export default GuestsActionTypes
