import ls from '../../../local-storage/local-storage'

import { getPropertyTimeZonedDate } from 'Src/utils'

import { selectReservation } from '../reservation.selectors'
import { updateReservation } from '../reservation.actions'

import TimesActionTypes from './times.types'
import { selectArrivalTimeData } from './times.selectors'
import {
	selectProducts,
	selectChosenUpgradeRoomCategoryId,
} from 'Redux/reservation/products/products.selectors'

import getTimesAvailability from 'Src/api/get-times-availability'

const getAvailableTimes = () => async (dispatch, getState) => {
	const {
		id: reservationId,
	} = selectReservation(getState())
	const products = selectProducts(getState())

	const propertyUpgradeCategoryId = selectChosenUpgradeRoomCategoryId(getState())
	const data = await getTimesAvailability(reservationId, propertyUpgradeCategoryId)
	const availableTimeslots = data.find((availability) => availability.isAvailable)
	const checkIn = availableTimeslots?.startDates || []
	const checkOut = availableTimeslots?.endDates || []

	// Filter out only super early check-in and super late check-out
	const superEarlyCheckIn = checkIn.filter((time) => time.isAvailable && time.productId)
		.map((time) => {
			const timeProduct = products.find((prod) => prod.productId === time.productId)
			return ({
				...time,
				price: timeProduct?.price,
				productKey: timeProduct?.productKey,
			})
		})
		.filter((time) => !!time.productKey)

	const superLateCheckOut = checkOut.filter((time) => time.isAvailable && time.productId)
		.map((time) => {
			const timeProduct = products.find((prod) => prod.productId === time.productId)
			return ({
				...time,
				price: timeProduct?.price,
				productKey: timeProduct?.productKey,
			})
		})
		.filter((time) => !!time.productKey)

	ls.setItem('availableCheckInCheckOutTimes', { checkIn, checkOut })
	dispatch({
		type: TimesActionTypes.GET_AVAILABLE_TIMES,
		payload: {
			superEarlyCheckIn,
			superLateCheckOut,
			checkIn,
			checkOut,
		},
	})
}

const updateSelectedArrivalTime = (selectedArrivalTimeUTC) => (dispatch) => {
	ls.setItem('selectedArrivalTimeUTC', selectedArrivalTimeUTC)
	dispatch({
		type: TimesActionTypes.UPDATE_SELECTED_ARRIVAL_TIME,
		payload: { selectedArrivalTimeUTC },
	})
}

const disableArrivalTimeField = () => (dispatch, getState) => {
	const { confirmedArrivalDateUTC } = selectArrivalTimeData(getState())
	const { property } = selectReservation(getState())

	const selectedArrivalTimeUTC = getPropertyTimeZonedDate(
		confirmedArrivalDateUTC,
		property.timezone,
		'HH:mm',
	)

	dispatch(updateReservation({ status: null }, true))
	dispatch(updateSelectedArrivalTime(selectedArrivalTimeUTC))
}

const updateSelectedDepartureTime = (selectedDepartureTimeUTC) => (dispatch) => {
	ls.setItem('selectedDepartureTimeUTC', selectedDepartureTimeUTC)
	dispatch({
		type: TimesActionTypes.UPDATE_SELECTED_DEPARTURE_TIME,
		payload: { selectedDepartureTimeUTC },
	})
}

export {
	getAvailableTimes,
	updateSelectedArrivalTime,
	disableArrivalTimeField,
	updateSelectedDepartureTime,
}
