import { isRangeBetween } from '@/utils/date-time'
import { OrderItemApi, ProductApi, Service } from 'Types/api.types'
import { getServicesActiveProducts } from 'Redux/reservation/products/products.utils'
import config from 'Globals/constants'

const { ACTIVE_PRODUCT_KEYS } = config
export const campaignTypes: Record<string, string> = {
	complimentaryBreakfast: 'complimentaryBreakfast',
}

export const enum PropertyUniqueKey {
	TallinnNar40 = 'tallinn-nar40',
	TartuLai29 = 'tartu-lai29',
}
export const propertyIds: Record<PropertyUniqueKey, string> = {
	[PropertyUniqueKey.TallinnNar40]: 'ed69ffb5-84ad-4a69-a886-af39006fa792',
	[PropertyUniqueKey.TartuLai29]: 'f3ac0603-5fa0-4d99-a478-af4700fad8f7',
}

export interface CampaignStayPeriodProps {
    stayStartDate: Date | null;
    stayEndDate: Date | null;
    validStayStartDate: string;
    validStayEndDate: string;
}

export function isCampaignStayPeriodValid(stayPeriodProps: CampaignStayPeriodProps): boolean {
	const { stayStartDate, stayEndDate, validStayStartDate, validStayEndDate } = stayPeriodProps

	if (stayStartDate === null || stayEndDate === null) {
		return false
	}

	const stayPeriod: [Date, Date] = [stayStartDate, stayEndDate]

	const validStayPeriod: [Date, Date] = [
		new Date(validStayStartDate),
		new Date(validStayEndDate),
	]

	return isRangeBetween(stayPeriod, validStayPeriod)
}

export interface OrderItemsProductCheckProps {
	orderItems: OrderItemApi[];
	services: Service[];
	productKey: string;
}

export function orderItemsHasCamaignProduct(checkProperties: OrderItemsProductCheckProps): boolean {
	const { orderItems, services, productKey } = checkProperties
	const activeProducts: ProductApi[] = getServicesActiveProducts(services)

	return orderItems?.some((item) => {
		const product = activeProducts.find((activeProduct) => activeProduct.id === item.productId)
		return product?.key === productKey
	}) ?? false
}

export interface CampaignAvailabilityData {
	stayStartDate: Date | null;
	stayEndDate: Date | null;
	orderItems: OrderItemApi[];
	services: Service[]
}

const campaigns = {
	[campaignTypes.complimentaryBreakfast]: [
		{
			propertyId: propertyIds[PropertyUniqueKey.TallinnNar40],
			validStayStartDate: '2022-12-20',
			validStayEndDate: '2023-03-31',
			isCampaignAvailable(availabilityData: CampaignAvailabilityData): boolean {
				const { stayStartDate, stayEndDate, orderItems, services } = availabilityData
				const isStayPeriodValid = isCampaignStayPeriodValid({
					stayStartDate,
					stayEndDate,
					validStayStartDate: this.validStayStartDate,
					validStayEndDate: this.validStayEndDate,
				})

				const hasProductOrdered = orderItemsHasCamaignProduct({
					orderItems,
					services,
					productKey: ACTIVE_PRODUCT_KEYS.breakfastComplimentary,
				})

				return isStayPeriodValid && hasProductOrdered
			},
		},
		{
			propertyId: propertyIds[PropertyUniqueKey.TartuLai29],
			validStayStartDate: '2022-02-09',
			validStayEndDate: '2023-03-31',
			isCampaignAvailable(availabilityData: CampaignAvailabilityData): boolean {
				const { stayStartDate, stayEndDate, orderItems, services } = availabilityData
				const isStayPeriodValid = isCampaignStayPeriodValid({
					stayStartDate,
					stayEndDate,
					validStayStartDate: this.validStayStartDate,
					validStayEndDate: this.validStayEndDate,
				})

				const hasProductOrdered = orderItemsHasCamaignProduct({
					orderItems,
					services,
					productKey: ACTIVE_PRODUCT_KEYS.breakfastComplimentary,
				})

				return isStayPeriodValid && hasProductOrdered
			},
		},
	],
}

export const getPropertyCampaign = (campaignType: string, propertyId: string) => {
	const campaign = campaigns[campaignType].find((campaign) => campaign.propertyId === propertyId)

	return campaign ?? undefined
}
