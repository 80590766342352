const CleaningMidStayActionTypes = {
	UPDATE_CURRENT_CLEANING_DATE: 'UPDATE_CURRENT_CLEANING_DATE',
	UPDATE_CURRENT_CLEANING_TIME: 'UPDATE_CURRENT_CLEANING_TIME',
	CURRENT_CLEANING_DATE_TIME_TO_ARRAY: 'CURRENT_CLEANING_DATE_TIME_TO_ARRAY',
	REMOVE_SELECTED_CLEANING_DATE_TIME: 'REMOVE_SELECTED_CLEANING_DATE_TIME',
	CLEAR_SELECTED_CLEANING_DATE_TIMES: 'CLEAR_SELECTED_CLEANING_DATE_TIMES',
	GET_AVAILABLE_CLEANING_TIMES_SUCCESS: 'GET_AVAILABLE_CLEANING_TIMES_SUCCESS',
}

export default CleaningMidStayActionTypes
