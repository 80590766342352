import styled, { css } from 'styled-components'

export const H1 = styled.h1`
  margin: 0; // h1 default replacement
  font-size: 28px;
  line-height: 40px;
  font-weight: 500;
`

export const H2 = styled.h2`
  margin: 0; // h2 default replacement
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
`

export const H3 = styled.h3`
  margin: 0; // h3 default replacement
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
`

export const Regular12 = css`
  font-size: 12px;
  line-height: 16px;
`

export const Regular14 = css`
  font-size: 14px;
  line-height: 20px;
`

export const Medium12 = css`
  ${Regular12}

  font-weight: 500;
`

export const Medium14 = css`
  ${Regular14}

  font-weight: 500;
`

export const Regular16 = css`
  font-size: 16px;
  line-height: 24px;
`

export const Medium16 = css`
  ${Regular16}

  font-weight: 500;
`
