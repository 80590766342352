import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import StartView from 'Views/check-in-views/start-view/start-view.component'
import MainGuestContactsView from 'Views/check-in-views/main-guest-contacts-view/main-guest-contacts-view.component'
import MainGuestIdentificationView from 'Views/check-in-views/main-guest-identification-view/main-guest-identification-view.component'
import GuestsView from 'Views/check-in-views/guests-view/guests-view.component'
import TimesView from 'Views/check-in-views/times-view/times-view.component'
import ProductsView from 'Views/check-in-views/products-view/products-view.component'
import RoomUpgradeView from 'Views/check-in-views/room-upgrade-view/room-upgrade-view.component'
import SummaryView from 'Views/check-in-views/summary-view/summary-view.component'
import SuccessView from 'Views/check-in-views/success-view/success-view.component'
import MainGuestDetailsView from 'Views/check-in-views/guests-view/main-guest-details-view.component'
import AdditionalGuestDetailsView from 'Views/check-in-views/guests-view/additional-guest-details-view.component'
import ParkingIndoorView from 'Views/check-in-views/products-view/parking-indoor-view.component'
import BreakfastView from 'Views/check-in-views/products-view/breakfast-view.component'
import SaunaView from 'Views/check-in-views/products-view/sauna-view.component'
import MealKitView from 'Views/check-in-views/products-view/mealkit-view.component'
import CleanKitchenMealKitView from 'Views/check-in-views/products-view/mealkit-cleankitchen-view.component'
import StockedFridgeView from 'Views/check-in-views/products-view/stocked-fridge-view.component'
import AddCardView from 'Views/check-in-views/summary-view/add-card-view.component'
import RoomUpgradeDetailsView from 'Views/check-in-views/room-upgrade-view/product-property-unit-category-upgrade-view.component'
import GuestAreaCleaningMidStayIntroView from 'Src/views/check-in-views/guest-area-view/cleaning-mid-stay-intro-view.component'
import GuestAreaCleaningMidStayYourScheduleView from 'Src/views/check-in-views/guest-area-view/cleaning-mid-stay-your-schedule-view.component'
import GuestAreaCleaningMidStaySelectTimeView from 'Views/check-in-views/guest-area-view/cleaning-mid-stay-select-time-view.component'
import GuestAreaCleaningMidStayConfirmView from 'Views/check-in-views/guest-area-view/cleaning-mid-stay-confirm-view.component'
import BusinessLeadIntroViewComponentView from 'Views/check-in-views/business-lead-view/business-lead-intro-view.component'
import BusinessLeadFormViewComponentView from 'Views/check-in-views/business-lead-view/business-lead-form-view.component'

import { useDoorPoller } from 'Hooks'
import Spinner from 'Components/spinner.component'
import { selectCurrentStep } from 'Redux/app/app.selectors'
import { getReservation, refetchReservation } from 'Redux/reservation/reservation.actions'
import { ConstantsContext } from 'Src/context'
import config from 'Globals/constants'
import { checkProductsForAvailability } from 'Redux/reservation/products/products.actions'

const { STEPS, POLL_RESERVATIONS_INTERVAL } = config

const { CHECK_IN } = STEPS
const STEPS_TO_VIEW_MAP = {
	[CHECK_IN.MAIN['start']]: StartView,
	[CHECK_IN.MAIN['main-guest-contacts']]: MainGuestContactsView,
	[CHECK_IN.MAIN['business-lead']]: BusinessLeadIntroViewComponentView,
	[CHECK_IN.SUB['business-lead-form']]: BusinessLeadFormViewComponentView,
	[CHECK_IN.MAIN['main-guest-identification']]: MainGuestIdentificationView,
	[CHECK_IN.MAIN['guests']]: GuestsView,
	[CHECK_IN.SUB['main-guest-details']]: MainGuestDetailsView,
	[CHECK_IN.SUB['additional-guest-details']]: AdditionalGuestDetailsView,
	[CHECK_IN.MAIN['times']]: TimesView,
	[CHECK_IN.MAIN['products']]: ProductsView,
	[CHECK_IN.SUB['product-parking-indoor']]: ParkingIndoorView,
	[CHECK_IN.SUB['product-breakfast']]: BreakfastView,
	[CHECK_IN.SUB['product-sauna-kit']]: SaunaView,
	[CHECK_IN.SUB['product-mealkit-platform-pro']]: MealKitView,
	[CHECK_IN.SUB['product-meal-kit-clean-kitchen-vegan-dan-dan-noodles-2']]: CleanKitchenMealKitView,
	[CHECK_IN.SUB['product-stocked-fridge']]: StockedFridgeView,
	[CHECK_IN.MAIN['room-upgrade']]: RoomUpgradeView,
	[CHECK_IN.SUB['product-property-unit-category-upgrade']]: RoomUpgradeDetailsView,
	[CHECK_IN.SUB['product-property-unit-category-upgrade-free']]: RoomUpgradeDetailsView,
	[CHECK_IN.MAIN['summary']]: SummaryView,
	[CHECK_IN.SUB['add-card']]: AddCardView,
	[CHECK_IN.COMPLETED['success']]: SuccessView,
	[CHECK_IN.SUB['guest-area-cleaning-mid-stay-intro']]: GuestAreaCleaningMidStayIntroView,
	[CHECK_IN.SUB['guest-area-cleaning-mid-stay-your-schedule']]: GuestAreaCleaningMidStayYourScheduleView,
	[CHECK_IN.SUB['guest-area-cleaning-mid-stay-select-time']]: GuestAreaCleaningMidStaySelectTimeView,
	[CHECK_IN.SUB['guest-area-cleaning-mid-stay-confirm']]: GuestAreaCleaningMidStayConfirmView,
}

const CheckIn = ({
	flow,
	reservationSlug,
	getReservation,
	refetchReservation,
	currentStep,
	updateProductsWithAvailability,
}) => {
	const { reloadConfig } = useContext(ConstantsContext)

	useEffect(() => {
		const load = async () => {
			const reservation = await getReservation(flow, reservationSlug)
			reloadConfig()
			if (reservation) await updateProductsWithAvailability()
		}
		load()
	}, [])

	useDoorPoller(() => {
		if (currentStep?.indexOf('guest-area') === 0) {
			refetchReservation(flow, reservationSlug)
		}
	}, POLL_RESERVATIONS_INTERVAL)

	if (!currentStep) return <Spinner />

	const CurrentView = STEPS_TO_VIEW_MAP[currentStep]

	return <CurrentView />
}

const mapStateToProps = createStructuredSelector({
	currentStep: selectCurrentStep,
})

const mapDispatchToProps = (dispatch) => ({
	getReservation: (flow, reservationId) => dispatch(getReservation(flow, reservationId)),
	updateProductsWithAvailability: () => dispatch(checkProductsForAvailability()),
	refetchReservation: (flow, reservationId) => dispatch(refetchReservation(flow, reservationId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn)
