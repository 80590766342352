import React, {
	useState, useRef, useEffect, useContext,
} from 'react'
import styled, { css } from 'styled-components'
import { components } from 'react-select'
import COUNTRIES from 'country-calling-code'

import 'react-phone-number-input/style.css'

import Select from './select.component'
import FieldLabel from './field-label.component'

import IconArrowDown from '../../assets/icons/icon-arrow-down.svg'
import IconSearch from '../../assets/icons/icon-search.svg'

import { COLORS } from '../../globals/global.styles'

import { TranslationContext } from 'Src/context'

const { Option } = components

const Label = styled(FieldLabel)`
	display: inline-block;
	margin-bottom: 2px;
`

const Wrapper = styled.div``

const AlertModeStyles = css`
	border-color: ${COLORS.border.alert};
`

const FocusedStyles = css`
	border-color: ${COLORS.border.highlight};
`

const InputsContainer = styled.div`
	display: flex;
	align-items: center;

	height: 40px;
	border: 1px solid ${COLORS.border.secondary};

	${({ isFocused }) => isFocused && FocusedStyles}
	${({ alertModeOn }) => alertModeOn && AlertModeStyles}
`

const FieldStyles = css`
	display: inline-block;
	height: 37px;
	padding: 14px 16px 10px 16px;
	border: none;
	border-radius: 0;

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: ${COLORS.text.minor};
	}
`

const CallingCode = styled.div`
	${FieldStyles}

	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 45%;
	padding-right: 14px;
	border-right: 1px solid ${COLORS.border.primary};
	cursor: pointer;

	${({ isFocused }) => isFocused && FocusedStyles}
	${({ alertModeOn }) => alertModeOn && AlertModeStyles}
	${({ hasValue }) => !hasValue && `
		color: ${COLORS.text.minor};
	`}
`

const ArrowContainer = styled.div`
	width: 27px;
	height: 20px;
	border-left: 1px solid ${COLORS.border.minor};
	display: flex;
	justify-content: flex-end;
	align-items: center;
`

const ArrowDownIcon = styled.img`
	width: 12px;
`

const NationalNumberInput = styled.input`
	${FieldStyles}

	width: 55%;
`

const SearchIconWrapper = styled.div`
	display: flex;
	padding: 0 16px;
`

const phoneContainsCallingCode = (phone, callingCode) => {
	if (!phone || !callingCode) return null
	return phone.indexOf(`+${callingCode}`) === 0
}

const parseNationalNumber = (initialPhone, callingCode) => {
	if (!initialPhone || !callingCode) return initialPhone

	let phone = initialPhone.replace(/\s/g, '')

	if (phone.indexOf(`+${callingCode}`) === 0) {
		phone = phone.replace(`+${callingCode}`, '')
	}

	return phone
}

const combineOptionData = ({ country, countryCodes, isoCode2 }) => ({
	value: isoCode2,
	countryName: country,
	countryCode: isoCode2,
	callingCode: countryCodes[0],
})

const OPTION_DATA_TO_SEARCH_IN = ['countryName', 'countryCode', 'callingCode']

const filterOptions = ({ data: optionData }, inputValue) => (
	!!OPTION_DATA_TO_SEARCH_IN.find((key) => {
		if (!inputValue) return true
		return !!optionData[key].toLowerCase().includes(inputValue.toLowerCase())
	}))

const OptionContent = styled.div`
	span:last-child {
		float: right;
	}

	${({ isSelected }) => isSelected && `
		span {
			color: ${COLORS.text.white};
		}
	`}
`

const CustomOption = (props) => {
	const {
		data: {
			countryName,
			countryCode,
			callingCode,
		},
		isSelected,
	} = props

	return (
		<Option {...props}>
			<OptionContent isSelected={isSelected}>
				<span>{`${countryName} (${countryCode})`}</span>
				<span>{`+${callingCode}`}</span>
			</OptionContent>
		</Option>
	)
}

const PhoneInput = ({
	value,
	label,
	className,
	handleChange,
	isValid,
}) => {
	const translation = useContext(TranslationContext)
	const [isFocused, setIsFocused] = useState(false)
	const alertModeOn = isValid === false

	const {
		phone,
		phoneCountryCallingCode,
		phoneCountryCode: currentCountryCode,
	} = value || {}
	const currentCallingCode = phoneCountryCallingCode !== -1 ? phoneCountryCallingCode : null
	const currentNationalNumber = phone ? parseNationalNumber(phone, currentCallingCode) : ''

	const [nationalNumber, setNationalNumber] = useState(currentNationalNumber)
	const [callingCode, setCallingCode] = useState(currentCallingCode?.toString())
	const [countryCode, setCountryCode] = useState(currentCountryCode)

	const callingCodeLabel = callingCode && countryCode
		? `${countryCode} +${callingCode}`
		: translation.t('Country')

	const countryCodeOptions = COUNTRIES.map(combineOptionData)

	const [selectIsVisible, setSelectIsVisible] = useState(false)

	const selectRef = useRef()

	let nationalNumberInputRef

	useEffect(() => {
		if (selectIsVisible) {
			selectRef.current.focus()
		}
	}, [selectIsVisible])

	const [isFirstRun, setIsFirstRun] = useState(true)
	useEffect(() => {
		if (isFirstRun) {
			setIsFirstRun(false)
			return
		}

		let phone

		if (!nationalNumber) {
			phone = null
		} else if (!phoneContainsCallingCode(nationalNumber, callingCode)) {
			phone = `+${callingCode}${nationalNumber}`
		} else {
			phone = nationalNumber
		}

		handleChange({
			phone,
			phoneCountryCallingCode: callingCode,
			phoneCountryCode: countryCode,
		})
	}, [nationalNumber, callingCode, countryCode])

	return (
		<Wrapper
			title={translation.t('Phone field')}
			className={className}
			onFocus={() => setIsFocused(true)}
			onBlur={() => setIsFocused(false)}
		>
			{label ? (
				<Label alertModeOn={alertModeOn} isFocused={isFocused}>
					{label}
				</Label>
			) : null}

			<InputsContainer
				selectIsVisible={selectIsVisible}
				isFocused={isFocused}
				alertModeOn={alertModeOn}
			>
				<CallingCode
					title={translation.t('Calling code')}
					tabIndex={0}
					isFocused={isFocused}
					alertModeOn={alertModeOn}
					onClick={() => setSelectIsVisible(!selectIsVisible)}
					hasValue={!!callingCode && !!countryCode}
				>
					{callingCodeLabel}
					<ArrowContainer>
						<ArrowDownIcon src={IconArrowDown} alt={translation.t('Select calling code')} />
					</ArrowContainer>
				</CallingCode>
				<NationalNumberInput
					title={translation.t('Phone number')}
					value={nationalNumber}
					onChange={({ target: { value } }) => setNationalNumber(value)}
					ref={(ref) => nationalNumberInputRef = ref}
					placeholder={translation.t('Phone number')}
				/>
			</InputsContainer>

			{!!selectIsVisible && (
				<Select
					inputId="calling-code-select"
					placeholder={translation.t('Select calling code')}
					menuIsOpen
					selectRef={selectRef}
					options={countryCodeOptions}
					filterOption={filterOptions}
					value={countryCode && { value: countryCode }}
					onBlur={() => setSelectIsVisible(false)}
					onChange={({ countryCode, callingCode }) => {
						setCountryCode(countryCode)
						setCallingCode(callingCode)
						setSelectIsVisible(false)
						nationalNumberInputRef.focus()
					}}
					components={{
						IndicatorsContainer: () => (
							<SearchIconWrapper>
								<img src={IconSearch} alt={translation.t('Select calling code')} />
							</SearchIconWrapper>
						),
						Option: (props) => <CustomOption {...props} />,
					}}
					reactSelectStyles={{
						control: (provided) => ({
							...provided,
							borderRadius: 0,
							borderColor: COLORS.border.highlight,
							borderTop: 0,
							boxShadow: 'none',
							'&:hover': null,
						}),
					}}
				/>
			)}
		</Wrapper>
	)
}

export default PhoneInput
