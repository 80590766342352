import React, { useContext } from 'react'
import { TranslationContext } from '@/context'
import styled from 'styled-components'

const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
  padding: 16px;
  border: 1px solid #dcdcdc;
  border-radius: 16px;
`

const InfoTitle = styled.div`
	font-weight: 500;
	font-size: 20px;
`

export const CityTaxInfo = ({ city = '', isCityTaxRequired = false }) => {
	const translation = useContext(TranslationContext)

	if (isCityTaxRequired) {
		return (
			<InfoContainer>
				<InfoTitle>
					{translation.t('City tax')}
				</InfoTitle>
				<div>
					{translation.t('The city of {city} requires us to collect city tax, which we\'ll calculate following local regulations and charge before your arrival. If you have any questions, please don\'t hesitate to reach out.', { city })}
				</div>
			</InfoContainer>
		)
	}

	return null
}
