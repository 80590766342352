import styled from 'styled-components'

export const Spinner = styled.div`
	width: 50px;
	height: 50px;
	border: 3px solid rgb(220, 220, 220);
	border-radius: 50%;
	border-top-color: rgb(140, 127, 78);
	animation: spin 1s ease-in-out infinite;

	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}
`
