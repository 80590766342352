import React, { useCallback, useContext, useState } from 'react'
import styled from 'styled-components'
import SignaturePad from 'react-signature-canvas'

import { TranslationContext } from 'Src/context'

import FieldLabel from 'Components/ui/field-label.component'
import { ContentMainHeading } from './view-structure.component'
import { Button } from './ui'

import { COLORS } from 'Globals/global.styles'

import SignatureIcon from 'Assets/icons/signature.svg'

const TextContent = styled.div`
	color: ${COLORS.text.secondary};
	font-size: 14px;
`

const SignatureContainer = styled.div`
	position: relative;
	height: 190px;
	width: 368px;
	${({ alertModeOn }) => `
		border: 1px solid ${alertModeOn ? COLORS.border.alert : COLORS.border.secondary};
	`}
`

const SignaturePlaceholderImg = styled.img`
	position: absolute;
	top: 65px;
	left: 70px;
	pointer-events: none;
`

const ButtonStyled = styled(Button)`
	margin-top: 10px;
`

const Label = styled(FieldLabel)`
	display: inline-block;
	margin-bottom: 3px;
`

const GuestSignatureField = ({ updateField, formErrors }) => {
	const [showPlaceholder, setShowPlaceholder] = useState(true)
	const translation = useContext(TranslationContext)
	let sigPad = {}

	const handleClearClick = useCallback(() => {
		sigPad?.clear()

		updateField('signature', undefined)

		setShowPlaceholder(true)
	}, [sigPad])

	const getSignatureImage = () => {
		if (!sigPad.isEmpty()) {
			const signature = sigPad.getTrimmedCanvas().toDataURL('image/png')

			updateField('signature', signature)
		}
	}

	return (
		<>
			<ContentMainHeading>
				{translation.t('Your Signature')}
			</ContentMainHeading>
			<TextContent>
				{translation.t(`We are using your signature to provide you with fast
				 and contactless check-in. More digital, less paper!`)}
			</TextContent>
			<br />
			<Label alertModeOn={formErrors?.includes('signature')}>
				{translation.t('Draw your signature with your finger or cursor*')}
			</Label>
			<SignatureContainer alertModeOn={formErrors?.includes('signature')}>
				<SignaturePad
					ref={(ref) => { sigPad = ref }}
					onBegin={() => setShowPlaceholder(false)}
					onEnd={() => getSignatureImage()}
					canvasProps={{ width: 368, height: 190 }}
				/>
				{showPlaceholder && (
					<SignaturePlaceholderImg src={SignatureIcon} alt="bobw signature" />
				)}
			</SignatureContainer>
			<ButtonStyled variant="transparent_with_border" onClick={handleClearClick}>
				{translation.t('Clear Signature')}
			</ButtonStyled>
		</>
	)
}

export default GuestSignatureField
