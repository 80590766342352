import styled, { css } from 'styled-components'
import { COLORS } from 'Globals/global.styles'

interface StyledCheckboxProps {
	readonly isChecked: boolean;
	readonly hasError?: boolean;
}

export const CheckboxWrapper = styled.div`
	display: grid;
	grid-template-columns: 24px 1fr;
	align-items: center;
	column-gap: 12px;
`

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #8C99A6;
	cursor: pointer;
	border-radius: 8px;
	padding: 4px;
	transition: background-color 0.15s ease-out;
	color: #fff;

	${({ isChecked }) => isChecked && css`
		background-color: #16191D;
		border-color: #16191D;
	`}

	${({ hasError, isChecked }) => hasError && !isChecked && css`
		border-color: ${COLORS.border.alert};
	`}

	input {
		display: none;
	}
`

export const CheckboxLabel = styled.label<StyledCheckboxProps>`
	color: #000;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	cursor: pointer;
	transition: color 0.15s ease-out;

	${({ hasError, isChecked }) => hasError && !isChecked && css`
		color: ${COLORS.border.alert};
	`}
`
