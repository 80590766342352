import React from 'react'
import styled, { css } from 'styled-components'
import { QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import { COLORS } from '../../globals/global.styles'

const IconStyle = css`
	position: absolute;
	top: 2px;

	svg,
	svg path {
		color: ${COLORS.text.secondary};
		font-size: 14px;
	}
`

const QuestionMarkIconStyled = styled(QuestionCircleOutlined)`
	${IconStyle}
`

const ExclamationMarkIconStyled = styled(ExclamationCircleOutlined)`
	${IconStyle}
`

const HelperTextStyled = styled.div`
	position: relative;
	margin-top: 2px;
	opacity: 0.86;

	${({ isFocused }) => isFocused
		&& `
			& {
				div,
				svg,
				svg path {
					color: ${COLORS.text.highlight};
				}
			}
		`}

	${({ alertModeOn }) => alertModeOn
		&& `
			& {
				div,
				svg,
				svg path {
					color: ${COLORS.text.alert};
				}
			}
		`}
`

const Text = styled.div`
	margin: 0 0 0 18px;
	padding-top: 2px;
	font-size: 12px;
	color: ${COLORS.text.secondary};
`

const HelperText = styled(({
	className,
	text,
	isFocused,
	alertModeOn,
	exclamationMark,
}) => (
	<HelperTextStyled
		isFocused={isFocused}
		alertModeOn={alertModeOn}
		className={className}
	>
		{exclamationMark ? <ExclamationMarkIconStyled /> : <QuestionMarkIconStyled />}
		<Text>{text}</Text>
	</HelperTextStyled>
))``

export default HelperText
