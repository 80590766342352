export function makeBookingLanguageUrlParam(language: string) : string {
	switch (language) {
		case 'en-GB': {
			return 'lng=en-GB'
		}

		case 'es-ES': {
			return 'lng=es-ES'
		}

		case 'et-EE': {
			return 'lng=et'
		}

		case 'fi-FI': {
			return 'lng=fi'
		}

		default: {
			return 'lng=en-GB'
		}
	}
}
