import { EmailErrorCode } from './error.actions'

export function transformEmailErrorCode(code: string) : EmailErrorCode | null {
	switch (code) {
		case 'EMAIL_VERIFICATION_REQUIRED': {
			return EmailErrorCode.EMAIL_VERIFICATION_REQUIRED
		}

		case 'TOO_MANY_VERIFICATION_ATTEMPTS': {
			return EmailErrorCode.TOO_MANY_VERIFICATION_ATTEMPTS
		}

		case 'WRONG_VERIFICATION_CODE': {
			return EmailErrorCode.WRONG_VERIFICATION_CODE
		}

		case 'VERIFICATION_CODE_EXPIRED': {
			return EmailErrorCode.VERIFICATION_CODE_EXPIRED
		}

		default: {
			return null
		}
	}
}
