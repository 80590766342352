import styled from 'styled-components'

import { COLORS } from '../../globals/global.styles'

const FieldLabel = styled.label`
	color: ${COLORS.text.label};
	font-size: 14px;

	${({ isFocused }) => isFocused
		&& `
			color: ${COLORS.text.highlight};
		`}

	${({ alertModeOn }) => alertModeOn
		&& `
			color: ${COLORS.text.alert};
		`}
`

export default FieldLabel
