import { TransitionStatus } from 'react-transition-group'
import styled from 'styled-components'
import { lighten } from 'polished'

interface OverlayProps {
  readonly state: TransitionStatus;
}

interface DialogProps {
  readonly state: TransitionStatus;
}

export const Overlay = styled.div<OverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease-out;
  opacity: ${({ state }) => (state === 'entering' || state === 'entered' ? 1 : 0)};
`

export const Dialog = styled.div<DialogProps>`
  position: relative;
  margin: auto;
  top: 50%;
  max-width: 368px;
  background-color: white;
  padding: 24px;
  border-radius: 32px;
  transition: transform 0.2s ease-out;
  transform: ${({ state }) => (state === 'entering' || state === 'entered' ? 'translateY(-50%) scale(1);' : 'translateY(-50%) scale(0.5)')};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  align-items: center;
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`

export const SubTitle = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #4E4E4E;
  opacity: 0.88;
`

export const CodeSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`

export const SentMessage = styled(SubTitle)`
  text-align: center;
`

export const Email = styled.span`
  font-weight: 500;
`

export const ErrorMessage = styled.div`
  color: #D42F2F;
  font-size: 14px;
  line-height: 20px;
  height: 20px;
`

export const CodeInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
`

export const SpinnerContainer = styled.div`
  height: 64px;
`

export const TextAction = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #8C7F4E;
  cursor: pointer;
  transition: color 0.15s ease-out;

  &:hover {
    color: ${lighten(0.1, '#8C7F4E')};
  }
`

export const SuccessMessage = styled(SubTitle)`
  color: #29A84C;
`

export const ResendItem = styled.div`
  line-height: 20px;
`
