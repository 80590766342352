import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
	ContentHeader,
	FooterSection,
	HeaderSticky,
	InvalidFieldsAlertSection,
	Section,
} from 'Components/view-structure.component'
import IdentificationFields from '../../../components/guest-identification-fields.component'
import ResidenceFields from '../../../components/guest-residence-fields.component'
import AdditionalDetailsFields from '../../../components/guest-additional-details-fields.component'
import GuestSignatureFields from '../../../components/guest-signature-field.component'

import { selectAppState, selectIsSubmitting } from 'Redux/app/app.selectors'
import { updateCurrentStepField } from 'Redux/app/app.actions'
import { goToPrevStep, goToStep } from 'Redux/app/go-to-step.actions'
import { selectReservation } from 'Redux/reservation/reservation.selectors'
import {
	selectAdditionalDocumentFieldsRequired,
	selectAddressRequired,
	selectBirthPlaceAndDateRequired, selectFireSafetyRequired,
	selectMainGuest,
	selectMainGuestAddress,
	selectMainGuestCustomer,
	selectMainGuestDocument,
	selectMainGuestDocumentDetailsRequired,
	selectMainGuestGenderRequired,
	selectMainGuestSignatureRequired,
	selectMainGuestVisitedCountryRequired,
	selectNextDestinationRequired,
} from 'Redux/reservation/guests/guests.selectors'

import { TranslationContext } from 'Src/context'
import { getDocumentTypes } from 'Src/utils'
import PageView from 'Src/components/PageView'
import RulesAndAgreements from 'Src/components/RulesAndAgreements'
import BirthlpaceAndDate from 'Components/guest-birthplace-date.component'

const IdentificationView = ({
	appState,
	isSubmitting,
	reservation,
	guest,
	customer,
	document,
	documentDetailsRequired,
	additionalDocumentFieldsRequired,
	nextDestinationRequired,
	birthPlaceAndDateRequired,
	isAddressRequired,
	isFireSafetyConsentRequired,
	signatureRequired,
	visitedCountryRequired,
	genderRequired,
	mainGuestAddress,
	updateField,
	goToNextStep,
	goToPrevStep,
}) => {
	const translation = useContext(TranslationContext)
	const {
		progressData,
		currentStepFormErrors: formErrors,
	} = appState || {}

	const {
		property: {
			phone: propertyPhone,
			country: propertyCountry,
			countryCode: propertyCountryCode,
		},
	} = reservation ?? {}

	const { visitedCountryCode, destinationCountryCode, destinationCity } = guest || {}

	const {
		firstName,
		lastName,
		birthDate,
		birthCountryCode,
		birthProvince,
		nationalityCode,
		gender,
		isMarketingConsentGiven,
		isFireSafetyConsentGiven,
		options,
		email,
	} = customer ?? {}

	const {
		type: documentType,
		number: documentNumber,
		issueCountry: documentIssueCountry,
		issueProvince: documentIssueProvince,
	} = document ?? {}

	const {
		addressLine1,
		city,
		countryCode: guestCountryCode,
		languageCode,
		countrySubdivisionCode,
	} = mainGuestAddress || {}

	const documentTypes = useMemo(
		() => getDocumentTypes(propertyCountryCode, nationalityCode, translation),
		[propertyCountryCode, nationalityCode, translation],
	)

	const [initialMarketingConsent] = useState(isMarketingConsentGiven)
	const [goingToNextStep, setGoingToNextStep] = useState(false)
	const trackNewsletterSignup = () => {
		const shouldTrack = isMarketingConsentGiven && initialMarketingConsent !== isMarketingConsentGiven
		&& goingToNextStep

		if (shouldTrack) {
			const dataLayerData = {
				event: 'newsletterSignup',
				email,
			}
			window.dataLayer?.push(dataLayerData)
		}
	}

	const handleGoingToPrev = () => {
		setGoingToNextStep(false)
		goToPrevStep()
	}

	useEffect(() => {
		if (documentTypes.length > 0 && (documentType === undefined || documentType === '')) {
			updateField('documentType', documentTypes[0].value)
		}
	}, [])

	useEffect(() => {
		return () => {
			trackNewsletterSignup()
		}
	}, [])

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault()
				setGoingToNextStep(true)
				goToNextStep()
			}}
		>
			<PageView
				Header={() => (
					<HeaderSticky
						{...progressData}
						onBackButtonClick={goToPrevStep}
						contactButton={{ url: `tel:${propertyPhone}` }}
					/>
				)}
			>
				<ContentHeader
					mainText={translation.t('Identification')}
					subText={translation.t('Enter the details of the main guest')}
				/>

				{!!formErrors?.length && <InvalidFieldsAlertSection />}

				<Section>
					<IdentificationFields
						{...{
							heading: translation.t('Details of main guest'),
							firstName,
							lastName,
							birthDate,
							nationalityCode,
							propertyCountryCode,
							birthPlaceAndDateRequired,
							gender,
							genderRequired,
							updateField,
							formErrors,
						}}
					/>
				</Section>

				{birthPlaceAndDateRequired && (
					<Section>
						<BirthlpaceAndDate
							{...{
								heading: translation.t('Birthplace and date'),
								birthDate,
								birthCountryCode,
								birthProvince,
								updateField,
								formErrors,
							}}
						/>
					</Section>
				)}

				<Section>
					<ResidenceFields
						{...{
							addressLine1,
							city,
							countryCode: guestCountryCode,
							formErrors,
							isAddressRequired,
							isProvinceRequired: true,
							countrySubdivisionCode,
							updateField,
							languageCode,
						}}
					/>
				</Section>
				{(visitedCountryRequired || documentDetailsRequired || nextDestinationRequired) && (
					<Section>
						<AdditionalDetailsFields
							{...{
								documentType,
								documentNumber,
								documentIssueCountry,
								documentIssueProvince,
								documentDetailsRequired,
								additionalDocumentFieldsRequired,
								visitedCountryCode,
								visitedCountryRequired,
								nextDestinationRequired,
								destinationCountryCode,
								destinationCity,
								documentTypes,
								propertyCountry,
								updateField,
								formErrors,
							}}
						/>
					</Section>
				)}

				{ signatureRequired && (
					<Section>
						<GuestSignatureFields
							updateField={updateField}
							formErrors={formErrors}
						/>
					</Section>
				) }

				<Section>
					<RulesAndAgreements
						updateField={updateField}
						isMarketingConsentGiven={isMarketingConsentGiven}
						isFireSafetyConsentGiven={isFireSafetyConsentGiven}
						isFireSafetyConsentRequired={isFireSafetyConsentRequired}
						customerOptions={options}
						formErrors={formErrors}
					/>
				</Section>

				<FooterSection
					onPrevClick={handleGoingToPrev}
					disableNextButton={isSubmitting}
				/>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	appState: selectAppState,
	reservation: selectReservation,
	guest: selectMainGuest,
	customer: selectMainGuestCustomer,
	documentDetailsRequired: selectMainGuestDocumentDetailsRequired,
	additionalDocumentFieldsRequired: selectAdditionalDocumentFieldsRequired,
	birthPlaceAndDateRequired: selectBirthPlaceAndDateRequired,
	isAddressRequired: selectAddressRequired,
	isFireSafetyConsentRequired: selectFireSafetyRequired,
	visitedCountryRequired: selectMainGuestVisitedCountryRequired,
	nextDestinationRequired: selectNextDestinationRequired,
	genderRequired: selectMainGuestGenderRequired,
	signatureRequired: selectMainGuestSignatureRequired,
	mainGuestAddress: selectMainGuestAddress,
	document: selectMainGuestDocument,
	isSubmitting: selectIsSubmitting,
})

const mapDispatchToProps = (dispatch) => ({
	updateField: (fieldName, data) => dispatch(updateCurrentStepField(fieldName, data)),
	goToNextStep: () => dispatch(goToStep()),
	goToPrevStep: () => dispatch(goToPrevStep()),
})

export default connect(mapStateToProps, mapDispatchToProps)(IdentificationView)
