import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { format } from 'date-fns-tz'
import FieldLabel from './field-label.component'
import NumberInputReset from './number-input-reset.component'
import { TranslationContext } from 'Src/context'
import { COLORS } from '../../globals/global.styles'

const AlertModeStyles = css`
	border: 1px solid ${COLORS.border.alert};
`

const FocusedStyles = css`
	border: 1px solid ${COLORS.border.highlight};
`

const InputsContainer = styled.div<{ alertModeOn: boolean, isFocused: boolean }>`
	height: 40px;
	padding: 10px 0;
	border: 1px solid ${COLORS.border.secondary};

	${({ isFocused }) => isFocused && FocusedStyles}
	${({ alertModeOn }) => alertModeOn && AlertModeStyles}
`

const FieldStyles = css`
	display: inline-block;
	height: 24px;
	padding: 0 16px;
	border: none;
	border-radius: 0;

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: ${COLORS.text.minor};
	}
`

const DayInput = styled.input`
	${NumberInputReset}
	${FieldStyles}

	width: 25%;
	border-right: 1px solid ${COLORS.border.minor};
`

const MonthSelect = styled.select<{ valueSelected: boolean }>`
	${FieldStyles};

	appearance: none;
	color: ${({ valueSelected }) => (valueSelected ? COLORS.text.primary : COLORS.text.minor)};

	width: 45%;
	border-right: 1px solid ${COLORS.border.minor};
	background-color: ${COLORS.background.primary};

	&:focus {
		color: ${COLORS.text.highlight};
	}
`

const YearInput = styled.input`
	${NumberInputReset}
	${FieldStyles}

	width: 30%;
`

const Label = styled(FieldLabel)<{ alertModeOn: boolean, isFocused: boolean }>`
	display: inline-block;
	margin-bottom: 2px;
`

const getDaysInMonth = (month: string, year: string) => ((!month || !year)
	? 31
	: new Date(parseInt(year), parseInt(month, 10), 0).getDate())

interface Props {
	value: string;
	label: string;
	isValid: boolean;
	className?: string;
	handleChange: (value: string) => void;
}

const Datepicker: FC<Props> = ({
	value: date,
	handleChange,
	label,
	isValid,
	className,
}) => {
	const yearInputRef = useRef<HTMLInputElement>(null)

	const translation = useContext(TranslationContext)
	const [isFocused, setIsFocused] = useState(false)
	const alertModeOn = isValid === false
	const utcDate = date && new Date(date)
	const [day, setDay] = useState(() => (utcDate ? format(utcDate, 'd') : ''))
	const [month, setMonth] = useState(() => (utcDate ? format(utcDate, 'M') : '0'))
	const [year, setYear] = useState(() => (utcDate ? format(utcDate, 'y') : ''))
	const daysInMonth = getDaysInMonth(month, year)
	const [isFirstRun, setIsFirstRun] = useState(true)

	const months = [
		{ value: 1, label: translation.t('January') },
		{ value: 2, label: translation.t('February') },
		{ value: 3, label: translation.t('March') },
		{ value: 4, label: translation.t('April') },
		{ value: 5, label: translation.t('May') },
		{ value: 6, label: translation.t('June') },
		{ value: 7, label: translation.t('July') },
		{ value: 8, label: translation.t('August') },
		{ value: 9, label: translation.t('September') },
		{ value: 10, label: translation.t('October') },
		{ value: 11, label: translation.t('November') },
		{ value: 12, label: translation.t('December') },
	]

	useEffect(() => {
		if (isFirstRun) {
			setIsFirstRun(false)
			return
		}

		if (year?.length === 4 && month && month !== '0' && day) {
			const newDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
			const isoDate = format(newDate, 'yyyy-MM-dd')
			handleChange(isoDate)
		} else {
			handleChange('')
		}
	}, [year, month, day])

	return (
		<div
			className={className}
			onFocus={() => setIsFocused(true)}
			onBlur={() => setIsFocused(false)}
		>
			{label ? (
				<Label isFocused={isFocused} alertModeOn={alertModeOn}>
					{label}
				</Label>
			) : null}

			<InputsContainer isFocused={isFocused} alertModeOn={alertModeOn}>

				<DayInput
					title={translation.t('Enter the day')}
					placeholder={translation.t('Day')}
					type="number"
					value={day}
					onChange={({ target: { value } }) => {
						if (parseInt(value, 10) > daysInMonth) return
						setDay(value || '')
					}}
				/>

				<MonthSelect
					title={translation.t('Select the month')}
					value={month}
					valueSelected={!!month}
					onChange={({ target: { value } }) => {
						setMonth(value)
						yearInputRef?.current?.focus()
					}}
				>
					{(() => {
						const monthOptions = [
							<option key={0} value={0} disabled>Month</option>,
						]

						months.forEach(({ value, label }) => monthOptions.push(
							<option key={value} value={value}>{label}</option>,
						))

						return monthOptions
					})()}
				</MonthSelect>

				<YearInput
					title={translation.t('Enter the year')}
					ref={yearInputRef}
					placeholder={translation.t('Year')}
					type="number"
					value={year}
					onChange={({ target: { value } }) => {
						if (value.length > 4) return
						setYear(value || '')
					}}
				/>

			</InputsContainer>
		</div>
	)
}

export default Datepicker
