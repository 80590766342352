export const enum DoorCodeError {
  NOT_AVAILABLE_YET,
  NOT_PAID
}

interface InvalidDoorCode {
	readonly code: null;
	readonly errorCode: DoorCodeError;
	readonly availableAt?: string;
}

interface ValidDoorCode {
	readonly code: string;
	readonly validToDate: string;
}

export type DoorCodeModel = InvalidDoorCode | ValidDoorCode;
