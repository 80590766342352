import { createReducer } from '@reduxjs/toolkit'
import { clearCustomerVerificationCode, updateCustomerVerificationCode } from './customer.actions'

export interface CustomerState {
	$emailVerificationCode: string;
}

const defaultState : CustomerState = {
	$emailVerificationCode: '',
}

const customerReducer = createReducer(defaultState, (builder) => {
	builder.addCase(updateCustomerVerificationCode, (state, action) => {
		state.$emailVerificationCode = action.payload
	})

	builder.addCase(clearCustomerVerificationCode, (state) => {
		state.$emailVerificationCode = ''
	})
})

export default customerReducer
