import { createSelector } from 'reselect'
import { addDays, isAfter } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { isImmediatelyPurchasablePaidProduct, isTimeProduct } from './products.utils'
import { selectArrivalTimeData } from '../times/times.selectors'
import { selectCleaningMidStayState } from '../cleaning-mid-stay/cleaning-mid-stay.selectors'

import { selectReservation, selectReservationState } from '../reservation.selectors'

import config, { getConfig } from 'Globals/constants'

const {
	ACTIVE_PRODUCT_KEYS,
	DISPLAYABLE_PRODUCTS_DETAILS,
} = config

const getOrderedProductByKey = (orderedProducts, productKey) => orderedProducts
	.find((orderedProduct) => orderedProduct.productKey === productKey)

const selectAppState = (state) => state.appState

const selectProductsState = createSelector(
	[selectReservationState],
	(reservationState) => reservationState.productsState || {},
)

const selectProducts = createSelector(
	[selectProductsState],
	(productsState) => productsState.products || [],
)

const selectOrderedProducts = createSelector(
	[selectProductsState],
	(productsState) => productsState.orderedProducts || [],
)

const selectSelectedProductKeys = createSelector(
	[selectProductsState],
	(productsState) => productsState.selectedProductKeys || [],
)

const selectProductsAdditionalData = createSelector(
	[selectProductsState],
	(productState) => productState.productsAdditionalData || [],
)

const selectProductsConsumptionDate = createSelector(
	[selectProductsState],
	(productState) => productState.productsConsumptionDates || [],
)

const selectProductsAmount = createSelector(
	[selectProductsState],
	(productState) => productState.productsAmount || [],
)

const selectUpgradeableRoom = createSelector(
	[selectProductsState],
	(productState) => {
		const { propertyUnitFreeUpgrade, propertyUnitPaidUpgrade } = ACTIVE_PRODUCT_KEYS
		const freeUpgradeProduct = productState.products.find(
			(prod) => prod.productKey === propertyUnitFreeUpgrade,
		)
		const paidUpgradeProduct = productState.products.find(
			(prod) => prod.productKey === propertyUnitPaidUpgrade,
		)

		const getPropertiesFromUpgradeProduct = (upgradeProduct) => {
			if (upgradeProduct) {
				const available = upgradeProduct?.availability?.filter((property) => (
					property.startDates.some((date) => !!date.isAvailable)
					&& property.endDates.some((date) => !!date.isAvailable))) || []
				return available?.map((property) => !!property && property.propertyUnitCategory && ({
					id: property.propertyUnitCategoryId,
					pricePerNight: property.priceGrossPerNight,
					productKey: property.product.key,
					...property.propertyUnitCategory,
				}))
			}
			return []
		}

		const freeUpgradeProperties = getPropertiesFromUpgradeProduct(freeUpgradeProduct)
		const paidUpgradeProperties = getPropertiesFromUpgradeProduct(paidUpgradeProduct)
		return [
			...freeUpgradeProperties,
			// paid upgrades should not contain free upgrades
			...paidUpgradeProperties.filter(
				(room) => !freeUpgradeProperties.map((freeRoom) => freeRoom.id).includes(room.id),
			),
		]
	},
)

const selectChosenUpgradeRoomCategoryId = createSelector(
	[
		selectUpgradeableRoom,
		selectSelectedProductKeys,
		selectProductsAdditionalData,
	],
	(
		propertyUpgradableRooms,
		selectedProductKeys,
		productsAdditionalData,
	) => {
		if (selectedProductKeys.includes(ACTIVE_PRODUCT_KEYS.propertyUnitFreeUpgrade)) {
			return propertyUpgradableRooms.find(
				({ productKey }) => productKey === ACTIVE_PRODUCT_KEYS.propertyUnitFreeUpgrade,
			)?.id || ''
		} if (selectedProductKeys.includes(ACTIVE_PRODUCT_KEYS.propertyUnitPaidUpgrade)) {
			return productsAdditionalData?.find(
				(prodData) => prodData.productKey === ACTIVE_PRODUCT_KEYS.propertyUnitPaidUpgrade,
			)?.data?.propertyUnitCategoryId || ''
		}
		return null
	},
)

const selectProductsPageListItems = createSelector(
	[
		selectProducts,
		selectOrderedProducts,
		selectAppState,
		selectReservation,
	],
	(
		products,
		orderedProducts,
		appState,
		{
			propertyUnit,
			requestedPropertyUnitCategoryId,
		},
	) => {
		const displayableProductKeys = DISPLAYABLE_PRODUCTS_DETAILS.concat(
			products.filter(({ productKey }) => isTimeProduct(productKey)),
		).map(({ productKey }) => productKey)
		const { pciProxyMerchantId } = appState
		const productsListItems = []
		displayableProductKeys.forEach((productKey) => {
			const product = products.find((product) => product.productKey === productKey)
			const {
				inProductsList,
				availableRooms,
				isUpgradeableProduct,
			} = product || {}
			const propertyUnitCategoryId = propertyUnit?.propertyUnitCategoryId || requestedPropertyUnitCategoryId
			const isRestrictedByHack = availableRooms && !availableRooms.includes(propertyUnitCategoryId)
			const isAlreadyOrdered = !!getOrderedProductByKey(orderedProducts, productKey)

			let isRestrictedByAvailability = false

			if (product?.requiresAvailability) {
				isRestrictedByAvailability = !product.availability
					// Check for Timeslot based availability
					|| product?.availability[0]?.timeslots?.every((slot) => !slot.isAvailable)
					// Check for General Availability based availability
					|| product.availability[0]?.isAvailable === false
			}

			if (
				!product
				|| (!inProductsList && !isTimeProduct(productKey))
				|| (isImmediatelyPurchasablePaidProduct(product) && !pciProxyMerchantId)
				|| isRestrictedByHack
				|| (isAlreadyOrdered && productKey !== ACTIVE_PRODUCT_KEYS.breakfastComplimentary)
				|| (isRestrictedByAvailability)
			) {
				if (!isUpgradeableProduct) {
					return
				}
			}

			productsListItems.push(product)
		})

		return productsListItems
	},
)

const selectSelectedProducts = createSelector(
	[
		selectProductsPageListItems,
		selectSelectedProductKeys,
	],
	(
		products,
		selectedProductKeys,
	) => products.filter((product) => selectedProductKeys.includes(product.productKey)),
)

const selectHasImmediatelyPurchasablePaidProducts = createSelector(
	[selectSelectedProducts],
	(selectedProducts) => !!selectedProducts?.find(isImmediatelyPurchasablePaidProduct),
)

const selectCurrentProductDetails = createSelector(
	[
		selectAppState,
		selectProducts,
	],
	(
		{ stepDataIndex, stepDataHistory },
		products,
	) => {
		let currentProductKey
		if (stepDataHistory[stepDataIndex].productKeys) {
			[currentProductKey] = stepDataHistory[stepDataIndex].productKeys
		} else {
			currentProductKey = stepDataHistory[stepDataIndex].productKey
		}
		return products.find((product) => product.productKey === currentProductKey)
	},
)

const selectHasOrderedParking = createSelector(
	[selectOrderedProducts],
	(orderedProducts) => !!orderedProducts.find((prod) => prod.productKey === ACTIVE_PRODUCT_KEYS.parking),
)

const selectCleaningMidStayProduct = createSelector(
	[selectProducts],
	(products) => products.find(({ productKey }) => productKey === ACTIVE_PRODUCT_KEYS.cleaningMidStay),
)

const selectBreakfastProduct = createSelector(
	[selectProducts],
	(products) => products.find(({ productKey }) => productKey === ACTIVE_PRODUCT_KEYS.breakfast),
)

const selectCleaningDateTimeOrderItems = createSelector(
	[selectOrderedProducts],
	(orderedProducts) => orderedProducts
		.filter(({ productKey }) => productKey === ACTIVE_PRODUCT_KEYS.cleaningMidStay),
)

const selectOrderedCleaningDatesTimes = createSelector(
	[selectCleaningDateTimeOrderItems],

	(orderItems) => orderItems.sort(
		(productA, productB) => (new Date(productA.consumptionDate) - new Date(productB.consumptionDate)),
	),
)

const selectGuestAreaProductNotifcations = createSelector(
	[
		selectOrderedProducts,
		selectArrivalTimeData,
		selectCleaningMidStayProduct,
		selectOrderedCleaningDatesTimes,
		selectReservationState,
		selectCleaningMidStayState,
	],
	(
		orderedProducts,
		{ confirmedArrivalDateUTC },
		cleaningMidStayProduct,
		[cleaningMidStayOrderedProduct],
		{ reservation },
		{
			maxCleaningTimesCount,
			availableCleaningTimeslots,
		},
	) => {
		const notifications = []

		const {
			property: { timezone: propertyTimeZone },
		} = reservation
		const [startDateString] = confirmedArrivalDateUTC.split('T')
		const secondDay = addDays(new Date(`${startDateString}T00:00:00.000Z`), 1)
		const is2ndDayOrLater = isAfter(new Date(), secondDay)

		getConfig().DISPLAYABLE_PRODUCTS_DETAILS
			.forEach(({
				productKey,
				title,
				description,
				notificationInGuestArea,
			}) => {
				const isOrdered = !!getOrderedProductByKey(orderedProducts, productKey)

				if (notificationInGuestArea && isOrdered) {
					const {
						title: notificationTitle,
						description: notificationDescription,
					} = notificationInGuestArea

					if (productKey !== ACTIVE_PRODUCT_KEYS.cleaningMidStay) {
						notifications.push({
							productKey,
							title: notificationTitle || title,
							description: notificationDescription || description,
						})
					} else if (productKey === ACTIVE_PRODUCT_KEYS.cleaningMidStay && is2ndDayOrLater) {
						const { consumptionDate, consumptionEndDate } = cleaningMidStayOrderedProduct
						const dateString = format(utcToZonedTime(consumptionDate, propertyTimeZone), 'EEE, do MMMM yyyy')
						const startTimeString = format(utcToZonedTime(consumptionDate, propertyTimeZone), 'HH:mm')
						const endTimeString = format(utcToZonedTime(consumptionEndDate, propertyTimeZone), 'HH:mm')

						notifications.push({
							productKey,
							// :todo - the way these titles and descriptions are passed around needs refactoring
							title: 'Your cleanings',
							description: `Your next cleaning is scheduled
							 for ${dateString} between ${startTimeString} - ${endTimeString}`,
						})
					}
				}
			})

		const hasAlreadyOrderedCleaningMidStay = notifications
			.find(({ productKey }) => productKey === ACTIVE_PRODUCT_KEYS.cleaningMidStay)

		if (
			cleaningMidStayProduct
			&& is2ndDayOrLater
			&& !hasAlreadyOrderedCleaningMidStay
			&& maxCleaningTimesCount
			&& availableCleaningTimeslots?.length
		) {
			const {
				notificationInGuestArea: {
					title,
					description,
				},
			} = cleaningMidStayProduct

			notifications.unshift({
				productKey: ACTIVE_PRODUCT_KEYS.cleaningMidStay,
				title,
				description,
			})
		}

		return notifications
	},
)

const selectGuestAreaReservationDetailsProducts = createSelector(
	[
		selectOrderedProducts,
		selectReservationState,
	],
	(
		orderedProducts,
		{ reservation },
	) => {
		const reservationDetailsProducts = []
		const { property: { key: propertyKey } } = reservation

		getConfig().DISPLAYABLE_PRODUCTS_DETAILS
			.forEach(({
				productKey,
				title,
				description,
				notificationInReservationDetails,
			}) => {
				const isOrdered = !!getOrderedProductByKey(orderedProducts, productKey)

				if (notificationInReservationDetails && isOrdered) {
					const {
						title: notificationTitle,
						description: notificationDescription,
						opensInSeparateView,
					} = notificationInReservationDetails

					reservationDetailsProducts.push({
						productKey,
						title: notificationTitle || title,
						description: notificationDescription || description?.[propertyKey] || description?.default,
						opensInSeparateView,
					})
				}
			})

		return reservationDetailsProducts
	},
)

const selectOrderedEarlyCheckIn = createSelector(
	[selectProductsState],
	(productsState) => {
		const orderedProducts = productsState.orderedProducts || []

		if (!orderedProducts.length) return null

		const earlyCheckIn = productsState
			.orderedProducts
			.find((product) => product.productType === 'check-in-early')

		return earlyCheckIn ?? null
	},
)

const selectOrderedLateCheckOut = createSelector(
	[selectProductsState],
	(productsState) => {
		const orderedProducts = productsState.orderedProducts || []

		if (!orderedProducts.length) return null

		const lateCheckOut = productsState
			.orderedProducts
			.find((product) => product.productType === 'check-out-late')

		return lateCheckOut ?? null
	},
)

export {
	selectProducts,
	selectOrderedProducts,
	selectSelectedProductKeys,
	selectSelectedProducts,
	selectProductsAdditionalData,
	selectProductsConsumptionDate,
	selectProductsAmount,
	selectHasImmediatelyPurchasablePaidProducts,
	selectCurrentProductDetails,
	selectUpgradeableRoom,
	selectChosenUpgradeRoomCategoryId,
	selectProductsPageListItems,
	selectHasOrderedParking,
	selectCleaningMidStayProduct,
	selectBreakfastProduct,
	selectCleaningDateTimeOrderItems,
	selectOrderedCleaningDatesTimes,
	selectGuestAreaProductNotifcations,
	selectGuestAreaReservationDetailsProducts,
	selectOrderedEarlyCheckIn,
	selectOrderedLateCheckOut,
}
