import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import {
	goToStep,
	goToPrevStep,
} from 'Redux/app/go-to-step.actions'
import { selectCleaningMidStayState } from 'Redux/reservation/cleaning-mid-stay/cleaning-mid-stay.selectors'

import HypeLines from 'Components/ui/hype-lines.component'
import {
	HeaderSticky,
	Section,
	ContentMainHeading,
	ContentSubHeading,
	FooterSection,
} from 'Components/view-structure.component'

import config from 'Globals/constants'
import { FONT_WEIGHTS } from 'Globals/global.styles'

import { TranslationContext } from 'Src/context'
import PageView from 'Src/components/PageView'

const {
	ACTIVE_PRODUCT_KEYS,
	DISPLAYABLE_PRODUCTS_DETAILS,
} = config

const headerImage = DISPLAYABLE_PRODUCTS_DETAILS
	.find((product) => product.productKey === ACTIVE_PRODUCT_KEYS.cleaningMidStay)?.image

const ImageFullWidth = styled.div`
	position: relative;
	margin: -16px -16px 16px -16px;
	height: 155px;
	background: url(${({ image }) => image}) center center;
	background-size: cover;
`

// :todo - refactor - PageHeading and PageDescription used similarly in multiple files
const PageHeading = styled.h1`
	margin-bottom: 0;
	margin-top: 0;
	font-size: 28px;
	font-weight: ${FONT_WEIGHTS.normal};
`

const PageDescription = styled.div`
	margin-bottom: 32px;
	font-weight: ${FONT_WEIGHTS.light};

	&,
	& > b {
		font-size: 14px;
	}

	b {
		font-weight: ${FONT_WEIGHTS.normal};
	}
`

const SectionStyled = styled(Section)`
	margin-bottom: 32px;
`

const ContentSubHeadingStyled = styled(ContentSubHeading)`
	margin-bottom: 24px;
`

const GuestAreaCleaningMidStayIntroView = ({
	cleaningMidStayState,
	goToSelectTime,
	goToPrevStep,
}) => {
	const translation = useContext(TranslationContext)
	const { maxCleaningTimesCount } = cleaningMidStayState

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault()
				goToSelectTime()
			}}
		>
			<PageView
				Header={() => <HeaderSticky onCloseButton={goToPrevStep} />}
			>
				<ImageFullWidth image={headerImage} />

				<PageHeading>{translation.t('Schedule your cleaning')}</PageHeading>
				<PageDescription>
					{/* eslint-disable max-len */}
					{translation.t('Please let us know when we could pop in, clean your apartment spotless and refresh your sheets and towels.')}
				</PageDescription>

				<SectionStyled>
					<ContentMainHeading>{translation.t('Good to know')}</ContentMainHeading>
					<ContentSubHeadingStyled>
						{translation.t('These are guidelines to keep in mind when scheduling your apartment cleanings.')}
					</ContentSubHeadingStyled>
					<HypeLines
						lines={[
							<div dangerouslySetInnerHTML={{
								/* eslint-disable max-len */
								__html: translation.t('You can have {maxCleaningTimesCount} complimentary {maxCleaningTimesCount, plural, =1{cleaning} other{cleanings}} during your current stay at Bob W.', { maxCleaningTimesCount, escapeInterpolation: false }),
							}}
							/>,
							translation.t('Cleanings are done only from Monday to Friday between 9:00 and 17:00'),
							translation.t('Cleaning takes up to 1 hour to complete.'),
							translation.t('If possible, we kindly ask you to leave the apartment for the cleaning period.'),
							translation.t('Please clear your personal belongings from any surfaces that need cleaning (bed, kitchen counter, etc).'),
						]}
					/>
				</SectionStyled>

				<FooterSection
					onPrevClick={goToPrevStep}
					nextButtonText={translation.t('Start scheduling')}
				/>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	cleaningMidStayState: selectCleaningMidStayState,
})

const mapDispatchToProps = (dispatch) => ({
	goToSelectTime: () => dispatch(goToStep(
		{ step: 'guest-area-cleaning-mid-stay-select-time' },
		{ toSkipPatch: true },
	)),
	goToPrevStep: () => dispatch(goToPrevStep()),
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestAreaCleaningMidStayIntroView)
