import React from 'react'
import ReactStars from 'react-rating-stars-component'
import styled from 'styled-components'

import { COLORS } from '../../globals/global.styles'

const RatingSelectorWrapper = styled.div`
	display: inline-block;

	.react-stars {
		letter-spacing: 8px;

		&:focus {
			outline: none;
		}
	}
`

const RatingSelector = (props) => (
	<RatingSelectorWrapper>
		<ReactStars
			size={36}
			color={COLORS.fill.minor}
			activeColor={COLORS.fill.primary}
			{...props}
		/>
	</RatingSelectorWrapper>
)

export default RatingSelector
