import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import { EnrichedProduct, ReservationState } from 'Src/types/reservation-state.types'
import { AppState } from 'Types/app-state.types'

import { FooterSection, HeaderSticky, MainHeading, Section } from 'Components/view-structure.component'

import { selectAppState, selectIsFinalStep, selectIsSubmitting } from 'Redux/app/app.selectors'
import { showAlertPopup, updateCurrentStepField } from 'Redux/app/app.actions'
import { goToPrevStep, goToStep } from 'Redux/app/go-to-step.actions'
import {
	selectIsBreakfastCampaignActive,
	selectIsWhiteLabel,
	selectReservation,
} from 'Redux/reservation/reservation.selectors'
import { selectArrivalTimeData } from 'Redux/reservation/times/times.selectors'
import { selectProductsPageListItems, selectSelectedProductKeys } from 'Redux/reservation/products/products.selectors'

import ProductCard from './product-card.component'
import { RootState } from 'Redux/store'
import { checkProductsForAvailability } from 'Redux/reservation/products/products.actions'

import { TranslationContext } from 'Src/context'
import { getCurrencyIcon } from 'Src/utils'
import PageView from 'Src/components/PageView'
import config from 'Globals/constants'
import { filterDisplayableProducts } from '@/utils/products'

const { ACTIVE_PRODUCT_KEYS } = config

const ProductsHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
`

const ProductCardsContainer = styled.div``

const ProductCardStyled = styled(ProductCard)`
	margin-bottom: 16px;
`

interface StateProps {
	appState: AppState
	isFinalStep: boolean,
	isBreakfastCampaignActive: boolean,
	isSubmitting: boolean | undefined
	reservation: ReservationState['reservation']
	products: EnrichedProduct[]
	selectedProductKeys: string[]
	arrivalTimeData: {
		confirmedArrivalDateUTC?: string
	}
	isWhiteLabel: boolean
}

interface DispatchProps {
	showAlertPopup: (text?: string) => void
	updateSelectedProductKeys: (productKey: string, subProductKeys?: string[]) => void
	openProductDetailsView: (productKey: string, subProductKeys?: string[]) => void
	updateProductsWithAvailability: () => Promise<void>
	goToNextStep: () => void
	goToPrevStep: () => void
}

interface ProductsViewProps extends StateProps, DispatchProps { }

const ProductsView: React.FC<ProductsViewProps> = ({
	appState: { progressData },
	isFinalStep,
	isSubmitting,
	isWhiteLabel,
	reservation,
	products,
	selectedProductKeys,
	arrivalTimeData: { confirmedArrivalDateUTC: reservationStartDate },
	showAlertPopup,
	isBreakfastCampaignActive,
	updateSelectedProductKeys,
	updateProductsWithAvailability,
	openProductDetailsView,
	goToNextStep,
	goToPrevStep,
}) => {
	const [isFetching, setIsFetching] = useState(true)
	const translation = useContext(TranslationContext)
	const {
		phone: propertyPhone,
		countryCode: propertyCountryCode,
		defaultCurrency,
	} = reservation?.property || {}

	const currency = getCurrencyIcon(defaultCurrency)

	useEffect(() => {
		const run = async () => {
			setIsFetching(true)
			await updateProductsWithAvailability()
			setIsFetching(false)
		}

		run()
	}, [])

	const getNextButtonText = () => (isFinalStep ? translation.t('Finish Check-In') : translation.t('Next'))

	const isSelectedProduct = (productKey:string) => selectedProductKeys.includes(productKey)

	const availableProducts = filterDisplayableProducts(products, isBreakfastCampaignActive)

	const headerString = isWhiteLabel ? 'Extras' : 'Bob’s extras'

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault()
				goToNextStep()
			}}
		>
			{/* @ts-ignore */}
			<PageView
				Header={() => (
					// @ts-ignore
					<HeaderSticky
						{...progressData}
						onBackButtonClick={goToPrevStep}
						contactButton={{ url: `tel:${propertyPhone}` }}
					/>
				)}
			>
				<ProductsHeader>
					<MainHeading>{translation.t(headerString)}</MainHeading>
					{translation.t('The cherries on top')}
				</ProductsHeader>

				<Section>
					<ProductCardsContainer>
						{availableProducts.map(({
							productKey,
							subProductKeys,
							opensInSeparateView,
							checkIsDisabled,
							disabledReason,
							price,
							priceOnlyDisplay,
							chargeMethod,
							addButtonText,
							...otherProps
						}) => {
							const isDisabled = checkIsDisabled && checkIsDisabled(
								reservationStartDate,
								propertyCountryCode,
							)

							let fullPrice = null
							const isSelected = isSelectedProduct(productKey)
							const isBreakfastComplimentary = productKey
									=== ACTIVE_PRODUCT_KEYS.breakfastComplimentary

							if (isBreakfastComplimentary) {
								const breakfastProduct = products
									.find((prod) => prod.productKey === ACTIVE_PRODUCT_KEYS.breakfast)
								fullPrice = breakfastProduct?.price || null
							}

							return (
								// @ts-ignore
								<ProductCardStyled
									key={productKey}
									isSelected={isSelected}
									chargeMethod={chargeMethod}
									addButtonText={addButtonText}
									price={price || priceOnlyDisplay}
									fullPrice={fullPrice}
									currency={currency}
									isDisabled={isDisabled}
									onClick={() => {
										if (isDisabled) {
											showAlertPopup(disabledReason)
											return null
										}

										if (opensInSeparateView && isBreakfastComplimentary) {
											openProductDetailsView(ACTIVE_PRODUCT_KEYS.breakfast, subProductKeys)
										}

										return (opensInSeparateView && !isSelected)
											? openProductDetailsView(productKey, subProductKeys)
											: updateSelectedProductKeys(productKey, subProductKeys)
									}}
									{...otherProps}
								/>
							)
						})}
					</ProductCardsContainer>
				</Section>

				<FooterSection
					onPrevClick={goToPrevStep}
					disableNextButton={isSubmitting || isFetching}
					nextButtonText={isFetching ? translation.t('Loading...') : getNextButtonText()}
				/>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
	appState: selectAppState,
	isFinalStep: selectIsFinalStep,
	reservation: selectReservation,
	products: selectProductsPageListItems,
	selectedProductKeys: selectSelectedProductKeys,
	arrivalTimeData: selectArrivalTimeData,
	isSubmitting: selectIsSubmitting,
	isBreakfastCampaignActive: selectIsBreakfastCampaignActive,
	isWhiteLabel: selectIsWhiteLabel,
})

const mapDispatchToProps = (dispatch: (...args: any[]) => void): DispatchProps => ({
	showAlertPopup: (text?: string) => dispatch(showAlertPopup(text)),
	updateSelectedProductKeys: (productKey: string, subProductKeys: string[] = []) => dispatch(
		updateCurrentStepField('products', [productKey, ...subProductKeys.filter((key) => key !== productKey)]),
	),
	// @ts-ignore
	updateProductsWithAvailability: () => dispatch(checkProductsForAvailability()),
	openProductDetailsView: (productKey: string, subProductKeys: string[] = []) => dispatch(
		goToStep(
			{
				step: `product-${productKey}`,
				productKeys: [productKey, ...subProductKeys.filter((key) => key !== productKey)],
			},
		),
	),
	goToNextStep: () => dispatch(goToStep()),
	goToPrevStep: () => dispatch(goToPrevStep()),
})

const connector = connect<StateProps, DispatchProps, RootState, RootState>(
	mapStateToProps,
	mapDispatchToProps,
)

export default connector(ProductsView)
