import React, { FC, useEffect, useState } from 'react'
import {
	Environment,
	environmentNames,
	forceEnvironment,
	getEnvironment,
	getRealEnvironment,
	resetEnvironment,
} from '@/utils/env'
import {
	EnvironmentSwitcherComponent,
	EnvironmentSwitcherContent,
	EnvironmentSwitcherSelectContent,
	EnvironmentSwitcherTitle,
} from 'Components/debug/EnvironmentSwitcher.styled'

const EnvironmentSwitcher: FC = () => {
	const [realEnvironmentName, setRealEnvironmentName] = useState<string>('')
	const [isSwitcherVisible, setIsSwitcherVisible] = useState<boolean>(false)
	const [currentEnvironment, setCurrentEnvironment] = useState<string>()
	const [isExpanded, setIsExpanded] = useState<boolean>(localStorage.getItem('debug-panel-expanded') === 'true')
	const initRealEnvironment = (): void => {
		const environment = getRealEnvironment()
		const name = environmentNames[environment]
		const switcherVisible = environment !== Environment.Production

		setIsSwitcherVisible(switcherVisible)
		setRealEnvironmentName(name)
	}

	const initCurrentEnvironment = (): void => {
		const environment = getEnvironment()
		const name = environmentNames[environment]
		setCurrentEnvironment(name)
	}

	const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target
		forceEnvironment(value)
	}

	const hideSwitcher = (): void => {
		setIsExpanded(false)
		localStorage.setItem('debug-panel-expanded', 'false')
	}

	const expandSwitcher = (): void => {
		setIsExpanded(true)
		localStorage.setItem('debug-panel-expanded', 'true')
	}

	useEffect(() => {
		initRealEnvironment()
		initCurrentEnvironment()
	}, [])

	if (!isSwitcherVisible) return null

	return (
		<EnvironmentSwitcherComponent>
			{isExpanded ? (
				<>
					<EnvironmentSwitcherTitle>Select environment</EnvironmentSwitcherTitle>
					<EnvironmentSwitcherContent>
						<div>
							Real environment:
							{' '}
							<b>{realEnvironmentName}</b>
						</div>

						<EnvironmentSwitcherSelectContent>
							<select value={currentEnvironment} onChange={handleSelect}>
								{
									Object
										.values(environmentNames)
										.map((value) => {
											return <option key={value} value={value}>{value}</option>
										})
								}
							</select>

							<button onClick={resetEnvironment} type="button">Reset</button>
							<button onClick={hideSwitcher} type="button">Hide</button>
						</EnvironmentSwitcherSelectContent>
					</EnvironmentSwitcherContent>
				</>
			) : (
				<button onClick={expandSwitcher} type="button">Show</button>
			)}
		</EnvironmentSwitcherComponent>
	)
}

export default EnvironmentSwitcher
