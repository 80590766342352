import React, { useEffect, useContext, useMemo } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import {
	Section,
	HeaderSticky,
	MainHeading,
	FooterSection,
} from 'Components/view-structure.component'

import {
	selectAppState,
	selectIsFinalStep,
	selectIsSubmitting,
} from 'Redux/app/app.selectors'
import {
	showAlertPopup,
	submitAndUpdateProductsStep, updateCurrentStepField,
} from 'Redux/app/app.actions'
import {
	goToStep,
	goToPrevStep,
} from 'Redux/app/go-to-step.actions'
import { selectReservation } from 'Redux/reservation/reservation.selectors'
import { selectArrivalTimeData } from 'Redux/reservation/times/times.selectors'
import {
	selectUpgradeableRoom,
	selectSelectedProductKeys,
	selectChosenUpgradeRoomCategoryId,
} from 'Redux/reservation/products/products.selectors'

import { checkUpgradeAvailability } from 'Redux/reservation/products/products.actions'

import RoomCard, { RoomCardEmpty } from './room-upgrade-card.component'

import { TranslationContext } from 'Src/context'
import {
	COLORS,
	FONT_WEIGHTS,
} from 'Globals/global.styles'

import {
	getImageUrlBySequence,
	mewsImage,
} from 'Src/utils'

import config, { analyticsBrand } from 'Globals/constants'
import PageView from 'Src/components/PageView'
import { sendProductListViewedEvent, sendProductRemovedEvent } from 'Src/utils/analytics'

const { ACTIVE_PRODUCT_KEYS } = config
const { propertyUnitPaidUpgrade, propertyUnitFreeUpgrade } = ACTIVE_PRODUCT_KEYS

const ApartmentUpgradeHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
`

const ApartmentCardsContainer = styled.div``

const ApartmentCardStyled = styled(RoomCard)`
	margin-bottom: 16px;
`

const SubHeadingStyled = styled.div`
	margin: -5px 0 0 0;
	font-size: 14px;
	font-weight: ${FONT_WEIGHTS.light};
	color: ${COLORS.text.secondary};
`

const RoomUpgradeView = ({
	appState: { progressData },
	isFinalStep,
	isSubmitting,
	reservation,
	upgradeableRooms,
	selectedProductKeys,
	selectedRoomId,
	openProductDetailsView,
	removeRoomUpgradeProduct,
	updateFreeUpgradeAvailability,
	goToNextStep,
	showAlertPopup,
	goToPrevStep,
	updateField,
}) => {
	const translation = useContext(TranslationContext)
	const { phone: propertyPhone } = reservation?.property || {}

	useEffect(() => {
		if (!upgradeableRooms || upgradeableRooms.length === 0) {
			updateFreeUpgradeAvailability()
		}
	}, [])

	const analyticsProducts = useMemo(() => {
		if (upgradeableRooms && upgradeableRooms.length > 0) {
			/** @type {import('Src/utils/analytics').AnalyticsProduct[]} */
			return upgradeableRooms.map(
				/** @returns {import('Src/utils/analytics').AnalyticsProduct} */
				(roomData, index) => ({
					brand: analyticsBrand,
					category: 'Unit Category Upgrade',
					currency: reservation.property.defaultCurrency,
					image_url: getImageUrlBySequence(roomData.images),
					name: roomData.name,
					position: index + 1,
					price: roomData.pricePerNight,
					product_id: roomData.id,
					quantity: 1,
					value: roomData.pricePerNight,
					variant: roomData.type,
				}),
			)
		}

		return []
	}, [])

	// Send analytics
	useEffect(() => {
		if (analyticsProducts.length > 0) {
			sendProductListViewedEvent('Unit Category Upgrade', analyticsProducts)
		}
	}, [])

	function sendRemovedAnalytics(productId) {
		const foundProduct = analyticsProducts.find(({ product_id }) => product_id === productId)

		if (foundProduct !== undefined) {
			sendProductRemovedEvent(foundProduct)
		}
	}

	const ApartmentCardWrapper = () => {
		if (Object.keys(upgradeableRooms).length === 0) {
			return <RoomCardEmpty />
		}

		return (
			upgradeableRooms?.sort((rOne, rTwo) => (
				// eslint-disable-next-line no-nested-ternary
				(rOne.pricePerNight < rTwo.pricePerNight) ? -1 : (rOne.pricePerNight > rTwo.pricePerNight) ? 1 : 0
			)).map((roomData) => {
				const {
					name,
					descriptionShort,
					images,
					productKey,
					pricePerNight,
					id,
				} = roomData
				const isSelected = selectedProductKeys.includes(productKey) && selectedRoomId === id

				return (
					<ApartmentCardStyled
						key={id}
						isSelected={isSelected}
						addButtonText="Learn More"
						title={name}
						price={productKey === propertyUnitPaidUpgrade && pricePerNight}
						showGiftFromBob={productKey === propertyUnitFreeUpgrade}
						descriptionShort={descriptionShort}
						image={mewsImage(images, 335, 220)}
						onClick={() => {
							if (isSelected) {
								removeRoomUpgradeProduct(productKey)
								sendRemovedAnalytics(id)
								updateField(
									'propertyUnitCategoryId',
									{
										productKey: productKey === propertyUnitPaidUpgrade
											? propertyUnitPaidUpgrade
											: propertyUnitFreeUpgrade,
										propertyUnitCategoryId: '',
									},
								)
							} else {
								openProductDetailsView(productKey, id)
							}
						}}
					/>
				)
			})
		)
	}

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault()
				// :todo needs refactor (DEV-752)
				if (
					selectedProductKeys.includes(propertyUnitPaidUpgrade)
					&& selectedProductKeys.includes(propertyUnitFreeUpgrade)
				) {
					showAlertPopup('You must select only one apartment upgrade')
					return
				}
				goToNextStep()
			}}
		>
			<PageView
				Header={() => (
					<HeaderSticky
						{...progressData}
						onBackButtonClick={goToPrevStep}
						contactButton={{ url: `tel:${propertyPhone}` }}
					/>
				)}
			>
				<ApartmentUpgradeHeader>
					<MainHeading>{translation.t('Apartment Upgrade')}</MainHeading>
					<SubHeadingStyled>{translation.t('Enhance your comfort')}</SubHeadingStyled>
				</ApartmentUpgradeHeader>

				<Section>
					<ApartmentCardsContainer>
						<ApartmentCardWrapper />
					</ApartmentCardsContainer>
				</Section>

				<FooterSection
					onPrevClick={goToPrevStep}
					disableNextButton={isSubmitting}
					nextButtonText={isFinalStep ? translation.t('Finish Check-In') : translation.t('Next')}
				/>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	appState: selectAppState,
	isFinalStep: selectIsFinalStep,
	reservation: selectReservation,
	upgradeableRooms: selectUpgradeableRoom,
	selectedProductKeys: selectSelectedProductKeys,
	selectedRoomId: selectChosenUpgradeRoomCategoryId,
	arrivalTimeData: selectArrivalTimeData,
	isSubmitting: selectIsSubmitting,
})

const mapDispatchToProps = (dispatch) => ({
	updateFreeUpgradeAvailability: () => dispatch(checkUpgradeAvailability()),
	openProductDetailsView: (productKey, roomId) => dispatch(
		goToStep(
			{
				step: `product-${productKey}`,
				productKeys: [productKey],
				selectedRoom: roomId,
			},
		),
	),
	removeRoomUpgradeProduct: (productKey) => {
		dispatch(submitAndUpdateProductsStep([productKey]))
	},
	showAlertPopup: (text) => dispatch(showAlertPopup(text)),
	goToNextStep: () => dispatch(goToStep()),
	goToPrevStep: () => dispatch(goToPrevStep()),
	updateField: (fieldName, data) => dispatch(updateCurrentStepField(fieldName, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RoomUpgradeView)
