import logger from '../logger/logger'
import { getCoreApiBaseUrl } from '@/utils/env'

const apiHost = getCoreApiBaseUrl()

const getTimesAvailability = async (reservationId, propertyUnitCategoryId) => {
	let url = `${apiHost}/property-unit-availability?reservationId=${reservationId}&fallbackForDefaultTimeSelection=true`

	if (propertyUnitCategoryId) {
		url += `&propertyUnitCategoryId=${propertyUnitCategoryId}`
	}

	try {
		const response = await fetch(url, { method: 'GET' })
		const { data, message = '' } = await response.json()
		if (!data?.length) {
			logger.info('There is no availability for the selected property unit', message)
			return
		}
		return data
	} catch (error) {
		logger.error('Could not fetch property unit availability data', error)
		if (!error.response) {
			throw error
		}
	}
}

export default getTimesAvailability
