import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import { selectReservation } from 'Redux/reservation/reservation.selectors'
import { selectProductsAdditionalData } from 'Redux/reservation/products/products.selectors'
import { updateCurrentStepField } from 'Redux/app/app.actions'
import { selectAppState } from 'Redux/app/app.selectors'

import config from 'Globals/constants'

import { ContentMainHeading, ContentSubHeading, Section } from 'Components/view-structure.component'
import SingleProductWrapper from './single-product-wrapper.component'

import { TranslationContext } from 'Src/context'

import Input from 'Components/ui/form-input.component'

const { PARKING_INDOOR_DETAILS, ACTIVE_PRODUCT_KEYS } = config

const SectionStyled = styled(Section)`
	bottom: 48px 0;
`

const ImageContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 8px;

	> img {
		max-width: 48%;
		border-radius: 5%;
	}
`

const CarPlateWrapper = styled.div`
	margin-top: 20px;
`

const ParkingIndoorView = ({
	appState,
	reservation,
	productsAdditionalData,
	updateField,
}) => {
	const translation = useContext(TranslationContext)
	const {
		property: {
			key: propertyUniqueKey,
		},
	} = reservation

	const images = PARKING_INDOOR_DETAILS[propertyUniqueKey]?.images ?? []
	const { currentStepFormErrors: formErrors } = appState

	const carPlate = productsAdditionalData?.find(
		(prodData) => prodData.productKey === ACTIVE_PRODUCT_KEYS.parking,
	)?.data?.carPlateNumber || ''

	return (
		<SingleProductWrapper
			title={translation.t('Car Parking')}
			/* eslint-disable max-len */
			description={translation.t('Coming with a car? We have dedicated Bob W parking spots located below the building. You can conveniently enter the parking area with your door code.')}
			buttonText={translation.t('Add car parking')}
		>
			<SectionStyled>
				<ImageContainer>
					{images.map((image, idx) => <img key={idx} src={image} alt={translation.t('Car Parking')} />)}
				</ImageContainer>
				<CarPlateWrapper>
					<ContentMainHeading>{translation.t('Required Information')}</ContentMainHeading>
					<ContentSubHeading>
						{translation.t('Please share your vehicle plate number to complete your parking request')}
					</ContentSubHeading>
					<Input
						label={translation.t('Vehicle plate number*')}
						value={carPlate}
						handleChange={
							(input) => updateField(
								'carPlate',
								{ productKey: ACTIVE_PRODUCT_KEYS.parking, carPlate: input },
							)
						}
						isValid={!formErrors?.includes('carPlate')}
					/>
				</CarPlateWrapper>
			</SectionStyled>
		</SingleProductWrapper>
	)
}

const mapStateToProps = createStructuredSelector({
	appState: selectAppState,
	reservation: selectReservation,
	productsAdditionalData: selectProductsAdditionalData,
})

const mapDispatchToProps = (dispatch) => ({
	updateField: (fieldName, data) => dispatch(updateCurrentStepField(fieldName, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ParkingIndoorView)
