import {
	COLORS,
	FONT_WEIGHTS,
} from '../../../globals/global.styles'

const SECURE_FIELDS_INPUT_STYLES = {
	fontSize: '16px',
	lineHeight: '1.6em',
	fontWeight: FONT_WEIGHTS.light,
	color: COLORS.text.primary,
	padding: '1px 0 0 0',
	fontFamily: 'Inter, sans-serif',
}

const SECURE_FIELDS_STYLES = {
	'@font-face': {
		fontFamily: 'Inter, sans-serif',
		src: 'url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap") format("woff2")',
	},
	cardNumber: SECURE_FIELDS_INPUT_STYLES,
	'cardNumber:focus': 'outline: none;',
	cvv: SECURE_FIELDS_INPUT_STYLES,
	'cvv:focus': 'outline: none;',
}

export {
	SECURE_FIELDS_STYLES,
}
