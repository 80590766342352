import TimesActionTypes from './times.types'

const timesReducer = (state, action) => {
	switch (action.type) {
		case TimesActionTypes.GET_AVAILABLE_TIMES: {
			const {
				checkIn, checkOut, superEarlyCheckIn, superLateCheckOut,
			} = action.payload
			return {
				...state,
				availableTimes: {
					checkIn: checkIn.filter((time) => !!time.isAvailable),
					checkOut: checkOut.filter((time) => !!time.isAvailable),
					superEarlyCheckIn: superEarlyCheckIn.filter((time) => !!time.isAvailable),
					superLateCheckOut: superLateCheckOut.filter((time) => !!time.isAvailable),
				},
			}
		}
		case TimesActionTypes.UPDATE_SELECTED_ARRIVAL_TIME: {
			const { selectedArrivalTimeUTC } = action.payload
			return {
				...state,
				arrivalTimeData: {
					...state.arrivalTimeData,
					selectedArrivalTimeUTC,
				},
			}
		}

		case TimesActionTypes.UPDATE_SELECTED_DEPARTURE_TIME: {
			const { selectedDepartureTimeUTC } = action.payload
			return {
				...state,
				departureTimeData: {
					...state.departureTimeData,
					selectedDepartureTimeUTC,
				},
			}
		}

		default:
			return state
	}
}

export default timesReducer
