import CleaningMidStayActionTypes from './cleaning-mid-stay.types'

const cleaningMidStayReducer = (state, action) => {
	switch (action.type) {
		case CleaningMidStayActionTypes.UPDATE_CURRENT_CLEANING_TIME: {
			return {
				...state,
				cleaningMidStayState: {
					...(state.cleaningMidStayState || {}),
					currentCleaningTimeUTC: action.payload.cleaningTime,
				},
			}
		}

		case CleaningMidStayActionTypes.UPDATE_CURRENT_CLEANING_DATE: {
			return {
				...state,
				cleaningMidStayState: {
					...(state.cleaningMidStayState || {}),
					currentCleaningDateLocal: action.payload.cleaningDate,
				},
			}
		}

		case CleaningMidStayActionTypes.CURRENT_CLEANING_DATE_TIME_TO_ARRAY: {
			const { currentCleaningTimeUTC } = state.cleaningMidStayState || {}

			return {
				...state,
				cleaningMidStayState: {
					...(state.cleaningMidStayState || {}),
					currentCleaningDateLocal: null,
					currentCleaningTimeUTC: null,
					selectedCleaningDatesTimes: [
						...(state.cleaningMidStayState?.selectedCleaningDatesTimes || []),
						currentCleaningTimeUTC,
					],
				},
			}
		}

		case CleaningMidStayActionTypes.REMOVE_SELECTED_CLEANING_DATE_TIME: {
			const currentSelectedCleaningDatesTimes = state.cleaningMidStayState?.selectedCleaningDatesTimes || []
			const selectedCleaningDatesTimes = currentSelectedCleaningDatesTimes
				.filter((dateTime) => dateTime !== action.payload.dateTime)

			return {
				...state,
				cleaningMidStayState: {
					...(state.cleaningMidStayState || {}),
					selectedCleaningDatesTimes,
				},
			}
		}

		case CleaningMidStayActionTypes.CLEAR_SELECTED_CLEANING_DATE_TIMES:
			return {
				...state,
				cleaningMidStayState: {
					...(state.cleaningMidStayState || {}),
					selectedCleaningDatesTimes: [],
				},
			}

		case CleaningMidStayActionTypes.GET_AVAILABLE_CLEANING_TIMES_SUCCESS: {
			const {
				data: {
					cleaningAllowance,
					timeslots,
				},
			} = action.payload

			const availableCleaningTimeslots = timeslots.filter(({ isAvailable }) => isAvailable)

			return {
				...state,
				cleaningMidStayState: {
					...(state.cleaningMidStayState || {}),
					maxCleaningTimesCount: cleaningAllowance,
					availableCleaningTimeslots,
				},
			}
		}

		default:
			return state
	}
}

export default cleaningMidStayReducer
