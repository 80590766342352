import { Environment, getEnvironment, isWhiteLabelDomain } from './env'

export const getReceptionBaseUrl = () => {
	switch (getEnvironment()) {
		case Environment.Production: {
			const isWhiteLabel = isWhiteLabelDomain()

			if (isWhiteLabel) {
				return 'https://reception.notahotel.co'
			}

			return 'https://reception.bobw.co'
		}
		case Environment.Stage: {
			return 'https://reception.staging-bobw.com'
		}
		case Environment.Dukenukem: {
			return 'https://reception.dukenukem.test-bobw.com'
		}
		case Environment.Development:
		default: {
			return 'http://localhost:3000'
		}
	}
}

export const redirectToReception = (reservationSlug: string) => {
	const baseUrl = getReceptionBaseUrl()
	const url = `${baseUrl}/stay?slug=${reservationSlug}`
	window.location.replace(url)
}
