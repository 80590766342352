import { Reducer } from 'redux'
import { AppState } from 'Types/app-state.types'

import AppActionTypes from './app.types'

interface Action {
	type: string
	payload: {
		flow?: AppState['flow']
		stepDataIndex: AppState['stepDataIndex']
		stepDataHistory: AppState['stepDataHistory']
		reservationId?: string
		fieldName?: string
		errors?: AppState['currentStepFormErrors']
		merchantId?: string
	}
}

const INITIAL_STATE: AppState = {
	flow: 'CHECK_IN',
	currentStepFormErrors: [],
	progressData: {},
	stepDataIndex: null,
	stepDataHistory: [],
	languageCode: 'en-GB',
}

const appReducer = (state: AppState = INITIAL_STATE, action: Action) => {
	switch (action.type) {
		case AppActionTypes.INIT_APP_STATE: {
			const { flow, reservationId } = action.payload
			return {
				...state,
				flow,
				reservationId,
			}
		}

		case AppActionTypes.SET_CURRENT_STEP: {
			const {
				stepDataIndex,
				stepDataHistory,
			} = action.payload

			return {
				...state,
				stepDataIndex,
				stepDataHistory,
			}
		}

		case AppActionTypes.SET_CURRENT_STEP_FORM_ERRORS:
			return {
				...state,
				currentStepFormErrors: action.payload.errors,
			}
		case AppActionTypes.REMOVE_CURRENT_STEP_FORM_ERROR: {
			const { fieldName } = action.payload

			if (!fieldName) return

			const newArr = [...state.currentStepFormErrors]
			const idx = newArr.indexOf(fieldName)

			if (idx >= 0) newArr.splice(idx, 1)

			return {
				...state,
				currentStepFormErrors: newArr,
			}
		}

		case AppActionTypes.SET_POPUP_DATA: {
			return {
				...state,
				popupData: action.payload,
			}
		}

		case AppActionTypes.SET_SNACKBAR_TEXT: {
			return {
				...state,
				snackbarText: action.payload,
			}
		}

		case AppActionTypes.SET_IS_SUBMITTING: {
			return {
				...state,
				isSubmitting: action.payload,
			}
		}

		case AppActionTypes.UPDATE_PROGRESS:
			return {
				...state,
				progressData: action.payload,
			}
		case AppActionTypes.SET_ERROR_VIEW_VISIBLE:
			return {
				...state,
				errorViewVisible: action.payload,
			}
		case AppActionTypes.SWITCH_LANGUAGE:
			return {
				...state,
				languageCode: action.payload,
			}
		case AppActionTypes.SET_PCI_PROXY_MERCHANT_ID:
			return {
				...state,
				pciProxyMerchantId: action.payload.merchantId,
			}
		default:
			return state
	}
}

export default appReducer as Reducer<AppState>
