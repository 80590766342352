import { useEffect, useState } from 'react'

export const usePoller = (onPoll, interval, reset) => {
	useEffect(() => {
		const poller = setInterval(onPoll, interval)
		return () => clearInterval(poller)
	}, [reset])
}

export const useWindowFocus = (onFocus, onBlur) => {
	useEffect(() => {
		window.addEventListener('focus', onFocus)
		window.addEventListener('blur', onBlur)

		return () => {
			window.removeEventListener('focus', onFocus)
			window.removeEventListener('blur', onBlur)
		}
	})
}

export const useDoorPoller = (fn, interval) => {
	const [shouldPoll, setShouldPoll] = useState(true)
	const [lastPollDate, setLastPollDate] = useState(new Date())

	const handler = () => {
		const newDate = new Date()
		newDate.setMilliseconds(newDate.getMilliseconds() - interval)

		if (shouldPoll && newDate.getTime() > lastPollDate.getTime()) {
			setLastPollDate(new Date())

			fn()
		}
	}
	// case when window got focused and latest update was later than interval
	// usePoller will start from 0 till interval and only after time passed will call handler
	// so we need to call handler here
	// setTimeout is to prevent setState during update as it's forbidden
	setTimeout(handler, 0)

	usePoller(handler, interval, shouldPoll)

	// this detects tab switches perfectly on mobile browsers
	// however, it does not detect tab switch until user clicks somewhere in the window on desktop browsers
	useWindowFocus(
		() => setShouldPoll(true),
		() => setShouldPoll(false),
	)
}
