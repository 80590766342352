import styled from 'styled-components'
import { COLORS } from 'Globals/global.styles'

export const ProductCard = styled.div`
	display: grid;
	grid-template-columns: 110px 1fr;
	column-gap: 16px;
	background-color: ${COLORS.background.secondary};
`

export const ProductImageContainer = styled.div`
	display: grid;
	align-items: center;
	padding: 8px;
	border-radius: 2px;
	border: 1px solid ${COLORS.border.minor};
	background: ${COLORS.background.primary};
`

export const ProductImage = styled.img`
	width: 100%;
`

export const ProductContent = styled.div`
	display: grid;
	row-gap: 8px;
	padding: 8px 8px 8px 0;
`

export const ProductTitle = styled.div`
	font-size: 16px;
	font-weight: 500;
`

export const ProductDescription = styled.div`
	font-size: 14px;
	line-height: 1.4em;
	color: ${COLORS.text.secondary};
`

export const ProductDetails = styled.div`
    display: flex;
    flex-direction: column;
`

export const LocationContent = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: 8px;
`

export const LocationIcon = styled.img`
	width: 16px;
	height: 16px;
`

export const LocationLink = styled.a`
	font-size: 14px;
	color: ${COLORS.text.link};
`
