import React from 'react'
import styled, { css } from 'styled-components'

import { COLORS } from 'Globals/global.styles'

const VARIANTS = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	TRANSPARENT: 'transparent',
	TRANSPARENT_WITH_BORDER: 'transparent_with_border',
	TRANSPARENT_WITH_LIGHT_BORDER: 'transparent_with_light_border',
	DEFAULT: 'default',
	EMPTY: 'empty',
}

const ButtonDefaultStyles = css`
	color: ${COLORS.text.white};
	border: 1px solid ${COLORS.border.highlight};
	background-color: ${COLORS.background.highlight};

	&:hover {
		background-color: ${COLORS.background.highlightHover};
	}
`

const ButtonEmptyStyles = css`
	background-color: #fff;
	border: none;
`

const ButtonPrimaryStyles = css`
	color: ${COLORS.text.white};
	border: 1px solid ${COLORS.border.primary};
	background-color: ${COLORS.background.action};

	&:hover {
		background-color: ${COLORS.background.actionHover};
	}

	&:disabled {
		color: ${COLORS.text.white};
		border: 0;
		background-color: ${COLORS.background.disabled};
	}
`

const ButtonSecondaryStyles = css`
	color: ${COLORS.text.primary};
	border: 1px solid ${COLORS.border.minor};
	background-color: ${COLORS.background.secondary};

	&:hover {
		background-color: ${COLORS.background.secondaryHover};
	}
`

const ButtonTransparentStyles = css`
	color: ${COLORS.text.primary};
	border: none;
	background-color: transparent;

	&:hover {
		text-decoration: underline;
	}
`

const ButtonTransparentBorderStyles = css`
	color: ${COLORS.text.primary};
	border: 1px solid ${COLORS.border.primary};
	background-color: transparent;
`

const ButtonTransparentLightBorderStyles = css`
	color: ${COLORS.text.primary};
	border: 1px solid ${COLORS.border.minor};
	background-color: transparent;

	&:hover:not(:disabled) {
		background-color: ${COLORS.background.hover};
	}

	&:disabled {
		color: ${COLORS.text.minor};
	}
`

const ButtonStyled = styled.button`
	height: 44px;
	padding: 0px 16px;
	border-radius: 2px;
	cursor: pointer;
	font-size: 16px;
	line-height: 2.8em;
	white-space: nowrap;

	&:focus {
		outline: none;
	}

	${({ variant }) => {
		switch (variant) {
			case VARIANTS.PRIMARY:
				return ButtonPrimaryStyles
			case VARIANTS.EMPTY:
				return ButtonEmptyStyles
			case VARIANTS.SECONDARY:
				return ButtonSecondaryStyles
			case VARIANTS.TRANSPARENT:
				return ButtonTransparentStyles
			case VARIANTS.TRANSPARENT_WITH_BORDER:
				return ButtonTransparentBorderStyles
			case VARIANTS.TRANSPARENT_WITH_LIGHT_BORDER:
				return ButtonTransparentLightBorderStyles
			case VARIANTS.DEFAULT:
			default:
				return ButtonDefaultStyles
		}
	}}

	${({ fullWidth }) => fullWidth && css`
		width: 100%;
	`}

	&:disabled {
		cursor: default;
	}
`

const Button = styled(({ type, ...otherProps }) => <ButtonStyled type={type || 'button'} {...otherProps} />)``

export default Button
