import React, {
	useState,
	useEffect,
	useRef,
	useContext,
	useMemo,
} from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import {
	FooterSection,
	MainHeading,
} from 'Components/view-structure.component'
import CreditCardFields from '../../../components/credit-card-fields.component'
import PaymentIcons from '../../../components/payment-icons.component'
import { SECURE_FIELDS_STYLES } from './secure-fields.styles'
import CloseButtonIcon from '../../../assets/icons/icon-close.svg'

import logger from '../../../logger/logger'
import config from 'Globals/constants'

import {
	updateCurrentStepField,
	showAlertPopup,
} from 'Redux/app/app.actions'
import { goToPrevStep, patchCurrentStep } from 'Redux/app/go-to-step.actions'
import {
	selectAppState,
	selectIsSubmitting,
} from 'Redux/app/app.selectors'
import { selectMainGuestNewCreditCard } from 'Redux/reservation/guests/guests.selectors'
import { validateExpirationDate } from 'Redux/reservation/guests/guests.utils'

import { generateMediaQuery } from 'Globals/global.styles'

import { TranslationContext } from 'Src/context'
import PageView from 'Src/components/PageView'
import { getSecureFieldsHost } from '@/utils/env'
import { generateAuthorizationUrl, isCardAuthorizationValid } from '@/utils/payment'
import { selectReservation } from 'Redux/reservation/reservation.selectors'
import { transformCreditCards } from '@/api/transforms/credit-card'
import { appendScriptToDOM } from '@/utils'

const { SECURE_FIELDS_ACCEPTABLE_PAYMENT_METHODS } = config

const Wrapper = styled.div`
	margin: 0 10px 0 10px;
`

const CloseButton = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 20px 0 20px 0;

	${generateMediaQuery.mobileSmall(`
		margin: 10px 0 10px 0;
	`)}

	> img {
		height: 20px;
		cursor: pointer;
	}
`

const DescriptionContainer = styled.div`
	margin: 20px 0 32px 0;

	${generateMediaQuery.mobileSmall(`
		margin: 10px 0 16px 0;
	`)}
`

const TextContent = styled.div`
	font-size: 14px;
	line-height: 17px;
`

const PaymentIconsStyled = styled(PaymentIcons)`
	margin-top: 24px;
`

const SECURE_FIELDS_INPUT_IDS = {
	cardNumber: 'card-number-secure-fields-input',
	cvv: 'cvv-secure-fields-input',
}

const PCIProxyCardTypesMap = {
	VIS: 'visa',
	ECA: 'mastercard',
	AMX: 'amex',
	DIN: 'diners-club',
	DIS: 'discover',
	JCB: 'jcb',
	CUP: 'union-pay',
}

let secureFields = null

const AddCardView = ({
	appState: {
		currentStepFormErrors: formErrors,
		pciProxyMerchantId,
	},
	isSubmitting,
	reservation,
	creditCard,
	updateField,
	showAlertPopup,
	patchCurrentStep,
	goToPrevStep,
	goToPrevStepWithAlert,
	goToPrevStepWithApiPatch,
}) => {
	const translation = useContext(TranslationContext)
	const expirationDateFieldRef = useRef()
	const [focusedField, setFocusedField] = useState(null)
	const [isHandlingAuthorizationState, setIsHandlingAuthorizationState] = useState(false)
	const [expirationDateIsValid, setExpirationDateIsValid] = useState(null)

	const { expirationDate } = creditCard || {}

	const handleAddedCardAuthorization = (transactionId, modifiedReservation) => {
		setIsHandlingAuthorizationState(true)

		const transformedUpdatedCreditCards = transformCreditCards(modifiedReservation?.creditCards) ?? []
		const foundCard = transformedUpdatedCreditCards.find(
			(card) => card.transactionId === transactionId,
		)

		const isCardAuthorized = isCardAuthorizationValid(foundCard?.authorizationState)

		if (foundCard !== undefined && !isCardAuthorized) {
			const cardId = foundCard.id
			const returnPath = `?cardId=${cardId}`

			const authorizationUrl = generateAuthorizationUrl({
				cardId,
				returnPath,
				slug: reservation.slug,
			})

			window.location.replace(authorizationUrl)
		} else {
			goToPrevStep()
		}
	}

	const setSecureFieldsListeners = () => {
		let cardNumberIsValid = null

		secureFields.on('change', (data) => {
			const {
				event: { field: fieldName, type: eventType },
				fields: { cardNumber, cvv },
			} = data
			const { paymentMethod } = cardNumber || {}
			const cardType = PCIProxyCardTypesMap[paymentMethod]

			if (fieldName === 'cardNumber') {
				updateField('cardNumber', cardNumber)
				updateField('type', cardType)

				const changedFromInvalidToValid = cardNumber.valid && !cardNumberIsValid
				const toFocusExpirationDateField = eventType === 'keyUp' && changedFromInvalidToValid
				if (toFocusExpirationDateField) expirationDateFieldRef.current.focus()

				cardNumberIsValid = !!cardNumber.valid
			}

			if (fieldName === 'cvv') {
				updateField('cvv', cvv)
			}

			if (eventType === 'blur') {
				setFocusedField(null)
			}

			if (eventType === 'focus') {
				// Delaying the focus event because otherwise the blur event of the previous field
				// would get trigger after the this focus event, for some reason :/
				setTimeout(() => {
					setFocusedField(fieldName)
				}, 0)
			}
		})

		secureFields.on('validate', ({ hasErrors }) => {
			// To trigger highlight the invalid fields
			if (hasErrors) goToPrevStepWithApiPatch()
		})

		secureFields.on('success', ({ transactionId }) => {
			updateField('transactionId', transactionId)
			patchCurrentStep((modifiedReservation) => handleAddedCardAuthorization(transactionId, modifiedReservation))
		})

		secureFields.on('error', (error) => {
			const errorString = typeof error === 'string' ? error : error.error

			if (errorString === 'Invalid merchantId') {
				logger.error(`SecureFields error: ${errorString}. Merchant ID: ${pciProxyMerchantId}.`)
				goToPrevStepWithAlert('Unfortunately it is currently not possible to add a new credit card.')
				return
			}

			logger.error(`SecureFields error: ${errorString}`)
			showAlertPopup(`Sorry! ${errorString}`)
		})
	}

	const initSecureFields = () => {
		secureFields = new window.SecureFields()

		secureFields.initTokenize(
			pciProxyMerchantId,
			{
				cardNumber: {
					placeholderElementId: SECURE_FIELDS_INPUT_IDS.cardNumber,
					inputType: 'tel',
				},
				cvv: {
					placeholderElementId: SECURE_FIELDS_INPUT_IDS.cvv,
					inputType: 'tel',
				},
			},
			{
				styles: SECURE_FIELDS_STYLES,
				paymentMethods: SECURE_FIELDS_ACCEPTABLE_PAYMENT_METHODS,
			},
		)

		setSecureFieldsListeners()
	}

	const secureFieldsScriptUrl = useMemo(() => {
		const secureFieldsHost = getSecureFieldsHost()

		return `https://${secureFieldsHost}/upp/payment/js/secure-fields-2.0.0.min.js`
	}, [])

	const onSubmit = (event) => {
		event.preventDefault()

		secureFields?.getCardInfo((info) => {
			if (info && info.maskedCard) {
				updateField('numberMasked', info.maskedCard)
			}
			secureFields?.submit()
		})
	}

	useEffect(() => {
		appendScriptToDOM(secureFieldsScriptUrl, initSecureFields)

		return () => {
			secureFields?.destroy()
			secureFields = null
		}
	}, [])

	useEffect(() => {
		const newExpirationDateIsValid = validateExpirationDate(expirationDate)
		const toFocusCVVField = newExpirationDateIsValid && !expirationDateIsValid

		if (toFocusCVVField) {
			secureFields.focus('cvv')
		}

		setExpirationDateIsValid(newExpirationDateIsValid)
	}, [expirationDate])

	return (
		<form onSubmit={onSubmit}>
			<PageView>
				<Wrapper>
					<CloseButton onClick={goToPrevStep}>
						<img src={CloseButtonIcon} alt="close" />
					</CloseButton>

					<DescriptionContainer>
						<MainHeading>{translation.t('New Card Details')}</MainHeading>
						<TextContent>{translation.t('Your credit/debit card details are safe with us')}</TextContent>
						<PaymentIconsStyled />
					</DescriptionContainer>

					<CreditCardFields
						focusedField={focusedField}
						creditCard={creditCard}
						updateField={updateField}
						focusSecureFieldsField={(fieldName) => secureFields.focus(fieldName)}
						expirationDateFieldRef={expirationDateFieldRef}
						formErrors={formErrors}
					/>

					<FooterSection
						onPrevClick={goToPrevStep}
						disableNextButton={isSubmitting || isHandlingAuthorizationState}
						nextButtonText={translation.t('Add Card')}
						prevButtonText={translation.t('Cancel')}
					/>
				</Wrapper>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	appState: selectAppState,
	isSubmitting: selectIsSubmitting,
	creditCard: selectMainGuestNewCreditCard,
	reservation: selectReservation,
})

const mapDispatchToProps = (dispatch) => ({
	updateField: (fieldName, data) => dispatch(updateCurrentStepField(fieldName, data)),
	showAlertPopup: (text) => dispatch(showAlertPopup(text)),
	goToPrevStepWithAlert: (alertMessage) => dispatch(showAlertPopup(
		alertMessage,
		() => dispatch(goToPrevStep()),
	)),
	goToPrevStepWithApiPatch: () => dispatch(goToPrevStep({ withApiPatch: true })),
	goToPrevStep: () => dispatch(goToPrevStep()),
	patchCurrentStep: (callback) => dispatch(patchCurrentStep(callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddCardView)
