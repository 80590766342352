import React, { FC, ReactNode } from 'react'
import PartnerImgUrl from '@/assets/breakfast-partners/placeholder.svg'

import {
	ProductCard,
	ProductContent,
	ProductDescription,
	ProductDetails,
	ProductImage,
	ProductImageContainer,
	ProductTitle,
} from './breakfast-partner-card.styled'

interface Props {
	readonly name: string;
	readonly imageUrl: string | null;
	readonly description: string;
	readonly children?: ReactNode;
}

const BreakfastPartnerCard : FC<Props> = ({
	name,
	description,
	imageUrl,
	children,
}) => {
	const image = imageUrl === null ? PartnerImgUrl : imageUrl
	return (
		<ProductCard>
			<ProductImageContainer>
				<ProductImage src={image} alt={name} />
			</ProductImageContainer>

			<ProductContent>
				<ProductTitle>
					{ name }
				</ProductTitle>

				<ProductDescription>
					{ description }
				</ProductDescription>

				<ProductDetails>
					{ children }
				</ProductDetails>
			</ProductContent>
		</ProductCard>
	)
}

export default BreakfastPartnerCard
