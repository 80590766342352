import React, { useContext, useEffect, useState } from 'react'

import BreakfastContent from 'Components/breakfast/breakfast-content.component'
import SingleProductWrapper from './single-product-wrapper.component'

import getBreakfastContent from '@/api/get-breakfast-content'

import { TranslationContext } from 'Src/context'
import { createStructuredSelector } from 'reselect'
import { selectIsBreakfastCampaignActive, selectReservation } from 'Redux/reservation/reservation.selectors'
import { connect } from 'react-redux'
import { selectAppState } from 'Redux/app/app.selectors'

const BreakfastView = ({ reservation, isBreakfastCampaignActive, appState: { languageCode } }) => {
	const translation = useContext(TranslationContext)
	const [isLoading, setIsLoading] = useState(true)
	const propertyId = reservation?.property.id
	const [breakfastContent, setBreakfastContent] = useState(null)

	useEffect(() => {
		const loadBreakfastContent = async () => {
			if (propertyId) {
				const res = await getBreakfastContent(propertyId, languageCode)
				setBreakfastContent(res)
				setIsLoading(false)
			}
		}

		loadBreakfastContent()
	}, [languageCode])

	return (
		<SingleProductWrapper
			title={translation.t('Local Breakfast')}
			/* eslint-disable max-len */
			description={translation.t('Add breakfast to your stay and eat at hand-picked cafes just across the street. Show your breakfast pass, eat and carry on with your day. Easy-peasy.')}
			buttonText={translation.t('Add breakfast')}
			isFreeProduct={isBreakfastCampaignActive}
		>
			<BreakfastContent
				isLoading={isLoading}
				breakfastContent={breakfastContent}
			/>
		</SingleProductWrapper>
	)
}

const mapStateToProps = createStructuredSelector({
	isBreakfastCampaignActive: selectIsBreakfastCampaignActive,
	appState: selectAppState,
	reservation: selectReservation,
})

export default connect(mapStateToProps)(BreakfastView)
