import ls from '../../../local-storage/local-storage'

import { combineSelectedProductKeys } from './products.utils'
import ProductsActionTypes from './products.types'
import {
	selectProducts,
	selectSelectedProductKeys,
	selectProductsAdditionalData,
	selectProductsConsumptionDate,
	selectProductsAmount,
} from './products.selectors'
import { selectReservation } from '../reservation.selectors'
import { getProductAvailability, getProductAvailabilityByType } from 'Src/api/get-product-availability'

import config from '../../../globals/constants'

const { ACTIVE_PRODUCT_KEYS } = config
const {
	propertyUnitPaidUpgrade: propertyUnitPaidUpgradeKey,
	propertyUnitFreeUpgrade: propertyUnitFreeUpgradeKey,
} = ACTIVE_PRODUCT_KEYS

const selectedProductKeysToLocalStorage = ({ productKey, productKeys }) => (dispatch, getState) => {
	const changedProductKeys = productKeys || [productKey]
	const selectedProductKeys = selectSelectedProductKeys(getState())
	ls.setItem('selectedProductKeys', combineSelectedProductKeys(selectedProductKeys, changedProductKeys))
}

const selectedProductAdditionalDataToLocalStorage = (productKey, productData) => (dispatch, getState) => {
	const productsWithSelectedData = selectProductsAdditionalData(getState())
	const otherProducts = productsWithSelectedData.filter((prod) => prod.productKey !== productKey)

	const newProductsData = [...otherProducts, {
		productKey,
		data: productData,
	}]

	ls.setItem('selectedProductsAdditionalData', newProductsData)
}

// :todo I will be merging these consumption date and amount product data in another branch
const selectedProductConsumptionToLocalStorage = (productKey, consumptionDate) => (dispatch, getState) => {
	const productsWithSelectedData = selectProductsConsumptionDate(getState())
	const otherProducts = productsWithSelectedData.filter((prod) => prod.productKey !== productKey)
	const data = [
		...otherProducts,
		{
			productKey,
			consumptionDate,
		},
	]
	ls.setItem('selectedProductsConsumptionDate', data)
}

const selectedProductAmountToLocalStorage = (productKey, amount) => (dispatch, getState) => {
	const productsWithSelectedData = selectProductsAmount(getState())
	const otherProducts = productsWithSelectedData.filter((prod) => prod.productKey !== productKey)
	const data = [
		...otherProducts,
		{
			productKey,
			amount,
		},
	]
	ls.setItem('selectedProductsAmount', data)
}

const productsAvailabilityDataToLocalStorage = () => (dispatch, getState) => {
	const products = selectProducts(getState())
	const productsWithAvailability = products.filter((product) => !!product.availability)
	if (productsWithAvailability?.length === 0) return

	const data = productsWithAvailability.map(
		({ availability, productKey }) => availability.length > 0 && ({ availability, productKey }),
	)
	ls.setItem('productsAvailability', data)
}

const updateSelectedProductKeys = (data) => (dispatch) => {
	const payload = {
		...(data.length ? { productKeys: data } : { productKey: data }),
	}
	dispatch(selectedProductKeysToLocalStorage(payload))
	dispatch({
		type: ProductsActionTypes.UPDATE_SELECTED_PRODUCT_KEYS,
		payload,
	})
}

const updateSelectedProductsAdditionalData = (productKey, productData) => (dispatch) => {
	dispatch(selectedProductAdditionalDataToLocalStorage(productKey, productData))
	dispatch({
		type: ProductsActionTypes.UPDATE_SELECTED_PRODUCT_ADDITIONAL_DATA,
		payload: { productKey, productData },
	})
}

const updateSelectedProductConsumptionDate = (productKey, date) => (dispatch) => {
	dispatch(selectedProductConsumptionToLocalStorage(productKey, date))
	dispatch({
		type: ProductsActionTypes.UPDATE_SELECTED_PRODUCT_CONSUMPTION_DATE,
		payload: { productKey, date },
	})
}

const updateSelectedProductAmount = (productKey, amount) => (dispatch) => {
	dispatch(selectedProductAmountToLocalStorage(productKey, amount))
	dispatch({
		type: ProductsActionTypes.UPDATE_SELECTED_PRODUCT_AMOUNT,
		payload: { productKey, amount },
	})
}

const checkProductsForAvailability = () => async (dispatch, getState) => {
	const products = selectProducts(getState())
	const { id: reservationId } = selectReservation(getState())

	const productsWithAvailability = products.filter(({ requiresAvailability }) => !!requiresAvailability)

	await Promise.all(productsWithAvailability.map(async (prod) => {
		const { productKey } = prod

		let data

		if (prod.productType) {
			data = await getProductAvailabilityByType(prod.serviceId, reservationId, prod.productType)
		} else {
			data = await getProductAvailability(prod.productId, reservationId)
		}

		if (!data.error) {
			const isFreeUpgrade = productKey === propertyUnitFreeUpgradeKey
			const isPaidUpgrade = productKey === propertyUnitPaidUpgradeKey
			// There is no differentiation between paid and free upgrade when fetching availability by type
			if (isFreeUpgrade || isPaidUpgrade) {
				data = data.filter(
					(property) => (isPaidUpgrade && property.priceGrossPerNight > 0)
						|| (isFreeUpgrade && property.priceGrossPerNight === 0),
				)
			}

			products[products.findIndex((findProd) => findProd.productKey === productKey)] = {
				...prod,
				availability: data,
			}
		}
	}))

	dispatch({
		type: ProductsActionTypes.UPDATE_PRODUCTS,
		payload: { products: [...products] },
	})
	dispatch(productsAvailabilityDataToLocalStorage())
}

const checkUpgradeAvailability = () => async (dispatch, getState) => {
	const products = selectProducts(getState())
	const { id: reservationId } = selectReservation(getState())

	const upgradeProducts = products.filter(({ productType }) => productType === 'property-unit-category-upgrade')
	const data = await getProductAvailabilityByType(upgradeProducts[0].serviceId, reservationId, 'property-unit-category-upgrade')
	await Promise.all(upgradeProducts.map(async (prod) => {
		const { productKey } = prod

		if (!data.error) {
			const availableAvailabilities = data.filter((availability) => (
				availability.isAvailable && availability.product.key === productKey
			))

			products[products.findIndex((findProd) => findProd.productKey === productKey)] = {
				...prod,
				propertyFreeUpgradeUnitCategoryId: availableAvailabilities[0]?.propertyUnitCategoryId,
				availability: availableAvailabilities,
			}
		}
	}))

	dispatch({
		type: ProductsActionTypes.UPDATE_PRODUCTS,
		payload: { products: [...products] },
	})
}

export {
	updateSelectedProductKeys,
	updateSelectedProductsAdditionalData,
	updateSelectedProductConsumptionDate,
	updateSelectedProductAmount,
	checkProductsForAvailability,
	checkUpgradeAvailability,
}
