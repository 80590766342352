import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { EmailErrorCode } from './error.actions'
import { ErrorState } from './error.reducer'

export function selectErrorState(state: RootState) : ErrorState {
	return state.error
}

export const selectEmailError = createSelector(selectErrorState, (error) => {
	return error.email
})

export const selectEmailErrorCode = createSelector(selectEmailError, (emailError) => {
	return emailError.errorCode
})

export const selectHasEmailVerificationCodeError = createSelector(selectEmailError, (emailError) => {
	return (
		emailError.errorCode === EmailErrorCode.WRONG_VERIFICATION_CODE
		|| emailError.errorCode === EmailErrorCode.TOO_MANY_VERIFICATION_ATTEMPTS
		|| emailError.errorCode === EmailErrorCode.VERIFICATION_CODE_EXPIRED
	)
})
