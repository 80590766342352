import React from 'react'
import styled, { css } from 'styled-components'

import { default as Label } from './field-label.component'
import HelperText from './field-helper-text.component'

import { COLORS } from '../../globals/global.styles'

const Wrapper = styled.div``

const IsFocusedStyles = css`
	border: 1px solid ${COLORS.border.highlight};
`

const AlertModeStyles = css`
	border: 1px solid ${COLORS.border.alert};
`

const InputContainer = styled.div`
	width: 100%;
	height: 40px;
	padding: 8px 16px;

	border: 1px solid ${COLORS.border.secondary};
	border-radius: 0;
	background: white;

	${({ isFocused }) => isFocused && IsFocusedStyles}
	${({ alertModeOn }) => alertModeOn && AlertModeStyles}
`

const WithLabel = ({
	label, alertModeOn, isFocused, children,
}) => (label ? (
	<Label alertModeOn={alertModeOn} isFocused={isFocused}>
		{label}
		{children}
	</Label>
) : (
	children
))

const SecureFieldsInput = ({
	secureFieldsInputId,
	label,
	helperText,
	isValid,
	isFocused,
	exclamationMark,
	className,
}) => {
	const alertModeOn = isValid === false

	return (
		<Wrapper
			className={className}
		>
			<WithLabel label={label} alertModeOn={alertModeOn} isFocused={isFocused}>
				<InputContainer
					id={secureFieldsInputId}
					alertModeOn={alertModeOn}
					isFocused={isFocused}
				>
					{/**
					 * SecureFields puts the iframe with the input here
					 */}
				</InputContainer>
			</WithLabel>
			{!!helperText && (
				<HelperText
					alertModeOn={alertModeOn}
					isFocused={isFocused}
					text={helperText}
					exclamationMark={exclamationMark}
				/>
			)}
		</Wrapper>
	)
}

export default SecureFieldsInput
