import React, { FC, useContext } from 'react'
import styled from 'styled-components'

import { Datepicker, SelectCountry } from './ui'
import { ContentMainHeading } from './view-structure.component'

import { TranslationContext } from 'Src/context'
import SelectItalianProvinces from 'Components/ui/select-italian-communes.component'
import { ValueType } from 'react-select/src/types'
import { SelectOption } from 'Components/ui/async-select.component'

const DatepickerStyled = styled(Datepicker)`
	margin-bottom: 16px;
`

const SelectCountryStyled = styled(SelectCountry)`
	margin-bottom: 16px;
`

const SelectItalianProvincesStyled = styled(SelectItalianProvinces)`
	margin-bottom: 16px;
`

interface Props {
	heading: string;
	birthDate: string;
	birthCountryCode: string;
	birthProvince: string;
	formErrors: string[];
	updateField: (field: string, value: any) => void;
}

const BirthlpaceAndDate: FC<Props> = ({
	heading,
	birthDate,
	birthCountryCode,
	birthProvince,
	updateField,
	formErrors,
}) => {
	const translation = useContext(TranslationContext)

	const handleProvinceChange = (province: ValueType<SelectOption, false>): void => {
		updateField('birthProvince', province?.value)
	}

	return (
		<>
			<ContentMainHeading>{heading}</ContentMainHeading>

			<DatepickerStyled
				label={`${translation.t('Date of birth')}*`}
				value={birthDate}
				handleChange={(value: string) => updateField('birthDate', value)}
				isValid={!formErrors?.includes('birthDate')}
			/>

			<SelectCountryStyled
				label={`${translation.t('Country of birth')}*`}
				placeholder={translation.t('Select country')}
				name="birthCountryCode"
				value={birthCountryCode}
				onChange={({ value }: { value: string }) => updateField('birthCountryCode', value)}
				isValid={!formErrors?.includes('birthCountryCode')}
			/>

			{birthCountryCode === 'IT' && (
				<SelectItalianProvincesStyled
					label={`${translation.t('Comune of birth')}*`}
					placeholder={translation.t('Find a comune')}
					name="birthProvince"
					value={birthProvince}
					onChange={handleProvinceChange}
					isValid={!formErrors?.includes('birthProvince')}
				/>
			)}

		</>
	)
}

export default BirthlpaceAndDate
