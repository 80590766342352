class LS {
	init(flow, reservationSlug) {
		this.localStorageKey = `bob-w-${flow}-${reservationSlug}`
	}

	getAllData() {
		let localStorageData

		try {
			localStorageData = JSON.parse(localStorage.getItem(this.localStorageKey))
		} catch (error) {
			localStorageData = null
		}

		return localStorageData
	}

	removeAllData() {
		localStorage.removeItem(this.localStorageKey)
	}

	getItem(key) {
		return (this.getAllData() || {})[key]
	}

	setItem(key, value) {
		localStorage.setItem(
			this.localStorageKey,
			JSON.stringify({
				...this.getAllData(),
				[key]: value,
			}),
		)
	}
}

export default new LS()
