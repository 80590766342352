import styled, { css } from 'styled-components'

export const Input = styled.input<{ hasError: boolean }>`
  width: 56px;
  height: 64px;
  border: 1px solid #B99E37;
  border-radius: 16px;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  background-color: #EDEDED;
  outline: none;
  transition:
    border-color 0.15s ease-out,
    background-color 0.15s ease-out;

  &:focus {
    border-color: #B99E37;
    background-color: white;
  }

  &:disabled {
    border-color: #EDEDED;
    cursor: not-allowed;
    opacity: 1; // iOS Safari fix
  }

  &:hover:not(:focus):not(:disabled) {
    background-color: #B7B7B7;
    border-color: #B7B7B7;
  }

  ${({ hasError }) => hasError && css`
    &,
    &:disabled,
    &:focus,
    &:hover {
      border-color: #D42F2F;
    }
  `}
`
