import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'Globals/global.styles'
import logger from './logger/logger'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { differenceInYears } from 'date-fns'
import { isEqual, kebabCase, minBy } from 'lodash'

import config, { PropertyKey, propertyKeysMap } from 'Globals/constants'

const {
	COUNTRIES,
	EU_COUNTRIES,
	SPAIN_REGULATION_ADDITIONAL_COUNTRIES,
	DENMARK_REGULATION_ADDITIONAL_COUNTRIES,
	COMMONWEALTH_COUNTRIES,
	PRODUCT_CHARGE_METHODS,
	DOCUMENT_NOT_NEEDED_COUNTRIES,
} = config

const getPropertyTimeZonedDate = (isoUtcDate, propertyTimeZone, pattern) => format(
	utcToZonedTime(isoUtcDate, propertyTimeZone),
	pattern || 'dd MMM yyyy',
	{
		timeZone: propertyTimeZone,
	},
)

const getDateUTCFromLocal = (dateUTC, timeLocal, timeZone) => {
	const dateLocalIsoPart = format(new Date(dateUTC), 'yyyy-MM-dd')
	return zonedTimeToUtc(`${dateLocalIsoPart} ${timeLocal}`, timeZone)
}

const getPropertyAddress = (addressLine, city, zipCode) => (
	`${addressLine}${city ? `, ${city}` : ''}, ${zipCode}`
)

const InfoLabel = styled.div`
	color: ${COLORS.text.hightlightBright};
	font-size: 12px;
	font-weight: 500;
`

const PriceLabel = styled.div`
	color: ${COLORS.text.hightlightBright};
	font-size: 14px;
`

const combineArrivalTimeOptions = (
	superEarlyArrivalTimes,
	regularArrivalTimes,
	timeZone,
	translation,
	currencyIcon,
) => [
	superEarlyArrivalTimes?.length && {
		label: <InfoLabel>{`${translation.t('EARLY CHECK-IN')}:`}</InfoLabel>,
		options: superEarlyArrivalTimes.map((time) => (
			{
				value: time,
				label: (
					<>
						<div>
							{format(utcToZonedTime(new Date(time.startDate), timeZone), 'HH:mm')}
						</div>
						<PriceLabel>{time?.price > 0 ? `${currencyIcon}${time?.price}` : translation.t('Free')}</PriceLabel>
					</>

				),
			}
		)),
	},
	{
		label: <InfoLabel>{`${translation.t('REGULAR CHECK-IN')}:`}</InfoLabel>,
		options: regularArrivalTimes.map((time, index) => (
			{
				value: time,
				label: (
					<>
						<div>
							{
								index === regularArrivalTimes?.length - 1
									? `${format(utcToZonedTime(new Date(time.startDate), timeZone), 'HH:mm')} or later`
									: format(utcToZonedTime(new Date(time.startDate), timeZone), 'HH:mm')
							}
						</div>
						<PriceLabel>{translation.t('Free')}</PriceLabel>
					</>
				),
			}
		)),
	},
].filter((v) => v)

const combineDepartureTimeOptions = (
	regularDepartureTimes,
	superLateCheckOut,
	timeZone,
	translation,
	currencyIcon,
) => [
	{
		label: <InfoLabel>{`${translation.t('REGULAR CHECK-OUT')}:`}</InfoLabel>,
		options: regularDepartureTimes.map((time, index) => ({
			value: time,
			label: (
				<>
					<div>
						{
							index === 0
								? `${format(utcToZonedTime(new Date(time.endDate), timeZone), 'HH:mm')} or earlier`
								: format(utcToZonedTime(new Date(time.endDate), timeZone), 'HH:mm')
						}
					</div>
					<PriceLabel>{translation.t('Free')}</PriceLabel>
				</>
			),
		})),
	},
	superLateCheckOut?.length && {
		label: <InfoLabel>{`${translation.t('LATE CHECK-OUT')}:`}</InfoLabel>,
		options: superLateCheckOut.map((time) => ({
			value: time,
			label: (
				<>
					<div>
						{format(utcToZonedTime(new Date(time.endDate), timeZone), 'HH:mm')}
					</div>
					<PriceLabel>{time?.price > 0 ? `${currencyIcon}${time?.price}` : translation.t('Free')}</PriceLabel>
				</>
			),
		})),
	},
].filter((v) => v)

const areDocumentDetailsRequired = (
	propertyCountryCode,
	nationalityCode,
	residenceCountryCode,
) => {
	if (!nationalityCode) return false

	if (
		propertyCountryCode === 'ES'
		|| propertyCountryCode === 'IT'
		|| propertyCountryCode === 'DE'
		|| propertyCountryCode === 'NL'
		|| propertyCountryCode === 'GR'
		|| propertyCountryCode === 'DK') {
		return true
	}

	const country = COUNTRIES.find(({ code }) => code === nationalityCode)

	if (!country) {
		return true
	}

	if (propertyCountryCode === 'EE') {
		return !DOCUMENT_NOT_NEEDED_COUNTRIES.EE.find((item) => item.code === country.code)
	}

	if (propertyCountryCode === 'FI') {
		return residenceCountryCode !== 'FI' && !DOCUMENT_NOT_NEEDED_COUNTRIES.FI.find((item) => item.code === country.code)
	}

	if (propertyCountryCode === 'GB') {
		return !DOCUMENT_NOT_NEEDED_COUNTRIES.GB.find((item) => item.code === country.code)
	}
}

const areAdditionalGuestResidencyFieldsRequired = (propertyCountryCode) => (
	['FI', 'IT'].includes(propertyCountryCode)
)

const isVisitedCountryRequired = (propertyCountryCode, residenceCountryCode) => (
	propertyCountryCode === 'FI' && residenceCountryCode !== 'FI'
)

const isGenderRequired = (propertyCountryCode) => (
	['ES', 'IT'].includes(propertyCountryCode)
)

const areAdditionalDocumentFieldsRequired = (propertyCountryCode) => (
	propertyCountryCode === 'IT'
)

const isBirthplaceAndDateRequired = (propertyCountryCode) => (
	propertyCountryCode === 'IT'
)

const isSignatureRequired = (propertyCountryCode) => (
	['ES', 'FI', 'IT', 'NL', 'DE', 'DK'].includes(propertyCountryCode)
)

const isAddressRequired = (propertyCountryCode) => (
	propertyCountryCode !== 'IT' && propertyCountryCode !== 'GR'
)

const isGuestResidencyProvinceRequired = (guestResidencyCountryCode) => (
	guestResidencyCountryCode === 'ES'
)

const isNextDestinationRequired = (propertyCountryCode, nationalityCode) => (
	propertyCountryCode === 'GB' && !['IE', ...COMMONWEALTH_COUNTRIES].includes(nationalityCode)
)

const isFireSafertyRequired = (propertyKey) => {
	return propertyKeysMap[propertyKey] === PropertyKey.OsloGro16
}

const getDocumentTypes = (propertyCountryCode, nationalityCode, translation) => {
	switch (propertyCountryCode) {
		case 'ES':
			if (nationalityCode === 'ES') {
				return [
					{ value: 'identity-card', label: translation.t('DNI') },
					{ value: 'drivers-license', label: translation.t('Driving License') },
					{ value: 'passport', label: translation.t('Passport') },
				]
			}
			if ([...EU_COUNTRIES, ...SPAIN_REGULATION_ADDITIONAL_COUNTRIES].includes(nationalityCode)) {
				return [
					{ value: 'passport', label: translation.t('Passport') },
					{ value: 'identity-card', label: translation.t('ID card') },
				]
			}
			return [{ value: 'passport', label: translation.t('Passport') }]

		case 'GB':
			if (!['IE', ...COMMONWEALTH_COUNTRIES].includes(nationalityCode)) {
				return [
					{ value: 'passport', label: translation.t('Passport') },
					{ value: 'identity-card', label: translation.t('ID card') },
				]
			}
			return []

		case 'IT':
			if (EU_COUNTRIES.includes(nationalityCode)) {
				return [
					{ value: 'drivers-license', label: translation.t('Driving License') },
					{ value: 'passport', label: translation.t('Passport') },
					{ value: 'identity-card', label: translation.t('ID card') },
				]
			} else {
				return [
					{ value: 'passport', label: translation.t('Passport') },
				]
			}

		case 'DK':
			if (EU_COUNTRIES.concat(DENMARK_REGULATION_ADDITIONAL_COUNTRIES).includes(nationalityCode)) {
				return [
					{ value: 'passport', label: translation.t('Passport') },
					{ value: 'identity-card', label: translation.t('ID card') },
				]
			} else {
				return [
					{ value: 'passport', label: translation.t('Passport') },
				]
			}
		default:
			return [
				{ value: 'passport', label: translation.t('Passport') },
				{ value: 'identity-card', label: translation.t('ID card') },
			]
	}
}

const getCurrencyIcon = (currencyCode) => {
	switch (currencyCode) {
		case 'GBP':
			return '£'
		case 'EUR':
			return '€'
		default:
			return currencyCode
	}
}

const getPriceBreakdownString = (
	{
		chargeMethod,
		price,
		guestCount,
		duration,
		isVariableNumber = false,
		currency,
		translation,
		guestPostfix = '',
	},
) => {
	let priceBreakdown = `${currency}${price.toFixed(2)}`

	if (chargeMethod === PRODUCT_CHARGE_METHODS.once) {
		if (isVariableNumber) {
			priceBreakdown += translation.t(' x {guestCount} {guestCount, plural, =1{person} other{people}}', { guestCount })

			if (guestPostfix) {
				priceBreakdown += `${guestPostfix}`
			}
			return priceBreakdown
		}
		return null
	}

	if (chargeMethod === PRODUCT_CHARGE_METHODS.perPersonPerTimeUnit) {
		priceBreakdown += translation.t(
			' x {guestCount} {guestCount, plural, =1{guest} other{guests}}', {
				guestCount,
			},
		)

		if (guestPostfix) {
			priceBreakdown += `${guestPostfix}`
		}
	}
	priceBreakdown += translation.t(
		' x {duration} {duration, plural, =1{night} other{nights}}', {
			duration,
		},
	)

	return priceBreakdown
}

const getProductPriceBreakdownString = (
	product,
	guestCount,
	durationOfStay,
	isVariableNumberOfProducts = false,
	translation,
	currency = '€',
) => {
	const {
		price,
		priceOnlyDisplay,
		chargeMethod,
	} = product

	const breakdownPrice = price || priceOnlyDisplay || 0

	const breakdownParams = {
		chargeMethod,
		price: breakdownPrice,
		guestCount,
		duration: durationOfStay,
		isVariableNumber: isVariableNumberOfProducts,
		currency,
		translation,
	}

	return getPriceBreakdownString(breakdownParams)
}

const getAdultChildrenCount = (customers, adultAge) => {
	return customers.reduce(
		([adultCount, childrenCount], customer) => {
			// If birthDate is null then differenceInYears produces NaN.
			// When NaN is one of the operands of any relational comparison, the result is always false.
			// So we consider the guest as an adult.

			const yearDifference = differenceInYears(new Date(), new Date(customer.birthDate))

			if (yearDifference < adultAge) {
				return [adultCount, childrenCount + 1]
			}

			return [adultCount + 1, childrenCount]
		}, [0, 0],
	)
}

const getSinglePriceTotal = (chargeMethod, price, guestCount, duration, productNumber = 1) => {
	if (!price) return 0

	switch (chargeMethod) {
		case PRODUCT_CHARGE_METHODS.perPersonPerTimeUnit:
			return price * guestCount * duration
		case PRODUCT_CHARGE_METHODS.perTimeUnit:
			return price * duration
		case PRODUCT_CHARGE_METHODS.once:
			return price * productNumber
		default:
			return price * guestCount * duration
	}
}

const getSingleProductPriceTotal = (product, guestCount, durationOfStay, productNumber = 1) => {
	const {
		price,
		priceOnlyDisplay,
		chargeMethod,
	} = product

	const productPrice = price || priceOnlyDisplay

	return getSinglePriceTotal(chargeMethod, productPrice, guestCount, durationOfStay, productNumber)
}

const getChangedPropsOfObject = (previous, current) => {
	const changedProps = {}

	if (previous) {
		Object.keys(current).forEach((key) => {
			if (key in previous && key in current && !isEqual(previous[key], current[key])) {
				changedProps[key] = current[key]
			}
		})
	} else if (current && !Object.values(current).some((x) => !!x)) {
		return null
	} else {
		Object.assign(changedProps, current)
	}

	if (!Object.keys(changedProps).length) {
		return null
	}

	return changedProps
}

const trackPageView = (flow, step) => {
	if (window.gtag === undefined) {
		return
	}

	const page = `${kebabCase(flow)}/${step}`

	window.gtag('event', 'page_view', {
		page_title: page,
		page_location: page,
		page_path: page,
	})
}

const appendScriptToDOM = (scriptUrl, callback) => {
	if (document.querySelector(`script[src="${scriptUrl}"]`)) {
		callback()
		return
	}

	const script = document.createElement('script')
	script.src = scriptUrl
	script.onload = () => callback()
	document.body.appendChild(script)
}

export function getImageUrlBySequence(images = []) {
	if (!images.length) {
		logger.warn('Empty mews url')
		return ''
	}

	const [firstElement] = images

	// safety check just in case
	if (typeof firstElement === 'string') return firstElement

	const firstImage = minBy(images, (image) => {
		return image.sequenceNumber
	})?.url

	if (firstImage === undefined) {
		return ''
	}

	return firstImage
}
// parses mews image url and sets Height and Width query params
const mewsImage = (images = [], width, height) => {
	const image = getImageUrlBySequence(images)

	if (!URL) {
		logger.warn('URL api is not supported')
		return ''
	}

	const [newUrl] = image?.split('?')

	return `${newUrl}?Mode=Fit&Width=${width * 2}&Height=${height * 2}`
}

const getLastUnmaskedDigits = (numberMasked, returnNumberLength = 4) => {
	if (!numberMasked) return ''

	if (numberMasked.includes('*')) {
		return numberMasked.split('*').slice(-1)
	}

	if (numberMasked.includes('x')) {
		return numberMasked.split('x').slice(-1)
	}

	return numberMasked.substr(numberMasked.length - returnNumberLength)
}

export {
	getPropertyTimeZonedDate,
	getDateUTCFromLocal,
	getPropertyAddress,
	combineArrivalTimeOptions,
	combineDepartureTimeOptions,
	areDocumentDetailsRequired,
	areAdditionalDocumentFieldsRequired,
	areAdditionalGuestResidencyFieldsRequired,
	isVisitedCountryRequired,
	isGenderRequired,
	isBirthplaceAndDateRequired,
	isAddressRequired,
	isSignatureRequired,
	isNextDestinationRequired,
	isFireSafertyRequired,
	getDocumentTypes,
	getCurrencyIcon,
	getPriceBreakdownString,
	getProductPriceBreakdownString,
	getSingleProductPriceTotal,
	getSinglePriceTotal,
	getChangedPropsOfObject,
	trackPageView,
	appendScriptToDOM,
	mewsImage,
	getAdultChildrenCount,
	getLastUnmaskedDigits,
	isGuestResidencyProvinceRequired,
}
