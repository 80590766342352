import React, {
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { useSelector } from 'react-redux'
import { selectHasEmailVerificationCodeError } from 'Src/redux/error/error.selectors'
import { Container } from './CodeInput.styled'
import CodeInputItem from './CodeInputItem'

interface Props {
	readonly codeLength: number;
	readonly onSubmit: (code: string, clearCodeCallback: () => void) => void;
}

const CodeInput : FC<Props> = ({ codeLength, onSubmit }) => {
	const [isDirty, setIsDirty] = useState(false)
	const [code, setCode] = useState(Array(codeLength).fill(''))
	const [activeIndex, setActiveIndex] = useState(0)
	const hasCodeError = useSelector(selectHasEmailVerificationCodeError)

	const hasError = useMemo(() => {
		return hasCodeError && !isDirty
	}, [hasCodeError, isDirty])

	const updateSymbol = useCallback((symbol: string, index: number) => {
		setIsDirty(true)

		setCode((currentCode) => {
			const newCode = [...currentCode]

			newCode[index] = symbol

			return newCode
		})
	}, [code])

	const clearCode = useCallback(() => {
		setCode(() => {
			return Array(codeLength).fill('')
		})

		setActiveIndex(0)
	}, [codeLength])

	const increaseActiveIndex = useCallback((index: number) => {
		if (index < codeLength - 1) {
			setActiveIndex(index + 1)
		}
	}, [codeLength])

	const decreaseActiveIndex = useCallback((index: number) => {
		if (index > 0) {
			setActiveIndex(index - 1)
		}
	}, [])

	const handleSymbolChange = useCallback((value: string, index: number) => {
		if (value === '') {
			updateSymbol(value, index)
			decreaseActiveIndex(index)

			// Clean previous input also
			if (index > 0) {
				updateSymbol(value, index - 1)
			}
		} else {
			updateSymbol(value, index)
			increaseActiveIndex(index)
		}
	}, [code])

	const handlePaste = useCallback((value: string) => {
		const potentialCode = value.replace(/\D/g, '').slice(0, codeLength)

		if (potentialCode.length === codeLength) {
			setCode(potentialCode.split(''))
			setActiveIndex(codeLength - 1)
		}
	}, [])

	useEffect(() => {
		const isFilled = code.every((symbol) => symbol !== '')

		if (isFilled) {
			const codeString = code.join('')

			onSubmit(codeString, clearCode)
			setIsDirty(false)
		}
	}, [code])

	return (
		<Container>
			{ Array.from({ length: codeLength }, (_, index) => (
				<CodeInputItem
					key={index}
					index={index}
					activeIndex={activeIndex}
					value={code[index]}
					onChange={handleSymbolChange}
					onPaste={handlePaste}
					hasError={hasError}
				/>
			)) }
		</Container>
	)
}

export default CodeInput
