export enum CreditCardFormat {
    Physical = 'physical',
    Virtual = 'virtual',
    Unknown = 'unknown'
}

export enum CreditCardState {
    Enabled = 'enabled',
    Unknown = 'unknown'
}

export enum CreditCardType {
    Visa = 'visa',
    MasterCard = 'mastercard',
    Amex = 'amex',
    Diners = 'diners',
    Discover = 'discover',
    Jcb = 'jcb',
    UnionPay = 'unionpay',
    Unknown = 'unknown'
}

export const enum CardAuthorizationState {
    Authorized = 'authorized',
    Confirmed = 'confirmed',
    Canceled = 'canceled',
    Declined = 'declined',
    Pending = 'pending',
    Requested = 'requested',
    Authorizable = 'authorizable',
    Unauthorizable = 'unauthorizable',
    Unknown = 'unknown'
}

export interface CreditCardModel {
    readonly createdAt: string;
    readonly id: string;
    readonly format: CreditCardFormat;
    readonly numberMasked: string;
    readonly cardHolderName: string;
    readonly expirationDate: string;
    readonly state: CreditCardState;
    readonly type: CreditCardType;
    readonly authorizationState: CardAuthorizationState;
    readonly transactionId: string;
}
