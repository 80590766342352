import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { CheckOutlined } from '@ant-design/icons'

import {
	COLORS,
	FONT_WEIGHTS,
	generateMediaQuery,
} from 'Globals/global.styles'
import { Button } from '../../../components/ui'

import { TranslationContext } from 'Src/context'

import IconGiftFromBob from 'Assets/icons/gift-from-bob-icon.svg'
import { getCurrencyIcon } from 'Src/utils'
import { useSelector } from 'react-redux'
import { selectReservation } from 'Src/redux/reservation/reservation.selectors'

const baseEmptyAnimatedBlock = css`
	@keyframes shine-lines {
		0% {
			background-position: 0px;
		}
		40%, 100% {
			background-position: 368px;
		}
	}
	${({ isLoading }) => isLoading && `
		background-color: ${COLORS.background.secondaryHover};
		background-image: linear-gradient(90deg,#f1f1f1 0px,#f7f7f7 40px,#f1f1f1 80px);
		animation: shine-lines 2.6s infinite linear;
	`}
`

const ApartmentCardWrapper = styled.div`
	position: relative;
	cursor: pointer;
	width: 100%;
	background: ${COLORS.background.primary};

	${({ isSelected }) => isSelected && css`
		outline: 2px solid ${COLORS.misc.green};
	`}
`

const ApartmentDetails = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: ${COLORS.background.secondary};
	padding: 20px 20px 20px 20px;

	${generateMediaQuery.mobileSmall(`
		padding: 16px 12px 12px 12px;
	`)}

	${baseEmptyAnimatedBlock}
`

const ApartmentTitleWrapper = styled.div`
	display: flex;
	align-items: center;

	${({ isLoading }) => isLoading && `
		height: 25px;
		background-color: white;
	`}
`

const ApartmentTitle = styled.div`
	font-size: 20px;
	line-height: 1em;
	font-weight: ${FONT_WEIGHTS.normal};

	${({ isLoading }) => isLoading && `
		background-color: white;
	`}
`

const FreeUpgradeIcon = styled.span`
	width: 100px;
	text-align: center;
	font-size: 16px;
	font-weight: ${FONT_WEIGHTS.bold};
	line-height: 1.5em;

	color: ${COLORS.misc.green};
`

const ApartmentDescription = styled.div`
	margin-top: 8px;
	margin-bottom: 32px;
	font-size: 14px;
	color: ${COLORS.text.secondary};
`

const ButtonStyled = styled(Button)`
	height: 34px;
	min-width: 95px;
	line-height: 2.3em;

	${({ isDisabled }) => isDisabled && css`
		opacity: 0.5;
	`}
`

const IconSelected = styled.div`
	position: absolute;
	right: 14px;
	top: 14px;
	border-radius: 100%;
	background-color: ${COLORS.misc.green};
	padding: 4px;

	> span {
		display: block;

		svg,
		svg > path {
			font-size: 13px;
			color: ${COLORS.background.secondary};
		}
	}
`

const PriceAndButton = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
`

const PriceTag = styled.div`
	font-size: 14px;
	font-weight: ${FONT_WEIGHTS.normal};
`

const PriceWrapper = styled.div`
	display: flex;
	flex-direction: column;

	${({ isLoading }) => isLoading && `
		height: 20px;
		width: 100px;
		background-color: white;
	`}
`

const PriceExplanation = styled.div`
	font-size: 12px;
`

const ImageContainer = styled.div`
	height: 220px;
	width: 100%;

	${({ image }) => image && `background-image: url(${image});`}
	background-position: center center;
	background-size: cover;

	${baseEmptyAnimatedBlock}
`

const EmptyImage = styled.div`
	background-color: white;
	width: calc(100% - 40px);
	height: calc(100% - 20px);
	top: 20px;
	left: 20px;
	position: relative;
`

const getButtonText = (isSelected, isDisabled, addButtonText) => {
	const translation = useContext(TranslationContext)

	if (isDisabled) return translation.t('Unavailable')
	if (isSelected) return translation.t('Remove')
	return addButtonText || translation.t('Add')
}

export const RoomCardEmpty = () => (
	<ApartmentCardWrapper>
		<ImageContainer isLoading>
			<EmptyImage />
		</ImageContainer>

		<ApartmentDetails isLoading>
			<div>
				<ApartmentTitleWrapper isLoading>
					<ApartmentTitle isLoading />
				</ApartmentTitleWrapper>
				<ApartmentDescription />
			</div>
			<PriceAndButton>
				<ButtonStyled variant="empty" />
				<PriceWrapper isLoading />
			</PriceAndButton>
		</ApartmentDetails>
	</ApartmentCardWrapper>
)

const ApartmentCard = ({
	title,
	descriptionShort,
	addButtonText,
	image,
	showGiftFromBob,
	price,
	onClick,
	isSelected,
	isDisabled,
	className,
}) => {
	const translation = useContext(TranslationContext)
	const reservation = useSelector(selectReservation)
	const currency = getCurrencyIcon(reservation.property.defaultCurrency)

	return (
		<ApartmentCardWrapper
			isSelected={isSelected}
			onClick={onClick}
			className={className}
		>
			{!!image && (
				<ImageContainer image={image}>
					{showGiftFromBob && <img width="72" src={IconGiftFromBob} alt="gift from bob" />}
				</ImageContainer>
			)}

			<ApartmentDetails isSelected={isSelected}>
				<div>
					<ApartmentTitleWrapper>
						<ApartmentTitle>{title}</ApartmentTitle>
					</ApartmentTitleWrapper>
					<ApartmentDescription>{descriptionShort}</ApartmentDescription>
				</div>
				<PriceAndButton>
					<ButtonStyled variant="transparent_with_border" isDisabled={isDisabled}>
						{getButtonText(isSelected, isDisabled, addButtonText)}
					</ButtonStyled>
					<PriceWrapper>
						{price ? (
							<PriceWrapper>
								<PriceTag>{`${currency}${price.toFixed(2)}`}</PriceTag>
								<PriceExplanation>{translation.t('Extra per night')}</PriceExplanation>
							</PriceWrapper>
						) : (
							<FreeUpgradeIcon>Free Upgrade</FreeUpgradeIcon>
						)}
					</PriceWrapper>
				</PriceAndButton>
			</ApartmentDetails>

			{isSelected && (
				<IconSelected title={translation.t('{title} is selected', { title })}>
					<CheckOutlined />
				</IconSelected>
			)}
		</ApartmentCardWrapper>
	)
}

export default ApartmentCard
