import React, { FC, ReactNode } from 'react'
import { CheckboxLabel, CheckboxWrapper, StyledCheckbox } from './Checkbox.styled'
import IconTick from 'Assets/icons/icon-tick16-white.svg'

interface Props {
	readonly name: string;
	readonly value: boolean;
	readonly label: string | ReactNode;
	readonly onChange: () => void
	readonly hasError?: boolean;
}

const Checkbox: FC<Props> = ({
	value,
	name,
	label,
	onChange,
	hasError,
}) => {
	return (
		<CheckboxWrapper>
			<StyledCheckbox
				isChecked={value}
				hasError={hasError}
				aria-checked={value}
				onClick={onChange}
			>

				{ value && <img src={IconTick} alt="" /> }

				<input
					id={name}
					name={name}
					type="checkbox"
					checked={value}
					onChange={onChange}
				/>
			</StyledCheckbox>

			<CheckboxLabel
				htmlFor={name}
				hasError={hasError}
				isChecked={value}
			>
				{label}
			</CheckboxLabel>
		</CheckboxWrapper>
	)
}

export default Checkbox
