/**
 * Temporary hack because Technolife might reset the door-code right after the check-in is completed and
 * the arrival or departure time is changed.
 * See: https://bobw.atlassian.net/browse/DEV-458
 */

import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { ReservationApi } from 'Types/api.types'
import { FLOW_SERVICE_MAP } from 'Types/app-state.types'
import { RootState } from '../store'

import { client } from '../../api/feathers'

import { selectAppState } from '../app/app.selectors'
import { selectReservation } from './reservation.selectors'
import { getReservationSuccess } from './reservation.actions'

import config from 'Globals/constants'
import { PropertyKeyType } from './reservation.constants'

const { PropertyKey } = config

let currentDoorCode: string
let doorCodeFetchRetryCount = 0
const doorCodeFetchRetryMaxCount = 15
const doorCodeFetchRetryInterval = 2000

const refreshDoorCodeWithRetry = () => (
	dispatch: ThunkDispatch<{}, void, Action>,
	getState: () => RootState,
) => {
	setTimeout(() => {
		doorCodeFetchRetryCount++

		const { flow } = selectAppState(getState())
		const { slug: reservationSlug } = selectReservation(getState()) || {}

		client
			.service(FLOW_SERVICE_MAP[flow])
			.get(reservationSlug)
			.then((reservation: ReservationApi) => {
				if (
					reservation.propertyKeys?.[0]?.code !== currentDoorCode
					|| doorCodeFetchRetryCount >= doorCodeFetchRetryMaxCount
				) {
					dispatch(getReservationSuccess(flow, reservation))
					return
				}

				// :todo typescript
				// @ts-ignore
				dispatch(refreshDoorCodeWithRetry())
			})
			.catch(() => {
				if (doorCodeFetchRetryCount < doorCodeFetchRetryMaxCount) {
					// @ts-ignore
					dispatch(refreshDoorCodeWithRetry())
				}
			})
	}, doorCodeFetchRetryInterval)
}

const startRefreshDoorCodeWithRetry = (
	patchData: ReservationApi,
	reservation: ReservationApi,
	dispatch: ThunkDispatch<{}, void, Action>,
	getState: () => RootState,
) => {
	const {
		checkInDone,
		startDate,
		endDate,
	} = patchData
	const completedCheckIn = !!checkInDone
	const changedTimes = !!startDate || !!endDate
	const { property } = selectReservation(getState()) || {}
	const { key: propertyKey } = property || {}
	const doorCode = reservation.propertyKeys?.[0]?.code

	if (
		completedCheckIn
		&& changedTimes
		&& propertyKey === PropertyKey.TELLISKIVI
		&& doorCode
	) {
		currentDoorCode = doorCode
		reservation.propertyKeys = [{
			code: 'Fetching your door code...',
			type: PropertyKeyType.propertyUnitDoor,
			validToDate: '2090-06-02T07:00:00.000Z',
		}]

		// @ts-ignore
		dispatch(refreshDoorCodeWithRetry())
	}

	return reservation
}

export {
	startRefreshDoorCodeWithRetry,
}
