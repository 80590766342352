const BusinessLeadActionTypes = {
	UPDATE_BUSINESS_LEAD_COMPANY_SIZE: 'UPDATE_BUSINESS_LEAD_COMPANY_SIZE',
	UPDATE_BUSINESS_LEAD_NAME: 'UPDATE_BUSINESS_LEAD_NAME',
	UPDATE_BUSINESS_LEAD_CONTACT_EMAIL: 'UPDATE_BUSINESS_LEAD_CONTACT_EMAIL',
	UPDATE_BUSINESS_LEAD_CONTACT_NAME: 'UPDATE_BUSINESS_LEAD_CONTACT_NAME',
	UPDATE_BUSINESS_LEAD_CONTACT_JOB_TITLE: 'UPDATE_BUSINESS_LEAD_CONTACT_JOB_TITLE',
	REMOVE_BUSINESS_LEAD: 'REMOVE_BUSINESS_LEAD',
}

export default BusinessLeadActionTypes
