import { createSelector } from 'reselect'

import { selectReservationState } from '../reservation.selectors'

// TRAVEL REASONS

const selectTravelReasons = createSelector(
	[selectReservationState],
	(reservationState) => reservationState.travelReasons,
)

export {
	selectTravelReasons,
}
