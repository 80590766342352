import { DoorCodeError } from 'Src/models/reservation'
import { BreakfastContentApi } from 'Src/types/api.types'
import { BreakfastContentData } from 'Types/breakfast-content.types'

export function transformDoorCodeError(errorCode: string) : DoorCodeError {
	switch (errorCode) {
		case 'NOT_PAID': {
			return DoorCodeError.NOT_PAID
		}

		case 'NOT_AVAILABLE_YET':
		default: {
			return DoorCodeError.NOT_AVAILABLE_YET
		}
	}
}

export function transformBreakfastPartnerContent(breakfastContentApi: BreakfastContentApi): BreakfastContentData {
	const partnersContent = breakfastContentApi.relatedContent
		.map((relatedContent) => {
			return {
				id: relatedContent.productPartnerContent.id,
				address: relatedContent.productPartnerContent.address,
				description: relatedContent.productPartnerContent.description,
				googleMapsUrl: relatedContent.productPartnerContent.googleMapsUrl,
				imageUrl: relatedContent.productPartnerContent.imageUrl,
				isAvailable: relatedContent.productPartnerContent.isAvailable,
				name: relatedContent.productPartnerContent.name,
				websiteUrl: relatedContent.productPartnerContent.websiteUrl,
			}
		})

	return {
		description: breakfastContentApi.description,
		guidelines: breakfastContentApi.guidelines,
		productType: breakfastContentApi.productType,
		productKey: breakfastContentApi.productKey,
		id: breakfastContentApi.id,
		partnersContent,
	}
}
