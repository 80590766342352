import { differenceInCalendarDays } from 'date-fns'

const getEarlyCheckinProduct = (selectedTime, availableTimes) => availableTimes.checkIn?.find(
	(time) => time.startDate === selectedTime,
)?.productId || availableTimes.superEarlyCheckIn?.find(
	(time) => time.startDate === selectedTime,
)?.productId
const getLateCheckoutProduct = (selectedTime, availableTimes) => availableTimes.checkOut?.find(
	(time) => time.endDate === selectedTime,
)?.productId || availableTimes.superLateCheckOut?.find(
	(time) => time.endDate === selectedTime,
)?.productId

const getTotalStayDuration = (reservation) => {
	const {
		startDate,
		endDate,
	} = reservation

	return differenceInCalendarDays(new Date(endDate), new Date(startDate))
}

const parseArrivalTimeData = (reservation) => {
	const {
		startDate: confirmedArrivalDateUTC,
	} = reservation

	return {
		selectedArrivalTimeUTC: null,
		confirmedArrivalDateUTC,
	}
}

const parseDepartureTimeData = (reservation) => {
	const {
		endDate: confirmedDepartureDateUTC,
	} = reservation

	return {
		selectedDepartureTimeUTC: null,
		confirmedDepartureDateUTC,
	}
}

export {
	getEarlyCheckinProduct,
	getLateCheckoutProduct,
	parseArrivalTimeData,
	parseDepartureTimeData,
	getTotalStayDuration,
}
