import React, { FC, MouseEventHandler, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Transition } from 'react-transition-group'
import { TranslationContext } from 'Src/context'
import { selectMainGuestCustomer } from 'Src/redux/reservation/guests/guests.selectors'
import { selectEmailErrorCode } from 'Src/redux/error/error.selectors'
import { selectIsEmailVerificationDialogOpened } from 'Src/redux/ui/ui.selectors'
import { EmailErrorCode } from 'Src/redux/error/error.actions'
import { hideEmailVerificationDialog } from 'Src/redux/ui/ui.actions'
import { trackPageView } from 'Src/utils'
import ResendAction from './ResendAction'
import CodeContainer from './CodeContainer'

import {
	CodeInputContainer,
	CodeSection,
	Content,
	Dialog,
	Email,
	ErrorMessage,
	Header,
	Overlay,
	SentMessage,
	SubTitle,
	Title,
} from './EmailVerification.styled'

interface Props {
	readonly onSubmit: (code: string, clearCodeCallback: () => void) => void;
	readonly onResend: () => void;
}

const EmailVerificationDialog : FC<Props> = ({ onSubmit, onResend }) => {
	const codeLength = 5
	const [isResending, setIsResending] = useState(false)
	const translation = useContext(TranslationContext)
	const hasEmailVerificationDialog = useSelector(selectIsEmailVerificationDialogOpened)
	const customer = useSelector(selectMainGuestCustomer)
	const dispatch = useDispatch()
	const emailErrorCode = useSelector(selectEmailErrorCode)

	useEffect(() => {
		if (hasEmailVerificationDialog) {
			trackPageView(window.bobw.flow, 'email-verification-dialog')
		}
	}, [hasEmailVerificationDialog])

	const closeDialog = useCallback(() => {
		dispatch(hideEmailVerificationDialog())
	}, [hasEmailVerificationDialog])

	const stopDialogClickPropagation : MouseEventHandler<HTMLDivElement> = useCallback((event) => {
		event.stopPropagation()
	}, [])

	const handleCodeSubmit = useCallback((code: string, clearCodeCallback: () => void) => {
		onSubmit(code, clearCodeCallback)
	}, [])

	const handleResendClick = useCallback(() => {
		window.ga?.('send', 'event', 'Resend button', 'click', 'Email verification dialog')

		// don't show "code has been sent" message for "too many attempts" error
		if (emailErrorCode !== EmailErrorCode.TOO_MANY_VERIFICATION_ATTEMPTS) {
			setIsResending(true)
		}

		onResend()
	}, [emailErrorCode])

	const errorMessage = useMemo(() => {
		switch (emailErrorCode) {
			case EmailErrorCode.WRONG_VERIFICATION_CODE: {
				return translation.t('Wrong code. Please try again.')
			}

			case EmailErrorCode.TOO_MANY_VERIFICATION_ATTEMPTS: {
				return translation.t('Too many attempts. Please try again later.')
			}

			case EmailErrorCode.VERIFICATION_CODE_EXPIRED: {
				return translation.t('Code has expired. Please try a new one.')
			}

			default: {
				return null
			}
		}
	}, [emailErrorCode])

	useEffect(() => {
		if (isResending) {
			const timeout = 3 * 1000
			const timer = setTimeout(() => setIsResending(false), timeout)

			return () => clearTimeout(timer)
		}
	}, [isResending])

	return (
		<Transition
			mountOnEnter
			unmountOnExit
			in={hasEmailVerificationDialog}
			timeout={300}
		>
			{ (state) => (
				<Overlay state={state} onClick={closeDialog}>
					<Dialog state={state} onClick={stopDialogClickPropagation}>
						<Content>
							<Header>
								<Title>
									{ translation.t('Confirm your email') }
								</Title>

								<SubTitle>
									{ translation.t('It helps keep your data safe and sound') }
								</SubTitle>
							</Header>

							<CodeSection>
								<SentMessage>
									{ translation.t('We sent a {codeLength}-digit code to', { codeLength }) }
									{ ' ' }

									<Email data-hj-suppress data-dd-privacy="mask">{ customer.email }</Email>
								</SentMessage>

								<CodeInputContainer>
									<CodeContainer
										codeLength={codeLength}
										onSubmit={handleCodeSubmit}
									/>

									<ErrorMessage>
										{ errorMessage }
									</ErrorMessage>

									<ResendAction
										isResending={isResending}
										onResend={handleResendClick}
									/>
								</CodeInputContainer>
							</CodeSection>
						</Content>
					</Dialog>
				</Overlay>
			) }
		</Transition>
	)
}

export default EmailVerificationDialog
