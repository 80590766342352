import OthersActionTypes from './others.types'

const othersReducer = (state, action) => {
	switch (action.type) {
		// TRAVEL REASONS
		case OthersActionTypes.UPDATE_TRAVEL_REASONS:
			return {
				...state,
				travelReasons: [...action.payload],
			}

		default:
			return state
	}
}

export default othersReducer
