import { createReducer } from '@reduxjs/toolkit'
import { transformEmailErrorCode } from './error.utils'

import {
	clearEmailErrorCode,
	EmailErrorCode,
	setEmailErrorCode,
	updateEmailErrorCode,
} from './error.actions'

interface EmailErrorState {
  errorCode: EmailErrorCode | null;
}

export interface ErrorState {
	email: EmailErrorState;
}

const defaultState : ErrorState = {
	email: {
		errorCode: null,
	},
}

const errorReducer = createReducer(defaultState, (builder) => {
	builder.addCase(setEmailErrorCode, (state, { payload }) => {
		const errorCode = transformEmailErrorCode(payload)

		state.email.errorCode = errorCode
	})

	builder.addCase(updateEmailErrorCode, (state, action) => {
		state.email.errorCode = action.payload
	})

	builder.addCase(clearEmailErrorCode, (state) => {
		state.email.errorCode = null
	})
})

export default errorReducer
