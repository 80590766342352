import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { default as Label } from './field-label.component'
import HelperText from './field-helper-text.component'

import { COLORS } from 'Globals/global.styles'

const Wrapper = styled.div``

const WithLabelStyles = css``

const AlertModeStyles = css`
	border: 1px solid ${COLORS.border.alert};
`

const Input = styled.input`
	width: 100%;
	height: 40px;
	padding: 5px 16px 0 16px;
	border: 1px solid ${COLORS.border.secondary};
	border-radius: 0;
	background: white;
	color: ${COLORS.text.primary};

	&:focus {
		outline: none;
		border: 1px solid ${COLORS.border.highlight};
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	${({ withLabel }) => withLabel && WithLabelStyles}
	${({ alertModeOn }) => alertModeOn && AlertModeStyles}
`

const WithLabel = ({
	label, alertModeOn, isFocused, children,
}) => (label ? (
	<Label alertModeOn={alertModeOn} isFocused={isFocused}>
		{label}
		{children}
	</Label>
) : (
	children
))

const FormInput = ({
	value,
	handleChange,
	label,
	helperText,
	exclamationMark,
	isValid,
	className,
	inputRef,
	disabled,
	...otherProps
}) => {
	const [isFocused, setIsFocused] = useState(false)
	const alertModeOn = isValid === false

	return (
		<Wrapper
			className={className}
			onFocus={() => setIsFocused(true)}
			onBlur={() => setIsFocused(false)}
		>
			<WithLabel label={label} alertModeOn={alertModeOn} isFocused={isFocused}>
				<Input
					value={value || ''}
					onChange={(ev) => handleChange((ev.target.value || null), ev)}
					withLabel={!!label}
					alertModeOn={alertModeOn}
					ref={inputRef}
					disabled={disabled}
					className={isValid ? 'valid-form-input' : 'invalid-form-input'}
					{...otherProps}
				/>
			</WithLabel>
			{!!helperText && (
				<HelperText
					exclamationMark={exclamationMark}
					alertModeOn={alertModeOn}
					isFocused={isFocused}
					text={helperText}
				/>
			)}
		</Wrapper>
	)
}

export default FormInput
