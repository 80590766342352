import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

import { ResetButton } from './ui'

import { COLORS, FONT_WEIGHTS } from '../globals/global.styles'

import { TranslationContext } from 'Src/context'

const Wrapper = styled.div``

const Label = styled.label`
	font-size: 16px;
	font-weight: ${FONT_WEIGHTS.light};
	color: ${COLORS.text.secondary};
`

const ControlsGroup = styled.div``

const Button = styled(ResetButton)`
	${({ isDisabled }) => isDisabled
		&& css`
			cursor: default;

			> span > svg,
			> span > svg > path {
				color: ${COLORS.border.minor};
			}
		`}
`

const ValueContainer = styled.div`
	display: inline-block;
	width: 50px;
	text-align: center;
	font-weight: ${FONT_WEIGHTS.normal};
`

const GuestAmountSelector = ({
	label,
	name,
	value: initialValue,
	minValue,
	maxValue,
	handleChange,
}) => {
	const [value, setValue] = useState(initialValue || 0)

	const setValueAndHandleChange = (value) => {
		setValue(value)
		handleChange(value)
	}

	const onDecrease = (ev) => {
		ev.preventDefault()
		if (value <= minValue) return
		setValueAndHandleChange(Number(value) - 1)
	}

	const onIncrease = (ev) => {
		ev.preventDefault()
		if (value >= maxValue) return
		setValueAndHandleChange(Number(value) + 1)
	}

	const translation = useContext(TranslationContext)

	return (
		<Wrapper>
			{label ? <Label htmlFor={name}>{label}</Label> : null}

			<ControlsGroup>
				<Button
					title={translation.t('Decrease number of guests')}
					type="button"
					isDisabled={value <= minValue}
					onClick={onDecrease}
				>
					<MinusCircleOutlined />
				</Button>
				<ValueContainer>{value}</ValueContainer>
				<Button
					title={translation.t('Increase number of guests')}
					type="button"
					isDisabled={value >= maxValue}
					onClick={onIncrease}
				>
					<PlusCircleOutlined />
				</Button>
			</ControlsGroup>

			<input id={name} hidden readOnly value={value} />
		</Wrapper>
	)
}

export default GuestAmountSelector
