import BusinessLeadActionTypes from './business-lead.types'
import { Dispatch } from 'redux'
import ls from '@/local-storage/local-storage'

const localStorageKey = 'businessLead'

const saveToLocalStorage = (fieldName:string, payload: string) => {
	const savedBusinessLead = ls.getItem(localStorageKey) || {}
	const newBusinessLead = { ...savedBusinessLead, [fieldName]: payload }
	ls.setItem(localStorageKey, newBusinessLead)
}

const removeFromLocalStorage = () => {
	ls.setItem(localStorageKey, null)
}

const updateBusinessLeadCompanyName = (payload: string) => (dispatch: Dispatch) => {
	saveToLocalStorage('companyName', payload)

	dispatch({
		type: BusinessLeadActionTypes.UPDATE_BUSINESS_LEAD_NAME,
		payload,
	})
}

const updateBusinessLeadContactPerson = (payload: string) => (dispatch: Dispatch) => {
	saveToLocalStorage('contactPerson', payload)

	dispatch({
		type: BusinessLeadActionTypes.UPDATE_BUSINESS_LEAD_CONTACT_NAME,
		payload,
	})
}

const updateBusinessLeadContactEmail = (payload: string) => (dispatch: Dispatch) => {
	saveToLocalStorage('contactEmail', payload)

	dispatch({
		type: BusinessLeadActionTypes.UPDATE_BUSINESS_LEAD_CONTACT_EMAIL,
		payload,
	})
}

const updateBusinessLeadContactJobTitle = (payload: string) => (dispatch: Dispatch) => {
	saveToLocalStorage('contactJobTitle', payload)

	dispatch({
		type: BusinessLeadActionTypes.UPDATE_BUSINESS_LEAD_CONTACT_JOB_TITLE,
		payload,
	})
}

const updateBusinessLeadCompanySize = (payload: string) => (dispatch: Dispatch) => {
	saveToLocalStorage('companySizeRange', payload)

	dispatch({
		type: BusinessLeadActionTypes.UPDATE_BUSINESS_LEAD_COMPANY_SIZE,
		payload,
	})
}

const removeBusinessLead = () => (dispatch: Dispatch) => {
	removeFromLocalStorage()

	dispatch({
		type: BusinessLeadActionTypes.REMOVE_BUSINESS_LEAD,
	})
}

export {
	updateBusinessLeadCompanyName,
	updateBusinessLeadContactPerson,
	updateBusinessLeadContactEmail,
	updateBusinessLeadContactJobTitle,
	updateBusinessLeadCompanySize,
	removeBusinessLead,
}
