import { createSelector } from 'reselect'
import { format, utcToZonedTime } from 'date-fns-tz'
import { isEqual } from 'date-fns'

import {
	selectReservationState,
	selectReservation,
} from '../reservation.selectors'

const selectCleaningMidStayState = createSelector(
	[selectReservationState],
	(reservationState) => reservationState.cleaningMidStayState || {},
)

const selectCurrentCleaningDate = createSelector(
	[selectCleaningMidStayState],
	(cleaningMidStayState) => cleaningMidStayState.currentCleaningDateLocal,
)

const selectCurrentCleaningTime = createSelector(
	[selectCleaningMidStayState],
	(cleaningMidStayState) => cleaningMidStayState.currentCleaningTimeUTC,
)

const selectSelectedCleaningDatesTimes = createSelector(
	[selectCleaningMidStayState],
	(cleaningMidStayState) => (cleaningMidStayState.selectedCleaningDatesTimes || [])
		?.sort((a, b) => (new Date(a.startDate) - new Date(b.startDate))),
)

const selectAvailableCleaningDatesTimes = createSelector(
	[
		selectCleaningMidStayState,
		selectSelectedCleaningDatesTimes,
		selectReservation,
	],
	(
		{ availableCleaningTimeslots },
		selectedCleaningDatesTimes,
		{
			property: { timezone: propertyTimeZone },
		},
	) => {
		const availableDatesTimes = {}

		// gettin' from the `/product-availability` response
		availableCleaningTimeslots?.forEach(({ startDate, endDate }) => {
			const dateStringLocal = format(
				utcToZonedTime(startDate, propertyTimeZone),
				'yyyy-MM-dd',
			)

			const isAlreadySelected = !!selectedCleaningDatesTimes
				?.find((dateTime) => isEqual(new Date(dateTime), new Date(startDate)))

			availableDatesTimes[dateStringLocal] = [
				...(availableDatesTimes[dateStringLocal] || []),
				{
					startDate,
					endDate,
					isAvailable: !isAlreadySelected,
				},
			]
		})

		Object.keys(availableDatesTimes).forEach((date) => {
			const isAnyAvailable = availableDatesTimes[date].find((timeslot) => timeslot.isAvailable)
			if (!isAnyAvailable) {
				delete availableDatesTimes[date]
			}
		})

		return availableDatesTimes
	},
)

export {
	selectCleaningMidStayState,
	selectCurrentCleaningDate,
	selectCurrentCleaningTime,
	selectSelectedCleaningDatesTimes,
	selectAvailableCleaningDatesTimes,
}
