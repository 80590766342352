import { isEmpty } from 'lodash'
import ls from '../../../local-storage/local-storage'
import config, { getConfig } from '../../../globals/constants'

const { ACTIVE_PRODUCT_KEYS } = config

const PRODUCT_VISIBLE_STATUSES = [
	'pending',
	'processed',
	'confirmed',
	'delivered',
]

const isImmediatelyPurchasablePaidProduct = ({ price, requiresRequest }) => !!price && !requiresRequest
const isTimeProduct = (productKey) => (productKey?.includes('check-out-late') || productKey?.includes('check-in-early'))

const getServicesActiveProducts = (services) => {
	return services
		?.filter(({ key }) => ['stay', 'custom'].includes(key))
		?.reduce((array, { products }) => array.concat(products), [])
		?.filter((product) => Object.values(ACTIVE_PRODUCT_KEYS).includes(product.key) || isTimeProduct(product.key))
		?? []
}

const parseProductsFromServices = (services, propertyKey) => {
	const activeProducts = getServicesActiveProducts(services)

	return activeProducts?.map((product) => {
		const additionalDetails = getConfig().DISPLAYABLE_PRODUCTS_DETAILS
			.find((prod) => prod.productKey === product.key) || {}

		const { description, skipPayment, subProductKeys } = additionalDetails
		return {
			productId: product.id,
			productKey: product.key,
			productType: product.type,
			serviceId: product.serviceId,
			...(!skipPayment && { price: product.priceGross }),
			...(skipPayment && { priceOnlyDisplay: product.priceGross }),
			chargeMethod: subProductKeys ? 'multiple' : product.chargeMethod,
			...additionalDetails,
			// In case the product doesn't have hardcoded details, fetch what is coming from BE
			// eslint-disable-next-line max-len
			// :todo This should be the default, we should refactor everything to just use the data from BE, never anything hardcoded, this is temporary
			...((!additionalDetails || isEmpty(additionalDetails)) && { title: product.name }),
			...((!additionalDetails || isEmpty(additionalDetails)) && { description: product.description }),
			description: description?.[propertyKey] || description?.default,
			isOrderable: product.isOrderable,
		}
	})
}

const parseOrderedProducts = (orderItems, products) => {
	if (!orderItems?.length) return []

	const orderedProducts = []

	const addProductToOrderedProducts = (
		{
			id,
			productId,
			consumptionDate,
			consumptionEndDate,
		},
		productKey,
		productType,
	) => {
		orderedProducts.push({
			id,
			productId,
			consumptionDate,
			consumptionEndDate,
			productKey,
			productType,
		})
	}

	/**
	 * In case of early-check-in / late-check-out the latest order-item matters. If latest order-item's
	 * status is "cancelled" then it means that any order of this product is currently "cancelled".
	 *
	 * In case of other products, if there's any order-item with status found in PRODUCT_VISIBLE_STATUSES,
	 * the order is active (not cancelled).
	 */
	products.forEach(({
		productId,
		productKey,
		productType,
	}) => {
		const productsFromOrderItems = orderItems
			?.filter((item) => {
				if (isTimeProduct(productKey)) {
					return item.productId === productId
				}

				return (item.productId === productId)
					&& PRODUCT_VISIBLE_STATUSES.includes(item.status)
			})

		if (!productsFromOrderItems.length) return

		if (productKey === ACTIVE_PRODUCT_KEYS.cleaningMidStay) {
			productsFromOrderItems.forEach((product) => addProductToOrderedProducts(product, productKey, productType))
			return
		}

		const [latestProduct] = productsFromOrderItems
			.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))

		if (latestProduct && PRODUCT_VISIBLE_STATUSES.includes(latestProduct.status)) {
			addProductToOrderedProducts(latestProduct, productKey, productType)
		}
	})

	return orderedProducts
}

const getProductsAvailabilityFromLocalStorage = (products) => {
	const availabilityData = ls.getItem('productsAvailability')
	if (!availabilityData) return products

	return products.map(
		(product) => (product?.requiresAvailability ? ({
			...product,
			availability: availabilityData?.find(
				(storedProduct) => storedProduct.productKey === product.productKey,
			)?.availability,
		}) : product),
	)
}

const parseProducts = (parseProductsParams) => {
	const {
		orderItems,
		services,
		propertyKey,
		isBreakfastCampaignActive = false,
	} = parseProductsParams
	const products = parseProductsFromServices(services, propertyKey) || []

	const selectedProductKeys = ls.getItem('selectedProductKeys') || []

	if (isBreakfastCampaignActive && !selectedProductKeys.includes(ACTIVE_PRODUCT_KEYS.breakfastComplimentary)) {
		selectedProductKeys.push(ACTIVE_PRODUCT_KEYS.breakfastComplimentary)
	}

	return {
		orderedProducts: parseOrderedProducts(orderItems, products),
		selectedProductKeys,
		productsAdditionalData: ls.getItem('selectedProductsAdditionalData') || [],
		productsConsumptionDates: ls.getItem('selectedProductsConsumptionDate') || [],
		productsAmount: ls.getItem('selectedProductsAmount') || [],
		products: getProductsAvailabilityFromLocalStorage(products.filter((product) => {
			return product.isOrderable === true || product.productKey === ACTIVE_PRODUCT_KEYS.breakfastComplimentary
		})),
	}
}

const combineSelectedProductKeys = (currentKeys, changedKeys) => {
	// Products may have one product key, or several in case of products with subProductKeys.
	// The key of the main product is always the first in the array, followed by the subProducts
	const isCurrentlySelected = currentKeys.includes(changedKeys[0])

	return isCurrentlySelected
		? currentKeys.filter((key) => !changedKeys.includes(key))
		: [...currentKeys, ...changedKeys]
}
// find a key of the product by its id and check is it present in the local store
const isProductKeySelected = (products, selectedProductId) => {
	if (!selectedProductId) {
		return false
	}

	const foundProduct = products.find(({ productId }) => productId === selectedProductId)

	if (foundProduct === undefined) {
		return false
	}

	const { productKey } = foundProduct
	const selectedProductKeys = ls.getItem('selectedProductKeys') ?? []

	return selectedProductKeys.includes(productKey)
}

export {
	isImmediatelyPurchasablePaidProduct,
	isTimeProduct,
	parseProducts,
	combineSelectedProductKeys,
	isProductKeySelected,
	getServicesActiveProducts,
}
