import React, {
	ClipboardEventHandler,
	FC,
	FormEventHandler,
	KeyboardEventHandler,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from 'react'

import { Input } from './CodeInputItem.styled'

interface Props {
	readonly index: number;
	readonly activeIndex: number;
	readonly value: string;
	readonly hasError: boolean;
	readonly onChange: (value: string, index: number) => void;
	readonly onPaste: (value: string) => void;
}

const testSymbolRegExp = /^\d$/

const CodeInputItem : FC<Props> = ({ index, activeIndex, onChange, value, onPaste, hasError }) => {
	const inputRef = useRef<HTMLInputElement>(null)

	const handleKeyDown : KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
		if (testSymbolRegExp.test(event.key)) {
			event.preventDefault()

			onChange(event.key, index)
		} else if (event.key === 'Backspace') {
			event.preventDefault()

			onChange('', index)
		}
	}, [index])

	useEffect(() => {
		if (index === activeIndex) {
			inputRef.current?.focus()
		}
	}, [index, activeIndex])

	const isDisabled = useMemo(() => {
		return value !== '' || index > activeIndex
	}, [value, index, activeIndex])

	const handlePaste : ClipboardEventHandler<HTMLInputElement> = useCallback((event) => {
		event.preventDefault()

		const clipboardText = event.clipboardData.getData('text')

		onPaste(clipboardText)
	}, [onPaste])

	const handleInput : FormEventHandler<HTMLInputElement> = useCallback((event) => {
		if (testSymbolRegExp.test(event.currentTarget.value) === false) {
			event.preventDefault()
		}
	}, [])

	return (
		<Input
			autoFocus={index === 0}
			ref={inputRef}
			maxLength={1}
			pattern="\d"
			inputMode="decimal"
			onKeyDown={handleKeyDown}
			defaultValue={value}
			disabled={isDisabled}
			onPaste={handlePaste}
			onBeforeInput={handleInput}
			hasError={hasError}
		/>
	)
}

export default CodeInputItem
