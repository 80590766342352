import logger from '../logger/logger'
import { ApiPatchError, CountrySubdivisionsApi } from 'Types/api.types'
import { getCoreApiBaseUrl } from '@/utils/env'

const apiHost = getCoreApiBaseUrl()

async function getCountrySubdivisions(countryCode: string): Promise<CountrySubdivisionsApi | ApiPatchError> {
	const url = new URL(`${apiHost}/country-subdivisions`)
	url.searchParams.append('countryCode', countryCode)

	try {
		const response = await fetch(url, { method: 'GET' })
		return await response.json()
	} catch (error) {
		logger.error('Could not fetch country subdivisions', error)
		throw error
	}
}

export default getCountrySubdivisions
