import React from 'react'
import styled from 'styled-components'

import { COLORS } from '../globals/global.styles'

const SpinnerOverlay = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`

const SpinnerContainer = styled.div`
	display: inline-block;
	width: 50px;
	height: 50px;
	border: 3px solid ${COLORS.border.minor};
	border-radius: 50%;
	border-top-color: ${COLORS.border.highlight};
	animation: spin 1s ease-in-out infinite;

	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}
`

const Spinner = () => (
	<SpinnerOverlay>
		<SpinnerContainer data-testid="main-spinner" />
	</SpinnerOverlay>
)

export default Spinner
