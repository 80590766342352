import { createStore as createStoreRedux, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './root-reducer'
import config from '../globals/constants'

const { IS_DEVELOPMENT } = config

const composeEnhancers = (IS_DEVELOPMENT && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== undefined)
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
	: compose

const createStore = () => createStoreRedux(
	rootReducer,

	composeEnhancers(
		applyMiddleware(thunk),
	),
)

const store = createStore()

export { store, createStore }

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
