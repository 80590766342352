import React, { useContext } from 'react'
import styled from 'styled-components'
import { format, utcToZonedTime } from 'date-fns-tz'
import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'

import { ResetButton } from 'Components/ui'
import { ContentMainHeading } from 'Components/view-structure.component'

import {
	COLORS,
	FONT_WEIGHTS,
} from 'Globals/global.styles'

import { TranslationContext } from 'Src/context'

const SelectedDatesTimes = styled.div``

const AddButton = styled(ResetButton)`
	margin-top: 8px;
	font-weight: ${FONT_WEIGHTS.normal};
	line-height: 2.2em;
	color: ${COLORS.text.link};

	&:hover {
		color: ${COLORS.text.linkHover};
	}
`

const DateTimeRowStyled = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 22px;
	padding-bottom: 18px;
	border-bottom: 1px solid ${COLORS.border.minor};
`

const DateTimeRow = ({
	startDate,
	endDate,
	propertyTimeZone,
	isOrdered,
	remove,
}) => {
	const translation = useContext(TranslationContext)
	const dateString = format(utcToZonedTime(startDate, propertyTimeZone), 'EEE, d MMMM yyyy')
	const startTimeString = format(utcToZonedTime(startDate, propertyTimeZone), 'HH:mm')
	const endTimeString = format(utcToZonedTime(endDate, propertyTimeZone), 'HH:mm')

	return (
		<DateTimeRowStyled>
			{`${dateString} between ${startTimeString} - ${endTimeString}`}
			{
				isOrdered ? (
					<CheckCircleOutlined />
				) : (
					<ResetButton
						title={
							translation.t('Remove cleaning time {dateString} between {startTime} — {endTime}', {
								dateString,
								startTime: startTimeString,
								endTime: endTimeString,
							})
						}
						type="button"
						onClick={() => remove(startDate)}
					>
						<MinusCircleOutlined />
					</ResetButton>
				)
			}
		</DateTimeRowStyled>
	)
}

const YourCleaningsList = ({
	reservation: {
		property: { timezone: propertyTimeZone },
	},
	cleaningMidStayState: { maxCleaningTimesCount },
	selectedCleaningDatesTimes = [],
	orderedCleaningDatesTimes,
	removeSelectedCleaningDateTime,
	goToSelectTime,
}) => {
	const translation = useContext(TranslationContext)

	const orderedSelectedDatesTimes = selectedCleaningDatesTimes
		?.map(({ startDate, endDate }) => ({ startDate, endDate, isOrdered: false }))
		// eslint-disable-next-line max-len
		.concat(orderedCleaningDatesTimes?.map(({ consumptionDate, consumptionEndDate }) => ({ startDate: consumptionDate, endDate: consumptionEndDate, isOrdered: true })))
		?.sort((a, b) => (new Date(a.startDate) - new Date(b.startDate)))

	return (
		<>
			<ContentMainHeading>
				{translation.t('Your cleanings')}
				{' '}
				<span>
					{translation.t('{orderedSelectedDatesTimesCount} of {maxCleaningTimesCount} scheduled', {
						orderedSelectedDatesTimesCount: orderedSelectedDatesTimes.length,
						maxCleaningTimesCount,
					})}
				</span>
			</ContentMainHeading>

			<SelectedDatesTimes>
				{orderedSelectedDatesTimes?.map(({ startDate, endDate, isOrdered }) => (
					<DateTimeRow
						key={`${startDate}-${isOrdered}`}
						startDate={startDate}
						endDate={endDate}
						propertyTimeZone={propertyTimeZone}
						isOrdered={isOrdered}
						remove={removeSelectedCleaningDateTime}
					/>
				))}
			</SelectedDatesTimes>

			{(orderedSelectedDatesTimes.length < maxCleaningTimesCount) && (
				<AddButton
					type="button"
					onClick={goToSelectTime}
				>
					{translation.t('+ Add new cleaning')}
				</AddButton>
			)}
		</>
	)
}

export default YourCleaningsList
