import React, { useContext } from 'react'
import { TranslationContext } from '@/context'
import EmptyBoxIcon from 'Assets/icons/icon-empty-box.svg'
import ProductRow from 'Views/check-in-views/summary-view/product-row.component'
import styled from 'styled-components'
import { COLORS } from 'Globals/global.styles'
import config from 'Globals/constants'
import CityTaxProduct from 'Views/check-in-views/summary-view/city-tax-product.component'

const { ACTIVE_PRODUCT_KEYS } = config
const { propertyUnitPaidUpgrade: propertyUnitPaidUpgradeKey } = ACTIVE_PRODUCT_KEYS

const EmptyState = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 32px;
	padding: 16px 16px 48px;
	border-bottom: 1px solid ${COLORS.border.minor};
`

const EmptyStateHeading = styled.div`
	margin: 16px 0 8px;
	font-size: 20px;
`

const EmptyStateInfo = styled.div`
	font-size: 14px;
	text-align: center;
`

const SummaryProducts = ({
	currencyIcon,
	products,
	breakfastProduct,
	productsAmount,
	guestCount,
	durationOfStay,
	updateSelectedProductKeys,
	paidUpgradeData,
	isCityTaxRequired,
}) => {
	const translation = useContext(TranslationContext)
	if (!products?.length && !isCityTaxRequired) {
		return (
			<EmptyState>
				<img src={EmptyBoxIcon} alt="Empty" />
				<EmptyStateHeading>{translation.t('Empty')}</EmptyStateHeading>
				<EmptyStateInfo>
					{translation.t('You do not have any extras selected. Please go back to select some or continue to finish your check-in.')}
				</EmptyStateInfo>
			</EmptyState>
		)
	}

	return (
		<>
			{products?.map((product) => {
				let currentProduct = product

				if (product.productKey === ACTIVE_PRODUCT_KEYS.breakfastComplimentary) {
					currentProduct = { ...product, price: breakfastProduct.price, originalPrice: product.price }
				}

				return (
					<ProductRow
						key={product.productId}
						product={currentProduct}
						paidUpgradeData={product.productKey === propertyUnitPaidUpgradeKey && paidUpgradeData}
						productsAmount={productsAmount}
						guestCount={guestCount}
						durationOfStay={durationOfStay}
						currencyIcon={currencyIcon}
						onRemove={() => updateSelectedProductKeys(product.productKey, product.subProductKeys)}
					/>
				)
			})}

			<CityTaxProduct isCityTaxRequired={isCityTaxRequired} />
		</>
	)
}

export default SummaryProducts
