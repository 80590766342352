import React, { useContext } from 'react'
import styled from 'styled-components'
import { COLORS } from 'Globals/global.styles'
import HypeLines from 'Components/ui/hype-lines.component'
import BreakfastPartnerCard from './breakfast-partner-card'
import { TranslationContext } from '@/context'
import { ContentMainHeading, Section } from 'Components/view-structure.component'
import DistanceDetails from 'Components/breakfast/distance-details'
import { BreakfastContentData } from 'Types/breakfast-content.types'

const SectionStyled = styled(Section)`
	margin-bottom: 48px;
`

const TextContent = styled.div`
	color: ${COLORS.text.secondary};
	font-size: 14px;
	margin-bottom: 20px;
`

const ContentMainHeadingStyled = styled(ContentMainHeading)`
	margin: 0 0 10px;
`

const PartnerCardsContainer = styled.div`
	display: grid;
	row-gap: 16px;
`

interface StateProps {
	readonly breakfastContent: BreakfastContentData | null,
	readonly isLoading: boolean
}

interface BreakfastViewProps extends StateProps { }

const BreakfastContent = ({ breakfastContent, isLoading = true }: BreakfastViewProps) => {
	const translation = useContext(TranslationContext)

	// todo UI loading component?
	if (isLoading) {
		return (
			<div>{translation.t('Loading...')}</div>
		)
	}

	const partners = breakfastContent?.partnersContent?.filter(({ isAvailable }) => isAvailable) ?? []

	return (
		<>
			<SectionStyled>
				<HypeLines lines={breakfastContent?.guidelines} useDots={false} />
			</SectionStyled>

			<SectionStyled>
				<ContentMainHeadingStyled>
					{ translation.t('Our Breakfast Partners') }
				</ContentMainHeadingStyled>

				{ breakfastContent?.description ? (
					<TextContent>
						{breakfastContent.description}
					</TextContent>
				) : null}

				<PartnerCardsContainer>
					{ partners.map(({
						id,
						name,
						description,
						imageUrl,
						googleMapsUrl,
					}) => {
						return (
							<BreakfastPartnerCard
								key={id}
								name={name}
								description={description}
								imageUrl={imageUrl}
							>
								<DistanceDetails distance="Google Maps" googleMapsUrl={googleMapsUrl} />
							</BreakfastPartnerCard>
						)
					}) }
				</PartnerCardsContainer>
			</SectionStyled>
		</>
	)
}

export default BreakfastContent
