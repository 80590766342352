import React from 'react'
import ReactDOM from 'react-dom'
import { createGlobalStyle } from 'styled-components'
import { Provider } from 'react-redux'
import SnackbarProvider from 'react-simple-snackbar'
import { TranslationContext } from 'Src/context'
import App from './App'
import { COLORS, FONT_WEIGHTS } from 'Globals/global.styles'
import initTranslator from './translator'
import { store } from 'Redux/store'
import './style.css'
import { setWindowVariable } from '@/utils/dom'
import logger from 'Logger/logger'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

const GlobalStyles = createGlobalStyle`
	body {
		height: 100%;
		margin: 0;
		background-color: ${COLORS.background.mainBackground};
		color: ${COLORS.text.primary};
		font-family: Dinot, sans-serif;
		line-height: 24px;
		font-size: 16px;
		font-weight: ${FONT_WEIGHTS.light};
	}

	#root {
		height: 100%;
	}

	* {
		box-sizing: border-box;
	}

	input,
	select {
		color: black;
		font-family: Dinot, sans-serif;
		line-height: 24px;
		font-size: 16px;
	}

	strong {
		font-weight: 500;
	}
`

setWindowVariable()

logger.init(datadogLogs, datadogRum)

const AppContainer = async () => {
	const i18next = await initTranslator()

	ReactDOM.render(
		<>
			<GlobalStyles />
			<Provider store={store}>
				<SnackbarProvider>
					<TranslationContext.Provider value={i18next}>
						<App />
					</TranslationContext.Provider>
				</SnackbarProvider>
			</Provider>
		</>,
		document.getElementById('root'),
	)
}

AppContainer()
