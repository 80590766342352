import React, { FC, useContext } from 'react'
import { TranslationContext } from '@/context'
import SustainabilityIcon from 'Assets/icons/icon-sustainability.svg'
import FacebookIcon from 'Assets/social-media/facebook.svg'
import SpotifyIcon from 'Assets/social-media/spotify.svg'
import LinkedInIcon from 'Assets/social-media/linkedIN.svg'
import InstagramIcon from 'Assets/social-media/instagram.svg'
import TwitterIcon from 'Assets/social-media/twitter.svg'

import {
	Divider,
	FooterIcon,
	FooterMessage,
	FooterMessageWrapper,
	FooterSocialMediaFollowUs,
	FooterSocialMediaIconsWrapper,
	FooterSocialMediaWrapper,
	Link,
	MessageWrapper,
	PageFooterStyled,
} from './PageFooter.styled'

import { useSelector } from 'react-redux'
import { selectIsWhiteLabel } from 'Src/redux/reservation/reservation.selectors'

interface Props {
  readonly showSocialMedia: boolean;
}

const PageFooter : FC<Props> = ({ showSocialMedia }) => {
	const translation = useContext(TranslationContext)
	const isWhiteLabel = useSelector(selectIsWhiteLabel)

	if (isWhiteLabel)	{
		return null
	}

	return (
		<PageFooterStyled showSocialMedia={showSocialMedia}>
			<MessageWrapper>
				<FooterIcon src={SustainabilityIcon} />
				<FooterMessageWrapper>
					<FooterMessage>
						{translation.t('Bob W is the first international climate-neutral hospitality provider')}
					</FooterMessage>
					<Link href="https://bobw.co/sustainability?lng=en-GB" target="_blank">
						{translation.t('Learn More')}
					</Link>
				</FooterMessageWrapper>
			</MessageWrapper>

			{ showSocialMedia && (
				<>
					<Divider />

					<FooterSocialMediaWrapper>
						<FooterSocialMediaFollowUs>
							{translation.t('Follow Us')}
						</FooterSocialMediaFollowUs>

						<FooterSocialMediaIconsWrapper>
							<a href="https://www.instagram.com/bobwco/" target="_blank" rel="noreferrer">
								<img src={InstagramIcon} alt="instagram" />
							</a>
							<a href="https://www.facebook.com/bobwstay/" target="_blank" rel="noreferrer">
								<img src={FacebookIcon} alt="facebook" />
							</a>
							<a href="https://www.linkedin.com/company/bobw" target="_blank" rel="noreferrer">
								<img src={LinkedInIcon} alt="linkedIn" />
							</a>
							<a href="https://open.spotify.com/user/fnrcjojtkr0uvqkf2y2duhbbj" target="_blank" rel="noreferrer">
								<img src={SpotifyIcon} alt="spotify" />
							</a>
							<a href="https://twitter.com/Bobwco" target="_blank" rel="noreferrer">
								<img src={TwitterIcon} alt="twitter" />
							</a>
						</FooterSocialMediaIconsWrapper>
					</FooterSocialMediaWrapper>
				</>
			)}
		</PageFooterStyled>
	)
}

export default PageFooter
