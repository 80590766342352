import React, { FC, useContext, useMemo, useState } from 'react'
import { debounce } from 'lodash'

import AsyncSelect, { SelectOption } from 'Components/ui/async-select.component'
import getItalianProvinces from '@/api/get-italian-provinces'
import { CommunesApi, ApiPatchError } from 'Types/api.types'
import IconSearchGrey from 'Assets/icons/icon-search-grey.svg'
import IconTick from 'Assets/icons/icon-tick.svg'
import styled from 'styled-components'
import { TranslationContext } from '@/context'
import { ValueType } from 'react-select/src/types'

const provincesEmptyOptionsText = 'Type in at least first two letters to find a comune'

const SearchIconWrapper = styled.div`
	display: flex;
	padding: 0 16px;
`

interface Props {
	value: string;
	label: string;
	name: string;
	placeholder: string;
	isValid: boolean;
	onChange: (province: ValueType<SelectOption, false>) => void;
}

const SelectItalianProvinces: FC<Props> = ({
	value: province,
	...otherProps
}) => {
	const translation = useContext(TranslationContext)
	const [noOptionsText, setNoOptionsText] = useState<string>(translation.t(provincesEmptyOptionsText))

	const selectedProvince = {
		value: province,
		label: province,
	}

	const iconSource = useMemo(() => {
		if (province) {
			return IconTick
		}

		return IconSearchGrey
	}, [province])

	const loadProvinces = (query: string, callback: (options: SelectOption[]) => void) => {
		if (query.length < 2) {
			setNoOptionsText(translation.t(provincesEmptyOptionsText))
			callback([])
			return
		}

		return getItalianProvinces(query)
			.then((response) => {
				if (!(response as CommunesApi).data
					&& (response as ApiPatchError)?.errors.length > 0) {
					setNoOptionsText(translation.t(provincesEmptyOptionsText))
					callback([])

					return
				}

				const selectOptions = (response as CommunesApi)
					.data
					.map((province) => ({ label: province.name, value: province.name }))

				callback(selectOptions || [])
			})
	}

	const updateNoOptionsText = (input: string) => {
		if (!input || input.length < 2) {
			setNoOptionsText(translation.t(provincesEmptyOptionsText))
		} else {
			setNoOptionsText(translation.t('Comunes not found'))
		}
	}

	return (
		<AsyncSelect
			value={selectedProvince}
			loadOptions={debounce(loadProvinces, 300)}
			cacheOptions
			loadingMessage={() => translation.t('Loading...')} // React select requires function as value
			onInputChange={updateNoOptionsText}
			noOptionsMessage={() => (noOptionsText)} // React select requires function as value
			components={{
				IndicatorsContainer: () => (
					<SearchIconWrapper>
						<img src={iconSource} alt={translation.t('Find a comune')} />
					</SearchIconWrapper>
				),
			}}
			{...otherProps}
		/>
	)
}

export default SelectItalianProvinces
