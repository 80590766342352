import { RootState } from 'Redux/store'
import { BusinessLeadState } from 'Redux/business-lead/business-lead.reducer'
import ls from '@/local-storage/local-storage'

const key = 'businessLead'
function selectBusinessLead(state: RootState) : BusinessLeadState {
	const savedBusinessLead = ls.getItem(key)
	return savedBusinessLead ?? state.businessLeadState ?? null
}

export {
	selectBusinessLead,
}
