export const enum Environment {
  Production,
  Stage,
  Dukenukem,
  Development
}

export const environmentNames = {
	[Environment.Production]: 'prod',
	[Environment.Stage]: 'stage',
	[Environment.Dukenukem]: 'dukenukem',
	[Environment.Development]: 'development',
} as const

export const coreApiBaseUrls = {
	[Environment.Production]: 'https://core-api.bobw.co',
	[Environment.Stage]: 'https://core-api.staging-bobw.com',
	[Environment.Dukenukem]: 'https://core-api.dukenukem.test-bobw.com',
	[Environment.Development]: 'http://localhost:3030',
} as const

export const gaTrackingIds = {
	[Environment.Production]: 'UA-136957112-3',
	[Environment.Stage]: 'UA-136957112-4',
	[Environment.Dukenukem]: 'G-JE2K6WV43T',
	[Environment.Development]: 'G-JE2K6WV43T',
} as const

export const segmentWriteKeys = {
	[Environment.Production]: 'xDI5U7s8aqQbuzuHMmx2mKbJphmmpghL',
	[Environment.Stage]: '6XQTjzozzRSTuLxkkGVC8o3z34GKlAEY',
	[Environment.Dukenukem]: 'Inu2lKwBca7WAP2lB7XpAAy7ERNUgt7h',
	[Environment.Development]: 'u2avBGfedtDn3BWA4oIFSiUsPjZGJgKJ',
} as const

export const secureFieldsHosts = {
	[Environment.Production]: 'pay.datatrans.com',
	[Environment.Stage]: 'pay.sandbox.datatrans.com',
	[Environment.Dukenukem]: 'pay.sandbox.datatrans.com',
	[Environment.Development]: 'pay.sandbox.datatrans.com',
} as const

const environmentsMap : Record<string, Environment> = {
	prod: Environment.Production,
	stage: Environment.Stage,
	dukenukem: Environment.Dukenukem,
	development: Environment.Development,
}

export function forceEnvironment(newEnvironment: string) : void {
	localStorage.setItem('environment', newEnvironment)

	window.location.reload()
}

export function resetEnvironment(): void {
	localStorage.removeItem('environment')

	window.location.reload()
}

export function getForcedEnvironment(): Environment | undefined {
	const possibleEnvironment = localStorage.getItem('environment')

	if (possibleEnvironment !== null) {
		return environmentsMap[possibleEnvironment]
	}
}

export function getRealEnvironment(): Environment {
	switch (window.location.hostname) {
		case 'guest.bobw.co':
		case 'guest.notahotel.co': {
			return Environment.Production
		}

		case 'guest.staging-bobw.com': {
			return Environment.Stage
		}

		case 'guest.dukenukem.test-bobw.com': {
			return Environment.Dukenukem
		}

		default: {
			return Environment.Development
		}
	}
}

export function getEnvironment(): Environment {
	const realEnvironment = getRealEnvironment()

	// Always use production environment in production
	if (realEnvironment === Environment.Production) {
		return realEnvironment
	}

	return getForcedEnvironment() ?? getRealEnvironment()
}

export function isWhiteLabelDomain() : boolean {
	return window.location.hostname.includes('notahotel.co')
}

export function getGaTrackingId(): string {
	const environment = getEnvironment()

	return gaTrackingIds[environment]
}

export function getSegmentWriteKey(): string {
	const environment = getEnvironment()

	return segmentWriteKeys[environment]
}

export function getSecureFieldsHost() : string {
	const environment = getEnvironment()

	return secureFieldsHosts[environment]
}

export function getCoreApiBaseUrl() : string {
	const environment = getEnvironment()

	return coreApiBaseUrls[environment]
}
