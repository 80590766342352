import { createSelector } from 'reselect'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { differenceInCalendarDays, subDays } from 'date-fns'

import ls from '../../../local-storage/local-storage'
import { getEarlyCheckinProduct, getLateCheckoutProduct } from './times.utils'

import config from 'Globals/constants'

import { selectReservation, selectReservationState } from '../reservation.selectors'
import { getPropertyTimeZonedDate } from 'Src/utils'

const {
	ARRIVAL_TIME_STATUSES,
	DEPARTURE_TIME_STATUSES,
} = config

// TIME AVAILABILITY

const selectAvailableTimes = createSelector(
	[selectReservationState],
	({ availableTimes }) => {
		let checkIn = null
		let checkOut = null
		if (!availableTimes.checkIn && !availableTimes.checkOut) {
			const availableTimesFromStorage = ls.getItem('availableCheckInCheckOutTimes')
			checkIn = availableTimesFromStorage?.checkIn
			checkOut = availableTimesFromStorage?.checkOut
		} else if (availableTimes.checkIn && availableTimes.checkOut) {
			checkIn = availableTimes.checkIn
			checkOut = availableTimes.checkOut
		}

		return {
			checkIn,
			checkOut,
			superEarlyCheckIn: availableTimes.superEarlyCheckIn,
			superLateCheckOut: availableTimes.superLateCheckOut,
		}
	},
)

// ARRIVAL TIME

const selectArrivalTimeData = createSelector(
	[selectReservationState],
	({ arrivalTimeData }) => {
		const arrivalTimeInLocalStorage = ls.getItem('selectedArrivalTimeUTC')

		return arrivalTimeInLocalStorage
			? {
				...arrivalTimeData,
				selectedArrivalTimeUTC: arrivalTimeInLocalStorage,
			}
			: arrivalTimeData
	},
)

const selectArrivalTimeStrings = createSelector(
	[
		selectReservation,
		selectArrivalTimeData,
	],
	(
		reservation,
		{ confirmedArrivalDateUTC },
	) => {
		const {
			property: {
				timezone: propertyTimeZone,
			},
		} = reservation ?? {
			property: {
				timezone: 'UTC',
			},
		}

		const arrivalDateLocal = utcToZonedTime(confirmedArrivalDateUTC, propertyTimeZone)
		const arrivalTimeLocal = format(arrivalDateLocal, 'HH:mm')
		const oneDayBeforeLocal = subDays(arrivalDateLocal, 1)
		const oneDayBeforeISOUtc = zonedTimeToUtc(
			oneDayBeforeLocal,
			propertyTimeZone,
		).toISOString()
		const oneDayBeforeLocalString = getPropertyTimeZonedDate(
			oneDayBeforeISOUtc,
			propertyTimeZone,
			'do MMMM',
		)

		return {
			arrivalTimeLocal,
			oneDayBeforeLocal,
			oneDayBeforeLocalString,
			confirmedArrivalDateUTC,
		}
	},
)

const selectArrivalTimeStatus = createSelector(
	[selectReservation, selectArrivalTimeData, selectAvailableTimes],
	({ property }, arrivalTimeData, availableTimes) => {
		const {
			selectedArrivalTimeUTC,
			confirmedArrivalDateUTC,
		} = arrivalTimeData

		const confirmedArrivalTimeLocal = format(
			utcToZonedTime(confirmedArrivalDateUTC, property.timezone),
			'HH:mm',
		)
		const isEarlyArrivalTime = !!getEarlyCheckinProduct(selectedArrivalTimeUTC, availableTimes)

		let status

		if (isEarlyArrivalTime && selectedArrivalTimeUTC !== confirmedArrivalTimeLocal) {
			status = ARRIVAL_TIME_STATUSES.unconfirmedEarly
		} else if (isEarlyArrivalTime && selectedArrivalTimeUTC === confirmedArrivalTimeLocal) {
			status = ARRIVAL_TIME_STATUSES.confirmedEarly
		} else {
			status = ARRIVAL_TIME_STATUSES.regular
		}

		return status
	},
)

// DEPARTURE TIME

const selectDepartureTimeData = createSelector(
	[selectReservationState],
	({ departureTimeData }) => {
		const departureTimeInLocalStorage = ls.getItem('selectedDepartureTimeUTC')

		return departureTimeInLocalStorage
			? {
				...departureTimeData,
				selectedDepartureTimeUTC: departureTimeInLocalStorage,
			}
			: departureTimeData
	},
)

const selectDepartureTimeStatus = createSelector(
	[selectReservation, selectDepartureTimeData, selectAvailableTimes],
	({ property }, departureTimeData, availableTimes) => {
		const {
			selectedDepartureTimeUTC,
			confirmedDepartureDateUTC,
		} = departureTimeData

		const confirmedDepartureTimeLocal = format(
			utcToZonedTime(confirmedDepartureDateUTC, property.timezone),
			'HH:mm',
		)
		const isLateDepartureTime = !!getLateCheckoutProduct(selectedDepartureTimeUTC, availableTimes)

		let status

		if (isLateDepartureTime && selectedDepartureTimeUTC !== confirmedDepartureTimeLocal) {
			status = DEPARTURE_TIME_STATUSES.unconfirmedLate
		} else if (isLateDepartureTime && selectedDepartureTimeUTC === confirmedDepartureTimeLocal) {
			status = DEPARTURE_TIME_STATUSES.confirmedLate
		} else {
			status = DEPARTURE_TIME_STATUSES.regular
		}

		return status
	},
)

const selectDurationOfStay = createSelector(
	[selectArrivalTimeData, selectDepartureTimeData],
	(
		{ confirmedArrivalDateUTC },
		{ confirmedDepartureDateUTC },
	) => differenceInCalendarDays(new Date(confirmedDepartureDateUTC), new Date(confirmedArrivalDateUTC)),
)

export {
	selectAvailableTimes,
	selectArrivalTimeData,
	selectArrivalTimeStrings,
	selectArrivalTimeStatus,
	selectDepartureTimeData,
	selectDepartureTimeStatus,
	selectDurationOfStay,
}
