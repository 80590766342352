import React, { useContext } from 'react'
import styled from 'styled-components'

import {
	SecureFieldsInput,
	FormInput,
	MonthYearPicker,
} from './ui'
import { InvalidFieldsAlertSection } from './view-structure.component'

import { TranslationContext, ConstantsContext } from 'Src/context'

const NameInput = styled(FormInput)`
	margin-bottom: 16px;
`

const CardNumberWrapper = styled.div`
	position: relative;
`

const CardNumberInput = styled(SecureFieldsInput)`
	margin-bottom: 16px;
	min-width: 100%;
`

const PaymentIcon = styled.img`
	position: absolute;
	right: 12px;
	bottom: 5px;
	height: 30px;
`

const ExpireDateCVVWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`

const ExpirationDatePicker = styled(MonthYearPicker)`
	width: 47%;
`

const CVVInput = styled(SecureFieldsInput)`
	width: 47%;
`

const CreditCardFields = ({
	focusedField,
	creditCard,
	updateField,
	focusSecureFieldsField,
	expirationDateFieldRef,
	formErrors,
}) => {
	const {
		name,
		expirationDate,
		type,
	} = creditCard || {}

	const {
		constants: {
			PAYMENT_METHODS,
			SECURE_FIELDS_INPUT_IDS,
		},
	} = useContext(ConstantsContext)

	const {
		icon: cardIcon,
		cvvHelperText,
		cvvAbbreviation,
	} = PAYMENT_METHODS[type] || {}

	const translation = useContext(TranslationContext)

	return (
		<>
			{!!formErrors?.length && <InvalidFieldsAlertSection />}

			<NameInput
				label={`${translation.t('Name on Card')}*`}
				name={translation.t('Name on Card')}
				type="text"
				handleChange={(value) => updateField('name', value)}
				value={name}
				isValid={!formErrors?.includes('name')}
				onKeyPress={(event) => {
					if (event.key === 'Enter') {
						event.preventDefault()
						focusSecureFieldsField('cardNumber')
					}
				}}
			/>
			<CardNumberWrapper>
				<CardNumberInput
					label={`${translation.t('Card Number')}*`}
					name={translation.t('Card number')}
					secureFieldsInputId={SECURE_FIELDS_INPUT_IDS.cardNumber}
					type="text"
					isFocused={focusedField === 'cardNumber'}
					exclamationMark
					isValid={!formErrors?.includes('cardNumber')}
				/>
				{!!cardIcon && <PaymentIcon src={cardIcon} alt={type} />}
			</CardNumberWrapper>
			<ExpireDateCVVWrapper>
				<ExpirationDatePicker
					label={`${translation.t('Expiry Date')}*`}
					name={translation.t('Expiry Date')}
					placeholder="MM/YY"
					handleChange={(value) => updateField('expirationDate', value)}
					value={expirationDate}
					isValid={!formErrors?.includes('expirationDate')}
					inputRef={expirationDateFieldRef}
				/>
				<CVVInput
					label={`${translation.t('Security Code')}${cvvAbbreviation ? ` (${cvvAbbreviation})` : ''}*`}
					name="CVV"
					secureFieldsInputId={SECURE_FIELDS_INPUT_IDS.cvv}
					type="text"
					isFocused={focusedField === 'cvv'}
					exclamationMark
					helperText={cvvHelperText}
					isValid={!formErrors?.includes('cvv')}
				/>
			</ExpireDateCVVWrapper>
		</>
	)
}

export default CreditCardFields
