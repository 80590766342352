import { useCodeScript, useUrlScript } from '@/hooks/use-script'
import { getGaTrackingId, getSegmentWriteKey } from '@/utils/env'

export const useAnalyticsScripts = () => {
	const gaTrackingId = getGaTrackingId()
	const segmentWriteKey = getSegmentWriteKey()

	useUrlScript(`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`, 'head')
	useCodeScript(`
			window.dataLayer = window.dataLayer || []

			function gtag() {
				dataLayer.push(arguments)
			}

			gtag('js', new Date())

			gtag('config', '${gaTrackingId}', {
				custom_map: {
					dimension0: 'reservation_channel'
				}
			})

			window.gtag = gtag
		`, 'head')

	useCodeScript(`(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
	(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
	m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
	})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
	ga('create', '${gaTrackingId}', 'auto');
	window.ga = ga;`, 'head')

	useCodeScript(`
		!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentWriteKey}";;analytics.SNIPPET_VERSION="4.15.3";
		analytics.load('${segmentWriteKey}');
		}}();
	`, 'head')
}
