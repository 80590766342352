import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

/* eslint import/no-cycle: 0 */
import { ApiError, ApiPatchError, ReservationApi } from 'Types/api.types'
import { AppError, AppState, ErrorCode, FLOW_SERVICE_MAP } from 'Types/app-state.types'
import { ReservationState } from 'Types/reservation-state.types'
import { RootState } from '../store'

import logger from '../../logger/logger'
import { client } from '../../api/feathers'
import i18next from 'i18next'

import { selectAppState } from '../app/app.selectors'
import {
	initAppState,
	setCurrentStepFormErrors,
	setErrorViewVisible,
	setIsSubmitting,
	setSnackbarText,
	showAlertPopup,
} from '../app/app.actions'
import { disableArrivalTimeField } from './times/times.actions'
import ReservationActionTypes from './reservation.types'
import { selectReservation } from './reservation.selectors'
import { startRefreshDoorCodeWithRetry } from './technolife-door-code-refresh-HACK.actions'
import { setEmailErrorCode } from '../error/error.actions'
import { showEmailVerificationDialog } from '../ui/ui.actions'
import { redirectToReception } from 'Src/utils/reception-helpers'

const API_FIELD_TO_UI_FIELD_MAP : Record<string, string | undefined> = {
	phone: 'phone',
	email: 'email',
	startDate: 'arrivalTime',
}

function handleEmailError({ message, code } : Pick<ApiError, 'code' | 'message'>) : AppError {
	switch (code) {
		case 'EMAIL_VERIFICATION_REQUIRED': {
			return {
				message,
				fieldName: 'email',
				code: ErrorCode.EMAIL_VERIFICATION_REQUIRED,
			}
		}

		case 'WRONG_VERIFICATION_CODE': {
			return {
				message,
				fieldName: 'email',
				code: ErrorCode.WRONG_VERIFICATION_CODE,
			}
		}

		case 'TOO_MANY_VERIFICATION_ATTEMPTS': {
			return {
				message,
				fieldName: 'email',
				code: ErrorCode.TOO_MANY_VERIFICATION_ATTEMPTS,
			}
		}

		case 'VERIFICATION_CODE_EXPIRED': {
			return {
				message,
				fieldName: 'email',
				code: ErrorCode.VERIFICATION_CODE_EXPIRED,
			}
		}

		default: {
			return 'email'
		}
	}
}

function getEmailErrorCode(errors?: ApiError[]) : string {
	return errors?.find(({ field }) => field === 'email' || field === '$emailVerificationCode')?.code ?? ''
}

const getFormFieldErrorsFromResponse = (errors?: ApiError[]) : AppError[] => {
	const formFieldErrors : AppError[] = []

	errors?.forEach(({ field, code, message }) => {
		if (field === 'email' || field === '$emailVerificationCode') {
			const emailError = handleEmailError({ code, message })

			formFieldErrors.push(emailError)
		} else {
			const mappedFieldName = API_FIELD_TO_UI_FIELD_MAP[field]

			if (mappedFieldName === undefined) {
				logger.warn(`Field not in fields-map: ${field}`)

				formFieldErrors.push(field)
			} else {
				formFieldErrors.push(mappedFieldName)
			}
		}
	})

	return formFieldErrors
}

const resetReservationState = () => ({
	type: ReservationActionTypes.RESET_RESERVATION_STATE,
})

const updateReservation = (
	data: ReservationState['reservation'],
	toUpdateInitialData: boolean,
) => (dispatch: ThunkDispatch<{}, void, Action>) => {
	dispatch({
		type: ReservationActionTypes.UPDATE_RESERVATION,
		payload: {
			data,
			toUpdateInitialData,
		},
	})
}

/**
 * GET RESERVATION
 */

const getReservationStart = () => ({
	type: ReservationActionTypes.GET_RESERVATION_START,
})

const getReservationSuccess = (
	flow: AppState['flow'],
	reservation: ReservationApi,
) => (dispatch: ThunkDispatch<{}, void, Action>) => {
	const {
		id: reservationId,
		slug,
		confirmationNumber,
		channelConfirmationNumber,
		property: { name: propertyName },
	} = reservation

	logger.datadogLogs.addLoggerGlobalContext('reservation', {
		id: reservationId,
		slug,
		confirmationNumber,
		channelConfirmationNumber,
		propertyName,
	})

	dispatch({
		type: ReservationActionTypes.GET_RESERVATION_SUCCESS,
		payload: { reservation },
	})

	dispatch(initAppState(flow, reservation))
}

const getReservationFail = () => (dispatch: ThunkDispatch<{}, void, Action>) => {
	dispatch(setErrorViewVisible(true))
}

const getReservation = (
	flow: AppState['flow'],
	reservationSlug: string,
) => (dispatch: ThunkDispatch<{}, void, Action>) => {
	dispatch(getReservationStart())

	return client
		.service(FLOW_SERVICE_MAP[flow])
		.get(reservationSlug)
		.then((reservation: ReservationApi) => {
			if (reservation.checkInDate !== null) {
				redirectToReception(reservation.slug)
				return
			}
			dispatch(getReservationSuccess(flow, reservation))

			return reservation
		})
		.catch((error: ApiPatchError) => {
			dispatch(getReservationFail())

			// @ts-expect-error TODO: have no idea what `.response` is ¯\_(ツ)_/¯. Probably, it's a client-side error.
			if (!error.response) {
				throw error
			}
		})
}

const refetchReservation = (
	flow: AppState['flow'],
	reservationSlug: string,
) => (dispatch: ThunkDispatch<{}, void, Action>) => {
	client
		.service(FLOW_SERVICE_MAP[flow])
		.get(reservationSlug)
		.then((reservation: ReservationApi) => {
			const {
				id: reservationId,
				slug,
				confirmationNumber,
				channelConfirmationNumber,
				property: { name: propertyName },
			} = reservation

			logger.datadogLogs.addLoggerGlobalContext('reservation', {
				id: reservationId,
				slug,
				confirmationNumber,
				channelConfirmationNumber,
				propertyName,
			})

			dispatch({
				type: ReservationActionTypes.GET_RESERVATION_SUCCESS,
				payload: { reservation },
			})
		})
		.catch((error: ApiPatchError) => {
			// @ts-expect-error TODO: have no idea what `.response` is ¯\_(ツ)_/¯. Probably, it's a client-side error.
			if (!error.response) {
				throw error
			}
		})
}

/**
 * PATCH RESERVATION
 */

const patchReservationStart = () => (dispatch: ThunkDispatch<{}, void, Action>) => {
	dispatch(setIsSubmitting(true))
	dispatch(setSnackbarText(`${i18next.t('Saving data')}...`))
	dispatch({
		type: ReservationActionTypes.PATCH_RESERVATION_START,
	})
}

const patchReservationSuccess = (reservation: ReservationApi) => (dispatch: ThunkDispatch<{}, void, Action>) => {
	dispatch(setIsSubmitting(false))
	dispatch(setSnackbarText(null))
	dispatch({
		type: ReservationActionTypes.PATCH_RESERVATION_SUCCESS,
		payload: { reservation },
	})
}

const patchReservationFail = (errors: ApiPatchError['errors']) => (dispatch: ThunkDispatch<{}, void, Action>) => {
	dispatch(setIsSubmitting(false))
	dispatch(setSnackbarText(null))

	// log all errors
	errors?.forEach((error) => {
		logger.warn('Error while patching reservation', error)
	})

	let errorMessage = 'Couldn\'t submit data. Please try again...'

	const cannotChangeArrivalTime = errors?.find(({ field, code }) => (
		field === 'startDate' && code === 'RESERVATION_INVALID_STATUS'
	))

	if (cannotChangeArrivalTime) {
		errorMessage = cannotChangeArrivalTime.message
		dispatch(disableArrivalTimeField())
	}

	const emailErrorCode = getEmailErrorCode(errors)
	const formFieldErrors = getFormFieldErrorsFromResponse(errors)

	dispatch(setEmailErrorCode(emailErrorCode))

	if (formFieldErrors) {
		dispatch(setCurrentStepFormErrors(formFieldErrors, true))
	}

	// those errors will be handled in different way
	const hasEmailVerificationError = formFieldErrors.some((error) => (
		typeof error !== 'string'
		&& typeof error === 'object'
		&& error.fieldName === 'email'
		&& (
			error.code === ErrorCode.EMAIL_VERIFICATION_REQUIRED
			|| error.code === ErrorCode.TOO_MANY_VERIFICATION_ATTEMPTS
			|| error.code === ErrorCode.WRONG_VERIFICATION_CODE
			|| error.code === ErrorCode.VERIFICATION_CODE_EXPIRED
		)
	))

	if (hasEmailVerificationError) {
		dispatch(showEmailVerificationDialog())
	} else {
		dispatch(showAlertPopup(errorMessage))
	}
}

const patchReservation = (
	data: ReservationApi,
	onSuccess?: ((modifiedReservation?: ReservationApi) => any) | undefined,
) => (
	dispatch: ThunkDispatch<{}, void, Action>,
	getState: () => RootState,
) => {
	dispatch(patchReservationStart())

	const { flow } = selectAppState(getState())
	const { slug: reservationSlug } = selectReservation(getState()) || {}

	client
		.service(FLOW_SERVICE_MAP[flow])
		.patch(reservationSlug, data)
		.then((reservation: ReservationApi) => {
			const modifiedReservation = startRefreshDoorCodeWithRetry(
				data,
				reservation,
				dispatch,
				getState,
			)

			dispatch(patchReservationSuccess(modifiedReservation))
			if (onSuccess) onSuccess(modifiedReservation)
		})
		.catch((error: ApiPatchError) => {
			dispatch(patchReservationFail(error.errors))

			// @ts-expect-error TODO: have no idea what `.response` is ¯\_(ツ)_/¯. Probably, it's a client-side error.
			if (!error.response) {
				throw error
			}
		})
}

export {
	resetReservationState,
	updateReservation,
	getReservationSuccess,
	getReservation,
	patchReservation,
	refetchReservation,
}
