import React, { useContext } from 'react'
import styled from 'styled-components'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import productMealkit from 'Assets/cleankitchen-mealkit/cleankitchen_mealkit_main.webp'
import danDanNoodleImage from 'Assets/cleankitchen-mealkit/dan_dan_noodle.webp'
import chickenNoodleImage from 'Assets/cleankitchen-mealkit/chicken_noodle.webp'

import SingleProductWrapper from 'Views/check-in-views/products-view/single-product-wrapper.component'
import HypeLines from 'Components/ui/hype-lines.component'
import { ContentMainHeading, Section } from 'Components/view-structure.component'

import { selectProducts } from 'Redux/reservation/products/products.selectors'

import { COLORS, FONT_WEIGHTS } from 'Globals/global.styles'
import config from 'Globals/constants'

import { TranslationContext } from 'Src/context'
import { getCurrencyIcon } from 'Src/utils'
import { selectReservation } from 'Redux/reservation/reservation.selectors'

const { ACTIVE_PRODUCT_KEYS } = config

const DELIVERY = [
	'After you have confirmed your request Bob will contact you to agree on the menu and delivery date.',
	'On the day of delivery Clean Kitchen will send you and SMS with delivery time.',
	`The courier will call you when they arrive. If you’re not home, no worries,
	 we’ll deliver the meal-kit to your apartment and add to the fridge.`,
]

const ImageWrapper = styled.div`
	width: 100%;
	height: 250px;
	margin-bottom: 30px;
	
	> img {
		width: 100%;
		height: 100%;
	}
`

const SmallSectionStyled = styled(Section)`
	margin-bottom: 24px;
`

const TextContent = styled.div`
	font-size: 14px;
	color: ${COLORS.text.secondary};
	margin-bottom: 10px;
	margin-top: -15px;
`

const BoldText = styled.span`
	font-size: 14px;
	font-weight: ${FONT_WEIGHTS.bold};
	color: ${COLORS.text.secondary};
`

const CleanKitchenMealkitViewComponent = ({ reservation, products }) => {
	const translation = useContext(TranslationContext)
	const cleanKitProd = products.find((prod) => prod.productKey === ACTIVE_PRODUCT_KEYS.mealkitCleanKitchenNoodles2)
	const subProductsPrice = products.filter((prod) => cleanKitProd.subProductKeys.includes(prod.productKey))

	const getPriceOfSubProduct = (productKey) => {
		const product = subProductsPrice.find((prod) => prod.productKey === productKey)
		return product?.price?.toFixed(2) || product?.priceOnlyDisplay?.toFixed(2)
	}

	const {
		property: {
			defaultCurrency,
		},
	} = reservation

	const currency = getCurrencyIcon(defaultCurrency)

	return (
		<SingleProductWrapper
			title={translation.t('Meal kit by Clean Kitchen')}
			titleAfterImage
			/* eslint-disable max-len */
			description={translation.t('Enjoy more time in your Bob and take advantage of your fully-equipped kitchen. Skip grocery shopping – have everything you need to cook a restaurant-quality meal delivered to your apartment.')}
			image={productMealkit}
			buttonText={translation.t('Add Meal Kit')}
			hidePrice
		>
			<ContentMainHeading>
				{translation.t('How it works')}
			</ContentMainHeading>
			<TextContent>
				{/* eslint-disable max-len */}
				{translation.t('This meal kit takes the work out of making a restaurant-quality meal. The pre-portioned ingredients are delivered to your apartment along with instructions on how to assemble them. You’ll find all the necessary cooking gadgets in your apartment’s kitchen (no blow torch needed).')}
			</TextContent>
			<ContentMainHeading>
				{translation.t('The Menu')}
			</ContentMainHeading>
			<TextContent>
				{translation.t('You can choose between the two following meal kits. Both items are available only for')}
				{' '}
				<BoldText>{translation.t('2 or 4 people.')}</BoldText>
			</TextContent>
			<br />
			<TextContent>
				{translation.t('Quick Chicken Wok With Celery And Peanuts')}
			</TextContent>
			<ImageWrapper>
				<img src={chickenNoodleImage} alt="Chicken noodle by cleankitchen" />
			</ImageWrapper>
			<TextContent>
				{translation.t('Vegan Dan Dan Noodles')}
			</TextContent>
			<ImageWrapper>
				<img src={danDanNoodleImage} alt="Dan dan noodle by cleankitchen" />
			</ImageWrapper>
			<ContentMainHeading>
				{translation.t('Delivery')}
			</ContentMainHeading>
			<SmallSectionStyled>
				<HypeLines lines={DELIVERY} useDots />
			</SmallSectionStyled>
			<ContentMainHeading>
				{translation.t('Price')}
			</ContentMainHeading>
			<BoldText>
				{translation.t('Chicken Wok With Celery And Peanuts')}
				:
			</BoldText>
			<SmallSectionStyled>
				<HypeLines
					lines={[
						translation.t('For 2 people: {currency}{price}', { currency, price: getPriceOfSubProduct(ACTIVE_PRODUCT_KEYS.mealkitCleanKitchenWok2) }),
						translation.t('For 4 people: {currency}{price}', { currency, price: getPriceOfSubProduct(ACTIVE_PRODUCT_KEYS.mealkitCleanKitchenWok4) }),
					]}
					useDots
				/>
				<BoldText>
					{translation.t('Vegan Dan Dan Noodles')}
					:
				</BoldText>
				<HypeLines
					lines={[
						translation.t('For 2 people: {currency}{price}', { currency, price: getPriceOfSubProduct(ACTIVE_PRODUCT_KEYS.mealkitCleanKitchenNoodles2) }),
						translation.t('For 4 people: {currency}{price}', { currency, price: getPriceOfSubProduct(ACTIVE_PRODUCT_KEYS.mealkitCleanKitchenNoodles4) }),
					]}
					useDots
				/>
			</SmallSectionStyled>
		</SingleProductWrapper>
	)
}

const mapStateToProps = createStructuredSelector({
	reservation: selectReservation,
	products: selectProducts,
})

export default connect(mapStateToProps)(CleanKitchenMealkitViewComponent)
