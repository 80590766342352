import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import { ContentMainHeading, MainHeading, Section } from 'Components/view-structure.component'
import Button from '../../../components/ui/button.component'
import CloseButtonIcon from '../../../assets/icons/icon-close.svg'

import { getCurrencyIcon, getProductPriceBreakdownString, getSingleProductPriceTotal } from 'Src/utils'

import { selectCurrentStepData } from 'Redux/app/app.selectors'
import { goToPrevStep } from 'Redux/app/go-to-step.actions'
import { selectDurationOfStay } from 'Redux/reservation/times/times.selectors'
import { selectCurrentProductDetails } from 'Redux/reservation/products/products.selectors'
import { selectReservation } from 'Redux/reservation/reservation.selectors'

import { COLORS, FONT_WEIGHTS, generateMediaQuery } from 'Globals/global.styles'
import { submitAndUpdateProductsStep } from 'Redux/app/app.actions'

import { TranslationContext } from 'Src/context'
import PageView from 'Src/components/PageView'

const CloseButton = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 20px 0 20px 0;

	${generateMediaQuery.mobileSmall(`
		margin: 10px 0 10px 0;
	`)}

	> img {
		height: 20px;
		cursor: pointer;
	}
`

const ImageWrapper = styled.div`
	width: 100%;
	height: 190px;
	margin-bottom: 20px;

	> img {
		width: 400px;
		margin-left: -16px;
		height: 100%;
	}
`

const MainHeadingStyled = styled(MainHeading)`
	${({ lighterHeading }) => !!lighterHeading && (`
		line-height: 32px;
		font-weight: 500;
	`)}
`

const DescriptionSection = styled(Section)`
	${generateMediaQuery.mobileSmall(`
		margin: 10px 0 20px 0;
	`)}
`

const TextContent = styled.div`
	color: ${COLORS.text.secondary};
	font-size: 14px;
`

const PriceSection = styled(Section)`
	margin-top: 30px;
	padding: 16px;
	background-color: ${COLORS.background.secondary};

	${generateMediaQuery.mobileSmall(`
		margin-top: 10px;
	`)}
`

const PriceBreakdownContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: -5px;
`

const DeliveryDateContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 10px;
`

const ProductTotal = styled.span`
	font-weight: ${FONT_WEIGHTS.normal};
`

const ProductTotalPrice = styled.span`
	display: inline-block;
	${({ isFree }) => isFree && css`
		margin-right: 2px;
		text-decoration: line-through;
	`}
`

const FreeProductText = styled.span`
	display: inline-block;
	color: ${COLORS.text.highlight};
`

const CustomProductTotalWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`

const CustomProductTotal = styled.span`
	font-size: 20px;
	font-weight: ${FONT_WEIGHTS.bold};
`

const CustomProductBreakdown = styled.span`
	font-size: 14px;
`

const Footer = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-end;
	padding: 32px 0 32px 0;

	${generateMediaQuery.mobileSmall(`
		padding: 12px 0 12px 0;
	`)}
`

const CancelButton = styled(Button)`
	margin-right: 20px;

	${({ noSpacings }) => noSpacings && css`
		margin-right: 0;
	`}
`

const SingleProductDetails = ({
	currentStepData,
	reservation: {
		adultCount,
		childrenCount,
		property: {
			defaultCurrency,
		},
	},
	goToPrevStep,
	confirmProduct,
	productData,
	productDataKey,
	product,
	durationOfStay,
	title,
	titleAfterImage,
	lighterHeading,
	description,
	image,
	buttonText,
	deliveryDate,
	numberOfProduct,
	hidePrice,
	customTotalPrice,
	priceInsteadOfCancelButton,
	customPriceDescription,
	children,
	isFreeProduct,
	onSubmit,
}) => {
	const translation = useContext(TranslationContext)
	const currency = getCurrencyIcon(defaultCurrency)
	const guestCount = adultCount + childrenCount

	const { productKeys } = currentStepData || {}
	const priceBreakdown = getProductPriceBreakdownString(
		product,
		guestCount,
		durationOfStay,
		!!numberOfProduct,
		translation,
		currency,
	)

	const totalPrice = getSingleProductPriceTotal(
		product,
		guestCount,
		durationOfStay,
		numberOfProduct,
	)?.toFixed(2)

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault()
				confirmProduct(productKeys, productDataKey, productData)

				onSubmit?.()
			}}
		>
			<PageView>
				<CloseButton onClick={goToPrevStep}>
					<img
						src={CloseButtonIcon}
						alt={translation.t('Close {title}', { title })}
					/>
				</CloseButton>

				<DescriptionSection>
					{!titleAfterImage && (
						<MainHeadingStyled lighterHeading={lighterHeading}>
							{title}
						</MainHeadingStyled>
					)}
					{image && (
						<ImageWrapper>
							<img src={image} alt={title} />
						</ImageWrapper>
					)}
					{(image && titleAfterImage) && (
						<MainHeadingStyled lighterHeading={lighterHeading}>
							{title}
						</MainHeadingStyled>
					)}
					<TextContent>
						{description}
					</TextContent>
				</DescriptionSection>

				{children}

				{(!hidePrice && !priceInsteadOfCancelButton) && (
					<PriceSection>
						{deliveryDate ? (
							<>
								<DeliveryDateContainer>
									{translation.t('Delivery Date')}
									<ProductTotal>{deliveryDate}</ProductTotal>
								</DeliveryDateContainer>
								<PriceBreakdownContainer>
									{priceBreakdown}
									<ProductTotal>{`${currency}${totalPrice}`}</ProductTotal>
								</PriceBreakdownContainer>
							</>
						) : (
							<>
								<ContentMainHeading>
									{translation.t('Total Cost')}
								</ContentMainHeading>
								<TextContent>
									<PriceBreakdownContainer>
										{priceBreakdown}
										<ProductTotal>
											<ProductTotalPrice isFree={isFreeProduct}>
												{`${currency}${totalPrice}`}
											</ProductTotalPrice>
											{isFreeProduct && (
												<FreeProductText>{translation.t('Free')}</FreeProductText>
											)}
										</ProductTotal>
									</PriceBreakdownContainer>
								</TextContent>
							</>
						)}
					</PriceSection>
				)}

				<Footer>
					{priceInsteadOfCancelButton ? (
						<CustomProductTotalWrapper>
							<CustomProductTotal>{`${currency}${customTotalPrice.toFixed(2)}`}</CustomProductTotal>
							<CustomProductBreakdown>
								{customPriceDescription || (
									`${translation.t('Total for')} ${durationOfStay} ${translation.t('days')}`
								)}
							</CustomProductBreakdown>
						</CustomProductTotalWrapper>
					) : (
						<CancelButton
							variant={isFreeProduct ? 'primary' : 'transparent'}
							fullWidth={isFreeProduct}
							noSpacings={isFreeProduct}
							onClick={() => goToPrevStep()}
						>
							{translation.t('Cancel')}
						</CancelButton>
					)}

					{!isFreeProduct && (
						<Button variant="primary" type="submit">
							{buttonText}
						</Button>
					)}
				</Footer>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	currentStepData: selectCurrentStepData,
	reservation: selectReservation,
	durationOfStay: selectDurationOfStay,
	product: selectCurrentProductDetails,
})

const mapDispatchToProps = (dispatch) => ({
	confirmProduct: (productKeys, productDataKey, productData) => {
		dispatch(submitAndUpdateProductsStep(productKeys, productDataKey, productData))
		dispatch(goToPrevStep({ withApiPatch: true }))
	},
	goToPrevStep: () => dispatch(goToPrevStep()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SingleProductDetails)
