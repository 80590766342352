import React, { CSSProperties, FC, ReactNode, Ref, useRef, useState } from 'react'
import ReactAsyncSelect from 'react-select/async'
import styled from 'styled-components'

import FieldLabel from './field-label.component'
import HelperText from './field-helper-text.component'

import { COLORS, FONT_WEIGHTS } from '../../globals/global.styles'
import { DebouncedFunc } from 'lodash'
import { OptionsType, ValueType } from 'react-select/src/types'

const Label = styled(FieldLabel)<{ alertModeOn: boolean, isFocused: boolean }>`
	display: inline-block;
	margin-bottom: 2px;
`

const Wrapper = styled('div')<{ alertModeOn: boolean }>``

const reactSelectCustomStyles = {
	control: (
		provided: CSSProperties,
		{ menuIsOpen, isFocused, selectProps: { alertModeOn } }: any,
	) => {
		const styles = {
			...provided,
			height: '40px',
			borderRadius: 0,
			borderColor: COLORS.border.secondary,
			boxShadow: 'none',
			'&:hover': null,
		}

		if (alertModeOn) {
			styles.borderColor = COLORS.border.alert
		} else if (menuIsOpen || isFocused) {
			styles.borderColor = COLORS.border.highlight
		}

		return styles
	},
	option: (provided: CSSProperties, { isFocused, isSelected }: Record<string, boolean>) => {
		const styles: Record<string, any> = {
			...provided,
			fontWeight: FONT_WEIGHTS.light,
			'&:active': {
				color: 'white',
				backgroundColor: COLORS.background.highlight,
			},
		}

		if (isSelected) {
			styles.backgroundColor = COLORS.background.highlight
		} else if (isFocused) {
			styles.backgroundColor = COLORS.background.hover
		}

		return styles
	},
	placeholder: (provided: any) => ({
		...provided,
		paddingTop: '5px',
	}),
}

export interface SelectOption {
	value: string;
	label: string;
}

interface Props {
	value: SelectOption;
	loadOptions: DebouncedFunc<(
			inputValue: string,
			callback: (options: OptionsType<SelectOption>) => void,
		) => Promise<void>>;
	name: string;
	onChange: (value: ValueType<SelectOption, false>) => void
	label?: string;
	cacheOptions?: boolean;
	helperText?: string;
	isValid?: boolean;
	className?: string;
	reactSelectStyles?: CSSProperties;
	loadingMessage?: () => string;
	onInputChange?: (input: string) => void;
	noOptionsMessage?: () => string;
	components?: Record<any, ReactNode>;
	selectRef?: Ref<any>;
}

const AsyncSelect: FC<Props> = ({
	label,
	helperText,
	name,
	isValid,
	className,
	selectRef = useRef(),
	reactSelectStyles,
	onChange,
	cacheOptions,
	loadOptions,
	...otherProps
}) => {
	const [isFocused, setIsFocused] = useState(false)
	const alertModeOn = isValid === false

	const styles: any = {
		...reactSelectCustomStyles,
		...reactSelectStyles,
	}

	return (
		<Wrapper
			className={className}
			alertModeOn={alertModeOn}
			onFocus={() => setIsFocused(true)}
			onBlur={() => setIsFocused(false)}
		>
			{label ? (
				<Label htmlFor={name} alertModeOn={alertModeOn} isFocused={isFocused}>
					{label}
				</Label>
			) : null}
			<ReactAsyncSelect
				ref={selectRef}
				loadOptions={loadOptions}
				name={name}
				inputId={name}
				alertModeOn={alertModeOn}
				isFocused={isFocused}
				openMenuOnFocus
				cacheOptions={cacheOptions}
				onChange={onChange}
				styles={styles}
				{...otherProps}
			/>
			{!!helperText && (
				<HelperText
					alertModeOn={alertModeOn}
					isFocused={isFocused}
					text={helperText}
				/>
			)}
		</Wrapper>
	)
}

export default AsyncSelect
