import React, { FC, useContext } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectReservation } from 'Redux/reservation/reservation.selectors'
import { selectAppState } from 'Redux/app/app.selectors'
import { goToPrevStep, goToStep } from 'Redux/app/go-to-step.actions'
import { ReservationState } from 'Types/reservation-state.types'
import { RootState } from 'Redux/store'
import { HeaderSticky, PrevNextButtonsSticky } from 'Components/view-structure.component'
import PageView from 'Components/PageView'
import { AppState } from 'Types/app-state.types'
import { TranslationContext } from '@/context'
import styled from 'styled-components'
import { removeBusinessLead } from 'Redux/business-lead/business-lead.actions'
import { FONT_WEIGHTS } from 'Globals/global.styles'
import { Medium12, Regular12 } from 'Components/Typography.styled'
import TallinnBobWProperty from 'Assets/bobw-tallinn-kesklinn.webp'

interface StateProps {
	appState: AppState;
	reservation: ReservationState['reservation'];
	isSubmitting?: boolean;
}

interface DispatchProps {
	goToNextStep: (options?: any) => void;
	goToBusinessLeadForm: () => void;
	removeBusinessLead: () => void;
	goToPrevStep: () => void;
}

const ContentWithPadding = styled('div')`
	padding: 4px;
`

const Sections = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 40px;
`

const HeroContainer = styled('div')`
	position: relative;
	height: 300px
`

const HeroImageInfoContainer = styled('div')`
	${Regular12}
	
	position: absolute;
	right: 8px;
	bottom: 8px;
	padding: 12px;
	border-radius: 16px;
	background: #16191D99;
	color: white;
`

const HeroImageInfo = styled('span')`
	${Medium12}
`

const HeroImage = styled('img')`
	width: 100%;
	height: 300px;
`

const Title = styled('h2')`
	font-size: 24px;
	margin: 0;
	font-weight: ${FONT_WEIGHTS.normal};
	text-align: center;
	line-height: 32px;
`

const Stats = styled('div')`
	display: flex;
	justify-content: space-between;
    color: #000;
`

const StatColumn = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
`

const StatTitle = styled('div')`
	font-weight: ${FONT_WEIGHTS.normal};
	font-size: 15px;
`

const StatValue = styled('div')`
	font-weight: ${FONT_WEIGHTS.normal};
	font-size: 20px;
`

const VerticalDivider = styled('div')`
	width: 1px;
	background-color: #DBDFE3;
`

interface BusinessLeadIntroViewProps extends StateProps, DispatchProps { }

const BOB_STATS = {
	countries: 10,
	cities: 17,
	properties: 34,
}

const BusinessLeadIntroView: FC<BusinessLeadIntroViewProps> = ({
	appState: {
		progressData,
	},
	isSubmitting,
	reservation,
	goToPrevStep,
	goToNextStep,
	goToBusinessLeadForm,
	removeBusinessLead,
}) => {
	const translation = useContext(TranslationContext)

	const {
		phone: propertyPhone,
	} = reservation?.property || {}

	const skipToNextStep = () => {
		removeBusinessLead()
		goToNextStep({ toSkipPatch: true })
	}

	const handleClickOnNext = (event: React.FormEvent<any>) => {
		event.preventDefault()
		event.stopPropagation()

		goToBusinessLeadForm()
	}

	const imageInfo = 'Estonia, Tallinn, Bob W Kesklinn'
	const HeroSection = () => (
		<HeroContainer>
			<HeroImage src={TallinnBobWProperty} alt="Property image" loading="lazy" />
			<HeroImageInfoContainer>
				{translation.t('Photo')}
				:&nbsp;
				<HeroImageInfo>{imageInfo}</HeroImageInfo>
			</HeroImageInfoContainer>
		</HeroContainer>
	)

	return (
		<form
			onSubmit={handleClickOnNext}
		>
			<PageView
				Header={() => (
					<HeaderSticky
						{...progressData}
						onBackButtonClick={goToPrevStep}
						contactButton={{ url: `tel:${propertyPhone}` }}
					/>
				)}
				HeroSection={HeroSection}
				FooterSection={() => (
					<PrevNextButtonsSticky
						onPrevClick={goToPrevStep}
						disableNextButton={isSubmitting}
						nextButtonText={translation.t('Interested')}
						hideSkipButton={false}
						onSkipClick={skipToNextStep}
					/>
				)}
			>

				<ContentWithPadding>
					<Sections>
						<Title>
							{translation.t('Bob loves to host business travellers across Europe')}
						</Title>

						<Stats>
							<StatColumn>
								<StatTitle>
									{translation.t('Countries')}
								</StatTitle>
								<StatValue>
									{BOB_STATS.countries}
								</StatValue>

							</StatColumn>

							<VerticalDivider />

							<StatColumn>
								<StatTitle>
									{translation.t('Cities')}
								</StatTitle>
								<StatValue>
									{BOB_STATS.cities}
								</StatValue>
							</StatColumn>

							<VerticalDivider />

							<StatColumn>
								<StatTitle>
									{translation.t('Properties')}
								</StatTitle>
								<StatValue>
									{BOB_STATS.properties}
								</StatValue>
							</StatColumn>
						</Stats>

						<div>
							{translation.t('We\'d love to hook you up with a corporate rate. Get better deals, dedicated support, employee perks discounts, and other extras.')}
						</div>
					</Sections>
				</ContentWithPadding>

			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
	appState: selectAppState,
	reservation: selectReservation,
})

const mapDispatchToProps = (dispatch: (...args: any[]) => void): DispatchProps => ({
	goToNextStep: (options) => dispatch(goToStep(undefined, options)),
	goToBusinessLeadForm: () => dispatch(goToStep({ step: 'business-lead-form' }, { toSkipPatch: true })),
	removeBusinessLead: () => dispatch(removeBusinessLead()),
	goToPrevStep: () => dispatch(goToPrevStep({ resetStepHistoryTip: true })),
})

export default connect(mapStateToProps, mapDispatchToProps)(BusinessLeadIntroView)
