import React, { useContext, useMemo } from 'react'
import type { FC } from 'react'
import { TranslationContext } from '@/context'
import { houseRulesUrl, getPrivacyUrl, getTermsUrl, fireSafetyRulesUrl } from '@/globals/constants'
import { Link } from './ui'
import { H2 } from './Typography.styled'
import { HorizontalDivider, RulesAndAgreementsStyled, Text } from './RulesAndAgreements.styled'
import { makeBookingLanguageUrlParam } from 'Src/utils/language'
import Checkbox from 'Components/base/checkbox/Checkbox'

const termsUrl = getTermsUrl()
const privacyUrl = getPrivacyUrl()

interface Props {
    readonly updateField: (fieldName: string, data: unknown) => void;
    readonly isMarketingConsentGiven: boolean;
    readonly isFireSafetyConsentGiven: boolean;
    readonly isFireSafetyConsentRequired: boolean;
    readonly customerOptions: string[];
    readonly formErrors: string[];
}

const RulesAndAgreements: FC<Props> = ({
	updateField,
	isMarketingConsentGiven,
	isFireSafetyConsentGiven,
	isFireSafetyConsentRequired,
	customerOptions,
	formErrors,
}) => {
	const translation = useContext(TranslationContext)
	const bookingLanguageUrlParam = makeBookingLanguageUrlParam(translation.language)

	const handleMarketingConsentChange = () => {
		updateField('isMarketingConsentGiven', !isMarketingConsentGiven)
	}

	const handleFireSafetyConsentChange = () => {
		updateField('isFireSafetyConsentGiven', !isFireSafetyConsentGiven)
	}

	// Consent checkbox shouldn't be shown in case consent is given earlier
	const isMarketingConsentInitiallyGiven = useMemo(() => {
		return customerOptions?.includes('send-marketing-emails') ?? false
	}, [])

	return (
		<RulesAndAgreementsStyled>
			<HorizontalDivider />

			<H2>{ translation.t('Rules and agreements') }</H2>

			<Text>
				{ translation.t('By proceeding with the check-in, you agree to our') }

				{ ' ' }

				<Link
					href={`${termsUrl}?${bookingLanguageUrlParam}`}
					target="_blank"
					rel="noreferrer"
				>
					{ translation.t('Terms of Use') }
				</Link>

				{ ', ' }

				<Link
					href={`${privacyUrl}?${bookingLanguageUrlParam}`}
					target="_blank"
					rel="noreferrer"
				>
					{ translation.t('Privacy Policy') }
				</Link>

				{ ' ' }

				{ translation.t('and') }

				{ ' ' }

				<Link
					href={`${houseRulesUrl}?${bookingLanguageUrlParam}`}
					target="_blank"
					rel="noreferrer"
				>
					{ translation.t('House Rules') }
				</Link>
			</Text>

			{ isFireSafetyConsentRequired && (
				<Checkbox
					name="fireSafetyConsent"
					value={isFireSafetyConsentGiven}
					label={(
						<Text>
							{ translation.t('I confirm that I have reviewed and understand the') }

							{ ' ' }

							<Link
								href={`${fireSafetyRulesUrl}?${bookingLanguageUrlParam}`}
								target="_blank"
								rel="noreferrer"
							>
								{ translation.t('fire safety rules') }
							</Link>
						</Text>
					)}
					hasError={formErrors?.includes('fireSafetyConsent')}
					onChange={handleFireSafetyConsentChange}
				/>
			)}

			{ !isMarketingConsentInitiallyGiven && (
				<Checkbox
					name="consent-agreement"
					value={isMarketingConsentGiven}
					label={translation.t('I\'d love to receive information about Bob\'s exclusive offers and new locations')}
					onChange={handleMarketingConsentChange}
				/>
			) }
		</RulesAndAgreementsStyled>
	)
}

export default RulesAndAgreements
