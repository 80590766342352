import React, { useContext, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import {
	ContentHeader,
	ContentMainHeading,
	ContentSubHeading,
	FooterSection,
	HeaderSticky,
	Section,
} from 'Components/view-structure.component'
import { Button } from 'Components/ui'

import { getCurrencyIcon, getImageUrlBySequence, getSingleProductPriceTotal } from 'Src/utils'

import { selectAppState, selectIsSubmitting } from 'Redux/app/app.selectors'
import { updateCurrentStepField } from 'Redux/app/app.actions'
import { goToPrevStep, goToStep } from 'Redux/app/go-to-step.actions'
import { selectIsCityTaxRequired, selectReservation } from 'Redux/reservation/reservation.selectors'
import {
	selectArrivalTimeData,
	selectDepartureTimeData,
	selectDurationOfStay,
} from 'Redux/reservation/times/times.selectors'
import {
	selectBreakfastProduct,
	selectProductsAdditionalData,
	selectProductsAmount,
	selectSelectedProductKeys,
	selectSelectedProducts,
	selectUpgradeableRoom,
} from 'Redux/reservation/products/products.selectors'
import { selectCreditCards, selectMainGuestCurrentCreditCard } from 'Redux/reservation/guests/guests.selectors'

import { COLORS, FONT_WEIGHTS } from 'Globals/global.styles'
import config, { analyticsBrand } from 'Globals/constants'

import { checkProductsForAvailability } from 'Redux/reservation/products/products.actions'

import { TranslationContext } from 'Src/context'
import PageView from 'Src/components/PageView'
import SummaryProducts from 'Views/check-in-views/summary-view/summary-products'
import SummaryCreditCardDetails from 'Views/check-in-views/summary-view/summary-credit-card-details'
import { sendCheckoutStepViewedEvent, sendOrderCompletedEvent } from 'Src/utils/analytics'
import { isGuestCardValid } from '@/utils/payment'

const { ACTIVE_PRODUCT_KEYS } = config
const { propertyUnitPaidUpgrade: propertyUnitPaidUpgradeKey } = ACTIVE_PRODUCT_KEYS

const ContentHeaderStyled = styled(ContentHeader)`
	background: ${COLORS.background.primary};
	padding: 16px 0;
`

const TotalSection = styled(Section)`
	margin-bottom: 48px;

	font-size: 20px;
	font-weight: ${FONT_WEIGHTS.normal};

	> span {
		float: right;
	}
`

const PaymentSubHeading = styled(ContentSubHeading)`
	${({ alertModeOn }) => alertModeOn && `
		color: ${COLORS.text.alert};
	`}
`

const PaymentContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const CardAuthError = styled(Section)`
	display: grid;
	padding: 16px;
	row-gap: 8px;
	border-radius: 16px;
	border: 1px solid #EEAAAA;
	background: #F6D5D5;
`

const CardAuthErrorTitle = styled.div`
	font-size: 16px;
	line-height: 24px;
	color: #16191D;
	font-weight: 500;
`

const CardAuthErrorDescription = styled.div`
	font-size: 16px;
	line-height: 24px;
	color: #16191D;
	font-weight: 400;
`

const calculateTotal = (
	products,
	guestCount,
	durationOfStay,
	productsAmount,
	paidUpgradeData,
) => {
	let productsTotal = products
		.reduce((sum, product) => {
			const productCount = productsAmount?.find(
				(prodData) => prodData.productKey === product.productKey,
			)?.amount || 1
			return sum + getSingleProductPriceTotal(product, guestCount, durationOfStay, productCount)
		}, 0)

	if (paidUpgradeData) {
		const { upgradePricePerNight } = paidUpgradeData
		productsTotal += upgradePricePerNight * durationOfStay
	}

	return productsTotal.toFixed(2)
}

const SummaryView = ({
	appState: {
		progressData,
		currentStepFormErrors: formErrors,
	},
	isCityTaxRequired,
	isSubmitting,
	reservation: {
		adultCount,
		childrenCount,
		property: {
			phone: propertyPhone,
			defaultCurrency,
		},
	},
	creditCard,
	creditCards,
	products,
	productsAmount,
	selectedProductKeys,
	breakfastProduct,
	productsAdditionalData,
	upgradeableRooms,
	durationOfStay,
	updateSelectedProductKeys,
	updateProductsWithAvailability,
	goToPrevStep,
	goToNextStep,
	goToAddCard,
}) => {
	const translation = useContext(TranslationContext)
	const guestCount = adultCount + childrenCount
	let paidUpgradeData = null

	if (selectedProductKeys.includes(propertyUnitPaidUpgradeKey)) {
		const selectedRoomId = productsAdditionalData?.find(
			(prodData) => prodData.productKey === propertyUnitPaidUpgradeKey,
		)?.data?.propertyUnitCategoryId || ''
		const upgradePricePerNight = upgradeableRooms.find(
			(room) => room.id === selectedRoomId && room.productKey === propertyUnitPaidUpgradeKey,
		)?.pricePerNight
		paidUpgradeData = {
			upgradePricePerNight,
		}
	}

	const cardId = useMemo(() => {
		const urlParams = new URLSearchParams(window.location.search)

		return urlParams.get('cardId')
	}, [])

	const hasCreditCardAuthError = useMemo(() => {
		if (cardId === null) {
			return false
		}

		return !isGuestCardValid(creditCards, cardId)
	}, [])

	const total = calculateTotal(
		products,
		guestCount,
		durationOfStay,
		productsAmount,
		paidUpgradeData,
	)

	const currencyIcon = getCurrencyIcon(defaultCurrency)

	useEffect(() => {
		updateProductsWithAvailability()
		sendCheckoutStepViewedEvent()
	}, [])

	const handleAddCard = () => {
		if (cardId !== null) {
			// Remove cardId from URL
			const newURL = window.location.href.split('?')[0]
			window.history.pushState('object', document.title, newURL)
		}
		goToAddCard()
	}

	function sendCompletedAnalyticsEvent() {
		const orderedUnitCategoryUpgrageId = productsAdditionalData?.find(
			(prodData) => prodData.productKey === propertyUnitPaidUpgradeKey,
		)?.data?.propertyUnitCategoryId

		const foundUnitCategoryData = upgradeableRooms.find(
			(room) => room.id === orderedUnitCategoryUpgrageId && room.productKey === propertyUnitPaidUpgradeKey,
		)

		if (foundUnitCategoryData !== undefined) {
			const totalPrice = foundUnitCategoryData.pricePerNight * durationOfStay

			const unitUpgradeData = {
				brand: analyticsBrand,
				category: 'Unit Category Upgrade',
				currency: defaultCurrency,
				image_url: getImageUrlBySequence(foundUnitCategoryData.images),
				name: foundUnitCategoryData.name,
				price: foundUnitCategoryData.pricePerNight,
				product_id: foundUnitCategoryData.id,
				quantity: durationOfStay,
				value: totalPrice,
				variant: foundUnitCategoryData.type,
			}

			sendOrderCompletedEvent({
				subtotal: parseFloat(total),
				currency: defaultCurrency,
			}, [unitUpgradeData])
		}
	}

	const showTotalAmountRow = products?.length > 0

	function getPaymentMethodSubheading() {
		if (creditCard && isCityTaxRequired) {
			return translation.t('Your card will be charged before arrival for the selected extra services and city tax.')
		}

		if (creditCard) {
			return translation.t('Your card will be charged before arrival for the selected extra services.')
		}

		if (isCityTaxRequired) {
			return translation.t('Please add a payment card to finish check-in. The card will be charged before arrival for selected extra services and city tax.')
		}

		return translation.t('Please add a payment card to finish check-in. The card will be charged before arrival for selected extra services.')
	}

	const paymentMethodSubheading = getPaymentMethodSubheading()

	const isMissingCreditCard = (parseFloat(total) !== 0 || isCityTaxRequired) && creditCard === null

	const disableNextButton = useMemo(() => {
		return isSubmitting || isMissingCreditCard || hasCreditCardAuthError
	}, [isSubmitting, isMissingCreditCard, hasCreditCardAuthError])

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault()
				goToNextStep()

				sendCompletedAnalyticsEvent()
			}}
		>
			<PageView
				Header={() => (
					<HeaderSticky
						{...progressData}
						onBackButtonClick={goToPrevStep}
						contactButton={{ url: `tel:${propertyPhone}` }}
					/>
				)}
			>
				<ContentHeaderStyled
					mainText={translation.t('Summary')}
					subText={translation.t('Review and confirm to complete your check-in')}
				/>

				<Section>
					<SummaryProducts
						currencyIcon={currencyIcon}
						products={products}
						breakfastProduct={breakfastProduct}
						productsAmount={productsAmount}
						guestCount={guestCount}
						durationOfStay={durationOfStay}
						updateSelectedProductKeys={updateSelectedProductKeys}
						paidUpgradeData={paidUpgradeData}
						isCityTaxRequired={isCityTaxRequired}
					/>
				</Section>

				{showTotalAmountRow && (
					<TotalSection>
						{translation.t('Total')}
						<span>{`${currencyIcon}${total}`}</span>
					</TotalSection>
				)}

				{ hasCreditCardAuthError && (
					<CardAuthError>
						<CardAuthErrorTitle>
							{ translation.t('Payment method cannot be authorised') }
						</CardAuthErrorTitle>
						<CardAuthErrorDescription>
							{ translation.t('The card authorisation is declined or canceled, please try again or try to use different payment card.') }
						</CardAuthErrorDescription>
					</CardAuthError>
				)}

				<Section>
					<ContentMainHeading>{translation.t('Payment method')}</ContentMainHeading>
					<PaymentSubHeading alertModeOn={!!formErrors?.includes('noCreditCard')}>
						{paymentMethodSubheading}
					</PaymentSubHeading>
					<PaymentContent>
						<SummaryCreditCardDetails creditCard={creditCard} />
						{ creditCard === null && (
							<Button
								variant="transparent_with_border"
								onClick={handleAddCard}
							>
								{ translation.t('Add Card') }
							</Button>
						)}
					</PaymentContent>
				</Section>

				<FooterSection
					onPrevClick={goToPrevStep}
					disableNextButton={disableNextButton}
					nextButtonText={translation.t('Finish Check-In')}
				/>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	appState: selectAppState,
	reservation: selectReservation,
	creditCard: selectMainGuestCurrentCreditCard,
	creditCards: selectCreditCards,
	products: selectSelectedProducts,
	breakfastProduct: selectBreakfastProduct,
	selectedProductKeys: selectSelectedProductKeys,
	productsAdditionalData: selectProductsAdditionalData,
	upgradeableRooms: selectUpgradeableRoom,
	durationOfStay: selectDurationOfStay,
	arrivalTimeData: selectArrivalTimeData,
	departureTimeData: selectDepartureTimeData,
	productsAmount: selectProductsAmount,
	isCityTaxRequired: selectIsCityTaxRequired,
	isSubmitting: selectIsSubmitting,
})

const mapDispatchToProps = (dispatch) => ({
	updateSelectedProductKeys: (productKey, subProductKeys = []) => dispatch(
		updateCurrentStepField('products', [productKey, ...subProductKeys.filter((key) => key !== productKey)]),
	),
	updateProductsWithAvailability: () => dispatch(checkProductsForAvailability()),
	goToAddCard: () => dispatch(goToStep({ step: 'add-card' }, { toSkipPatch: true })),
	goToNextStep: () => dispatch(goToStep()),
	goToPrevStep: () => dispatch(goToPrevStep()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SummaryView)
