import React, { useState } from 'react'
import styled from 'styled-components'

import FieldLabel from './field-label.component'
import Button from './button.component'

const Label = styled(FieldLabel)`
	display: inline-block;
	margin-bottom: 4px;
`

const Wrapper = styled.div``

const BlocksContainer = styled.div`
	margin: -16px 0 0 -16px;
`

const Block = styled.span`
	display: inline-block;
	padding: 16px 0 0 16px;
`

class MultiValuesBlocks extends React.Component {
	constructor(props) {
		super(props)
		this.state = { values: props.values }
	}

	onBlockClicked(opt) {
		let { values } = this.state
		values = values || []
		const { onChange } = this.props

		if (this.isOptionSelected(opt)) {
			for (let i = 0; i < values.length; i++) {
				if (values[i].value === opt.value) {
					values.splice(i, 1)
				}
			}
		} else {
			values.push(opt)
		}

		onChange(values)
		this.setState({ values })
	}

	isOptionSelected(opt) {
		const { values } = this.state
		return !!values?.find((valuesOpt) => valuesOpt.value === opt.value)
	}

	render() {
		const { options } = this.props
		return options.map((opt) => (
			<Block key={opt.value}>
				<Button
					variant={this.isOptionSelected(opt) ? 'default' : 'transparent_with_light_border'}
					disabled={opt.isDisabled}
					onClick={() => this.onBlockClicked(opt)}
				>
					{opt.label}
				</Button>
			</Block>
		))
	}
}

const SingleValueBlocks = ({
	options,
	value: initialValue,
	onChange,
}) => {
	const [value, setValue] = useState(initialValue)

	return options.map((opt) => (
		<Block key={opt.value}>
			<Button
				variant={value && opt.value === value.value ? 'default' : 'transparent_with_light_border'}
				disabled={opt.isDisabled}
				onClick={() => {
					onChange(opt)
					setValue(opt)
				}}
			>
				{opt.label}
			</Button>
		</Block>
	))
}

const BlockSelector = ({
	className,
	isMulti,
	isValid,
	label,
	value,
	...otherProps
}) => {
	const [isFocused, setIsFocused] = useState(false)
	const alertModeOn = isValid === false

	return (
		<Wrapper
			className={className}
			onFocus={() => setIsFocused(true)}
			onBlur={() => setIsFocused(false)}
		>
			{label ? (
				<Label alertModeOn={alertModeOn} isFocused={isFocused}>
					{label}
				</Label>
			) : null}

			<BlocksContainer>
				{isMulti ? (
					<MultiValuesBlocks values={value} {...otherProps} />
				) : (
					<SingleValueBlocks value={value} {...otherProps} />
				)}
			</BlocksContainer>
		</Wrapper>
	)
}

export default BlockSelector
